import React, { useEffect, useMemo, useState } from "react";
import { SearchResultCard } from "../SearchResultCard/SearchResultCard";
import { LimitedSearchResultCard } from "../SearchResultCard/LimitedSearchResultCard";
import Box from "@mui/material/Box";
import axios from "axios";
import { getUrl } from "../../requests";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Avatar, Link } from "@mui/material";
import { Button } from "@mui/material";
import { ImageScroller } from "../Atoms/ImageScroller";
import { Helmet } from 'react-helmet-async';
import cameraImg from "../../images/camera.png";


export const PartnersList = (session, apikey, city) => {
  const [partners, setPartners] = useState([]);
  const navigate = useNavigate();

  const memoPartners = useMemo(() => partners.filter(el => true))

  const [pagingOffset, setPagingOffset] = useState(0);
  const [pagingLimit, setPagingLimit] = useState(10);

  const fetchData = (newPaggingOffset = 0) => {
    const latitude = localStorage.getItem("lat");
    const longitude = localStorage.getItem("lng");
    const body = {
      browserGeographicCoordinates: {
        latitude: latitude,
        longitude: longitude,
      },
      limit: pagingLimit,
      offset: newPaggingOffset,
    };
    axios
      .post(`${getUrl()}/api/v1/partners`, body, {
        headers: {},
      })
      .then((res) => {
        const data = res.data;
        setPartners([...partners, ...data]);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  /** 
  const onChange = ({ coords }) => {
    fetchData(coords.latitude.toFixed(2), coords.longitude.toFixed(2));
  };
*/
  const onIncrasePagingLimit = (newPagingLimit) => {
    setPagingLimit(newPagingLimit);
    const newPagingOffset = pagingOffset + newPagingLimit;
    setPagingOffset(newPagingOffset);
    fetchData(newPagingOffset);
  };

  const onDecreasePagingLimit = () => {
    const tmpPaggingOffset = pagingOffset > 9 ? pagingOffset - pagingLimit : 0;
    const tmpPartners = partners.slice(0, tmpPaggingOffset + pagingLimit);
    setPagingOffset(tmpPaggingOffset);
    setPartners(tmpPartners);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const makeServices = (firm) => {
    const lServices = [];
    const fiberInternetList = firm.products.filter(
      (el) => el.productCategory === "Internet światłowodowy"
    );
    const mobileInternetList = firm.products.filter(
      (el) => el.productCategory === "Internet mobilny"
    );
    const wirelessInternetList = firm.products.filter(
      (el) => el.productCategory === "Internet radiowy"
    );
    const TvList = firm.products.filter(
      (el) => el.productCategory === "Telewizja"
    );
    const phoneList = firm.products.filter(
      (el) => el.productCategory === "Telefonia"
    );
    const mobilePhoneList = firm.products.filter(
      (el) => el.productCategory === "Telefonia mobilna"
    );
    const tvPacketsList = firm.products.filter(
      (el) => el.productCategory === "Pakiety TV"
    );
    const otherList = firm.products.filter(
      (el) => el.productCategory === "Inne"
    );
    if (fiberInternetList) {
      lServices.push({
        label: "Internet światłowodowy",
        info: fiberInternetList,
        other: "",
      });
    }

    if (wirelessInternetList) {
      lServices.push({
        label: "Internet radiowy",
        info: wirelessInternetList,
        other: "",
      });
    }

    if (mobileInternetList) {
      lServices.push({
        label: "Internet mobilny",
        info: mobileInternetList,
        other: "",
      });
    }

    if (TvList) {
      lServices.push({
        label: "Telewizja",
        info: TvList,
        other: "",
      });
    }

    if (tvPacketsList) {
      lServices.push({
        label: "Pakiety TV",
        info: tvPacketsList,
        other: "",
      });
    }

    if (phoneList) {
      lServices.push({
        label: "Telefon domowy",
        info: phoneList,
        other: "",
      });
    }

    if (mobilePhoneList) {
      lServices.push({
        label: "Telefon komórkowy",
        info: mobilePhoneList,
        other: "",
      });
    }

    if (otherList) {
      lServices.push({
        label: "Inne",
        info: otherList,
        other: "",
      });
    }

    return lServices;
  };

  return (
    <>
      <Helmet>
        <title>O nas - lista porównywarek - Lokalni.pl
        </title>
        <meta name="description" content=" Lista partnerów telekomunikacyjnych - cena, promocje, porównanie ofert -
            Znajdź najlepsze usługi od operatorów telekomunikacyjnych z Twojej
            okolicy!" />
      </Helmet>
      <Box
        //backgroundColor="#2B46AF"
        height="100%"
        width="100%"
        id="partnersList"
        sx={{
          display: "flex",

          [Theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            p: "3vw",
            gap: "16px",
          },

          [Theme.breakpoints.up("tablet")]: {
            flexDirection: "column",
            p: "5vw",
            gap: "24px",
          },
          [Theme.breakpoints.up("laptop")]: {
            flexDirection: "row",
            padding: "80px",
            justifyContent: "space-evenly",
            //alignItems: "space-between",
          },
          [Theme.breakpoints.up("desktop")]: {
            flexDirection: "row",
            padding: "80px",
            justifyContent: "space-evenly",
          },
        }}
      >
        <h1 style={{ display: 'none' }}>Lokalni operatorzy telekomunikacyjni, dostawcy internetu</h1>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ThemeProvider theme={Theme}>
            <Box
              id="manifest"
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: "44px",
                //justifyContent: "space-evenly",
                [Theme.breakpoints.up("laptop")]: {
                  width: "50vW",
                  //height: "565px",
                },
              }}
            >
              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    textAlign: "justify",
                  },
                }}
              >
                Świat pędzi, ludzie się spieszą, czas leci. Rozwój technologii,
                nowe możliwości, alternatywne rozwiązania - coś niesamowitego.
                <font> ALE…</font>
              </Typography>

              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    textAlign: "justify",
                  },
                }}
                display="block"
              >
                W tym pośpiechu zapominamy często o tym, co{" "}
                <font> najważniejsze</font> - o nas samych, o{" "}
                <font> ludziach</font>. Szukamy zamienników, zbywamy nasze
                potrzeby, coraz mniej rozmawiamy - nie komunikujemy się.{" "}
                <font> A komunikacja to podstawa. </font>
              </Typography>
              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    textAlign: "justify",
                  },
                }}
                display="block"
              >
                Kim są Lokalni? Łączymy małych i średnich operatorów
                telekomunikacyjnych -<font> to lokalni dostawcy Internetu.</font>{" "}
                Lokalni, czyli jacy? Działamy na terenie Twojego miejsca
                zamieszkania lub pracy, dzięki czemu zagwarantować Ci świetne
                łącze.
                <br />
              </Typography>

              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    mb: "16px",
                    textAlign: "justify",
                  },
                }}
                display="block"
              >
                Kiedy masz problem - dzwonisz, a odbiera nasza Magda:{" "}
                <font>Twoja sąsiadka</font>, ta, co zawsze uśmiecha się, gdy
                spotykasz ją w warzywniaku, która wszędzie chodzi ze swoim psem z
                seledynową obrożą. Magda rozmawia z Tobą i{" "}
                <font>słucha Twoich potrzeb</font>. Nigdy nie czekasz w długiej
                kolejce na infolinii. Kończysz rozmowę ze spokojem i wiedzą, co
                dalej.
              </Typography>
              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    mb: "16px",
                    textAlign: "justify",
                  },
                }}
                display="block"
              >
                Potem od razu przyjeżdża Krzysiek - nasz inżynier, pewnie też go
                gdzieś widziałeś, wysoki, gęste wąsy, zawsze koszula w kratę. Jest
                u Ciebie szybko, bo jest <font>blisko Ciebie</font>, więc po
                prostu wsiada do auta i do Ciebie przyjeżdża. I rozwiązuje twój
                problem.
              </Typography>
              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    mb: "16px",
                  },
                  textAlign: "justify",
                }}
                display="block"
              >
                Jesteśmy operatorami telekomunikacyjnymi, ale nie chcemy być
                postrzegani jak reszta w tej kategorii. Wiemy, że się wyróżniamy
                ponieważ działamy <font>zupełnie inaczej</font>. Kiedy wszyscy
                automatyzują, utrudniają kontakt z człowiekiem, my robimy wręcz
                odwrotnie. Dlaczego? Bo chcemy <font>łączyć ludzi</font>.
              </Typography>

              <Typography
                variant="bodyMediumRegural"
                fontSize="14px"
                lineHeight={1.29}
                letterSpacing={0.13}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                    textAlign: "justify",
                  },
                }}
                display="block"
              >
                Wierzymy w siłę lokalności, dlatego <font>wspieramy</font> różne
                inicjatywy lokalne, wspieramy{" "}
                <font>polskich przedsiębiorców</font> - wspieramy naszą
                gospodarkę, nas - <font>Polaków</font>. Internet może być pojęciem
                dość abstrakcyjnym. Ale Internet u Lokalnych jest inny.{" "}
                <font>Internet po ludzku</font>.
              </Typography>
            </Box>
          </ThemeProvider>
          <Box sx={{}}>
            {memoPartners.map((firm, idx) => (
              <Box key={idx} sx={{ mb: 2 }}>
                {firm?.visibilityLevel > 0 ? (
                  //<Link onClick={() => navigate(`/company/${firm?.seoLink}`)} component="button">
                  <SearchResultCard
                    key={idx}
                    providerLogo={firm.companyLogoUrl? firm.companyLogoUrl : cameraImg}
                    logoUrl={firm?.companyLogoUrl}
                    difrentiatorLogoUrl={firm?.photo}
                    providerName={`${firm?.companyName ? firm?.companyName : firm?.ePid
                      }`}
                    phoneNumber={firm?.phone}
                    address={`${firm?.companyAddressCity ? firm?.companyAddressCity : ""} ${firm?.companyAddressStreet ? firm?.companyAddressStreet : ""} ${firm?.companyAddressBuildNumber ? firm?.companyAddressBuildNumber : ""}`}
                    email={firm?.contactEmail}
                    companyDescription={firm?.companyDifferentiators}
                    goodPionts={`${firm?.pointsOfGood}`}
                    infos2={[firm?.distance, firm?.distanceToCompanyHQKm]}
                    services={makeServices(firm)}
                    infos={[
                      firm?.distanceToCompanyHQKm
                        ? `Biuro ${firm?.distanceToCompanyHQKm.toFixed(
                          0
                        )} km od wybranego adresu`
                        : "",
                      (firm?.distance * 1000).toFixed(0) < 40
                        ? "Przyłącze jest w twojej lokalizacji"
                        : `Przyłącze - ${Math.round(
                          Math.round((firm?.distance * 1000).toFixed(0) / 10) *
                          10
                        )}m`,
                    ]}
                    showModalMapHandler={() => { }}
                    redirectToDetailsHandler={() => { }}
                    providerId={`${firm?.seoLink}`}
                    ref={partners[`${firm?.id}`]}
                    specialProducts={firm.metadata ? firm.metadata.filter(el => el.rowId === 33).map(el => ({
                      koalaTel: el.booleanValue1 ? el.booleanValue1 : false, kameleonTV: el.booleanValue2 ? el.booleanValue2 : false,
                    })) : ""}
                    seoLink={firm?.seoLink}
                  />
                  //</Link>
                ) : (
                  <LimitedSearchResultCard
                    providerName={`${firm?.companyName ? firm?.companyName : firm?.ePid
                      }`}
                    address={`${firm?.companyAddressCity} ${firm?.companyAddressStreet} ${firm?.companyAddressBuildNumber}`}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "Flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => {
                onIncrasePagingLimit(10);
              }}
              sx={{
                ...Theme.OrangeButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
            >
              Pokaż więcej
            </Button>
            <Button
              onClick={() => {
                onDecreasePagingLimit();
              }}
              sx={{
                ...Theme.OrangeButton,
                alignSelf: "flex-end",
                mt: "32px",
              }}
              disabled={pagingOffset > 9 ? false : true}
            >
              Pokaż mniej
            </Button>
          </Box>
        </Box>
        <ImageScroller />

      </Box>
    </>
  );
};
