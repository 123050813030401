import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "@fontsource/lato";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FormControlLabel from "@mui/material/FormControlLabel";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SelectItem = ({
  selectedItem,
  options,
  availOptions,
  changeHandler = null,
}) => {
  const [itemState, setItemState] = useState(selectedItem);

  const handleChange = (event) => {
    setItemState({
      label: event.target.name,
      value: event.target.value,
    });
    changeHandler(event.target.value)
  };

  const selectStyle = {
    width: "300px",
    height: "44px",
    borderRadius: "45px",
    backgroundColor: "#fff",
    boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
    //".MuiOutlinedInput-notchedOutline": { border: 2 },
   
   // ".MuiSelect-iconOutlined": { fontSize: 36, color: "#363636" },
   "& .MuiMenuItem-root.Mui-selected": {
    backgroundColor: "yellow"
  },
  "& .MuiMenuItem-root.Mui-selected:hover": {
    backgroundColor: "red"
  },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
    
   
  };

  return (
    <FormControl>
      <ThemeProvider theme={Theme}>
        <Select
        
          labelId="demo-simple-select-label"
          id="hourFrom"
          variant="outlined"
          name="hourFrom"
          value={itemState.value}
          label={itemState.label}
          onChange={(event) => handleChange(event)}
          inputProps={{}}
          IconComponent={ExpandMoreIcon}
          sx={selectStyle}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "#a9ce50",
                  opacity:0.5,
                },
                "& .MuiMenuItem-root:hover": {
                  //backgroundColor: "#9d9d9d"
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  //backgroundColor: "#9d9d9d"
                },
                borderRadius:"20px"
              }
            }
          }}
          
        >
          {availOptions.map((item, i) => {
            return (
              <MenuItem value={item.value}>
                <Typography variant="body1">
                  {item.label}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </ThemeProvider>
    </FormControl>
  );
};
