import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { StatusBox } from "./StatusBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import { IconMenu } from "./IconMenu";
//import { makeStyles } from "@material-ui/core/styles";

export const GoodListItemAdmin = ({
  goodName,
  date,
  location,
  goodPionts,
  status,
  clickaccept,
  onClickReject,
  onClickEdit,
  onClickHistory,
  onClickPreview,
  rejectDisabled = false,

  itemList,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        // justifyContent: "space-around",
        // justifyContent: "space-evenly",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
        // bgcolor: 'magenta'
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box
          sx={{
            // bgcolor: 'black',
            ml: "24px",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#f2f2f2",
              width: 44,
              height: 44,
            }}
          >
            <MapOutlinedIcon
              sx={{
                fontSize: 26,
                color: "#363636",
              }}
            />
          </Avatar>
        </Box>
        <Box
          sx={{
            ml: "2vw",
            // bgcolor: 'yellow',
            width: "30%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              // bgcolor: 'blue',
              width: "100%",
            }}
          >
            <Typography variant="bodyMediumRegural">Dobro</Typography>
            <Typography variant="bodyMediumBlack">{goodName}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: status === "Oczekuje na weryfikację" ? "5%" : 0,
            ml: "1%",
            // bgcolor: 'red'
          }}
        >
          {status === "Oczekuje na weryfikację" && (
            <Box
              sx={{
                // width: 58,
                width: "100%",
                height: 25,
                borderRadius: "16px",
                backgroundColor: "#2bfece",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                ml: "2%",
              }}
            >
              <Typography variant="bodyMediumRegural" fontWeight="bold">
                Nowy
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            // ml: '2%',
            width: "7vw",
          }}
        >
          <Typography variant="bodyMediumRegural">Data</Typography>
          <Typography variant="bodyMediumBlack">{date}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural" noWrap>
              Lokalizacja
            </Typography>
            <Typography variant="bodyMediumBlack" noWrap>
              {location}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "8px",
              width: "5%",
            }}
          >
            <Typography variant="bodyMediumRegural">Pkt</Typography>
            <Typography variant="bodyMediumBlack">{goodPionts}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "13%",
            }}
          >
            <Typography variant="bodyMediumRegural">Status</Typography>
            <Box
              sx={{
                // width:'5vw',
                width: "fit-content",
              }}
            >
              <StatusBox statusMessage={status} />
            </Box>
          </Box>
          <Box>
            {status.match(/Oczekuje na weryf/) ||
            status.match(/W trakcie weryf/) ? (
              <Box
                sx={{
                  width: "12vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ml: "24px",
                }}
              >
                <Button
                  onClick={() => {
                    clickaccept();
                  }}
                  sx={Theme.outlinedAcceptButton}
                >
                  Akceptuj
                </Button>
                <Button
                  onClick={onClickReject}
                  disabled={rejectDisabled}
                  sx={Theme.outlinedCancelButton}
                >
                  Odrzuć
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  mr: "24px",
                }}
              >
                <IconMenu
                  onClickHistory={onClickHistory}
                  optionSelected={onClickEdit}
                  list={itemList}
                  onClickPreview={() => {
                    onClickPreview();
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
