import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";

export const ShortInfoList = ({ header, list=null, imageUrl=null, }) => {
  return (
    <Box
      id="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box id="listHeader" sx={{ mb: "16px" }}>
          <Typography variant="body1" textTransform="uppercase">
            {header}
          </Typography>
        </Box>
        {imageUrl && (
           <Avatar
           sx={{
             width:"150px",
             height:"auto",
             borderColor: "#f2f2f2",
             borderRadius: 3,
             mb:8,
           }}
           alt="Logo"
           src={imageUrl}
         ></Avatar>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item, i) => (
            <Box
              sx={{
                
                display: "flex",
                flexDirection: "row",
                mb: "8px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box sx={{ width: "45%", display: "flex", flexDirection: "row" }}>
                <Box>
                  <Typography variant="bodyMediumRegural" color="#9d9d9d">
                    {item.label}
                  </Typography>
                </Box>

                <Divider
                  flexItem={true}
                  sx={{ flexGrow: 1, ml: "5px", mr: "5px" }}
                ></Divider>
              </Box>

              <Box sx={{ width: "55%" }}>
                {" "}
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                >
                  {item.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </ThemeProvider>
    </Box>
  );
};
ShortInfoList.propTypes = {
  header: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};
