import React, { useState } from "react";
import axios from "axios";
import { ButtonsFooter } from "../Atoms/ButtonFooter";
//import { InputListCardContainer } from "../InputListCard/InputListCardContainer";
import { InputSelectListCardContainer } from "../InputSelectListCard/InputSelectListCardContainer";

export const withEditableClientTypes = (
  Component,
  profileId,
  initialClientTypes,
  authToken,
  urlApi,
  authEpid,
  cancelActionHandler,
  refreshProfile
) => {
  return (props) => {
    const config = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
    };
    const [originalServices, setOriginalServices] =
      useState(initialClientTypes);
    const [clientTypes, setServices] = useState(initialClientTypes);

    const onChangeServices = (changes) => {
      setServices({ ...clientTypes, ...changes });
    };

    const onSaveServices = async () => {
      const convertedServices = {
        id: profileId,
        ePid: authEpid,
        ...(clientTypes["Klient indywidualny"] !== undefined && {
          b2c: clientTypes["Klient indywidualny"],
        }),
        ...(clientTypes["Klient biznesowy"] !== undefined && {
          b2b: clientTypes["Klient biznesowy"],
        }),
        ...(clientTypes["Instytucje rządowe"] !== undefined && {
          institutions: clientTypes["Instytucje rządowe"],
        }),
      };
      const result = await axios.patch(
        urlApi,
        { ...convertedServices },
        config
      );
      refreshProfile();
      cancelActionHandler();
    };

    const onReset = () => {
      setServices(originalServices);
    };

    return (
      <InputSelectListCardContainer
        cardName="Edycja typu odbiorcy"
        inputListCard1={
          <Component
            header="Typ klienta"
            objectToUpdate={clientTypes}
            onChangeServices={onChangeServices}
          />
        }
        buttonsFooter={
          <ButtonsFooter
            saveActionHandler={onSaveServices}
            cancelActionHandler={cancelActionHandler}
          />
        }
      />
    );
  };
};
