import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Theme } from "../styles/styles";
import { Box, Checkbox, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";


export const AcceptConsent = ({ label, checked, handleChange }) => {

    

    

    return (
        <Box
            sx={{
                display: "flex",
                //justifyContent: "space-around",
                alignItems:"center",
                //width: "100%"

            }}
        >
            <ThemeProvider theme={Theme}>
                <Checkbox 
                checked={checked}
                onChange={handleChange}
                sx={{alignSelf:"flex-start"}}
                
                />
                <Typography variant="bodyMediumRegural" color="#FE1919" sx={{fontSize:"20px"}}>
                    *
                </Typography>
                <Typography variant="bodyMediumRegural" sx={{ml:"8px"}}>
                    {label}
                </Typography>
            </ThemeProvider>

        </Box>
    )
}