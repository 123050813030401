import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Button, Modal, Typography } from "@mui/material";
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { useState, useEffect } from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ContactMailForm } from "../Lokalni2/ContactMailForm";

import { getUrl } from "../../requests";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

export const CompanyInfoCard = ({
    goodPionts,
    logoImg,
    companyName = "Brak informacji",
    companyDescription = "Brak informacji",
    profileId,
    companyInfoOnClickButtonAction,
    
    clickButtonHandler,

}) => {

    const [profileData, setProfileData] = useState([]);

    
    const navigate = useNavigate()

    const { seolink } = useParams();

   

   

    return (
        <ThemeProvider theme={Theme}>
            <Box
                id="mainBox"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#FFFFFF",
                    p: "24px",
                    justifyContent: "flex-start",
                    [Theme.breakpoints.down("tablet")]: {
                        width: "95vw",
                        minHeight: "547px"
                    },
                    [Theme.breakpoints.up("tablet")]: {
                        width: "30vw",
                        minHeight: "723px",
                        borderRadius: "16px"
                    },
                    [Theme.breakpoints.up("laptop")]: {
                        width: "22vw",
                    },
                    [Theme.breakpoints.up("desktop")]: {
                        width: "18vw",
                    },

                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        [Theme.breakpoints.up("tablet")]: {
                            alignSelf: "flex-end"
                        },
                    }}>
                    <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27, mr: "8px" }}>
                        <FavoriteIcon
                            sx={{
                                fontSize: 14,
                                color: "#fff",
                            }}
                        />
                    </Avatar>
                    <Typography variant="subtitle"

                    >{goodPionts}
                    </Typography>

                </Box>
                <Box
                    id="Line_Separator"
                    sx={{ borderBottom: 2, borderColor: "#e3e3e3", width: "100%", mb: "16px", mt: "16px" }}
                ></Box>
                <Avatar variant="rounded"
                    src={logoImg}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fff",
                        borderRadius: "12px",
                        [Theme.breakpoints.down("tablet")]: {
                            width: "52%",
                            height: "auto",

                        },
                        [Theme.breakpoints.up("tablet")]: {
                            width: "30.5%",

                        },
                        [Theme.breakpoints.up("laptop")]: {
                            width: "23.8%",


                        },
                        [Theme.breakpoints.up("desktop")]: {
                            width: "25.6%",

                        },
                        height: "auto",
                    }}
                >
                    {!logoImg &&
                        <NoPhotographyOutlinedIcon
                            sx={{
                                fontSize: "80px",
                                color: "#363636"

                            }}
                        />
                    }
                </Avatar>
                <Box
                    id="Line_Separator"
                    sx={{ borderBottom: 2, borderColor: "#e3e3e3", width: "100%", mb: "16px", mt: "16px" }}
                ></Box>
                <Typography variant="bodyVeryLargeBlack" sx={{ fontSize: "26px" }}

                >{companyName}
                </Typography>

                <Box
                    id="Line_Separator"
                    sx={{ borderBottom: 2, borderColor: "#e3e3e3", width: "100%", mb: "16px", mt: "32px" }}
                ></Box>
                <Typography variant="subtitle" sx={{ alignSelf: "flex-start", mb: "16px" }}

                >O nas
                </Typography>
                <Typography variant="bodySmallRegural" sx={{ alignSelf: "flex-start", fontSize: "12px", mb: "16px" }}

                >{companyDescription}
                </Typography>

                
                <Button
                    onClick={companyInfoOnClickButtonAction}
                    variant="outlined"
                    sx={{
                        justifySelf: "flex-end",

                        ...Theme.outlinedCancelGrayButton,
                        backgroundColor: "#F2F3F5",
                        //[Theme.breakpoints.down("laptop")]: {
                        fontSize: 12,
                        width: "199px",
                        height: "38px",
                        borderRadius: "13px",
                        border: 0,
                        mt: "auto"
                        //},

                    }}
                    endIcon={<KeyboardArrowRightIcon sx={{}} />}
                >
                    Kontakt
                </Button>

                

                

            </Box>
            
        </ThemeProvider>

    );
};


