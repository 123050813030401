import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, Modal } from "@mui/material";
import { useState } from "react";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import ClearIcon from "@mui/icons-material/Clear";
import { LabelLeftInput } from "../Atoms/LabelLeftInput";


export const ContactMailForm = ({
    headerText1="Skontaktuj się z nami",
    info="Prześlij nam zapytanie, a chętnie na nie odpowiemy",
    topicLabel="Temat emaila",
    emailTextLabel="Wiadomość",
    onSaveButton,
    onCancel,
}) => {
    const [emailText, setEmailText] = useState("");
    const [topic, setTopic] = useState("");
    const [email, setEmail] = useState(undefined);
    const [isEmailValidated, setIsEmailValidated] = useState(false);
 
   
    useEffect(() => {
      console.log(`email: ${email}`)
    }, [email]);
  

    return (
        <Box
            sx={{
                bgcolor: "white",
                [Theme.breakpoints.down("tablet")]: {
                    width: "82.8vw",
                    padding: "2px",
                    pb: "8px",
                    pt: "8px",
                },
                [Theme.breakpoints.up("tablet")]: {
                    width: "43vw",
                    //height: "950px",
                    padding: 3,
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: "35.2vw",
                    //height: "830px",
                    padding: 3,
                },
                [Theme.breakpoints.up("desktop")]: {
                    width: "23.4vw",
                    padding: 3,
                },
                borderRadius: 4,

                boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    [Theme.breakpoints.down("tablet")]: {
                        gap: 3,
                    },
                    [Theme.breakpoints.up("tablet")]: {
                        gap: 3,
                    },

                    p: 2,
                }}
            >
                <ThemeProvider theme={Theme}>
                    <Box>
                        <Typography
                            variant="subtitle"
                            lineHeight={0.83}
                            letterSpacing={0.16}
                        >
                            {headerText1}
                        </Typography>


                    </Box>


                    <Box>
                        <Typography variant="bodyMediumRegural" lineHeight={1.43}>
                            {info}
                        </Typography>
                    </Box>
                    
                    <LabelLeftInput label="Twój email" placeholderText="np. joanna@lokalni.pl" name="email" changeHandler={(obj) => setEmail(obj.email)} type="email" checkIsEmailValidated={(prop) => setIsEmailValidated(prop)} />
                    <LabelLeftInput label="Temat" placeholderText="np. Zapytanie o ofertę" name="topic" changeHandler={(obj) => setTopic(obj.topic)} />
                    
                    <InputIconTextItem
                        getValue={setEmailText}
                        label={emailTextLabel}
                        text={emailText}
                        customWidth= {"100%"}
                        customHeight= "284px"
                        multiLine={true}
                        //customWidth={0.9}
                        placeholder="Wpisz tekst emaila....."
                        icon={emailText ? <ClearIcon /> : null}
                    />
                    
                    {/** 
                    <InputIconTextItem
                        getValue={setEmail}
                        label={"Podaj swój email"}
                        customWidth={0.9}
                        placeholder={"joanna@lokalni.com"}
                        type={"email"}
                        
                        checkIsEmailValidated={(prop) => setIsEmailValidated(prop)}
                    />
                    */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignSelf: "flex-end",
                        }}
                    >
                        <Box
                            sx={{
                                [Theme.breakpoints.down("tablet")]: {
                                    mr: "2px"
                                },
                                [Theme.breakpoints.up("tablet")]: {
                                    mr: "8px"
                                },
                                [Theme.breakpoints.up("laptop")]: {
                                    mr: "16px"
                                },

                            }}>
                            <Button
                                onClick={() => {
                                    onCancel();
                                }}
                                sx={Theme.outlinedOrangeButton}
                            >
                                Anuluj
                            </Button>
                        </Box>
                        
                            <Button
                                onClick={() => {
                                    onSaveButton(topic, emailText, email);
                                }}
                                sx={Theme.OrangeButton}
                                //disabled={isEmailValidated ? false : true}
                            >
                                Wyślij
                            </Button>
                     
                    </Box>
                    
                    
                </ThemeProvider>
            </Box>

        </Box>
    );
};

ContactMailForm.propTypes = {
    nameLabel: PropTypes.string,
    nameValue: PropTypes.string,
    addressLabel: PropTypes.string,
    addressValue: PropTypes.string,
    phoneNumberLabel: PropTypes.string,
    phoneNumberValue: PropTypes.string,
    headerText: PropTypes.string,
    info: PropTypes.string,
    contactSelectLabel: PropTypes.string,
    footerText: PropTypes.string,
};
