import * as React from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button, FormGroup, Grow, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { InputIconTextItem } from "../../Components/Atoms/InputIconTextItem";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//import "dayjs/locale/pl";
import dayjs from "dayjs";

import { useCallback, useEffect, useState } from "react";
import { AutoSuggest } from "../AutoSuggests";
import MapContainer from "../UI/Map/MapContainer";
export const AddGoodForm = ({
  topLabel,
  topText,
  titleLabel,
  titleText,
  titlePlaceHolder,
  locationPlaceHolder,
  descriptionPlaceHolder,
  locationLabel,
  locationText,
  dateLabel,
  dateText,
  descriptionLabel,
  descriptionText,
  picture,
  disableVerifyButton,
  disableSaveButton,
  top = "50%",
  left = "50%",
  transform = "translate(-50%, -50%)",
  position = "absolute",
  cancelAction = () => { },
  saveAction = () => { },
  sendToVerifyAction = () => { },
  titleAction = () => { },
  locationAction = () => { },
  descriptionAction = () => { },
  dateValue,
  handleChangeDate,
  goodTitleError,
  goodLocationError,
  goodDescriptionError,
  helperText,
  searchByIdHandler,
  enteredValue,
  formSubmitHandler,
  sessionid,
  lat,
  lon,
  isValid,
  apikey,
  searchResults,
  references,
  city,
  modapMap = true,
}) => {
  const [locale, setLocale] = React.useState("pl");
  const validDateValue = dateValue ? dayjs(dateValue) : null;
  const [title, setTitle] = useState("");
  const [locationPlace, setlocationPlace] = useState("");
  const [goodDescription, setgoodDescription] = useState("");
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const mapStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "24px",
  }

  useEffect(() => {
    setTitle(titleText);
    setlocationPlace(locationText);
    setgoodDescription(descriptionText);
    locationAction(enteredValue);
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const thumbs = files.map((file) => (
   
    <Avatar
      variant="rounded"
      sx={{
        width: "13vw",
        height: "13vw",
      }}
      alt="Logo"
      src={file?.preview}
    ></Avatar>
  ));

  const InternalDateIcon = () => {
    return (
      <CalendarMonthOutlinedIcon
        sx={{
          fontSize: 20,
          color: "#363636",
          backgroundColor: "#2bfece",
        }}
      />
    );
  };
  return (
    <Box
      id="mainBox"
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "56.4%",
        minHeight: 700,
        p: "40px 60px 40px",
        borderRadius: "24px",
        boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-around",
        top: top,
        left: left,
        transform: transform,
        position: position,
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box id="topLabel">
          <Typography variant="subtitle">{topLabel}</Typography>
        </Box>
        <Box id="topText" sx={{ width: "50%" }}>
          <Typography variant="bodySmallBlack">{topText}</Typography>
        </Box>
        <Box
          id="mainContent"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            height: 500,
          }}
        >
          <Box
            id="mapAndImage"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",

              height: "100%",
            }}
          >
            <Box
              id="mapBox"
              sx={{
                width: "13vw",
                height: "13vw",
              }}
            >

              <MapContainer
                lat={lat}
                lon={lon}
                companies={searchResults}
                references={references}
                apikey={apikey}
                modal={modapMap}
                zoom={10}
                place={{
                  geographicCoordinates: {
                    lat: lat,
                    lng: lon,
                  },
                  companyName: enteredValue ? enteredValue : city,
                }}
                mapStyles= {mapStyles}
              />

              {/*</Avatar>*/}
            </Box>
            <Box id="imageTitle">
              <Typography variant="body1">Zdjęcie</Typography>
            </Box>
            <Box
              id="image"
              sx={{
                width: "13vw",
                height: "13vw",
              }}
            >
              {/*<Avatar*/}
              {/*  variant="rounded"*/}
              {/*  sx={{*/}
              {/*    width: "13vw",*/}
              {/*    height: "13vw",*/}
              {/*  }}*/}
              {/*  alt="Logo"*/}
              {/*  src={picture}*/}
              {/*></Avatar>*/}
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Upuść plik, lub kliknij</p>
                </div>
                {files?.length > 0 ? (
                  <aside>{thumbs}</aside>
                ) : (
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "13vw",
                      height: "13vw",
                    }}
                    alt="Logo"
                    src={picture}
                  ></Avatar>
                )}
              </section>
            </Box>
          </Box>
          <Box
            id="inputFields"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <InputIconTextItem
              id="title"
              label={titleLabel}
              text={titleText}
              placeholder={titlePlaceHolder}
              getValue={titleAction}
              icon={null}
              customWidth="35.2vw"
              error={goodTitleError}
              helperText={helperText}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >

              <AutoSuggest
                name="search"
                handleChange={searchByIdHandler}
                value={enteredValue}
                sessionid={sessionid}
                lat={lat}
                lon={lon}
                onClick={formSubmitHandler}
                valid={isValid}
                position="fixed"
                zindex={10}
                debounceTime={500}
                top="40%"
              />
              {!isValid && (
                <span style={{ color: "red" }}>
                  Wprowadzone dane są niepoprawne!
                </span>
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
                <DesktopDatePicker
                  label="Data dobra"
                  inputFormat="MM/DD/YYYY"
                  value={validDateValue}
                  onChange={(val) => handleChangeDate(val)}
                  renderInput={(params) => <TextField {...params} />}
                  components={{
                    OpenPickerIcon: InternalDateIcon,
                  }}
                />
              </LocalizationProvider>
            </Box>
            <InputIconTextItem
              id="description"
              label={descriptionLabel}
              text={descriptionText}
              placeholder={descriptionPlaceHolder}
              icon={null}
              customWidth="35.2vw"
              customHeight="284px"
              multiLine={true}
              getValue={descriptionAction}
              error={goodDescriptionError}
              helperText={helperText}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "65%",
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            gap: 14,
          }}
        >
          <Button
            onClick={cancelAction}
            variant="outlined"
            sx={{
              ...Theme.outlinedCancelGrayButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
                width: 90,
              },
            }}
          >
            Anuluj
          </Button>
          <Button
            disabled={disableSaveButton}
            onClick={(event) => {
              saveAction(event, files ? files : []);
            }}
            variant="outlined"
            sx={{
              ...Theme.outlinedAcceptBlackButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
                width: 90,
              },
            }}
          >
            Zapisz
          </Button>
          <Box>
            {disableVerifyButton ? (
              <Button
                disabled={disableSaveButton}
                onClick={(event) => {
                  saveAction(event, files ? files : []);
                }}
                //variant="outlined"
                sx={{
                  ...Theme.OrangeButton,
                  [Theme.breakpoints.down("tablet")]: {
                    width: "145px"
                  },
                  [Theme.breakpoints.up("tablet")]: {
                    width: "151px"
                  },
                  [Theme.breakpoints.up("laptop")]: {
                    width: "166px"
                  },

                }}
              >
                Akceptuj
              </Button>
            ) :
              <Button
                //disabled={disableVerifyButton}
                onClick={() => {
                  sendToVerifyAction();
                }}
                variant="outlined"
                sx={{
                  display: { disableVerifyButton },
                  ...Theme.containedGreenButton,
                  [Theme.breakpoints.down("laptop")]: {
                    fontSize: 12,
                  },
                }}
              >
                Wyślij do weryfikacji
              </Button>
            }
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
