import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import React from "react";
import ClearIcon from '@mui/icons-material/Clear';

export const SearchClearIcon = ({ color, ...props }) => {
    return (
        <>
            <Avatar sx={{bgcolor: "white", width: '0.5em', height: '0.5em', mr:'8px'}}>
                <ClearIcon
                    sx={{
                        fontSize: 16,
                        color: "#363636",
                    }}
                />
            </Avatar>
        </>
    );
};

// eslint-disable-next-line react/no-typos
SearchClearIcon.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func,
};