import React, { useEffect, useState } from "react";
import { AutoSuggestOptions } from "./AutoSuggestOptions.js";
import useOnClickOutside from "../CustomHooks/useOnClickOutside";
import { SearchIconButton } from "../SearchIconButton/SearchIconButton";
import { TextField } from "@mui/material";
import "../AutocompleteSearch/autoCompleteInput.css";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { SearchClearIcon } from "../SearchClearIcon/SearchClearIcon";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "black",
    backgroundColor: "white",
    disableUnderline: "false",
    borderRadius: "24px",
  },
  root: {
    disableUnderline: "true",
    backgroundColor: "white",
    color: "black",
    borderRadius: "24px",
  },
  root_bad: {
    disableUnderline: "true",
    backgroundColor: "white",
    color: "red",
    borderRadius: "24px",
  },
  floatingLabelFocusStyle: {
    color: "black",
    "&.Mui-focused": {
      color: "#000000",
    },
  },
  closeIcon: {
    border: "1px solid transparent",
    backgroundColor: "white",
    display: "inline-block",
    verticalAlign: "middle",
    outline: "0",
    cursor: "pointer",
    position: "relative",
    padding: "10px",

    "&:after": {
      content: "X",
      display: "block",
      width: "15px",
      height: "15px",
      position: "absolute",
      backgroundColor: "#FA9595",
      Zindex: "400",
      right: "35px",
      top: "0",
      bottom: "0",
      margin: "auto",
      padding: "2px",
      borderRadius: "50%",
      textAlign: "center",
      color: "black",
      fontWeight: "normal",
      fontSize: "12px",
      boxShadow: "0 0 2px #E50F0F",
      cursor: "pointer",
    },
  },
}));

export const AutoSuggestContainer = React.forwardRef(
  (
    {
      name,
      options,
      error = false,
      setSearchText,
      searchText = "",
      openListbox,
      setOpenListbox,
      clearText,
      noResult = false,
      styles,
      loading = false,
      setLoading = () => {},
      dataType,
      activeDescendant,
      setActiveDescendant,
      disabled,
      valid,
      onClick,
      labelInputSearch = "Lokalizacja",
      inputSearchPlaceHolder = "wpisz nazwę miejscowości, ulicy, lub jej fragment",
      position = null,
      zindex = null,
      top = null,
    },
    inputRef
  ) => {
    const keys = {
      ESC: 27,
      TAB: 9,
      RETURN: 13,
      UP: 38,
      DOWN: 40,
    };

    const suggestionRef = React.createRef();

    useOnClickOutside(suggestionRef, () => setOpenListbox(false));
    const [showClear, setShowClear] = useState(false);

    useEffect(() => {
      if (searchText.trim() !== "") {
        setShowClear(true);
      }
      if (searchText.trim() === "") {
        setShowClear(false);
      }
    }, [searchText]);

    const doSearch = (event) => {
      inputRef.current.focus();

      setSearchText(event.target.value, event.target.id);
      setOpenListbox(true);
    };

    const copyTextRemoveSuggestions = (id, value) => {
      // setSearchText(event.target.innerText,event.target.id);
      setSearchText(value, id);
      setActiveDescendant(undefined);
      setOpenListbox(false);
      setLoading(false);
      // localStorage.setItem("lastSearch",event.target.innerText);
      localStorage.setItem("lastSearch", value);
    };
    const doKeyPress = (event) => {
      let highlighted =
        suggestionRef.current &&
        [...suggestionRef.current.children].find(
          (node) => node.id === activeDescendant
        );
      switch (event.which) {
        case keys.ESC:
          setOpenListbox(false);
          setLoading(false);
          setActiveDescendant(undefined);
          clearText();
          break;

        case keys.TAB:
        case keys.RETURN:
          event.preventDefault();
          event.stopPropagation();

          setOpenListbox(false);
          setLoading(false);
          // setActiveDescendant(undefined);

          if (highlighted) {
            event.preventDefault();
            event.stopPropagation();
            return selectOptions(highlighted);
          } else {
            event.preventDefault();
            event.stopPropagation();
            onClick(event);
            return;
          }
          break;

        case keys.UP:
          event.preventDefault();
          event.stopPropagation();
          return moveUp(highlighted);

        case keys.DOWN:
          event.preventDefault();
          event.stopPropagation();
          return moveDown(highlighted);

        default:
          return;
      }
    };

    const moveUp = (highlighted) => {
      if (!openListbox) return;
      let current = highlighted;
      if (current && current.previousElementSibling) {
        let prev = current.previousElementSibling;
        prev && setActiveDescendant(prev.id);
        highlighted = false;
      } else {
        current = suggestionRef.current.lastChild;
        setActiveDescendant(current.id);
      }
    };

    const moveDown = (highlighted) => {
      if (!openListbox) return;
      let current = highlighted;
      if (current && current.nextElementSibling) {
        let next = current.nextElementSibling;
        next && setActiveDescendant(next.id);
        highlighted = false;
      } else {
        current = suggestionRef.current.firstChild;
        setActiveDescendant(current.id);
      }
    };
    const selectOptions = (highlighted) => {
      let text = highlighted.getAttribute("textvalue");
      let local_id = highlighted.getAttribute("id");

      setSearchText(text, local_id);
    };
    const classes = useStyles();

    return (
      <>
        <>
          <div className={valid ? "Rectangle-2876" : "Rectangle-2876_bad"}>
            <span style={{ flexGrow: "5" }}>
              {/*<div className="-Input-text-1">Lokalizacja</div>*/}
              <div>
                {/*<input*/}
                {/*    id={name}*/}
                {/*    ref={inputRef}*/}
                {/*    className="-Input-text-2"*/}
                {/*    type="text"*/}
                {/*    value={searchText}*/}
                {/*    onChange={doSearch}*/}
                {/*    onKeyDown={doKeyPress}*/}
                {/*    disabled={disabled ? true : false}*/}
                {/*    autoComplete="off"*/}
                {/*/>*/}
                {/*className: `${classes.input} ${classes.root} ${classes.disableUnderline}`*/}

                <TextField
                  label={labelInputSearch}
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    className: `${classes.input}`,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  inputProps={{
                    className: `${classes.input}  ${
                      valid ? classes.root : classes.root_bad
                    }`,
                  }}
                  id={name}
                  ref={inputRef}
                  className="-Input-text-2"
                  type="text"
                  value={searchText}
                  onChange={doSearch}
                  onKeyDown={doKeyPress}
                  disabled={disabled ? true : false}
                  autoComplete="off"
                  error={!valid}
                  placeholder={inputSearchPlaceHolder}
                />
              </div>
            </span>

            <Box
              type="clear"
              onClick={() => {
                clearText();
                document.getElementById("search").focus();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                zIndex: "10000",
              }}
            >
              <SearchClearIcon />
            </Box>

            <Box
              type="submit"
              onClick={onClick}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <SearchIconButton type="submit" />
            </Box>
          </div>
        </>
        <div
          id={`${name}-autocomplete`}
          style={
            styles.suggestionsContainer
              ? {
                  ...styles.suggestionsContainer,
                  display: openListbox ? "block" : "none",
                  width: inputRef.current
                    ? `${inputRef.current.offsetWidth + 150}px`
                    : "80%",
                  position:
                    position === "fixed"
                      ? position
                      : styles.suggestionsContainer.position,
                  zIndex: zindex ? zindex : styles.suggestionsContainer.zIndex,
                  top: top ? top : styles.suggestionsContainer.top,
                }
              : { display: openListbox ? "block" : "none" }
          }
        >
          {openListbox && options.length > 0 && (
            <AutoSuggestOptions
              ref={suggestionRef}
              id={`${name}-autosuggest-options`}
              options={options}
              // onClick={(e) => copyTextRemoveSuggestions(e)}
              onClick={copyTextRemoveSuggestions}
              styles={styles}
              name={name}
              selected={activeDescendant}
            />
          )}
        </div>
        {/*{error && <p>Wyniki nie mogą być pobrane {error} </p>}*/}
        {/*</div>*/}
        {/*{noResult && <p>Nie znaleziono wyników</p>}*/}
      </>
    );
  }
);
