import React from "react";
import PropTypes from "prop-types";
import "./autoCompleteListItem.css";
import Avatar from "@mui/material/Avatar";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ListItemAvatar from "@mui/material/ListItemAvatar";
export const AutoCompleteAddressListItem = ({
  address,
  numOfChars,
  id,
  styles,
  value,
  selected,
  onClick,
}) => {
  const firstString = address ? address.slice(0, numOfChars) : " ";
  const lastString = address ? address.slice(numOfChars) : " ";

  return (
    <div key={`li${id}`}>
      <span id={id} className="-Input-text">
        <span>
          <ListItemAvatar
            sx={{ display: "flex", justifyContent: "start", padding: "0px" }}
          >
            <Avatar
              sx={{
                bgcolor: "#2bfece",
                width: 25,
                height: 28,
                marginRight: "16px",
              }}
            >
              <FmdGoodOutlinedIcon
                sx={{
                  fontSize: 16,
                  color: "#363636",
                }}
              />
            </Avatar>
            <span>
              <span className="text-style-1">{firstString}</span>
              {lastString}
            </span>
          </ListItemAvatar>
        </span>
        {/*<span className="Path-1230"><MdRoom /></span>*/}
        {/*<span className="text-style-1">{firstString}</span>*/}
        {/*{lastString}*/}
      </span>
    </div>
  );
};

AutoCompleteAddressListItem.propTypes = {
  address: PropTypes.string,
  numOfChars: PropTypes.number,
};

// AutoCompleteAddressListItem.defaultProps = {
//   address: "Katowice Mariacka 36",
//   numOfChars: 4,
// };
