import React, { useState } from "react";
import axios from "axios";
import { ContactForm } from "../Lokalni2/ContactForm";
import { NewLeadForm } from "./NewLeadForm";
import { create } from 'zustand'

export const withEditableForm = (
  searchId,
  leadFormUrl,
  operatorsCount,
  formatedAddress,
  formSubmited,
  cancelButton
) => {
  return (props) => {

    const onSaveLeadForm = (reqBody) => {
      const body = {
        searchId: searchId,
        name: reqBody.name,
        address: reqBody.address,
        contactPhone: reqBody.contactPhone,
        emailAddress: reqBody.emailAddress,
        internet: reqBody.internet,
        tv: reqBody.tv,
        tel: reqBody.tel,
        biz: reqBody.biz,
        companyName: reqBody.companyName,
        nip: reqBody.nip,
        service: reqBody.service,
      };
      axios
        .post(leadFormUrl, body, {
          headers: {
            accept: "*/*",
          },
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          formSubmited(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const conditionsLink = "https://example.com/regulamin"; // Przykładowy link
    const privacyPolicyLink = "https://example.com/polityka-prywatnosci"; // Przykładowy link

    const updatedConditionsConsentText = (
      <span>
        {`Wyrażam zgodę na udostępnienie moich danych lokalnym operatorom telekomunikacyjnym, przygotowanie przez nich oferty dla mnie i kontakt ze mną. Oświadczam, że znam `}
        <a href={conditionsLink} target="_blank" rel="noopener noreferrer">
          Regulamin
        </a>
        {` korzystania z Usług Portalu Lokalni.pl, w tym zasady przetwarzania danych osobowych opublikowane w `}
        <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
          Polityce prywatności
        </a>
        {`, akceptuję ich treść i przyjmuję do stosowania.`}
      </span>
    );

    return (
      <NewLeadForm
        address={formatedAddress}
        radioList={[
          { label: "Internet", value: "internet", name:"internet" },
          { label: "Telewizja", value: "telewizja", name:"tv" },
          { label: "Telefon", value: "telefon", name:"tel"  },
          { label: "Prowadzę firmę", value: "firma", name:"biz" },
        ]}
        piDataConsentText="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w Formularzu w celu rozpatrzenia mojego zgłoszenia."
        //conditionsConsentText={`Wyrażam zgodę na udostępnienie moich danych lokalnym operatorom telekomunikacyjnym, przygotowanie przez nich oferty dla mnie i kontakt ze mną. Oświadczam, że znam Regulamin (link) korzystania z Usług Portalu Lokalni.pl, w tym zasady przetwarzania danych osobowych opublikowanie w Polityce prywatności (link), akceptuję ich treść i przyjmuję do stosowania. `}
        conditionsConsentText={updatedConditionsConsentText}
        marketingConsentText="Wyrażam zgodę na otrzymywanie informacji handlowych od lokalnych operatorów telekomunikacyjnych w celu marketingu bezpośredniego."
        numOfOperators={operatorsCount}
        onCancel={cancelButton}
        onSave={onSaveLeadForm}
        
      />
     
    );
  };
};
