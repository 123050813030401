import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { Theme } from "../styles/styles";
import Box from '@mui/material/Box';
import { ThemeProvider } from '@emotion/react';
import { Typography } from '@mui/material';
import { debounce } from "@mui/material";

export const LabelLeftInput = ({ label, name, placeholderText, isEmail, isPhone = false, checkIsEmailValidated, checkIsPhoneValidated, changeHandler, inputText="", type = "text", }) => {

    const [isValidEmail, setIsValidEmail] = React.useState(true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState(true);

    const [inputValue, setInputValue] = React.useState(inputText);

    const validateEmail = (email) => {
        // Regular expression to validate email addresses
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression to validate Polish phone numbers (9 digits)
        //const phoneRegex = /^(?:(?:(\+|00)48)|(?:0))?(?:(61|62|63|65|67|68|69|71|74|75|76|77|81|82|83|84|85|86|87|89)[1-9]\d{6}|[1-9]\d{8})$/;
        const phoneRegex =
            /^(?:(?:(\+|00)48)|(?:0))?\s?(?:(61|62|63|65|67|68|69|71|74|75|76|77|81|82|83|84|85|86|87|89))?\s?[1-9]\d{2}\s?\d{3}\s?\d{3}$/;
        return phoneRegex.test(phoneNumber);
    };


    const handleChange = (event) => {
        const tmpValue = event.target.value;
        isEmail && setIsValidEmail(validateEmail(tmpValue));
        isPhone && setIsValidPhoneNumber(validatePhoneNumber(tmpValue));
        changeHandler({[event.target.name]: event.target.value});
        setInputValue(tmpValue);
    };

    React.useEffect(() => {
        isEmail && checkIsEmailValidated(isValidEmail ? true : false);
        isPhone && checkIsPhoneValidated(isValidPhoneNumber ? true : false);
    }, [isValidPhoneNumber, isValidEmail]);

    return (
        <Box sx={{display:"flex", flexDirection:"column", width:"100%"}}>
            <Box sx={{
                display: "flex", width: "100%",


            }}>
                <Paper
                    sx={{
                        p: '8px 8px', display: 'flex', alignItems: 'center', justifyContent: "center",
                        backgroundColor: "#F2F3F5", width: "30%", border: "1px solid #0000001A", boxShadow: "0px", borderRadius: "16px", borderBottomRightRadius: 0, borderTopRightRadius: 0,

                        [Theme.breakpoints.down("tablet")]: {
                            height: "39px",
                        },
                        [Theme.breakpoints.up("tablet")]: {
                            height: "39px",
                        },
                    }}
                >

                    <ThemeProvider theme={Theme}>
                        <Typography variant="bodyMediumRegural"
                            sx={{
                                [Theme.breakpoints.down("laptop")]: {
                                    fontSize: "15px",
                                    lineHeight: "21px",
                                },
                                [Theme.breakpoints.up("laptop")]: {
                                    fontSize: "16px",
                                    lineHeight: "27px",
                                },
                            }}
                        >{label}</Typography>
                    </ThemeProvider>

                </Paper>
                <Paper
                    component="form"

                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "70%", borderRadius: "16px", borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                >

                    <InputBase
                        sx={{
                            ml: 1, flex: 1, fontFamily: "Lato",

                            color: "#363636",
                            [Theme.breakpoints.down("laptop")]: {
                                fontSize: "15px",
                                lineHeight: "21px",
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                fontSize: "18px",
                                lineHeight: "27px",
                            },
                        }}
                        placeholder={placeholderText}
                        inputProps={{ 'aria-label': 'lalal' }}
                        onChange={(event) => debounce(handleChange(event), 1000)}
                        name={name}
                        value={inputValue}
                        type={type}
                    />

                </Paper>

            </Box>
            <Box sx={{alignSelf:"center"}}>
                <Typography variant="bodyMediumRegural" marginTop={-17} color="#db2251">
                    {!isValidEmail && isEmail ? "Nieprawidłowy adres email !! " : ""}
                </Typography>
                <Typography variant="bodyMediumRegural" marginTop={-17} color="#db2251">
                    {!isValidPhoneNumber && isPhone
                        ? "Nieprawidłowy numer telefonu !! "
                        : ""}
                </Typography>
            </Box>
        </Box>


    );
}