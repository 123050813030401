import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
//import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarsIcon from '@mui/icons-material/Stars';

const chipColors = ["#ba1fa5", "#fff"]

const IconListItem = ({ header, text, iconColor }) => {
    return (
        <Box
            sx={{
                width: "89%",
                display: "flex",
                backgroundColor: "#2944ac",
                justifyContent: "space-around",
                alignItems:"center",
                minHeight: "94px",
                borderRadius:"16px",
                pl:"17px",
                pt:"17px",
                pb:"17px",
                //alignItems:"flex-start",
                [Theme.breakpoints.up("tablet")]: {
                    width: "92%",
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: "96%",
                },
            }}
        >
            <StarsIcon sx={{ fontSize: 30, color: iconColor, borderColor:"#fff", }} />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
            }}>
                <ThemeProvider theme={Theme} >
                    <Typography variant="bodyMediumBlack"
                        sx={{
                            lineHeight: 1.07,
                            letterSpacing: 0.13,
                            color: "#fff",
                            width:"82%",
                            mb:"8px",
                            

                        }}
                    >{header}</Typography>
                    <Typography variant="bodyMediumRegural:"
                        sx={{
                            lineHeight: 1.21,
                            letterSpacing: 0.13,
                            color: "#fff",
                            width:"82%",
                            

                        }}
                    >{text}</Typography>
                </ThemeProvider>

            </Box>
        </Box>
    );

}


export const TriviasBox = ({ header, textList }) => {

    return (
        <Box
            sx={{
                display: "flex",
                borderRadius: "24px",
                //opacity: 0.09,
                boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
                backgroundColor: "#4B70F5",
                //backgroundColor: "#2a45ad",
                flexDirection: "column",
                //padding: "20px 19px 22px 15px",
                [Theme.breakpoints.down("tablet")]: {
                    
                    width: "81vw",
                    p: "16px",


                },
                [Theme.breakpoints.up("tablet")]: {
                    //flexDirection: "row",
                    width: "89.5vw",
                    p: "24px",
                   // justifyContent: "space-around"

                },
                [Theme.breakpoints.up("laptop")]: {
                    //flexDirection: "row",
                    width: "87.5vw",
                    p: "24px",
                   // justifyContent: "space-around"

                },
                [Theme.breakpoints.up("desktop")]: {
                    width: "76vw",
                },
               
            }}
        >
            <ThemeProvider theme={Theme} >
                <Typography variant="subtitle1"
                    sx={{
                        color: "#fff",
                        mb: "18px",
                        

                    }}
                >{header}</Typography>
            </ThemeProvider>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                {textList
                    .map((filtredItem, i) => (
                        <Box marginBottom="10px">
                            <IconListItem
                                header={filtredItem.header}
                                text={filtredItem.text}
                                iconColor={chipColors[(i%2)]}
                            />
                        </Box>

                    ))}

            </Box>
        </Box>

    )

}