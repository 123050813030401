import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { Avatar, Button, Link, Typography } from "@mui/material";
import img1 from "../../images/marketing/L1.jpg";
import img2 from "../../images/marketing/L2.jpg";
import img3 from "../../images/marketing/L3.jpg";
import img4 from "../../images/marketing/L4.jpg";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";

export const BeforeLogin = () => {
    const [imageURL, setImageURL] = useState(img1);
    const [imageIdx, setImageIdx] = useState(0);
    const images = [img1, img2, img3, img4];

    useEffect(() => {
        const timer = setTimeout(() => {
            setImageURL(imageIdx < 3 ? images[imageIdx + 1] : images[0]);
            setImageIdx(imageIdx < 3 ? imageIdx + 1 : 0);
            console.log(imageIdx);
            console.log("Initial timeout!");
        }, 7000);
        return () => clearTimeout(timer);
    }, [imageIdx]);
    const navigate = useNavigate();

    return (
        <Box

            height="100vh"
            width="100%"
            id="partnersList"
            sx={{
                display: "flex",
                flexDirection: "column",

                [Theme.breakpoints.down("tablet")]: {


                },

                [Theme.breakpoints.up("tablet")]: {

                },
                [Theme.breakpoints.up("laptop")]: {

                    p: "0px",

                },
                [Theme.breakpoints.up("desktop")]: {

                    p: "0px",

                },
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                <Avatar
                    id="mContent"
                    alt="marketing"
                    src={imageURL}
                    variant="square"
                    sx={{
                        width: "100%",

                        [Theme.breakpoints.down("tablet")]: {

                            height: "auto",
                        },
                        [Theme.breakpoints.up("tablet")]: {

                            height: "auto",

                        },
                        [Theme.breakpoints.up("desktop")]: {

                            height: "auto",

                        },
                    }}

                />
            </Box>
            <Box

                sx={{
                    display: "flex",
                    flexDirection: "column",
                    //height: "100vw",
                    bgcolor: "#fff",
                    alignItems: "center",
                    justifyContent: "space-evenly"

                }}
                flexGrow={1}
            >
                <ThemeProvider theme={Theme}>
                    <Typography
                        variant="bodyVeryLargeBlack"
                        textAlign="center"
                        sx={{
                            [Theme.breakpoints.down("tablet")]: {
                                //width: 0,
                                width: "298px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "588px"
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                width: "908px"
                            },
                        }}
                    >
                        Logowanie do portalu Lokalni dostępne jest wyłącznie dla MiŚOT
                        przez personalny identyfikator w systemie EPID
                    </Typography>

                    <Button
                        onClick={() => {
                            navigate(`/zaplecze/`)
                        }}
                        sx={{
                            ...Theme.OrangeButton,
                            backgroundColor: "#18a75c",
                            border: 0,
                            width: 226,
                        }}
                    >
                        Zaloguj mnie
                    </Button>
                    <Typography
                        variant="bodyMediumRegural"
                        textAlign="center"
                        sx={{
                            [Theme.breakpoints.down("tablet")]: {
                                //width: 0,
                                width: "222px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "514px"
                            },

                        }}
                    >
                        Jeżeli nie posiadasz identyfikatora EPID,
                        możesz bezpłatnie zarejestrować się <Link href="https://panel.epid.pl/epid-member-panel/registration.html" color="inherit" target="new" > tutaj</Link>
                    </Typography>
                </ThemeProvider>

            </Box>

        </Box>
    );
};
