import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getUrl } from "../../requests";
import classes from "../../pages/Search/Search.module.css";
import axios from "axios";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import useHttp from "../CustomHooks/use-http";
import { useLocation, useNavigate } from "react-router-dom";

import { withEditableForm } from "../NewLeadForm/withEditableForm";
import { MessageBox } from "../Lokalni2/MessageBox";
import Popup from "../UI/Popup/Popup";
import { Circles } from "react-loader-spinner";
//import { NewLeadForm } from "../NewLeadForm/NewLeadForm";

import { Helmet } from 'react-helmet-async';
const containerStyle = {
  height: "100%",
  width: "100%",
  borderRadius: "24px",
};

const exampleMapStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        hue: "#ffff00",
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];



function SearchView(props) {
  const [inprogress, SetInProgress] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResult, setSearchResult] = useState(0);
  const { name, id } = useParams();
  const httpData = useHttp();
  const location = useLocation();
  const { isLoading, error, sendRequest: fetchData } = httpData;
  const navigate = useNavigate();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const inprogressMsg = "Pracuję...";

  const center = {
    lat: location.state.lat,
    lng: location.state.lng,
  };

  const formatedAddress = location.state.formatedAddress;

  useEffect(() => {
    const body = {
      searchByGooglePlaceId: {
        sessionId: props.sessionid,
        googlePlaceId: `${id}`,
      },
      freeTextQuery: "",
      browserGeographicCoordinates: {
        latitude: 0,
        longitude: 0,
      },
    };

    axios
      .post(`${getUrl()}/api/v2/rfs`, body, {
        headers: {
          accept: "*/*",
        },
      })
      .then((res) => {
        const data = res.data;
        setSearchResult(data);
        SetInProgress(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const LeadForm = withEditableForm(
    searchResult.searchId,
    `${getUrl()}/api/v2/rfs/offerRequest`,
    searchResult.numberOfHits,
    formatedAddress,
    setIsFormSubmited,
    () => navigate("/szukaj")
  );

  const appCont = document.querySelector("#appcontainer");
  if (appCont) {
    appCont.className = classes.Container;
  }

  if (searchResults.length === 0 || !searchResults) {
    if (appCont) {
      appCont.className = classes.Container_empty;
    } else {
      navigate(`/szukaj/`);
    }
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Wyniki wyszukiwania - porównywarka ofert internetu i telewizji,
          specjalizująca się w lokalnych dostawcach. Znajdź najlepsze usługi od
          operatorów z Twojej okolicy!" />
      </Helmet>
      <Box sx={{ maxHeight: "100hw" }}>
        {!inprogress ? (
          <Box
            sx={{
              //bgcolor: "#2B46AF",
              width: "100vw",

              [Theme.breakpoints.down("tablet")]: {
                padding: "3vw",
              },

              [Theme.breakpoints.up("tablet")]: {
                padding: "40px",
              },
              [Theme.breakpoints.up("laptop")]: {
                padding: "80px",
              },
              [Theme.breakpoints.up("desktop")]: {
                padding: "80px",
              },
            }}
          >
            {searchResult.numberOfHits > 0 ? (
              !isFormSubmited ? (
                <Box
                  sx={{
                    display: "flex",
                    [Theme.breakpoints.down("tablet")]: {
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    },
                    [Theme.breakpoints.up("tablet")]: {
                      flexDirection: "row",
                      justifyContent: "center",
                    },

                    //boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <LeadForm /> 
                
                  
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    [Theme.breakpoints.down("tablet")]: {
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    },
                    [Theme.breakpoints.up("tablet")]: {
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "16px",
                    },
                    [Theme.breakpoints.up("laptop")]: {
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "flex-start",
                      gap: "16px",
                    },
                  }}
                >
                  <MessageBox
                    headerText="Twoje zgłoszenie zostało przesłane do operatora"
                    info=""
                    onNextButton={() => navigate("/szukaj")}
                  />


                </Box>
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  [Theme.breakpoints.down("tablet")]: {
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  },
                  [Theme.breakpoints.up("tablet")]: {
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  },
                  [Theme.breakpoints.up("laptop")]: {
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    gap: "16px",
                    mr: -20,
                  },
                }}
              >
                <MessageBox
                  headerText="Niestety nie znaleźliśmy operatora w Twojej okolicy"
                  info="Prosimy o kontakt z nami w późniejszym terminie"
                  onNextButton={() => navigate("/szukaj")}
                />


              </Box>
            )}
          </Box>
        ) : (
          <Popup
            title={inprogressMsg}
            openPopup={inprogress}
            message={inprogressMsg}
            disabledButton={true}
            setOpenPopup={() => {
              SetInProgress(false);
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "20%",
                justifyContent: "center",
              }}
            >
              <Circles
                height="50"
                width="50"
                radius="9"
                color="#2bfece"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            </Box>
          </Popup>
        )}
        
      </Box>
    </>
  );
}

export default SearchView;
