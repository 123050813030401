import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { StatusBox } from "./StatusBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { IconMenu } from "./IconMenu";
import { Button } from "@mui/material";

export const LeadListItem = ({
  isReviewer = false,
  misot = "Brak",
  date,
  location,
  handleTime,
  status,
  note,
  onClickEdit,
  onClickHistory,
  onClickPreview,
  onClickStatusChange,
  onClieckLeadView,
  onClickNotesView,
  onClickDelete,
  handleAcceptLead,
  handleRejectLead,
  showvertIcon = true,

  itemList,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {showvertIcon && (
          <Box
            sx={{
              // bgcolor: 'black',
              ml: "16px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#f2f2f2",
                width: 44,
                height: 44,
              }}
            >
              <MapOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            ml: "2%",
            width: "10%",
          }}
        >
          <Typography variant="bodyMediumRegural">Data</Typography>
          <Typography variant="bodyMediumBlack">{date}</Typography>
        </Box>
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "20%",
              
            }}
          >
            <Typography variant="bodyMediumRegural" noWrap>
              Adres instalacji
            </Typography>
            <Typography variant="bodyMediumBlack" sx={{width:"200px", flexWrap:"wrap"}}>
              {location}
            </Typography>
          </Box>
          
        <Box
          sx={{
            display: "flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <Typography variant="bodyMediumRegural">Czas realizacji</Typography>
            <Typography variant="bodyMediumBlack">{handleTime}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural">Status</Typography>
            <StatusBox statusMessage={status} />
          </Box>

          {isReviewer && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "30%",
              }}
            >
              <Typography variant="bodyMediumRegural">MiŚOT</Typography>
              <Typography variant="bodyMediumBlack">{misot}</Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural">Notatka</Typography>
            <Typography variant="bodyMediumBlack">{note}</Typography>
          </Box>

          <Box>
            {status.match(/Oczekuje na operator/) && !isReviewer ? (
              <Box
                sx={{
                  width: "12vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  ml: "24px",
                }}
              >
                <Button
                  onClick={handleAcceptLead}
                  sx={Theme.outlinedAcceptButton}
                >
                  Przyjmuje
                </Button>
                <Button
                  onClick={handleRejectLead}
                  disabled={false}
                  sx={Theme.outlinedCancelButton}
                >
                  Odrzucam
                </Button>
              </Box>
            ) : status.match(/Oczekuje na operator/) && isReviewer ? (
              <Box
                sx={{
                  mr: "24px",
                }}
              >
                <IconMenu
                
                  onClickHistory={onClickHistory}
                  optionSelected={onClickEdit}
                  onClickStatusChange={onClickStatusChange}
                  onClieckLeadView={onClieckLeadView}
                  onClickNotesView={onClickNotesView}
                  list={["Podgląd leada", "Notatki"]}
                  onClickPreview={() => {
                    onClickPreview();
                  }}
                />
              </Box>
              /** 
              <Box
                sx={{
                  mr: "32px",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#a9ce50",
                    width: 44,
                    height: 44,
                  }}
                >
                  <HourglassTopIcon
                    sx={{
                      fontSize: 26,
                      color: "#fff",
                    }}
                  />
                </Avatar>
              </Box>
              */
            ) : (
              <Box
                sx={{
                  mr: "24px",
                }}
              >
                <IconMenu
                  onClickHistory={onClickHistory}
                  optionSelected={onClickEdit}
                  onClickStatusChange={onClickStatusChange}
                  onClieckLeadView={onClieckLeadView}
                  onClickNotesView={onClickNotesView}
                  list={itemList}
                  onClickPreview={() => {
                    onClickPreview();
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
