import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { AccordionInput } from "../Atoms/AccordionInput";

export const ConfirmationForm = ({
  boldText = `Czy na pewno chcesz usunąć`,
  boldText2 = "użytkownika",
  reguralText,
  goodName,
  cancelActionHandler,
  saveActionHandler,
  disableVerifyButton,
  dropDown = false,
  misotName,
  accordionReasonHandler,
  accordionLabel,
  accordionText,
                                     top= '50%',
                                     left= '50%',
                                     transform  = 'translate(-50%, -50%)',
                                     position = 'absolute',
                                     buttonLabel = 'Odrzuć'
}) => {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        id="mainBox"
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          width: "23vw",
          minHeight: 250,
          p: "34px 31px 40px 40px",
          borderRadius: "24px",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
            top: top,
            left: left,
            transform: transform,
            position: position,
        }}
      >
        {dropDown && (
          <Box id="boldText">
            <Typography variant="h4">
              {`${boldText} "${goodName}" ${boldText2} ${misotName}`}
            </Typography>
          </Box>
        )}
        {!dropDown && (
          <Box id="boldText">
            <Typography variant="h4">{`${boldText} "${goodName}" ${misotName}`}</Typography>
          </Box>
        )}
        <Box id="reguralText">
          <Typography variant="bodySmallRegural">{reguralText}</Typography>
        </Box>

        {dropDown && (
          <Box sx={{width:"98%", mt:2, mb:2}}>
          <AccordionInput
            infoLabel={accordionLabel}
            text={accordionText}
            handleChange={accordionReasonHandler}
          />
          </Box>
        )}

        <Box
          id="ButtonsCancelAccept"
          sx={{
            width: "65%",
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            gap: 14,
          }}
        >
          <Button
            onClick={cancelActionHandler}
            variant="outlined"
            sx={{
              ...Theme.outlinedAcceptBlackButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
                width: 90,
              },
            }}
          >
            Anuluj
          </Button>

          <Button
            disabled={disableVerifyButton}
            onClick={saveActionHandler}
            variant="outlined"
            sx={{
              display: { disableVerifyButton },
              ...Theme.containedGreenButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
              },
            }}
          >
              {buttonLabel}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

ConfirmationForm.propTypes = {
  cancelActionHandler: PropTypes.func,
  saveActionHandler: PropTypes.func,
  disableVerifyButton: PropTypes.func,
  dropDown: PropTypes.bool,
  accordionReasonHandler: PropTypes.func,
  accordionLabel: PropTypes.string,
  accordionText: PropTypes.string,
};
