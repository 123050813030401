import { useEffect, useState } from 'react';
import {  getApplicationData }  from '../../requests';
import Loader from "../../Components/UI/Loader/Loader";

function Actuator(props) {
    const [lokalniData, setLokalniData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getApplicationData().then(results => {
            if ( results.status !== 200 && results.status  !== 201 && results.status !== 202 && results.status !== 203) {
                setLokalniData([]);
                return;
            }
            return results.json();
        }
        ).then( result => {
            if (result.status === "Company not found") {
                setLokalniData({});
                return;
            }
            const dane  = result;
            setLokalniData(dane);
            setIsLoading(false);
          
        }).catch ( error => {console.log("Actuator => ERROR HAS OCCURRED: " + error)});
    },[]);
    const title = 'Application\'s Data ' + props.data
    return (
        <>
            {
            (lokalniData) && (
                <section>
                    <p>
                     <strong>{title}</strong>
                    </p>
                    <div key="app">
                        <ul><strong>applicationName:</strong> {lokalniData.applicationName}</ul>
                        <ul><strong>applicationVersion:</strong> {lokalniData.applicationVersion}</ul>
                        <ul><strong>build:</strong> {lokalniData.build}</ul>
                        <ul><strong>applicationStartTime:</strong> {lokalniData.applicationStartTime}</ul>
                        <ul><strong>applicationUpTime:</strong> {lokalniData.applicationUpTime}</ul>
                        <ul><strong>commitHash:</strong> {lokalniData.commitHash}</ul>
                        <ul><strong>commitTime:</strong> {lokalniData.commitTime}</ul>
                        <ul><strong>commitDescription:</strong> {lokalniData.commitDescription}</ul>
                        <ul><strong>commitBranch:</strong> {lokalniData.commitBranch}</ul>
                        <ul><strong>commitMessageShort:</strong> {lokalniData.commitMessageShort}</ul>
                        <ul><strong>commitMessageFull:</strong> {lokalniData.commitMessageFull}</ul>
                        <ul><strong>jvm:</strong> {lokalniData.jvm}</ul>
                        <ul><strong>os:</strong> {lokalniData.os}</ul>
                        <ul><strong>database:</strong> {lokalniData.database}</ul>
                    </div>

                </section>
            )
            } {
                (!lokalniData && isLoading) && <Loader />
              }
        </>
    );
}

export default Actuator;