import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { AutocompleteLocalSearch } from "../AutocompleteLocalSearch/AutocompleteLocalSearch";
import axios from "axios";
import { getUrl } from "../../requests";

export const withAutocompleteLocalSearch = (getPlace) => {
  return (props) => {
    const [localPlacePredictions, setLocalPlacePredictions] = useState([]);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const getLocalPlacePredictions = (textValue) => {
      axios
        .get(
          `${getUrl()}/api/v1/places/autocomplete`,
          { params: { zapytanie: textValue } },
          {
            headers: {},
          }
        )
        .then((res) => {
          const data = res.data;
          setLocalPlacePredictions(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const searchHandler = (event) => {
      const textValue = event.target.value;
      if (textValue && textValue.length > 3) {
        setInputValue(textValue);
        getLocalPlacePredictions(textValue);
      }
    };

    const debouncedSearchHandler = useMemo(
      () => debounce(searchHandler, 700),
      []
    );

    return (
        <AutocompleteLocalSearch
        searchHandler={searchHandler}
        getPredictions={
          localPlacePredictions.predictions
            ? localPlacePredictions.predictions
            : []
        }
        getValue={value}
        setValue={setValue}
        getPlace={getPlace}
      />
      );
  };
};
