import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonsFooter } from "../Atoms/ButtonFooter";
import { InputSelectListCardContainer } from "../InputSelectListCard/InputSelectListCardContainer";
import { SwitchListCard } from "../InputListCard/SwitchListCard";

export const withEditableInfo = (
  Component,
  cardName,
  imageName,
  profileId,
  itemList1,
  companyDifferentiatorsMap,
  workingHours,
  itemList2,
  switchList1,
  itemList3,
  switchList2,
  itemList4,
  imageUrl,
  authToken,
  urlApi,
  authEpid,
  cancelActionHandler,
  refreshProfile,
  setopenInprogress
) => {
  return (props) => {
    const config = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
    };

    const [companyInfoChanges, setCompanyInfoChanges] = useState();
    const [workingHoursState, setWorkingHoursState] = useState(workingHours);
    const [switchList1State, setSwitchList1State] = useState(switchList1);
    const [switchList2State, setSwitchList2State] = useState(switchList2);

    const onChangeCompanyInfo = (changes) => {
      if (changes.workingHours) {
        const workingHoursTmp = workingHoursState.reduce(
          (acc, currentValue) =>
            changes.workingHours[0].weekDay === currentValue.weekDay
              ? [...acc, changes.workingHours[0]]
              : [...acc, currentValue],
          []
        );
        const tmp = {
          workingHours: workingHoursTmp,
        };
        setWorkingHoursState(tmp.workingHours);
        if (workingHours !== workingHoursState) {
          setCompanyInfoChanges({ ...companyInfoChanges, ...tmp });
        }
      } else if (changes.ebokServicesList) {
        const tmp = { ebokServicesList: [changes.ebokServicesList] };
        setCompanyInfoChanges({ ...companyInfoChanges, ...tmp });
      } else {
        setCompanyInfoChanges({ ...companyInfoChanges, ...changes });
      }
    };

    const onSaveCompanyInfo = async () => {
      /** 
        //console.log(switchList1State);
      //console.log(switchList1);
      //   if (switchList1 !== switchList1State) {
      const switchList1sChanges = Object.entries(switchList1State).map(([key, value]) => {
        const tmp =
          key === "Czy jest miejsce na rower"
            ?  {placeForBike: value }
            : key === "Czy jest miejsce na pieska"
            ? { placeForDog: value }
            : key === "Czy jest poczekalnia"
            ? { waitingRoom: value }
            : key === "Czy jest klimatyzacja"
            ? { airConditioning: value }
            : key === "Czy oferowane są napoje"
            ? { drinks: value }
            : key === "Czy jest obsługa w języku obcym"
            ? { foreignLanguage: value }
            : null;
            console.log({...tmp});
            setCompanyInfoChanges({ ...companyInfoChanges, ...tmp });
            
        return tmp;
        
      });
*/
      const switchList1Changes = {
        ...(switchList1State["Czy jest miejsce na rower"] !== undefined && {
          placeForBike: switchList1State["Czy jest miejsce na rower"],
        }),
        ...(switchList1State["Czy jest miejsce na pieska"] !== undefined && {
          placeForDog: switchList1State["Czy jest miejsce na pieska"],
        }),
        ...(switchList1State["Czy jest poczekalnia"] !== undefined && {
          waitingRoom: switchList1State["Czy jest poczekalnia"],
        }),
        ...(switchList1State["Czy jest klimatyzacja"] !== undefined && {
          airConditioning: switchList1State["Czy jest klimatyzacja"],
        }),
        ...(switchList1State["Czy oferowane są napoje"] !== undefined && {
          drinks: switchList1State["Czy oferowane są napoje"],
        }),
        ...(switchList1State["Czy jest obsługa w języku obcym"] !==
          undefined && {
          foreignLanguage: switchList1State["Czy jest obsługa w języku obcym"],
        }),
      };

      const switchList2Changes = {
        ...(switchList2State[
          "Czy jest możliwy kontakt telefoniczny po godzinach pracy biura"
        ] !== undefined && {
          supportAfterHours:
            switchList2State[
              "Czy jest możliwy kontakt telefoniczny po godzinach pracy biura"
            ],
        }),
        ...(switchList2State["Czy można zgłaszać usterki"] !== undefined && {
          supportAvailable: switchList2State["Czy jest miejsce na pieska"],
        }),
        ...(switchList2State["Czy można zamówić usługi"] !== undefined && {
          ordersAvailable: switchList2State["Czy można zamówić usługi"],
        }),
      };

      if (companyInfoChanges || switchList1Changes || switchList2Changes) {
        const payload = {
          id: profileId,
          ePid: authEpid,
          ...companyInfoChanges,
          ...switchList1Changes,
          ...switchList2Changes,
        };
        setopenInprogress(true);
        const result = await axios.patch(urlApi, payload, config);
      }
      setopenInprogress(false);
      refreshProfile();
      cancelActionHandler();
    };

    const onChangeSwitchList1 = (changes) => {
      setSwitchList1State({ ...switchList1State, ...changes });
    };

    const onChangeSwitchList2 = (changes) => {
      setSwitchList2State({ ...switchList2State, ...changes });
    };

    return (
      <InputSelectListCardContainer
        cardName={cardName}
        inputListCard1={
          <Component
            itemList={itemList1}
            selectItemList={workingHours}
            onChangeActionHandler={onChangeCompanyInfo}
          />
        }
        inputListCard2={
          <Component
            itemList={itemList2}
            selectItemList={null}
            onChangeActionHandler={onChangeCompanyInfo}
          />
        }
        inputListCard3={
          <SwitchListCard
            objectToUpdate={switchList1State}
            onChangeServices={onChangeSwitchList1}
          />
        }
        inputListCard4={
          <Component
            itemList={itemList3}
            selectItemList={null}
            onChangeActionHandler={onChangeCompanyInfo}
          />
        }
        inputListCard5={
          <SwitchListCard
            objectToUpdate={switchList2State}
            onChangeServices={onChangeSwitchList2}
          />
        }
        inputListCard6={
          <Component
            itemList={itemList4}
            selectItemList={null}
            onChangeActionHandler={onChangeCompanyInfo}
          />
        }
        buttonsFooter={
          <ButtonsFooter
            saveActionHandler={onSaveCompanyInfo}
            cancelActionHandler={cancelActionHandler}
          />
        }
      />
    );
  };
};
