import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { GoodMapListItem } from "../Atoms/GoodMapListItem";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Avatar from "@mui/material/Avatar";

export const GoodMapCard = ({
  goodMapItems,
  references,
  redirectToDetailsHandler,
  onClick,
}) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const chipColors = ["#F49B00", "#18a75c","#db2251", "#2943a8" ];

  return (
    <Box
      id="MainContainer"
      bgcolor="white"
      
      sx={{
        height:"85vh",
        [Theme.breakpoints.down("laptop")]: {
          width: "100vw",
        },

        [Theme.breakpoints.up("laptop")]: {
          width: "32.9vw",
          borderRadius: 5,
          borderBottomRightRadius:"0px",
          borderTopRightRadius:"0px",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "35.4vw",
          //borderRadius: 5,
        },
      }}
    >
      <Box
        id="MainFlexBox"
        sx={{
          [Theme.breakpoints.down("laptop")]: {
            width: "100vw",
          },

          [Theme.breakpoints.up("laptop")]: {
            width: "32.9vw",
            borderRadius: 5,
          },
          [Theme.breakpoints.up("desktop")]: {
            width: "35.4vw",
            borderRadius: 5,
          },
          display: "flex",
          flexDirection: "column",
          // alignContent: "flex-start",
          alignContent: "flex-start",
          justifyContent: "center",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          padding: 2,
        }}
      >
        <Box
          id="IconAndDescription"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "top",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#f29a26",
              width: 44,
              height: 44,
              mr: 2,
            }}
          >
            <MapOutlinedIcon
              sx={{
                fontSize: 26,
                color: "#fff",
              }}
            />
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ThemeProvider theme={Theme}>
              <Typography variant="h4">Mapa dobra</Typography>
            </ThemeProvider>

            <ThemeProvider theme={Theme}>
              <Typography variant="bodyMediumRegural">
                To mapa działań CSR, czyli inicjatyw społecznie użytecznych,
                jakie podejmowane są przez małych i średnich operatorów
                telekomunikacyjnych w Twojej okolicy. Zobacz, ile dobra dzieje
                się wokół Ciebie!
              </Typography>
            </ThemeProvider>
          </Box>
        </Box>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 2,
            }}
          >
            <TabList
              borderbottom={1}
              onChange={handleChange}
              sx={{
                "& button.Mui-selected": { color: "#363636" },
              }}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: {
                  width: 10,
                  backgroundColor: "#18a75c",
                  height: 4,
                },
              }}
              textColor="inherit"
            >
              <Tab label="Najnowsze" value="1" sx={NaviStyle} />
              {/**<Tab label="Najwyżej punktowane" value="2" sx={NaviStyle} /> /*/}
            </TabList>
          </Box>
        </TabContext>

        <Box sx={{ width: "auto", height:"60vh", overflow:"auto" }}>
          {goodMapItems.map((item, i) => (
            <GoodMapListItem
              onClick={onClick}
              companyName={item.companyName}
              goodDescription={item.description}
              goodPoints={item.score}
              providerId={`${item.seoLink}`}
              redirectToDetailsHandler={() => redirectToDetailsHandler(item)}
              ref={references[`${item.id}`]}
              color={chipColors[i%4]}
              //color="#2943a8"
              
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

GoodMapCard.propTypes = {
  goodMapItems: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  references: PropTypes.arrayOf(PropTypes.number),
  redirectToDetailsHandler: PropTypes.func,
};

GoodMapCard.defaultProps = {
  goodMapItems: [
    {
      companyName: "Prof-Tel Krystian Zblewski",
      goodDescription:
        "Zamontowanie czujnika smogu dla mieszkańców siemianowic",
      goodPoints: "25",
    },
    {
      companyName: "Prof-Tel Krystian Zblewski",
      goodDescription: "Uruchomienie wydarzeń online z transmisji kościelnych",
      goodPoints: "20",
    },
    {
      companyName: "Infoglob Security System Łukasz Pietras",
      goodDescription:
        " Wychodząc na przeciw potrzeb nauczania zdalnego w okresie epidemi, wdrożyliśmy platformę e-lerningową Google Classroom @szczercow.edu.pl, administrujemy pl",
      goodPoints: "25",
    },
  ],
};
