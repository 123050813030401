import Box from "@mui/material/Box";
import {Theme} from "../styles/styles";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ThemeProvider} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const UnderMapButton = ({onClick, title=`MAPA DOBRA`, subtitle=`Zobacz więcej`, subtitleTextTransform=`uppercase`,
                            subtitletextDecoration=`underline`,savingIcon=false
}) => {


    return (
        <Box onClick={onClick}
            sx={{
                [Theme.breakpoints.down("tablet")]: {
                    width: 279,
                    height: 52,
                },

                [Theme.breakpoints.up("tablet")]: {
                    width: 220,
                    height: 52,
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: 299,
                    height: 60,
                },
                [Theme.breakpoints.up("dektop")]: {
                    width: 2450,
                    height: 75,
                },
                borderRadius: 24,
                backgroundColor: "#f29a26",
                mt: 4,
                mb: 1,
                ml: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                width: '100%',
                cursor: 'pointer'
            }}
        >
            <Box>
                {!savingIcon && <MapOutlinedIcon
                    sx={{
                        fontSize: 30,
                        color: "#fff",
                        ml: 2,
                        mt: 1,
                    }}
                />}
                {savingIcon && <SavingsOutlinedIcon
                    sx={{
                        fontSize: 30,
                        color: "#fff",
                        ml: 2,
                        mt: 1,
                    }}
                />}
            </Box>
            <Box sx={{ mt: -2.6 }}>
                <ListItem  sx={{
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: "white",
                            "& .MuiListItemIcon-root": {
                                color: "white"
                            }
                        }

                }}>
                    <ListItemText>
                        <ThemeProvider theme={Theme}>
                            <Typography variant="h4" >{title}</Typography>
                        </ThemeProvider>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ 
                    "&:hover": {
                        backgroundColor: "transparent",
                    }
                }}>
                    <ListItemText>
                        <ThemeProvider theme={Theme}>
                            <Typography
                                variant="bodyLargeBlack"
                                sx={{
                                    textTransform: `${subtitleTextTransform}`,
                                    textDecoration: `${subtitletextDecoration}`,
                                    color:"#fff"
                                }}
                            >
                                {subtitle}
                            </Typography>
                        </ThemeProvider>
                    </ListItemText>
                </ListItem>
            </Box>
        </Box>
    );
}

export default UnderMapButton;