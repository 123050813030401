import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { InputIconTextItem } from "./InputIconTextItem";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const InputList = ({
  header = null,
  list = null,
  icon = null,
  handlers = null,
  onChangeActionHandler,
}) => {
  return (
    <Box
      id="mainList"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={Theme}>
        {header &&
        <Box id="header" marginBottom={16} marginTop={16}>
          <Typography variant="body1" textTransform="uppercase">
            {header}
          </Typography>
        </Box>
        }
        {list.map((item, i) => (
          <Box id="listItem" marginBottom={16}>
            {item.textBox ? (
              <InputIconTextItem
                label={item.label}
                text={item.value}
                icon={icon}
                type={item.type}
                customWidth="86%"
                customHeight="222px"
                multiLine={true}
                onChangeActionHandler={onChangeActionHandler}
              />
            ) : item.icon ? (
              <InputIconTextItem
                label={item.label}
                text={item.value}
                type={item.type}
                icon={
                  <CalendarMonthOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: "#363636",
                    }}
                  />
                }
                customWidth="86%"
                onChangeActionHandler={onChangeActionHandler}
              />
            ) : (
              <InputIconTextItem
                label={item.label}
                text={item.text}
                text2={item.value}
                name={item.name}
                type={item.type}
                disabled={item.disabled}
                icon={icon}
                getValue={handlers && handlers[i]}
                delay={300}
                customWidth="86%"
                onChangeActionHandler={onChangeActionHandler}
              />
            )}
          </Box>
        ))}
      </ThemeProvider>
    </Box>
  );
};
InputList.propTypes = {
  header: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};
