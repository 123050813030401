import React from "react";
import { AutoCompleteAddressListItem } from "../AutocompleteSearch/AutoCompleteAddressListItem";

export const AutoSuggestOption = ({
  id,
  styles,
  value,
  selected,
  onClick,
  name = undefined,
  abbr = undefined,
}) => {
  return (
    <li
      role="option"
      id={id}
      key={`li${id}`}
      aria-selected={id === selected}
      className={id === selected ? `highlighted` : "auto-suggestions"}
      //style={styles.suggestionOption && styles.suggestionOption}
      textvalue={value}
      onClick={onClick}
      // onKeyPress={onClick}
      name={name && name}
      abbr={abbr && abbr}
    >
      <AutoCompleteAddressListItem id={id} address={value} numOfChars={4} />

      {/*{value}*/}
    </li>
  );
};
