import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { StatusBox } from "./StatusBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconMenu } from "./IconMenu";

export const GoodListItem = ({
  goodName,
  date,
  location,
  goodPionts,
  status,
  onClickEdit,
  onClickHistory,
  onClickPreview,
  onClickDelete,

  col1name = `Dobro`,
  col3name = `Lokalizacja`,
  showvertIcon = true,

  itemList,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0px 0px 24px 0px rgba(177, 167, 188, 0.1);",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {showvertIcon && (
          <Box
            sx={{
              // bgcolor: 'black',
              ml: "16px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#f2f2f2",
                width: 44,
                height: 44,
              }}
            >
              <MapOutlinedIcon
                sx={{
                  fontSize: 26,
                  color: "#363636",
                }}
              />
            </Avatar>
          </Box>
        )}
        <Box
          sx={{
            ml: "2vw",
            // bgcolor: 'yellow',
            width: "30%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography variant="bodyMediumRegural">{col1name}</Typography>
            <Typography variant="bodyMediumBlack">{goodName}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            ml: "2%",
            width: "7vw",
          }}
        >
          <Typography variant="bodyMediumRegural">Data</Typography>
          <Typography variant="bodyMediumBlack">{date}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              ml: "2%",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural" noWrap>
              {col3name}
            </Typography>
            <Typography variant="bodyMediumBlack" noWrap>
              {location}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural">Pkt</Typography>
            <Typography variant="bodyMediumBlack">{goodPionts}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "30%",
            }}
          >
            <Typography variant="bodyMediumRegural">Status</Typography>
            <StatusBox statusMessage={status} />
          </Box>
          {showvertIcon && (
            <IconMenu
              onClickHistory={onClickHistory}
              onClickPreview={onClickPreview}
              onClickDelete={onClickDelete}
              optionSelected={onClickEdit}
              list={itemList}
            />
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
};

GoodListItem.propTypes = {
  ref: PropTypes.func,
};
