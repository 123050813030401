import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Button, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { getUrl } from "../../requests";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


export const FileDownloadBox = ({

  fileName,
  icon = null,
  clickHandler,
  auth
}) => {
  const hiddenFileInput = React.useRef(null);
  const [internalfileName, setInternalFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [lastUpdatedFile, setLastUpdatedFile] = useState("Nie wgrano jeszcze pliku");


  const handleFileChange = (event) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Proszę wybierz plik w formacie CSV');
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('csvFile', file);
      formData.append('epid', auth.epid);

      await axios.put(`${getUrl()}/api/v1/rfsManagement/upload`, formData, {
        headers: {
          Authorization: `Bearer ${auth.user}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Plik załadowany prawidłowo!');
    } catch (error) {
      console.error('Błąd ładowania pliku:', error);
      alert('Błąd ładowania pliku. Spróbuj ponownie.');
    } finally {
      setIsUploading(false);
      setTimeout(getFileUploadStatus, 3000);
      //onCloseButton();
    }
  };

  const getFileUploadStatus = () => {
    axios.get(`${getUrl()}/api/v1/rfsManagement/upload/report/${auth.epid}`, {
      headers: {
        Authorization: `Bearer ${auth.user}`,

      },
    }).then(resp => {
      const data = resp.data;
      let theDate = new Date(data.insertDate);
      setLastUpdatedFile(`Data: ${theDate.toLocaleDateString("pl-pl", {
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}, Nazwa pliku: ${data.fileName}, Ilość wszystkich rekordów w plku: ${data.rowsTotal}, ilość poprawnie wgranych: ${data.rowsLoaded}`)

    }).catch(err => console.log(err));
  }

  useEffect(() => {
    getFileUploadStatus();

  }, []);

  const handleFileDownload = async () => {
    try {
      const response = await axios.get(`${getUrl()}/api/v1/rfsManagement/lastUpload?epid=${auth.epid}`, {
        headers: {
          Authorization: `Bearer ${auth.user}`,

        },
        responseType: "blob", // Ensures binary data is handled correctly
      });

      const blobObj = new Blob([response.data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blobObj);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "pobrany plik";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Błąd pobierania pliku:", error);
    }
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box id="listHeader" sx={{ mb: "16px", ml: "10px", display: "flex", alignItems: "flex-start" }}>
        <ThemeProvider theme={Theme}>
          <Typography variant="body1" textTransform="uppercase">
            Wgrane zasięgi:
          </Typography>
          <Typography variant="bodySmallRegural" sx={{ ml: "16px" }}>
            {lastUpdatedFile}
            <IconButton color="primary" onClick={handleFileDownload}>
              < CloudDownloadIcon 
              sx={{
                
                color: "#db2251",
            }}
              />
            </IconButton>
          </Typography>
          


        </ThemeProvider>
      </Box>
      <Box
        id="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center", justifyContent: "space-evenly",
          width: "100%",

          height: "72px",
          borderRadius: "16px",
          backgroundColor: "#fafafa",
          pl: 4,
          pr: 4,
        }}
      >
        <ThemeProvider theme={Theme}>


          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{
              backgroundColor: "#f2f2f2",
              color: "#363636",
              mr: "16px",
              '&:hover': {
                backgroundColor: '#fff',
                color: '#3c52b2',
              }
            }}
          >
            Wybierz plik
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          {!isUploading ? (
            <Typography variant="body2" lineHeight={1} letterSpacing={0.22} marginRight="16px">
              {file ? file.name : "Nie wybrano pliku..."}
            </Typography>
          ) : (
            <CircularProgress />
          )}


          <Box id="button" flexGrow={1}>
            <Button
              variant="outlined"
              onClick={() => handleUpload()}
              disabled={file ? false : true}
              sx={{
                ...Theme.outlinedAcceptBlackButton,
                fontSize: 14,
                [Theme.breakpoints.down("laptop")]: {
                  fontSize: 12,
                },
              }}

            >
              Wyślij
            </Button>

          </Box>

        </ThemeProvider>
      </Box>
    </Box>
  );
};
FileDownloadBox.propTypes = {
  header: PropTypes.string,
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
};
