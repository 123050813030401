import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/system";
//import tmpLogo from "../../images/marketing/kameleon.png"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const GeneralInfoBox = React.forwardRef(
  (
    {
      providerName,
      providerLogo,
      goodPionts,
      infos,
      logoUrl,
      phoneNumber,
      address,
      email,
      companyDescription,
    },
    ref
  ) => {
    const CustomImageContainer = styled(Avatar)(({ theme }) => ({
      width: 124,
      height: 100,
      padding: "7px",
      borderColor: "#f2f2f2",
      borderRadius: 3,
      backgroundColor: "#fff",
      overflow: "hidden", // Standard CSS property
    }));

    const CustomImage = styled("img")({
      width: "100%",
      height: "100%",
      objectFit: "contain", // Standard CSS property
    });

  

    return (
      <Box
        id="MainContainer"
        sx={{
          //minHeight: 267,
          height:"100%",
          bgcolor: "#ffff",
          borderRadius: "24px",
          [Theme.breakpoints.down("tablet")]: {
            width: "91vw",
          },
          [Theme.breakpoints.up("tablet")]: {
            width: "43vw",
            borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
          },
          [Theme.breakpoints.up("laptop")]: {
            width: "26vw",
            
            
          },
          [Theme.breakpoints.up("desktop")]: {
            width: "18vw",
            
          },
        }}
      >
        <Box
          id="MainBox"
          ref={ref}
          borderColor="#e3e3e3"
          sx={{
            padding: 3,
            fontFamily: "Lato",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#ffff",
            borderRadius: "24px",
           
            [Theme.breakpoints.down("tablet")]: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomColor: "white",
              
            },
            [Theme.breakpoints.up("tablet")]: {
              //minHeight: "300px",
              //borderTopRightRadius: 0,
              //borderBottomRightRadius: 0,
              //borderRightColor: "white",
            },
          }}
        >
          <Box
            id="LogoAndGoofPointsBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ThemeProvider theme={Theme}>
              <Badge
                id="LogoBadge"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <CustomImageContainer>
                  <Avatar
                    src={logoUrl}
                    //alt={tmpLogo}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "12px",
                      backgroundColor: "white",
                    }}
                    imgProps={{
                      style: {
                        objectFit: "contain",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor: "transparent",
                      }}
                    ></Box>
                  </Avatar>
                </CustomImageContainer>
              </Badge>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: 3,
                  gap: 5,
                }}
              >
                <Typography variant="bodyLargeBlack">{providerName}</Typography>
                <Typography variant="bodyLargeBlack">
                  tel: {phoneNumber}
                </Typography>
              </Box>
            </ThemeProvider>
          </Box>
          <Box
            id="FacitilitiesList"
            sx={{ ml: -2, fontFamily: "Lato", fontSize: 12, mb: 2.5 }}
            disablePadding
          >
            <List>
              {address.length > 2 && (
                <ListItem
                  key={1}
                  sx={{
                    mb: -1,
                    display: `${address ? "" : "none"}`,
                    "&:hover": {
                      backgroundColor: "transparent", // Remove the background color on hover
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                      <FmdGoodOutlinedIcon
                        sx={{
                          fontSize: 16,
                          color: "#fff",
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText sx={{ ml: -2.5 }}>
                    <ThemeProvider theme={Theme}>
                      <Typography variant="bodySmallRegural">
                        {address}{" "}
                      </Typography>
                    </ThemeProvider>
                  </ListItemText>
                </ListItem>
              )}
              <ListItem
                key={2}
                sx={{
                  mb: -1,
                  display: `${email ? "" : "none"}`,
                  "&:hover": {
                    backgroundColor: "transparent", // Remove the background color on hover
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                    <AlternateEmailIcon
                      sx={{
                        fontSize: 16,
                        color: "#fff",
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText sx={{ ml: -2.5 }}>
                  <ThemeProvider theme={Theme}>
                    <Typography variant="bodySmallRegural">{email} </Typography>
                  </ThemeProvider>
                </ListItemText>
              </ListItem>

              <ListItem
                key={1}
                sx={{
                  mb: -1,
                  display: `${companyDescription ? "" : "none"}`,

                  "&:hover": {
                    backgroundColor: "transparent", // Remove the background color on hover
                  },
                }}
              >
                {" "}
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  sx={{ maxHeight: 120, overflow: "auto" }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: 16,
                          color: "#fff",
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText sx={{ ml: -2.5, mt: -0.5 }}>
                    <ThemeProvider theme={Theme}>
                      <Typography variant="bodySmallRegural">
                        {companyDescription}
                        {""}
                      </Typography>
                    </ThemeProvider>
                  </ListItemText>
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  }
);

GeneralInfoBox.propTypes = {
  infos: PropTypes.arrayOf(PropTypes.string),
  providerName: PropTypes.string,
  providerLogo: PropTypes.string,
  goodPionts: PropTypes.string,
  logoUrl: PropTypes.string,
};
