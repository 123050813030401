import React, { useEffect, useState } from "react";
import Actuator from "./pages/Actuator/Actuator";
import Layout from "./Components/layout/Layout";
import { Routes, Route, Navigate, BrowserRouter, useNavigate } from "react-router-dom";
import "./pages/Search/Search.module.css";
import "./App.module.css";
import CookieConsent from "react-cookie-consent";
import CssBaseline from "@mui/material/CssBaseline";


import { LandingSearchView } from "./Components/LandingSearchPage/LandingSearchView";
import { ZapleczeLandingPage } from "./Components/Zaplecze/ZapleczeLandingPage"
import { PartnersList } from "./Components/PartnersList/PartnersList";
import { ClientServices } from "./Components/ClientServices/ClientServices";
import { BeforeLogin } from "./Components/Login/BeforeLogin"
import useHttp from "./Components/CustomHooks/use-http";
import { getUrl } from "./requests";
import MapOfGood from "./Components/MapOfGood/MapOfGood";

import Privacy from "./Components/Privacy/Privacy";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useAuth0, Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import { SupplierPage } from "./Components/SupplierCard/SupplierPage";
import { Helmet } from "react-helmet"
import { ErrorPage404 } from "./Components/ErrorPage/ErrorPage404";
import NewSearchView from "./Components/SearchView/NewSearchView";
import { CompanyInfoCard } from "./Components/GoodDescriptionCard/CompanyInfoCard";
import { GoodDetailsBox } from "./Components/GoodDescriptionCard/GoodDetailsBox";
import { GoodDetailsCard } from "./Components/GoodDescriptionCard/GoodDetailsCard";


const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const redirectURL = `${window.location.origin}/zaplecze`;

const ExternalRedirect = ({url}) => {
  useEffect(() => {
    // Przekierowanie na zewnętrzny adres URL
    window.location.href = url; 
  }, []);

  return <div>Redirecting...</div>;
};

function App() {


  const { sendRequest: getData } = useHttp();
  const [Glat, setGlat] = useState();
  const [Glon, setGlon] = useState();
  const [apikey, setApiKey] = useState();
  const [cityName, setCityName] = useState("");
  const [sessionid, setsessionid] = useState(() => {
    return Date.now() + Math.random().toFixed(0);
  }, []);

  const [cityId, setCityId] = useState();

  const { loginWithRedirect } = useAuth0();

  const LoginRedirect = () => {
    loginWithRedirect();
    /** 
    loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
    });
    */
  };

  useEffect(() => {
    //localStorage.clear();
    const getCityData = (data) => {
      const myInput = document.querySelector("#search");
      if (myInput && myInput.value.trim().length > 0) {
        setCityName(myInput.value);
        localStorage.setItem("city", myInput.value);
      } else if (data) {
        setCityName(data.city);
        localStorage.setItem("city", data.city);
      }
      localStorage.setItem("geolocation", true);
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGlat(position.coords.latitude.toFixed(4));
        setGlon(position.coords.longitude.toFixed(4));
        localStorage.setItem("lat", position.coords.latitude.toFixed(4));
        localStorage.setItem("lng", position.coords.longitude.toFixed(4));
        getData(
          {
            url: `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${+position.coords.latitude.toFixed(
              4
            )}&longitude=${+position.coords.longitude.toFixed(
              4
            )}&localityLanguage=pl`,
          },
          getCityData
        );
      },
      (error) => {
        localStorage.setItem("geolocation", false);
      }
    );
  }, [getData]);

  useEffect(() => {
    const getApiKey = () => {
      axios
        .get(
          `${getUrl()}/api/v1/system/frontendConfig`,

          {
            headers: {},
          }
        )
        .then((res) => {
          const data = res.data;
          setApiKey(data.googleMapsKey);
          localStorage.setItem("ApiKey", data.googleMapsKey);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getApiKey();


  }, []);


  useEffect(() => {
    const getCityId = (data) => {
      if (data.results[0]?.place_id) {
        setCityId(data.results[0]?.place_id);

        localStorage.setItem("cityId", data.results[0]?.place_id);
        localStorage.setItem("placeID", data.results[0]?.place_id);
      }
    };

    if (cityName && cityName !== "user") {
      getData(
        { url: `${getUrl()}/api/v1/geocoding?zapytanie=${cityName}` },
        getCityId
      );
    }
  }, [cityName]);


  return (
     
      <BrowserRouter >
      
        <Auth0ProviderWithRedirectCallback
          domain="epid.eu.auth0.com"
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: redirectURL,
            scope: "openid lokalni epid profile msa",
          }}
          cacheLocation="localstorage"
        >

          <CssBaseline />
          <GlobalStyles
            styles={{
              body: {
                background: "linear-gradient(-45deg, #f29a26, #db2251, #ba1fa5, #2B46AF, #18a75c, #a9ce50)",
                backgroundSize: "400% 400%",
                animation: "gradient 15s ease infinite}",
              }
            }}
          />

          <Routes>
            <Route path="/" element={<Layout

              city={cityName}
            />}>
              <Route index element={<LandingSearchView
                sessionid={sessionid}
                apikey={apikey}

              />} />
              <Route
                path="/szukaj"
                element={
                  <LandingSearchView
                    sessionid={sessionid}
                    apikey={apikey}

                  />
                }
              />

              <Route
                path="/zaplecze/"
                element={<ProtectedRoute component={ZapleczeLandingPage} />}
              />

              <Route path="/uslugi/" element={<ClientServices />} />

              <Route path="/logowanie/" element={<BeforeLogin />} />

              <Route path="/login/" element={<LoginRedirect />} />

              <Route
                path="/startowa/"
                element={
                  <LandingSearchView
                    sessionid={sessionid}
                    apikey={apikey}

                  />
                }
              />
              <Route
                path="/onas/"
                element={
                  <PartnersList
                    sessionid={sessionid}
                    lat={+Glat}
                    lon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              
              <Route
                path="/wyniki/:name/:id"
                exact
                element={
                  <NewSearchView
                    sessionid={sessionid}
                    lat={+Glat}
                    lon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                path="/wyniki/:name"
                exact
                element={
                  <NewSearchView
                    sessionid={sessionid}
                    lat={+Glat}
                    lon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
             
              <Route
                path="/mapadobra/"
                element={
                  <MapOfGood
                    sessionid={sessionid}
                    mlat={+Glat}
                    mlon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                //path="/company/:seolink"
                path="/partner/:seolink"
                element={
                  <SupplierPage
                    sessionid={sessionid}
                    mlat={+Glat}
                    mlon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                path="/mapadobra/:name"
                element={
                  <MapOfGood
                    sessionid={sessionid}
                    mlat={+Glat}
                    mlon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                path="/privacy"
                element={
                  <Privacy
                    sessionid={sessionid}
                    mlat={+Glat}
                    mlon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                path="/test"
                element={
                  <CompanyInfoCard/>
                }
              />
              <Route
                path="/test1"
                element={
                  <GoodDetailsBox/>
                }
              />
              <Route
                path="/test2"
                element={
                  <GoodDetailsCard/>
                }
              />
              <Route
                path="/mapadobra/:name/:id"
                element={
                  <MapOfGood
                    sessionid={sessionid}
                    mlat={+Glat}
                    mlon={+Glon}
                    apikey={apikey}
                    city={cityName}
                  />
                }
              />
              <Route
                path="/actuator"
                exact
                element={<Actuator data="moj actuator" />}
              />
              <Route
                path="/blad/404/"
                exact
                element={<ErrorPage404 />}
              />
             
             
              <Route path="/startowa/*" element={<Navigate to="/" />} />
              <Route path="/blog/" element={<ExternalRedirect url="http://lokalni.pl/blog/"  />} />
              <Route path="/kontakt/" element={<ExternalRedirect url="http://lokalni.pl/kontakt/"  />} />
              
              {/*<Route path='*' element={<NotFoundComponent/>}/>*/}
            </Route>
          </Routes>
          <CookieConsent
            debug={false}
            location="bottom"
            buttonText="Rozumiem"
            cookieName="consentCookie"
            style={{
              background: "white",
              color: "black",
              border: "1px solid #000",
              borderRadius: "10px",
              width: "90vw",
              marginLeft: "5vw",
              marginBottom: "2vw",
              zIndex: "3000",
            }}
            buttonStyle={{
              backgroundColor: "#2bfece",
              fontSize: "13px",
              borderRadius: "5px",
            }}
            expires={150}
          >
            Ta strona korzysta z plików cookies.
          </CookieConsent>

        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
   
  );
}

export default App;
