import * as React from "react";
import Box from "@mui/material/Box";
import { Theme, NaviStyle } from "../styles/styles";
import { CompanyInfoCard } from "./CompanyInfoCard";
import { GoodDetailsBox } from "./GoodDetailsBox";


export const GoodDetailsCard = ({

    goodImg,
    goodTitle = "Przykładowy tytuł dobra",
    goodDescription = "Brak opisu",
    goodDate, 
    goodPionts = 0,
    logoImg ,
    companyName,
    companyDescription,
    companyInfoOnClickButtonAction,
    clickButtonHandler,

}) => {

    return (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "#F2F3F5",

                [Theme.breakpoints.down("tablet")]: {
                    flexDirection:"column"
                },
                [Theme.breakpoints.up("tablet")]: {
                    flexDirection:"row",
                    width:"99vw",
                    height:"740px",
                    p:"8px",
                },
                [Theme.breakpoints.up("laptop")]: {
                    width:"60vw",
                    height:"740px",
                    
                },
                [Theme.breakpoints.up("laptop")]: {
                    width:"53vw",
                    height:"740px",
                    
                },

            }}
        >
            <CompanyInfoCard goodPionts={goodPionts} logoImg={logoImg} companyName={companyName} companyDescription={companyDescription} clickButtonHandler={""} companyInfoOnClickButtonAction={companyInfoOnClickButtonAction} />
            <GoodDetailsBox goodImg={goodImg} goodTitle={goodTitle} goodDescription={goodDescription} goodDate={goodDate} />
        </Box>

    );
};


