import React, {useRef} from "react";
import { useDebounce } from "./utils/useDebounce";
import { AutoSuggestContainer } from "./AutoSuggestContainer.js";
import "./index.css";
import {getUrl} from "../../requests";
import useHttp from "../CustomHooks/use-http";

export const AutoSuggestServer = React.forwardRef(
    (
        {name, debounceTime = 200, styles, type, isOpen, setIsOpen, handleChange, disabled, value, sessionid, lat, lon, onClick, valid,
            labelInputSearch,
            inputSearchPlaceHolder,top,position,zindex
        }
        , ref) => {

        const previousOpen = useRef(isOpen);
        const prevValue = useRef(value);
        const prevLat = useRef(lat);
        const prevLon = useRef(lon);
        const prevSessionId = useRef(sessionid);
        const prevSetIsOpen = useRef(setIsOpen);

        const { isLoading, error, sendRequest: fetchData } =  useHttp();
        const [options, setOptions] = React.useState([]);
        const [noResult, setNoResult] = React.useState(false);
        const debouncedSearchText = useDebounce(value, debounceTime, isOpen);
        const [activeDescendant, setActiveDescendant] = React.useState();




        const setData = (data) => {

            if (data && data.predictions.length > 0) {

                setOptions(data.predictions);
                setNoResult(false);

            } else {
                setIsOpen(false);
                setOptions([]);
                setNoResult(true);
            }
        }



        React.useEffect(() => {

            if (previousOpen.current !== isOpen || prevValue.current !== value || prevLat !== lat || prevLon !== lon || prevSessionId.current !== sessionid  || prevSetIsOpen !== setIsOpen ) {
                if (isOpen && value && value.trim().length > 3 && debouncedSearchText && value === debouncedSearchText) {

                    let myUrl = `${getUrl()}/api/v1/places/autocomplete?zapytanie=${encodeURIComponent(debouncedSearchText)}&sessionId=${sessionid}&latitude=${lat}&longitude=${lon}`
                    if (isNaN(lat) || isNaN(lon)) {
                        myUrl =  `${getUrl()}/api/v1/places/autocomplete?zapytanie=${encodeURIComponent(debouncedSearchText)}&sessionId=${sessionid}`
                    }
                    fetchData(
                        {
                            url: `${myUrl}`
                        },
                        setData);
                } else if (isOpen && value && debouncedSearchText && value === debouncedSearchText) {

                    if (localStorage.getItem('city')) {
                        if (localStorage.getItem('lastSearch')) {
                            setData({predictions: [{
                                    description: `${localStorage.getItem('city')}`,
                                    place_id: localStorage.getItem('cityId') ? localStorage.getItem('cityId') : `1`
                                },
                                {
                                   description: `${localStorage.getItem('lastSearch')}`,
                                   place_id: localStorage.getItem('last_place_id') ? localStorage.getItem('last_place_id') : `2`
                                }

                                ]});
                        } else {
                            setData({predictions: [{
                                    description: `${localStorage.getItem('city')}`,
                                    place_id:  localStorage.getItem('cityId') ? localStorage.getItem('cityId') :`1`
                                }]});
                        }
                    }

                }
            }

        }, [isOpen, value, debouncedSearchText,lat,lon,sessionid,setIsOpen]);

        React.useEffect(() => {
            if (!value) {
                setIsOpen(false);
            }
        },[isLoading,setIsOpen,value]);

        const handleInputChange = (val,placeId) => {
            handleChange(val,placeId);
        };

            return (
                <AutoSuggestContainer
                    dataType={type}
                    ref={ref}
                    name={name}
                    options={options}
                    error={error}
                    setSearchText={handleInputChange}
                    searchText={value}
                    openListbox={isOpen}
                    setOpenListbox={setIsOpen}
                    noResult={noResult}
                    styles={styles}
                    loading={isLoading}
                    // setLoading={setLoading}
                    activeDescendant={activeDescendant}
                    setActiveDescendant={setActiveDescendant}
                    clearText={() => {
                        handleChange();
                    }}
                    disabled={disabled}
                    onClick={onClick}
                    valid={valid}
                    labelInputSearch={labelInputSearch}
                    inputSearchPlaceHolder={inputSearchPlaceHolder}
                    top={top}
                    position={position}
                    zindex={zindex}
                />
            );
    }
);