import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { Theme } from "../styles/styles";
import { Box } from '@mui/material';

// Custom Checkbox styles
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '30%',
    width: "28px",
    height: "28px",
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    border: "none",
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: "16px",
        height: "16px",
        backgroundColor: "#2B46AF",
        borderRadius: '35%',
        transform: 'translate(-50%, -50%)', // Centering
    },
    border: "none",
});

// Custom Checkbox component
function BpCheckbox(props) {
    return (
        <Checkbox
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            sx={{
                '& .MuiSvgIcon-root': {
                    border: 'none', // Remove border inside the icon
                },
                '&.Mui-checked': {
                    border: 'none', // Remove border when checked
                },
            }}
            {...props}
        />
    );
}

export const CustomizedCheckBoxGroup = ({ checkboxList, changeHandler }) => {
    const [selectedValues, setSelectedValues] = React.useState([]);

    const handleChange = (event) => {
        const value = event.target.value;
        changeHandler(selectedValues.includes(value) ? {[event.target.name]: false } : {[event.target.name]: true } )
        //changeHandler({[event.target.name]: event.target.value});
        setSelectedValues((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    React.useEffect(() => {
            //console.log(selectedValues);
            //  const radioListStatuses = selectedValues.map((el) => )
        }, [selectedValues]);

    return (
        <FormControl>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",

                    [Theme.breakpoints.down("tablet")]: {
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "250px",
                    },
                    [Theme.breakpoints.up("tablet")]: {
                        flexDirection: "row",
                        //alignItems: "space-around",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        height: "120px",
                    },
                }}
            >
                {checkboxList.map((item, i) => (
                    <FormControlLabel
                        key={i}
                        sx={{
                            height: "42px",
                            backgroundColor: '#2B46AF',
                            borderRadius: '16px',
                            padding: '4px 10px',
                            color: '#fff',
                            '& .MuiFormControlLabel-label': {
                                color: '#fff',
                            },
                            '&:hover': {
                                backgroundColor: '#1D3B7B',
                            },
                            width: "220px",
                            [Theme.breakpoints.up("tablet")]: {
                                width: "240px",
                            },

                        }}
                        value={item.value}
                        name={item.name}
                        control={
                            <BpCheckbox
                                checked={selectedValues.includes(item.value)}
                                onChange={handleChange}
                                value={item.value}
                                name={item.name}
                            />
                        }
                        label={item.label}
                    />
                ))}
            </Box>
        </FormControl>
    );
};
