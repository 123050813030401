import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "@fontsource/lato";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const AccordionInput = ({ infoLabel, text, handleChange }) => {
  return (
    <Accordion
      square="false"
      sx={{
        borderRadius: "45px",
        boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: 40, color: "#363636" }} />}
      sx={{height:"55px"}}
      >
        <ThemeProvider theme={Theme}>
          <Typography variant="bodyMediumBlack" >{infoLabel}</Typography>
        </ThemeProvider>
      </AccordionSummary>
      <AccordionDetails>
        <ThemeProvider theme={Theme}>
          <Typography variant="body1">
            <InputBase
              sx={{
                
                ml:4,
                fontFamily: "Lato",
                fontSize: 14,
                color: "#363636",
                fontWeight: "normal",
                width: "90%",
                backgroundColor: "#fff",
              }}
              defaultValue={text}
              multiline={true}
              onChange={handleChange}
            />
          </Typography>
        </ThemeProvider>
      </AccordionDetails>
    </Accordion>
  );
};
