import {useCallback, useState} from "react";

const useHttp = ( ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    let data ='';
    const sendRequest =  useCallback( async (requestConfig, applyData) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(
                requestConfig.url, {
                    method: requestConfig.method ? requestConfig.method : 'GET' ,
                    headers: requestConfig.headers ? requestConfig.headers : {},
                    body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
                }
            );

            if (!response.ok) {
                data = await response.json();
                // console.log(`GK data use-http ${data?.errorCode} ${data?.errorSubCode} ${data?.message} ${data?.info}`)
                if ('errorCode' in data) {
                    throw {data}
                    return
                }
                throw new Error(`${data.message}`);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                 data = await response.json();
            } else {
                data = await response.text();
            }
            // console.log(`GK data use-http 001 data ${data}`);
            // const data = await response.json();
            applyData(data);
        } catch (error) {
            // console.log(`GK data use-http 002 ${error===null} [${error}] ${Object.keys(error)} ${error?.data?.errorCode} ${error?.data?.errorSubcode} ${error?.data?.message} ${error?.data?.info}`)
            setError(
                error
                // `${error.message} ${error.data?.message}`
            );
            //"Błąd: " + error.message
        }

        setIsLoading(false);
    },[]);
    return {
        isLoading,
        error,
        sendRequest,
    };

};

export default useHttp;