import React from "react";
import { AutoSuggestServer } from "./AutoSuggestServer.js";
import "./index.css";
import { defaultOptions } from "./utils/defaultOptions";

export const AutoSuggest = ({
  type = "",
  name = "Search",
  debounceTime = 200,
  styles = defaultOptions,
  options = [],
  handleChange,
  disabled = false,
  value,
  sessionid,
  lat,
  lon,
  caseInsensitive = true,
  onClick,
  valid,
  labelInputSearch,
  inputSearchPlaceHolder,
  top,
  position,
  zindex,
}) => {
  const combinedStyles = {
    announcement: {
      ...defaultOptions.announcement,
      // ...styles.announcement
    },
    combobox: {
      // ...defaultOptions.combobox,
      // ...styles.combobox
    },
    searchField: {
      ...defaultOptions.searchField,
      // ...styles.searchField,
      focus: {
        ...defaultOptions.searchField.focus,
        // ...(styles.searchField && styles.searchField.focus && { ...styles.searchField.focus })
      },
    },
    searchLabel: {
      ...defaultOptions.searchLabel,
      // ...styles.searchLabel
    },
    suggestionsContainer: {
      ...defaultOptions.suggestionsContainer,
      // ...styles.suggestionsContainer
    },
    suggestionOptions: {
      ...defaultOptions.suggestionOptions,
      // ...styles.suggestionOptions
    },
    suggestionOption: {
      ...defaultOptions.suggestionOption,
      // ...styles.suggestionOption
    },
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  return (
    <AutoSuggestServer
      ref={ref}
      name={name}
      type="Server"
      debounceTime={debounceTime}
      styles={combinedStyles}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleChange={handleChange}
      disabled={disabled}
      value={value}
      sessionid={sessionid}
      lat={lat}
      lon={lon}
      onClick={onClick}
      valid={valid}
      labelInputSearch={labelInputSearch}
      inputSearchPlaceHolder={inputSearchPlaceHolder}
      //top={top}
      position="relative"
      zindex={zindex}
    />
  );
};
