import * as React from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { InputIconTextItem } from "./../Atoms/InputIconTextItem";
import Stack from "@mui/material/Stack";

export const ShortSwitchListCard = ({
  header,
  hasSpecialProductHandler,
  kameleonTV,
  koalaTel,
  setKameleonTV,
  setKoalaTel,
  
}) => {
  return (
    <Box
      id="mainInputListCard"
      sx={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={Theme}>
        {header && (
          <Box id="header" marginBottom={16} marginTop={16}>
            <Typography variant="body1" textTransform="uppercase">
              {header}
            </Typography>
          </Box>
        )}
        <Box
          id="switchList"
          width="100%"
          sx={{
            maxHeight: 300,
            overflow: "auto",
            mb: "25px",
          }}
        >
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {kameleonTV ? (
                      <Typography variant="body1">TAK</Typography>
                    ) : (
                      <Typography variant="body1">NIE</Typography>
                    )}

                    <Switch
                      checked={kameleonTV}
                      onChange={(event) => {
                        setKameleonTV(event.target.checked);
                      }}
                      name="kameleonTV"
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#2bfece",
                          "&:hover": {
                            backgroundColor: "#2bfece",
                          },
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#2bfece",
                          },
                      }}
                    />
                    <Typography variant="bodyMediumRegural">
                      KameleonTV
                    </Typography>
                  </Stack>
                  
                </Box>
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {koalaTel ? (
                      <Typography variant="body1">TAK</Typography>
                    ) : (
                      <Typography variant="body1">NIE</Typography>
                    )}

                    <Switch
                      checked={koalaTel}
                      onChange={(event) => {
                        setKoalaTel(event.target.checked);
                      }}
                      name="koalaTel"
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#2bfece",
                          "&:hover": {
                            backgroundColor: "#2bfece",
                          },
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#2bfece",
                          },
                      }}
                    />
                    <Typography variant="bodyMediumRegural">
                      KoalaTel
                    </Typography>
                  </Stack>
                  
                </Box>
            
            </FormGroup>
          </FormControl>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
