import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { InputIconTextItem } from "../Atoms/InputIconTextItem";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export const NotesHistoryBox = ({
  headerText,
  info,
  notesList = [],
  addNotesHandler,
  onCloseButton,
}) => {
  const [newHistoryItem, setNewHistoryItem] = useState('');
  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "900px",
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1}
              letterSpacing={0.22}
              fontSize="24px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography variant="bodySmallBlack">{info}</Typography>
          </Box>

          <InputIconTextItem
            label="Treść Notatki"
            text="np. Umowa z klientem podpisana"
            icon={null}
            customWidth="90%"
            customHeight="135px"
            multiLine={true}
            onChangeHistoryHandler={(value) => {setNewHistoryItem(value)}}
          />
          <Button
            onClick={() => {
              addNotesHandler(newHistoryItem);
            }}
            sx={{
              ...Theme.OrangeButton,
              alignSelf: "flex-start",
              mt: "16px",
              width: "174px",
            }}
          >
            Dodaj notatkę
          </Button>

          <Box>
            <Typography
              variant="bodyMediumBlack"
              lineHeight={1.07}
              letterSpacing={0.13}
            >
              Historia notatek:
            </Typography>
          </Box>

          {notesList.length===0 ? (
            <Typography
              variant="bodyMediumBlack"
              lineHeight={1.07}
              letterSpacing={0.13}
            >
              Brak notatek:
            </Typography>
          ) : (
            <Timeline
              position="right"
              sx={{
                width: "100%",
                alignSelf: "flex-start",
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                maxHeight: "250px",
                overflow: "auto",
              }}
            >
              {notesList.map((note) => (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    width="150"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography variant="bodySmallBlack">
                      {note.dateCreated} {", "}
                      autor: {note.author}
                    </Typography>
                    <Typography variant="bodySmallRegural" fontStyle="italic">
                      "{note.note}"
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}

          <Button
            onClick={() => {
              onCloseButton();
            }}
            sx={{
              ...Theme.OrangeButton,
              alignSelf: "flex-end",
              mt: "32px",
            }}
          >
            Zamknij
          </Button>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

NotesHistoryBox.propTypes = {
  headerText: PropTypes.string,
  info: PropTypes.string,
};
