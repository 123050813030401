import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { Avatar, Button, Link, Typography } from "@mui/material";
import imageURL from "../../images/404_duze.jpg";

import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";

export const ErrorPage404 = () => {
   
    const navigate = useNavigate();

    return (
        <Box
            
            height="100vh"
            width="100%"
            id="partnersList"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                [Theme.breakpoints.down("tablet")]: {


                },

                [Theme.breakpoints.up("tablet")]: {

                },
                [Theme.breakpoints.up("laptop")]: {

                    p: "0px",

                },
                [Theme.breakpoints.up("desktop")]: {

                    p: "0px",

                },
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center", bgcolor:"white" }} >
                <Avatar
                    id="mContent"
                    alt="marketing"
                    src={imageURL}
                    variant="square"
                    sx={{
                        width: "40%",

                        [Theme.breakpoints.down("tablet")]: {

                            height: "auto",
                        },
                        [Theme.breakpoints.up("tablet")]: {

                            height: "auto",

                        },
                        [Theme.breakpoints.up("desktop")]: {

                            height: "auto",

                        },
                    }}

                />
            </Box>
            <Box

                sx={{
                    display: "flex",
                    flexDirection: "column",
                    //height: "100vw",
                    bgcolor: "#fff",
                    alignItems: "center",
                    //justifyContent: "space-evenly"

                }}
                flexGrow={1}
            >
                <ThemeProvider theme={Theme}>
                <Typography
                        variant="h2"
                        textAlign="center"
                        sx={{
                            color:"#db2251",
                            mb:"1%",
                            [Theme.breakpoints.down("tablet")]: {
                                //width: 0,
                                width: "298px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "588px"
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                width: "908px"
                            },
                        }}
                    >
                        Strona nie istnieje
                    </Typography>
                    <Typography
                        variant="bodyVeryLargeBlack"
                        textAlign="center"
                        sx={{
                            [Theme.breakpoints.down("tablet")]: {
                                //width: 0,
                                width: "298px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "588px"
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                width: "908px"
                            },
                        }}
                    >
                        Upewnij się, ze adres jest prawidłowy lub wróć na stronę główną.
                    </Typography>

                    <Button
                        onClick={() => {
                            navigate(`/`)
                        }}
                        sx={{
                            mt:"2%",
                            ...Theme.OrangeButton,
                            backgroundColor: "#f29a26",
                            border: 0,
                            width: 226,
                        }}
                    >
                        Wróć na stronę główną
                    </Button>
                    
                </ThemeProvider>

            </Box>

        </Box>
    );
};
