import * as React from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import Avatar from "@mui/material/Avatar";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import bacImg from "../../images/marketing/lokalni_cover1.jpg";

export const ImageBox = ({ logoImg, backgroundImg }) => {

    return (
        <Box
            sx={{
                display: "flex",
                borderRadius: "24px",
                //backgroundColor: "#fff",
                backgroundImage: `url(${bacImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                //backgroundAttachment: "fixed",
                //flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                minHeight: "370px",
                [Theme.breakpoints.down("tablet")]: {
                    width: "81vw",
                    p: "19px",
                },
                [Theme.breakpoints.up("tablet")]: {
                    //flexDirection: "row",
                    width: "89.5vw",
                    minHeight: "245px",
                    p: "19px",
                    justifyContent: "flex-start",
                    

                },
                [Theme.breakpoints.up("laptop")]: {
                    //flexDirection: "row",
                    width: "87.5vw",
                    minHeight: "284px",
                    p: "24px",

                },
                [Theme.breakpoints.up("desktop")]: {
                    //flexDirection: "row",
                    width: "76vw",
                    minHeight: "399px",
                    p: "24px",
                    // justifyContent: "space-around"

                },

            }}
        >
            <Avatar variant="rounded"
                src={logoImg}
                sx={{
                    display: "flex",
                    justifyContent: "center", 
                    alignItems:"center",
                    bgcolor: "#f2f2f2",
                    borderRadius: "12px",
                    [Theme.breakpoints.down("tablet")]: {
                        width: "88.6%",
                        
                    },
                    [Theme.breakpoints.up("tablet")]: {
                        width: "30.5%",
                        
                    },
                    [Theme.breakpoints.up("laptop")]: {
                        width: "23.8%",
                        
    
                    },
                    [Theme.breakpoints.up("desktop")]: {
                        width: "25.6%",
    
                    },
                    height: "auto",
                }}
            >
                {!logoImg &&
                    <NoPhotographyOutlinedIcon
                        sx={{
                            fontSize: "80px",
                            color: "#363636"

                        }}
                    />
                }
            </Avatar>
        </Box>

    )

}