import * as React from "react";
import Box from "@mui/material/Box";

import "@fontsource/lato";
import { Theme } from "../styles/styles";
import { Avatar } from "@mui/material";
import img1 from "../../images/marketing/448x580_1.jpg";
import img2 from "../../images/marketing/448x580_2.jpg";
import img3 from "../../images/marketing/448x580_3.jpg";
import img4 from "../../images/marketing/448x580_4.jpg";
import img5 from "../../images/marketing/448x580_5.jpg";
import img6 from "../../images/marketing/448x580_6.jpg";
import img7 from "../../images/marketing/448x580_7.jpg";
import img8 from "../../images/marketing/448x580_8.jpg";
import img9 from "../../images/marketing/448x580_9.jpg";


export const ImageScroller = ({ images=[img1, img2, img3, img4, img5, img6, img7, img8, img9] }) => {

    
    const [imageURL, setImageURL] = React.useState(images[0]);
    const [imageIdx, setImageIdx] = React.useState(0);

    React.useEffect(() => {
        const timer = setTimeout(() => {
          setImageURL(imageIdx < 8 ? images[imageIdx + 1] : images[0]);
          setImageIdx(imageIdx < 8 ? imageIdx + 1 : 0);
        }, 7000);
        return () => clearTimeout(timer);
      }, [imageIdx]);

  return (
    <Box>
    <Avatar
      id="mContent"
      alt="marketing"
      src={imageURL}
      sx={{
        [Theme.breakpoints.down("tablet")]: {
          width: 0,
          height: 0,
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "35vw",
          height: "auto",
          borderRadius: "24px",
          ml: "32px",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "38vw",
          height: "auto",
          borderRadius: "24px",
          ml: "32px",
        },
      }}
      variant="square"
    />
  </Box>
  );
};
