import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import lokalnilogo from "../../../images/cluster/Oval.png";
import pin from "../../../images/pin.png";


const MapContainer = ({
  lat,
  lon,
  references,
  companies,
  apikey,
  zoom = 11,
  modal = false,
  place,
  mapStyles,
}) => {
  //Below is a function which replaces repeatable fragments of code below while rendering pin point on the map.
  const DisplayMarker = (label, index, position, onclick, icon) => {
    return (
      <>
        <Marker
          key={index}
          id={index}
          position={position}
          label={label}
          onClick={onclick}
          icon={icon ? { icon } : null}
        />
        (
        <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
          <FavoriteIcon
            sx={{
              fontSize: 16,
              color: "#fff",
            }}
          />
        </Avatar>
        )
      </>
    );
  };

  const GOOD_MAKER = "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0";
  const MAP_MAKER =
    "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
  const FIRM_MAKER =
    "M16 10.7L2 2v42.1l14 8.7V10.7zm18-7.6l-14 7.7v42.1l14-7.7V3.1zM38 42a14 14 0 0 1 14-14l2 .2V11.9L38 3.1";

  const firmPointIcon = {
    path: MAP_MAKER,
    scale: 0.9,
    fillOpacity: 0.9,
    fillColor: "#db2251",
    strokeColor: "#fff",
  };

  const goodPointIcon = {
    path: MAP_MAKER,
    fillColor: "#f29a26",
    fillOpacity: 1,
    //strokeColor: "#fff",
    strokeWeight:0,
    anchor: { x: 12, y: 24 },
    scale: 2,
    
  };

  const placePointIcon = {
    path: MAP_MAKER,
    fillColor: "#18a75c",
    fillOpacity: 0.25,
    strokeColor: "#fff",
    anchor: { x: 12, y: 24 },
    scale: 4,
  };

  const exampleMapStyles = [/** 
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 65,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: "50",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [
        {
          lightness: "30",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "all",
      stylers: [
        {
          lightness: "40",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          hue: "#ffff00",
        },
        {
          lightness: -25,
        },
        {
          saturation: -97,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          lightness: -25,
        },
        {
          saturation: -100,
        },
      ],
    },
    */
  ];

  // here we use effect to make component re-render when longitude or latitude changes
  useEffect(() => {
    setdefaultCenter({
      lat: +lat,
      lng: +lon,
    });
  }, [lon, lat]);

  useEffect(() => {
    if (
      place?.geographicCoordinates?.lat &&
      place?.geographicCoordinates?.lng
    ) {
      setdefaultCenter({
        lat: +place?.geographicCoordinates.lat,
        lng: +place?.geographicCoordinates.lng,
      });
    }
  }, [place]);

  const [selected, setSelected] = useState({});
  const [defaultCenter, setdefaultCenter] = useState({
    lat: +lat,
    lng: +lon,
  });

  const onSelect = (item) => {
    setSelected(item);
    references &&
      references[item?.id]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  //Some inline styles making height of the map as high as container, width too.
  {/** 
  const mapStyles = {
    // height: "100vh",
    height: "85vh",
    width: "50vw",
    borderTopLeftRadius: "0px",  
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "24px",  
    borderBottomRightRadius: "24px"
    
  };

  const mapStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "24px",
  };
*/}

  const clusterStyles = [
    {
      height: 60,
      textColor: "white",
      textSize: "large",
      width: 60,
      // url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="50" width="50"%3E%3Ccircle cx="25" cy="25" r="20" stroke="yellow" stroke-width="3" fill="yellow" /%3E%3C/svg%3E',
      url: `${lokalnilogo}`,
      //color:"white",
      
    },
  ];

  return (
    <>
      {!modal && (
        <LoadScript googleMapsApiKey={apikey}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={zoom}
            options={{
              styles: exampleMapStyles,
              mapTypeControl: false,
            }}
            center={defaultCenter}
          >
            <MarkerClusterer
              options={{
                averageCenter: false,
                styles: clusterStyles,
                fontSize: "16",
                //color:"#fff"
              }}
            >
              {(clusterer) =>
                companies.map((item, index, array) => (
                  <Marker
                    key={index}
                    position={{
                      lat: +item?.geographicCoordinates?.latitude
                        ? +item?.geographicCoordinates?.latitude
                        : +item?.geometry?.location?.lat,
                      lng: +item?.geographicCoordinates?.longitude
                        ? +item?.geographicCoordinates?.longitude
                        : +item?.geometry?.location?.lng,
                    }}
                    onClick={() => onSelect(item)}
                    // icon={goodPointIcon}
                    icon={goodPointIcon}
                     // url: pin,
                     // size: { width: 512, height: 512 },
                     // anchor: { x: 15, y: 50 },
                     // scaledSize: { width: 50, height: 50 },
                    
                    title={
                      item?.companyName
                        ? item?.companyName
                        : item?.formatted_address
                    }
                    // zIndex={gzIndex}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
      )}
      {modal && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          options={{
            styles: exampleMapStyles,
            mapTypeControl: false,
          }}
          center={defaultCenter}
        >
          <MarkerClusterer
            options={{
              averageCenter: false,
              styles: clusterStyles,
              fontSize: "16",
            }}
          >
            {(clusterer) =>
              companies.map((item, index, array) => (
                <Marker
                  key={index}
                  position={{
                    lat: +item?.geographicCoordinates?.latitude
                      ? +item?.geographicCoordinates?.latitude
                      : +item?.geometry?.location?.lat,
                    lng: +item?.geographicCoordinates?.longitude
                      ? +item?.geographicCoordinates?.longitude
                      : +item?.geometry?.location?.lng,
                  }}
                  onClick={() => {
                    if (modal === true) {
                      return;
                    }
                    onSelect(item);
                  }}
                  // icon={goodPointIcon}
                  icon={{
                    url: pin,
                    size: { width: 512, height: 512 },
                    anchor: { x: 15, y: 50 },
                    scaledSize: { width: 50, height: 50 },
                  }}
                  title={
                    item?.companyName
                      ? item?.companyName
                      : item?.formatted_address
                  }
                  // zIndex={gzIndex}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      )}

     
    </>
  );
};

export default MapContainer;
