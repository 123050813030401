import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ShortInfoList } from "../Atoms/ShortInfoList";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
export const CompanyProfileCard = React.forwardRef(
  (
    {
      cardName,
      infoList,
      icon = null,
      fileDownloadBox = null,
      fileDownloadBoxHeader = null,
      buttonHandler, children,
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          width: "46vw",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "24px",
          p: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
          }}
        >
          <ThemeProvider theme={Theme}>
            <Box id="icon" sx={{ flexGrow: 1 }}>
              <Avatar
                sx={{
                  bgcolor: "#f19925",
                  width: 44,
                  height: 44,
                  mr: 2,
                }}
            src={icon}
              >
                {children}
              </Avatar>
            </Box>
            <Box
              id="content"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 14,
              }}
            >
              <Box id="cardName" sx={{ mb: "32px", mt: 8 }} ref={ref}>
                <Typography variant="h4">{cardName}</Typography>
              </Box>
              <Box id="text">
                {infoList.map((item, i) => (
                  <Box sx={{ mb: "32px" }}>
                    <ShortInfoList
                      header={item.header}
                      list={item.list}
                      imageUrl={item.imageUrl}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box id="button" sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                startIcon={<EditOutlinedIcon />}
                sx={{
                  ...Theme.OrangeButton,
                  fontSize: 14,
                  minWidth: 111,

                  [Theme.breakpoints.down("laptop")]: {
                    fontSize: 12,
                  },
                }}
                onClick={() => {
                  buttonHandler();
                }}
              >
                Edycja
              </Button>
            </Box>
          </ThemeProvider>
        </Box>
        {fileDownloadBox && (
          <Box 
          sx={{ 
            //width:"100%",
            [Theme.breakpoints.up("desktop")]: {
              ml: 9 
            },
            [Theme.breakpoints.down("desktop")]: {
              ml: 7 
            },
            
            }}>
            
            {fileDownloadBox}
          </Box>
        )}
      </Box>
    );
  }
);
