import MapContainer from "../UI/Map/MapContainer";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
//import classes from "./MapOfGoog.modules.css";
import classes2 from "../../pages/Search/Search.module.css";
import { Theme } from "../styles/styles";
import useHttp from "../CustomHooks/use-http";
import React, { useEffect, useState } from "react";
import { getUrl } from "../../requests";

import Avatar from "@mui/material/Avatar";

import ModalList from "../UI/ModalList/ModalList";
import List from "@mui/material/List";
import { Button, IconButton, Modal, Paper, Slide, useMediaQuery } from "@mui/material";
import { GoodMapCard } from "../GoodMapCard/GoodMapCard";

import { MapSlider } from "../Atoms/MapSlider";
import ModalMap from "../UI/ModalMap/ModalMap";

import { GoodMapCardHeader } from "../../GoodMapCardHeader/GoodMapCardHeader";

import { useAuth0 } from "@auth0/auth0-react";

import { MessageBox } from "../Lokalni2/MessageBox";
import { Helmet } from 'react-helmet-async';
import { GoodDetailsCard } from "../GoodDescriptionCard/GoodDetailsCard";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import { ContactMailForm } from "../Lokalni2/ContactMailForm";

const MapOfGoods = ({ apikey, sessionid }) => {
  const { name, id } = useParams();
  const { isLoading, error, sendRequest: fetchData } = useHttp();
  const [searchResults, setSearchResults] = useState([]);
  const [searchAddress, setSearchAddress] = useState("");
  const [pointsOfGood, setpointsOfGood] = useState([]);
  const [placeYouAreLookingFor, setplaceYouAreLookingFor] = useState({});
  const [localStatus, setLocalStatus] = useState(null);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [refs, setRefs] = useState([]);
  const [modalMapIsShown, setModalMapIsShown] = useState(false);
  const [cityName, setCityName] = useState("");

  const [myLoading, setMyLoading] = useState();
  const [httpError, setHttpError] = useState(null);
  const {
    isAuthenticated,
    handleRedirectCallback,
    getTokenSilently,
    getUser,
    loginWithPopup,
  } = useAuth0();

  const city = localStorage.getItem("city");
  //const placeID = localStorage.getItem("cityId");
  const placeID = localStorage.getItem("placeID");

  const navigate = useNavigate();
  const showModalMapHandler = () => {
    setModalMapIsShown(true);
  };

  const hideModalMpaHandler = () => {
    setModalMapIsShown(false);
  };

  const handleChange = () => {
    setModalMapIsShown((prev) => !prev);
  };

  const [isOpenGoodDetailsModal, setIsOpenGoodDetailModal] = React.useState(false);

  const [currentGoodDetail, setCurrentGoodDetail] = React.useState("");


  const handleCloseGoodDetailsModal = () => setIsOpenGoodDetailModal(false);

  const [isOpenContactMailForm, setIsOpenContactMailForm] = useState(false);
      const handleOpenContactMailForm = () => {
        handleCloseGoodDetailsModal();
        setIsOpenContactMailForm(true)};

      const handleCloseContactMailForm = () => {
        setIsOpenContactMailForm(false);
        setIsOpenGoodDetailModal(true);
      }

  useEffect(() => {
    const myrefs = searchResults.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    setRefs(myrefs);
  }, [searchResults]);

  useEffect(() => {
    console.log(currentGoodDetail);
  }, [currentGoodDetail]);

  useEffect(() => {
    const enteredSearchHandler = (data) => {
      if (data.totalResults === 0) {
        setLocalStatus(`Przepraszamy, nie mogliśmy znaleźć wyniku dla ${name}`);
        setSearchResults([]);
      } else {
        setSearchResults(data.pointsOfGood);
        setSearchAddress(data.placeYouAreLookingFor.addressSearch);
        setpointsOfGood(data.pointsOfGood);
        setplaceYouAreLookingFor(data.placeYouAreLookingFor);

        setLat(
          data.placeYouAreLookingFor.geographicCoordinates.latitude.toFixed(4)
        );
        setLon(
          data.placeYouAreLookingFor.geographicCoordinates.longitude.toFixed(4)
        );
        setLocalStatus(null);
      }
    };
    let respose = "";
    const fetchDetails = async () => {
      if (id) {
        respose = await fetch(
          `${getUrl()}/api/v1/rfs/byPlaceId?placeId=${id}&sessionId=${encodeURIComponent(
            sessionid
          )}`
        );
        setMyLoading(false);
      } else {
        respose = await fetch(
          `${getUrl()}/api/v1/rfs/byPlaceId?placeId=${placeID}&sessionId=${encodeURIComponent(
            sessionid
          )}`
        );
      }

      if (!respose.ok) {
        throw new Error(`Błąd! ${respose.status}`);
      }
      const responceData = await respose.json();
      enteredSearchHandler(responceData);
    };

   

    setMyLoading(false);
    setHttpError(null);
    try {
      setMyLoading(true);
      fetchDetails().catch((err) => {
        setHttpError(
          `Brak danych, ${err.message ? err : "Wystąpił niezidentyfikowany problem"
          }`
        );
        setMyLoading(false);
        setLocalStatus(`Przepraszamy, nie mogliśmy znaleźć wyniku dla ${name}`);
      });
    } catch (error) {
      setMyLoading(false);
      setHttpError(`Nie znaleziono danych, ${error.message}`);
    }

    setMyLoading(false);
  }, [name, id]);
  //fetchData,lat,lon,props.city

  const ourMediaQuerymax = useMediaQuery("only screen and (max-width:1279px)");
  const appCont = document.querySelector("#appcontainer");
  if (appCont) {
    appCont.className = "MapContainer";
  }

  const redirectToDetailsHandler = async (el) => {
    setCurrentGoodDetail(el);
    setIsOpenGoodDetailModal(true);
  };

  function setNativeValue(el, value) {
    const previousValue = el.value;
    el.value = value;
    const tracker = el._valueTracker;
    if (tracker) {
      tracker.setValue(previousValue);
    }
    el.dispatchEvent(new Event("input", { bubbles: true }));
  }

  const mapStyles = {
    // height: "100vh",
    height: "85vh",
    width: "60vw",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "24px",
    borderBottomRightRadius: "24px"

  };

  const postEmailForm = (topic, emailText, emailFrom) => {

    const body = {
        topic: topic,
        body: emailText,
        emailFrom: emailFrom
    }

    axios.post(`${getUrl()}/api/v1/profile/${currentGoodDetail.profileId}/message`, body)
        .then(function (response) {
            console.log(response.data);
            handleCloseContactMailForm();
        })
        .catch(function (error) {
            console.log(error);
        });

}

  return (
    <>
      <Helmet>
        <title>Mapa dobra
        </title>
        <meta name="description" content=" Mapa dobra - Informacje o działaniach charytatywnych oraz akcjach społecznych w twojej okolicy" />
      </Helmet>
      <Box height="100vw" alignSelf="center" justifySelf="center">
        {searchResults.length > 0 && !ourMediaQuerymax && !modalMapIsShown && (
          <Box
            sx={{
              width: "100%",
              [Theme.breakpoints.down("tablet")]: {
                display: "none",
              },
              [Theme.breakpoints.up("tablet")]: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",

                p: "14px"
              },
            }}
          >


            <GoodMapCard
              goodMapItems={searchResults}
              redirectToDetailsHandler={redirectToDetailsHandler}
              references={refs}
              onClick={handleChange}
            />


            <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
              <MapContainer
                lat={lat}
                lon={lon}
                companies={searchResults}
                points={pointsOfGood}
                references={refs}
                apikey={apikey ? apikey : localStorage.getItem("ApiKey")}
                mapStyles={mapStyles}
              />
              <Modal
                open={isOpenGoodDetailsModal}
                onClose={handleCloseGoodDetailsModal}
                slotProps={{
                  backdrop: {
                    invisible: true, // Usuwa wyszarzanie tła
                  },
                }}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
              >
                <Box
                  sx={{
                    position: "absolute", // Pozycjonowanie wewnątrz MapContainer
                    top: "50%",           // Wyśrodkowanie w pionie
                    left: "66%",          // Wyśrodkowanie w poziomie
                    transform: "translate(-50%, -50%)", // Dokładne wyśrodkowanie
                    height: "75%",        // 75% wysokości MapContainer
                    backgroundColor: "#F2F3F5",
                    display: "flex",      // Flexbox dla wewnętrznych elementów
                    flexDirection: "column", // Ustawienie elementów w kolumnie
                    border: "none",        // Usuwamy obramowanie
                    width: "auto",
                    boxShadow: "none",     // Usuwamy cień
                    outline: "none",
                  }}
                >
                  <GoodDetailsCard
                    goodTitle={currentGoodDetail.shortDescription}
                    goodImg={currentGoodDetail.image}
                    goodDescription={currentGoodDetail.description}
                    goodDate={currentGoodDetail.goodDate}
                    goodPionts={currentGoodDetail.score}
                    logoImg={currentGoodDetail.companyLogo}
                    companyName={currentGoodDetail.companyName}
                    companyDescription={currentGoodDetail.companyInformation}
                    companyInfoOnClickButtonAction={handleOpenContactMailForm}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center", mt: -3, }}>
                    <IconButton color="red" onClick={handleCloseGoodDetailsModal}>

                      <CancelIcon
                        sx={{
                          fontSize: 27,
                          //bgcolor: "#fff",
                          color: "#f29a26",
                        }}
                      />

                    </IconButton>
                    {/** 
                    <IconButton>
                      <Avatar sx={{ bgcolor: "#fff", width: 24, height: 24, mr: "8px" }}>
                        <KeyboardArrowLeftIcon
                          sx={{
                            fontSize: 24,
                            color: "#000",
                          }}
                        />
                      </Avatar>
                    </IconButton>
                    <IconButton>
                      <Avatar sx={{ bgcolor: "#fff", width: 24, height: 24, mr: "8px" }}>
                        <KeyboardArrowRightIcon
                          sx={{
                            fontSize: 24,
                            color: "#000",
                          }}
                        />
                      </Avatar>
                    </IconButton>
                    */}
                  </Box>
                </Box>
              </Modal>
              <Modal
                    open={isOpenContactMailForm}
                    onClose={handleCloseContactMailForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    //container={document.body}  // renderuj poza strukturą DOM rodzica
                    //disablePortal={false}
                >
                    <Box
                        sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                            //width:"50vw",
                            bgcolor: "lightblue",
                            borderRadius: "16px",
            

                        }}
                    >
                        <ContactMailForm onCancel={handleCloseContactMailForm} onSaveButton={postEmailForm} />
                    </Box>
                </Modal>
            </Box>
          </Box>
        )}
        {searchResults.length > 0 && ourMediaQuerymax && (
          <Box>
            <List style={{ overflow: "auto", top: "2vh" }}>
              <GoodMapCard
                goodMapItems={searchResults}
                redirectToDetailsHandler={redirectToDetailsHandler}
                references={refs}
                onClick={handleChange}
              />
              <Box
                sx={{
                  position: "fixed",
                  bottom: "-20px",
                  bgcolor: "white",
                  ml: "-15",
                  borderRadius: "4",
                }}
              >
                {!modalMapIsShown && <MapSlider onClick={handleChange} />}
              </Box>
            </List>
          </Box>
        )}
        {modalMapIsShown && (
          <ModalMap onClose={handleChange}>
            <GoodMapCardHeader />
            <Box id="slider-screen" className={classes2.mapcontainer__container}>
              <MapContainer
                lat={lat}
                lon={lon}
                companies={searchResults}
                points={pointsOfGood}
                apikey={apikey}
                references={refs}
                place={{
                  geographicCoordinates:
                    placeYouAreLookingFor.geographicCoordinates,
                  companyName: placeYouAreLookingFor.addressSearch,
                }}
                mapStyles={mapStyles}
              />
            </Box>
          </ModalMap>
        )}
        {(searchResults.length === 0 || !searchResults) &&
          localStorage.getItem("geolocation") === "true" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "44px",
              }}
            >
              <MessageBox
                headerText="Brak danych o dobrach"
                info="Nie istnieją żadne dobra w podanej lokalizacji !!"
                onNextButton={() => navigate(`/szukaj`)}
              />
            </Box>
          )}

        {localStorage.getItem("geolocation") === "false" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "44px",
            }}
          >
            <MessageBox
              headerText="Wyłączona geolokalizacja"
              info="Nie można wyświetlić strony z uwagi na fakt, że masz wyłączoną usługe geolokalizacji w przeglądarce !!"
              onNextButton={() => navigate(`/szukaj`)}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
export default MapOfGoods;
