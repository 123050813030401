import classes from './ModalList.module.css';
import {createPortal} from "react-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {ThemeProvider} from "@mui/material/styles";
import {Theme} from "../../styles/styles";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Collapse, Paper} from "@mui/material";
import {useState} from "react";

const Backdrop = props => {
    return <div className={classes.backdrop} onClick={props.onClose}/>
};
const ModalOverlay = props => {
    const [isChecked, setIsChecked] = useState(true);

    const collapseStyle = {
        fontSize: 16,
        color: "#fff",
        zIndex: 300,
        position: 'relative',
        top: '0.8vh',
        ml: '25%'
    };

    return (
           <Box className={classes.container_modal} id='test001'>
          <Collapse in={isChecked}  collapsedSize='8px' orientation='horizontal'>
              {/*<div className={classes.modal} id='GK-overlay'>*/}
                <div  className={classes.content}>{props.children}</div>
            {/*</div>*/}
          </Collapse>

               <Box sx={{ bgcolor: "#f19925", width: 40, height: 40, position:'relative', top: '5vh', borderRadius: '5px'}}
                    onClick={() => {setIsChecked((prev) => !prev)}}
               > { isChecked &&
                   <ArrowBackIosNewIcon
                       sx={collapseStyle}
                   />
               }
                   { !isChecked &&
                       <ArrowForwardIosIcon
                           sx={collapseStyle}
                       />
                   }
               </Box>
           </Box>
    );
};
const portalElement = document.getElementById('overlays');
const ModalDetails = props => {

    return ( <>
        {createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElement)}
    </>);
};

export default ModalDetails;