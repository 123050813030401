import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import CallIcon from '@mui/icons-material/Call';
import { useEffect, useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";

import FacebookOutlined from "@mui/icons-material/FacebookOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import ScheduleIcon from '@mui/icons-material/Schedule';
import Link from "@mui/material/Link";
import { Button, Stack } from "@mui/material";
import PlaceOutlined from "@mui/icons-material/PlaceOutlined";

const iconColors = ["#db2251", "#ba1fa5", "#2a45ad", "#18a75c"]

export const DetailsInfoBox = ({ webAddress, facebookAddress, email, otherSocialList = [], address, workingHours, servicePhone, generalPhone }) => {

    const [areMoreAdressesVisible, setAreMoreAdressesVisible] = useState(false);

    return (
        <Box
            sx={{
                //borderColor="#e3e3e3",
                fontFamily: "Lato",
                bgcolor: "#ffff",

                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                //alignItems: "stretch",
                borderRadius: "24px",
                boxShadow: 1,
                p: "16px",

                [Theme.breakpoints.down("tablet")]: {
                    width: "81vw",
                    
                },
                [Theme.breakpoints.up("tablet")]: {
                    width: "90vw",
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: "87.5vw",
                },
                [Theme.breakpoints.up("desktop")]: {
                    width: "76vw",
                },
            }}
        >



           
                <Box id="FindUs_Label" sx={{ mt: 3, pl: 2 }}>
                    <ThemeProvider theme={Theme}>
                        <Typography
                            variant="bodySmallBlack"
                            sx={{
                                textTransform: "uppercase", color: "#9b9b9b", lineHeight: 1.25,
                                letterSpacing: 0.11,
                            }}
                        >
                            Więcej o nas
                        </Typography>
                    </ThemeProvider>
                </Box>

                <Box
                    id="Internet_Contact"
                    sx={{
                        fontFamily: "Lato",
                        fontSize: 12,
                        width: "100%",
                        pl: 2
                    }}
                    disablePadding
                >
                    <Stack direction="row"  sx={{ ml: -2,flexWrap: "wrap",  }}>
                        <ListItem
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove the background color on hover
                                },
                                display: `${webAddress?.trim().length === 0 ? 'none' : ''}`,
                                [Theme.breakpoints.up("tablet")]: {
                                    width:"30%"
                                },
                                [Theme.breakpoints.up("desktop")]: {
                                    width:"25%"
                                },
                              
                                
                                
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "#db2251", width: 27, height: 27 }}>
                                    <LanguageIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#fff",
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box sx={{ ml: -2, width: "100%" }}>
                                    <ThemeProvider theme={Theme}>
                                        <Typography
                                            variant="bodyLargeBlack"
                                            
                                            sx={{
                                                textDecoration: "underline",
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            <Link
                                                href={webAddress}
                                                underline="none"
                                                target="_blank"
                                                color="black"
                                            >
                                                {webAddress}
                                            </Link>
                                        </Typography>
                                    </ThemeProvider>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove the background color on hover
                                },
                                display: `${facebookAddress?.trim().length === 0 ? 'none' : ''}`,
                                [Theme.breakpoints.up("tablet")]: {
                                    width:"30%"
                                },
                                [Theme.breakpoints.up("desktop")]: {
                                    width:"25%"
                                },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "#ba1fa5", width: 27, height: 27 }}>
                                    <FacebookOutlined
                                        sx={{
                                            fontSize: 14,
                                            color: "#fff",
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box sx={{ ml: -2, width: "100%" }}>
                                    <ThemeProvider theme={Theme}>
                                        <Typography
                                            variant="bodyLargeBlack"
                                            sx={{
                                                textDecoration: "underline",
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            <Link
                                                href={facebookAddress}
                                                underline="none"
                                                target="_blank"
                                                color="black"
                                            >
                                                {facebookAddress}
                                            </Link>
                                        </Typography>
                                    </ThemeProvider>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove the background color on hover
                                },
                                display: `${email?.trim().length === 0 ? 'none' : ''}`,
                                [Theme.breakpoints.up("tablet")]: {
                                    width:"30%"
                                },
                                [Theme.breakpoints.up("desktop")]: {
                                    width:"25%"
                                },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "#2943a8", width: 27, height: 27 }}>
                                    <AlternateEmailOutlinedIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#fff",
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box sx={{ ml: -2, width: "100%" }}>
                                    <ThemeProvider theme={Theme}>
                                        <Typography
                                            variant="bodyLargeBlack"
                                            sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                                        >
                                            <Link
                                                href={"mailto:" + email}
                                                underline="none"
                                                target="_blank"
                                                color="black"
                                            >
                                                {email}
                                            </Link>
                                        </Typography>
                                    </ThemeProvider>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove the background color on hover
                                },
                                display: `${email?.trim().length === 0 ? 'none' : ''}`,
                                [Theme.breakpoints.up("tablet")]: {
                                    width:"30%"
                                },
                                [Theme.breakpoints.up("desktop")]: {
                                    width:"25%"
                                },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "#18a75c", width: 27, height: 27 }}>
                                    <CallIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#fff",
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Box sx={{ ml: -2, width: "100%" }}>
                                    <ThemeProvider theme={Theme}>
                                        <Typography
                                            variant="bodyLargeBlack"
                                            sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                                        >
                                            <Link
                                                href={"mailto:" + email}
                                                underline="none"
                                                target="_blank"
                                                color="black"
                                            >
                                                {generalPhone}
                                            </Link>
                                        </Typography>
                                    </ThemeProvider>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        {otherSocialList.map((item, i) => (
                            <ListItem
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent', // Remove the background color on hover
                                    },
                                    display: `${otherSocialList.length === 0 ? 'none' : ''}`,
                                    [Theme.breakpoints.up("tablet")]: {
                                        width:"30%"
                                    },
                                    [Theme.breakpoints.up("desktop")]: {
                                        width:"25%"
                                    },

                                }}
                            >

                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                                        <LanguageIcon
                                            sx={{
                                                fontSize: 14,
                                                color: "#fff",
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Box sx={{ ml: -2, width: "100%" }}>
                                        <ThemeProvider theme={Theme}>
                                            <Typography
                                                variant="bodyLargeBlack"
                                                noWrap
                                                sx={{
                                                    textDecoration: "underline",
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                <Link
                                                    href={webAddress}
                                                    underline="none"
                                                    target="_blank"
                                                    color="black"
                                                >
                                                    {item}
                                                </Link>
                                            </Typography>
                                        </ThemeProvider>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </Stack>
                </Box>

                <Box
                    id="Line_Separator"
                    sx={{ borderBottom: 2, borderColor: "#e3e3e3" }}
                ></Box>

                <Box id="OpenHours_Label" sx={{ mt: 2, mb: 1, pl: 2 }}>
                    <ThemeProvider theme={Theme}>
                        <Typography
                            variant="bodySmallBlack"
                            sx={{
                                textTransform: "uppercase", color: "#9b9b9b", lineHeight: 1.25,
                                letterSpacing: 0.11,
                            }}

                        >
                            Godziny otwarcia
                        </Typography>
                    </ThemeProvider>
                </Box>
                <Stack direction="row" id="OpenHours" sx={{ ml: -2, mb: 1, pl: 2, flexWrap: "wrap" }}>
                    {workingHours.map((item, i) => (
                        <ListItem key={i}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove the background color on hover
                                },
                                display: "flex",
                                alignItems: "center",
                                [Theme.breakpoints.up("tablet")]: {
                                    width:"30%"
                                },
                                [Theme.breakpoints.up("laptop")]: {
                                    width:"15%"
                                },
                               
                            }}
                        >
                            <ScheduleIcon
                                sx={{
                                    fontSize: 12,
                                    color: iconColors[i % 4],
                                    mr: "4px"
                                }}

                            />
                            <ThemeProvider theme={Theme}>
                                <Typography variant="bodyMediumBlack">
                                    {item.weekDays}: {item.hours}
                                </Typography>
                            </ThemeProvider>
                        </ListItem>
                    ))}
                </Stack>

                <Box
                    id="Line_Separator"
                    sx={{ borderBottom: 2, borderColor: "#e3e3e3" }}
                ></Box>

                <Box id="Address_Label" sx={{ mt: 2, mb: 2, pl: 2 }}>
                    <ThemeProvider theme={Theme}>
                        <Typography
                            variant="bodySmallBlack"
                            sx={{
                                textTransform: "uppercase", color: "#9b9b9b", lineHeight: 1.25,
                                letterSpacing: 0.11,
                            }}
                        >
                            Biura obsługi klienta
                        </Typography>
                    </ThemeProvider>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap",}}>
                    {address.map((item, i) => {


                        return (
                            areMoreAdressesVisible || i < 2 ?


                                <Box id="Address" sx={{ mb: 1, pl: 2, display: "flex", alignItems: "center",
                                    [Theme.breakpoints.up("tablet")]: {
                                        width:"35%"
                                    },
                                    [Theme.breakpoints.up("laptop")]: {
                                        width:"25%"
                                    },
                                    [Theme.breakpoints.up("desktop")]: {
                                        width:"15%"
                                    },
                                 }}>
                                    <PlaceOutlined
                                        sx={{
                                            fontSize: 12,
                                            color: iconColors[i % 4],
                                            mr: "4px"
                                        }}

                                    />
                                    <ThemeProvider theme={Theme}>
                                        <Typography variant="bodyMediumBlack">{item}</Typography>
                                    </ThemeProvider>
                                </Box>

                                : ""


                        )
                    })}
                </Box>
                <Button onClick={() => setAreMoreAdressesVisible(!areMoreAdressesVisible)} sx={{ mb: 1, pl: 2, alignSelf:"flex-start"}} >
                    <ThemeProvider theme={Theme}>
                        <Typography variant="bodyMediumBlack" sx={{ textDecoration: "underline" }}>{!areMoreAdressesVisible ? "Pokaz wszystkie" : "Schowaj"}</Typography>
                    </ThemeProvider>
                </Button>


            </Box>

       
    )
}