import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { SelectItem } from "../Atoms/SelectItem";

export const StatusChangeBox = ({
  headerText,
  info,
  statusList,
  currentStatus,
  acceptHandler,
  cancelHandler,
}) => {
  const [status, setStatus] = useState();
  return (
    <Box
      sx={{
        bgcolor: "white",
        maxHeight: "450px",
        [Theme.breakpoints.down("tablet")]: {
          width: "90.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
        },
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 4,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1.38}
              letterSpacing={0.23}
              fontSize="26px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="bodyMediumRegural"
              lineHeight={1.33}
              letterSpacing={0.16}
              fontSize="18px"
            >
              {info}
            </Typography>
          </Box>
          <SelectItem
            availOptions={statusList}
            selectedItem={currentStatus}
            changeHandler={(newStatus) => {setStatus(newStatus)}}
          />
          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            <Button
              onClick={cancelHandler}
              sx={{
                ...Theme.outlinedOrangeButton,
                alignSelf: "flex-end",
                mr: "16px",
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={() => {acceptHandler(status)}}
              sx={{
                ...Theme.OrangeButton,
                alignSelf: "flex-end",
              }}
            >
              Zmień
            </Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

StatusChangeBox.propTypes = {
  headerText: PropTypes.string,
  info: PropTypes.string,
};
