import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";


const chipColors = ["#F49B00", "#18a75c","#db2251", "#2943a8" ];

const ServiceInfoItem = ({ header, text, color }) => {
    return (

        <Box sx={{ display: "flex", flexDirection: "column", }}>
            <ThemeProvider theme={Theme}>

                <Typography variant="subtitle1"
                    sx={{
                        color: color,
                        [Theme.breakpoints.down("tablet")]: {
                            mb: "16px",
                            mt: "16px"
                        },
                        [Theme.breakpoints.up("tablet")]: {
                            mb: "16px",
                        },


                    }}
                >
                    {header}
                </Typography>
                <Typography variant="bodyMediumReguralSp"
                    sx={{
                        color: color,
                        mb: "16px",
                    }}
                >
                    {text}
                </Typography>


            </ThemeProvider>
        </Box>


    );
};

export const ListInformationCard = ({ header, textList }) => {

    return (
        <Box
            sx={{
                //height:"100%",
                //mb: 2,
                backgroundColor: "#fff",
                display: "flex",


                borderRadius: "24px",
                //height:"100%",

                [Theme.breakpoints.down("tablet")]: {
                    flexDirection: "column",
                    width: "81vw",
                    p: "16px",
                    //p:"20px",
                    //mt: -3,
                },
                [Theme.breakpoints.up("tablet")]: {
                    flexDirection: "row",
                    width: "89.5vw",
                    p: "24px",
                    justifyContent:"flex-start"
                    
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: "87.5vw",
                    minHeight: 267,
                },
                [Theme.breakpoints.up("desktop")]: {
                    width: "76vw",
                    minHeight: 267,
                },
            }}
        >
            <ThemeProvider theme={Theme} >
                <Typography variant="subtitle1"
                    sx={{
                        
                        fontSize: 18,
                        color: chipColors[0],
                        [Theme.breakpoints.up("tablet")]: {
                            justifySelf:"flex-end",
                            width:"25%",
                            textAlign:"right",
                            mr:"16px",
                            lineHeight:"23px"

                        },
                        [Theme.breakpoints.up("laptop")]: {
                            fontSize: 24,
                            width:"15%",
                        },

                    }}
                >{header}</Typography>
            </ThemeProvider>
            <Box sx={{

                [Theme.breakpoints.down("tablet")]: {
                    mt: "16px", mb: "16px",
                },
                [Theme.breakpoints.up("tablet")]: {
                    width:"100%",
                    height:"100%",
                },
            }}>
                {textList
                    //              
                    .map((filtredItem, i) => (

                        <Box
                            key={i}
                            sx={{


                                display: "flex",
                                [Theme.breakpoints.down("tablet")]: {
                                    flexDirection: "column",
                                },
                                [Theme.breakpoints.up("tablet")]: {
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    mb:"16px",
                                    //alignItems:"flex-start"
                                   
                                },

                            }}
                        >
                            <Box sx={{
                                backgroundColor: chipColors[i%4],
                                [Theme.breakpoints.down("tablet")]: {

                                    width: "100%", height: "4px"
                                },
                                [Theme.breakpoints.up("tablet")]: {

                                    width: "4px", height: "auto",
                                    mr:"16px",
                                    //mt:"10px",
                                    mb:"10px"
                                },

                            }} ></Box>
                            <ServiceInfoItem
                                key={i}
                                header={filtredItem.header}
                                text={filtredItem.text}
                                color={chipColors[i%4]}
                            />

                        </Box>
                    ))}

            </Box>
        </Box>

    )

}
