import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import "../styles/CustomStyles1.css";

import axios from "axios";
import { getUrl } from "../../requests";
import { withAutocompleteLocalSearch } from "../AutocompleteLocalSearch/withAutocompleteLocalSearch";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Modal } from "@mui/material";
import Privacy from "../Privacy/Privacy";
import { Button } from "@mui/material";
import { Helmet } from 'react-helmet-async';



const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    [Theme.breakpoints.down("tablet")]: {
      display: "none",
    },
    [Theme.breakpoints.up("tablet")]: {
      backgroundColor: "transparent",
      color: "#fff",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      borderRadius: "16px",
      minWidth: 570,
    },
    [Theme.breakpoints.up("laptop")]: {
      minWidth: 450,
    },
  },
}));

export const LandingSearchView = ({
  sessionid,
  apikey,

}) => {
  const navigate = useNavigate();

  const getPlace = (val) => {
    if (val.place_id) {
      axios
        .get(
          `${getUrl()}/api/v1/places/details`,
          { params: { sessionId: sessionid, placeId: val.place_id } },
          {
            headers: {},
          }
        )
        .then((res) => {
          const data = res.data.result;
          localStorage.setItem("lat", data.geometry.location.lat);
          localStorage.setItem("lng", data.geometry.location.lng);
          const desc = val.description.replace("/", "%")
          localStorage.setItem("searchedPlace", desc);
          localStorage.setItem("placeID", val.place_id);
          localStorage.setItem("ApiKey", apikey);

          //console.log(desc);
          navigate(`/wyniki/${desc}/${val.place_id}`, {
            state: {
              lat: data.geometry.location.lat,
              lng: data.geometry.location.lng,
              formatedAddress: data.formatted_address,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [footerMenuSelectedOption, setFooterMenuSelectedOption] =
    React.useState(3);
  const AutocompleteLocalSearchInstance = withAutocompleteLocalSearch(getPlace);

  const [isOpenPrivacyModal, setIsOpenPrivacyModal] = React.useState(false);

  const handleClosePrivacyModal = () => setIsOpenPrivacyModal(false);

  useEffect(() => {
    if (footerMenuSelectedOption === 0) navigate(`/logowanie/`);
    else if (footerMenuSelectedOption === 1)
      window.open("https://www.facebook.com/LokalniInternetPoLudzku", "_blank");
    else if (footerMenuSelectedOption === 2)
      window.open("https://www.instagram.com/lokalni_internet/", "_blank");
  }, [footerMenuSelectedOption]);

  return (
    <>
      <Helmet>
        <title>Porównywarka ofert internetu i telewizji - dostępność
          internetu, telewizji pod adresem - Polscy operatorzy telekomunikacyjni -
          Lokalni.pl
        </title>
        <meta name="description" content="Lokalni.pl - porównywarka ofert internetu i telewizji,
          specjalizująca się w lokalnych dostawcach. Znajdź najlepsze usługi od
          operatorów z Twojej okolicy!" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <Box sx={{ height: "100hw", width: "100vw" }}>
          <h1 style={{ display: 'none' }}>Porównywarka ofert internetu i telewizji</h1>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              //height: "100vw",

              [Theme.breakpoints.down("tablet")]: {
                padding: "28px",
                alignItems: "center",
                gap: "20px",
              },
              [Theme.breakpoints.up("tablet")]: {
                padding: "42px",
                gap: "45px",
                //minHeight:"70%"
                //minHeight: "858px",
              },
              [Theme.breakpoints.up("laptop")]: {
                padding: "47px",
                paddingTop: "37px",
                gap: "30px",
                //minHeight:"70%"
              },
              [Theme.breakpoints.up("desktop")]: {
                padding: "99px",
                gap: "65px",
                //minHeight:"65%"
              },
            }}
          >
            <LightTooltip
              title={
                <Typography
                  variant="bodySmallRegural"
                  sx={{
                    color: "#fff",
                    [Theme.breakpoints.up("tablet")]: {
                      fontSize: 14,
                    },
                  }}
                >
                  Lokalny operator jest blisko, dzięki temu szybko reaguje na
                  ewentualne problemy techniczne. Gdy dzwonisz, wsparcia udzieli
                  Ci doświadczony konsultant. Nie trzeba próbować dogadać się z
                  botem lub wisieć godzinami w oczekiwaniu na połączenie z
                  infolinią.
                </Typography>
              }
              placement="right"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    minWidth: 500,
                  },
                },
              }}
            //followCursor
            >
              <Box
                sx={{
                  ///=width: "71vw",
                  alignSelf: "flex-start",
                  gap: "5px",
                  [Theme.breakpoints.up("laptop")]: {
                    mb: "24px",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  lineHeight={0.83}
                  letterSpacing={0.7}
                  color="#fff"
                  textTransform="uppercase"
                  sx={{
                    opacity: 0.4,
                    [Theme.breakpoints.up("tablet")]: {
                      fontSize: 18,
                    },
                    [Theme.breakpoints.up("laptop")]: {
                      fontSize: 22,
                    },
                  }}
                >
                  Jest blisko
                </Typography>
              </Box>
            </LightTooltip>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <LightTooltip
                title={
                  <Typography
                    variant="bodySmallRegural"
                    sx={{
                      color: "#fff",
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    Część pieniędzy wraca do Ciebie. Lokalne firmy inwestują w
                    swoje miasto i region, zatrudniają lokalnych pracowników oraz
                    angażują się w lokalne inicjatywy.
                  </Typography>
                }
                placement="bottom-start"
              >
                <Box sx={{ width: "30vw" }}>
                  <Typography
                    variant="bodyMediumBlack"
                    lineHeight={1.29}
                    letterSpacing={0.7}
                    color="#fff"
                    textTransform="uppercase"
                    sx={{
                      opacity: 0.4,
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 18,
                      },
                      [Theme.breakpoints.up("laptop")]: {
                        fontSize: 22,
                      },
                    }}
                  >
                    Wspierasz lokalną społeczność
                  </Typography>
                </Box>
              </LightTooltip>
              <LightTooltip
                title={
                  <Typography
                    variant="bodySmallRegural"
                    sx={{
                      color: "#fff",
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    Lokalny operator działa na Twoim obszarze geograficznym,
                    dzięki temu jakość sieci, a co za tym idzie - usług jest
                    znacznie wyższa niż u ogólnopolskich dostawców.
                  </Typography>
                }
                placement="bottom-start"
              >
                <Box sx={{ width: "30vw" }}>
                  <Typography
                    variant="bodyMediumBlack"
                    lineHeight={1.29}
                    letterSpacing={0.7}
                    color="#fff"
                    textTransform="uppercase"
                    sx={{
                      opacity: 0.4,
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 18,
                      },
                      [Theme.breakpoints.up("laptop")]: {
                        fontSize: 22,
                      },
                    }}
                  >
                    Jakość usług
                  </Typography>
                </Box>
              </LightTooltip>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "16px",
              }}
            >
              <Typography
                variant="bodyMediumBlack"
                lineHeight={1.29}
                letterSpacing={0.7}
                color="#f19925"
                sx={{
                  mb: "10px",
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 18,
                  },
                  [Theme.breakpoints.up("laptop")]: {
                    fontSize: 22,
                  },
                }}
              >
                Szukasz Internetu, telewizji lub telefonu
              </Typography>
              <AutocompleteLocalSearchInstance />
            </Box>

            <Box sx={{ width: "82vw" }}>
              <Typography
                variant="bodyMediumBlack"
                fontSize={12}
                lineHeight={1.33}
                letterSpacing={0.11}
                color="#fff"
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    fontSize: 14,
                  },
                  [Theme.breakpoints.up("laptop")]: {
                    fontSize: 16,
                  },
                }}
              >
                Lokalni.pl to wyszukiwarka, dzięki której znajdziesz w swojej
                okolicy najlepszego dostawcę internetu, telewizji i telefonu.
                Dlaczego warto wybrać lokalnego dostawcę internetu, telewizji i
                telefonu?
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <LightTooltip
                title={
                  <Typography
                    variant="bodySmallRegural"
                    sx={{
                      color: "#fff",
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    Lokalny operator działa na wybudowanej przez siebie
                    infrastrukturze, która jest dostosowana do specyfiki regionu.
                    Dzięki temu połączenie z internetem jest bardziej stabilne i
                    szybkie.
                  </Typography>
                }
                placement="bottom-start"
              >
                <Box sx={{ width: "30vw" }}>
                  <Typography
                    variant="bodyMediumBlack"
                    lineHeight={1.29}
                    letterSpacing={0.7}
                    color="#fff"
                    textTransform="uppercase"
                    sx={{
                      opacity: 0.4,
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 18,
                      },
                      [Theme.breakpoints.up("laptop")]: {
                        fontSize: 22,
                      },
                    }}
                  >
                    Stabilne i szybkie łącze
                  </Typography>
                </Box>
              </LightTooltip>
              <LightTooltip
                title={
                  <Typography
                    variant="bodySmallRegural"
                    sx={{
                      color: "#fff",
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    Lokalni płacą podatki na miejscu, część z tych środków wraca
                    do Ciebie w postaci inwestycji w Twojej miejscowości.
                  </Typography>
                }
                placement="bottom-start"
              >
                <Box sx={{ width: "30vw" }}>
                  <Typography
                    variant="bodyMediumBlack"
                    lineHeight={1.29}
                    letterSpacing={0.7}
                    color="#fff"
                    textTransform="uppercase"
                    sx={{
                      opacity: 0.4,
                      [Theme.breakpoints.up("tablet")]: {
                        fontSize: 18,
                      },
                      [Theme.breakpoints.up("laptop")]: {
                        fontSize: 22,
                      },
                    }}
                  >
                    Pieniądze wracają do ciebie
                  </Typography>
                </Box>
              </LightTooltip>
            </Box>
            <LightTooltip
              title={
                <Typography
                  variant="bodySmallRegural"
                  sx={{
                    color: "#fff",
                    [Theme.breakpoints.up("tablet")]: {
                      fontSize: 14,
                    },
                  }}
                >
                  Lokalnym firmom zależy. Nie jesteś tylko “cyferką w excelu”,
                  dlatego u Lokalnych normalne jest elastyczne podejście do
                  klienta.
                </Typography>
              }
              placement="bottom-start"
            >
              <Box marginTop={14}>
                <Typography
                  variant="bodyMediumBlack"
                  lineHeight={1.29}
                  letterSpacing={0.7}
                  color="#fff"
                  textTransform="uppercase"
                  sx={{
                    opacity: 0.4,
                    [Theme.breakpoints.up("tablet")]: {
                      fontSize: 18,
                    },
                    [Theme.breakpoints.up("laptop")]: {
                      fontSize: 22,
                    },
                  }}
                >
                  Indywidualne podejście
                </Typography>
              </Box>
            </LightTooltip>
          </Box>

          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={footerMenuSelectedOption}
              onChange={(event, newValue) => {
                setFooterMenuSelectedOption(newValue);
              }}
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <BottomNavigationAction
                label="Zaloguj się"
                icon={
                  <OpenInNewIcon
                    sx={{
                      //fontSize: 26,
                      color: "#f29a26",
                      backgroundColor: "#fff",
                    }}
                  />
                }
              />
              <BottomNavigationAction
                label="facebook"
                icon={
                  <FacebookIcon
                    sx={{
                      color: "#1877F2",
                      backgroundColor: "#fff",
                    }}
                  />
                }
              />
              <BottomNavigationAction
                label="Instagram"
                icon={
                  <InstagramIcon
                    sx={{
                      color: "#E4405F",
                      backgroundColor: "#fff",
                    }}
                  />
                }
              />
              <BottomNavigationAction
                sx={{
                  [Theme.breakpoints.up("tablet")]: {
                    position: "absolute",
                    right: "10px",
                  },
                }}
                label={
                  <Box
                    onClick={() => {
                      setIsOpenPrivacyModal(true);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      [Theme.breakpoints.up("tablet")]: {
                        width: "170px",
                      },
                    }}
                  >
                    <Typography
                      variant="bodyMediumReguralLinks"
                      marginRight="16px"

                      underline="none"
                      target="_blank"
                      color="#363636"
                      textAlign="left"

                    >
                      Regulamin
                    </Typography>
                    <Typography
                      variant="bodyMediumReguralLinks"
                      marginRight="16px"

                      underline="none"
                      target="_blank"
                      color="#363636"
                      textAlign="left"

                    >
                      Polityka prywatności
                    </Typography>
                  </Box>
                }
              />
            </BottomNavigation>
          </Paper>



          <Modal
            open={isOpenPrivacyModal}
            onClose={handleClosePrivacyModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "absolute",
                borderRadius: "24px",
                backgroundColor: "white",
                paddingTop: "44px",
                display: "flex",
                flexDirection: "column",

                [Theme.breakpoints.down("tablet")]: {
                  width: "90vw",
                },
              }}
            >
              <Privacy />
              <Button
                onClick={() => {
                  handleClosePrivacyModal();
                }}
                sx={{
                  ...Theme.OrangeButton,
                  alignSelf: "flex-end",

                  mr: "44px",
                  mb: "44px",
                }}
              >
                Zamknij
              </Button>
            </Box>
          </Modal>
        </Box>
      </ThemeProvider>
    </>
  );
};
