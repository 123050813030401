import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { GeneralInfoBox } from "./GeneralInfoBox";
import { ProviderServicesBox } from "./ProviderServicesBox";
import PropTypes from "prop-types";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SearchResultCard = React.forwardRef(
  (
    {
      /*  services1, */
      providerName,
      providerLogo,
      goodPionts,
      infos,
      services,
      logoUrl,
      difrentiatorLogoUrl,
      showModalMapHandler,
      redirectToDetailsHandler,
      providerId,
      phoneNumber,
      address,
      email,
      companyDescription,
      specialProducts,
      seoLink,
    },
    ref
  ) => {

    const navigate = useNavigate();
    return (


      <Box
        ref={ref}
        sx={{
          display: "flex",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: 4,
          [Theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            width: "82.8vw",
            //padding: "7px",
            paddingBottom: 1,
          },
          [Theme.breakpoints.up("tablet")]: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: "90vw",
          },
          [Theme.breakpoints.up("laptop")]: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: "50vw",
            //height:"auto"
          },
          [Theme.breakpoints.up("desktop")]: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: "52.7vw",
            //height:"auto"
          },
        }}
      >
        <Box sx={{
           "&:hover": {
            cursor:"pointer"
          },
        }}>
          <Link onClick={() => navigate(`/partner/${seoLink}`)} underline="none">
            <GeneralInfoBox
              providerName={providerName}
              providerLogo={providerLogo}
              goodPionts={goodPionts}
              infos={infos}
              logoUrl={logoUrl}
              phoneNumber={phoneNumber}
              address={address}
              email={email}
              companyDescription={companyDescription}
            />
          </Link>
        </Box>
        <Box>
          <ProviderServicesBox

            services={services}
            showModalMapHandler={showModalMapHandler}
            redirectToDetailsHandler={redirectToDetailsHandler}
            providerId={providerId}
            koalaTel={specialProducts[0]?.koalaTel}
            kameleonTV={specialProducts[0]?.kameleonTV}

          />
        </Box>
      </Box>

    );
  }
);

SearchResultCard.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      info: PropTypes.string,
      other: PropTypes.string,
    })
  ),

  infos: PropTypes.arrayOf(PropTypes.string),
  providerName: PropTypes.string,
  providerLogo: PropTypes.string,
  goodPionts: PropTypes.string,
  logoUrl: PropTypes.string,
};

SearchResultCard.defaultProps = {
  providerName: "Nazwa Dostawcy",
  providerLogo: "Logo",
  goodPionts: "25",

  infos: [
    "Przyłącze 200m od wybranej lokalicacji",
    "Biuro 3.0 km od wybranego adresu",
  ],
  services: [
    {
      label: "Internet",
      info: "Bezprzewodowy FTTH",
      other: "do 500 MB",
    },
    {
      label: "Internet    ",
      info: "Światłowodowy XPON",
      other: "do 500 MB",
    },
    {
      label: "Telewizja",
      info: "Pakiet Podstawowy",
      other: "Sport",
    },
    {
      label: "Telewizja",
      info: "Pakiet rozszerzony",
      other: "HBO GO",
    },
  ],
};
