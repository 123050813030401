import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {ThemeProvider} from "@mui/material/styles";
import {Theme} from "../Components/styles/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import PropTypes from "prop-types";

export const GoodMapCardHeader = ({ displayDescription }) => {
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "top",
                    mt: 2,
                    mb: 2,
                }}
            >
                <Avatar
                    sx={{
                        bgcolor: "#2bfece",
                        width: 44,
                        height: 44,

                        mr: 3,
                        display: "flex",
                    }}
                >
                    <MapOutlinedIcon
                        sx={{
                            fontSize: 26,
                            color: "#363636",
                        }}
                    />
                </Avatar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        mr: 2,
                        ml: -3,
                    }}
                >
                    <ThemeProvider theme={Theme}>
                        <Typography variant="h4">Mapa dobra</Typography>
                    </ThemeProvider>

                    <ThemeProvider theme={Theme}>
                        <Typography
                            variant="bodyMediumRegural"
                            sx={{
                                [Theme.breakpoints.down("tablet")]: {},
                                [Theme.breakpoints.up("tablet")]: {},
                                [Theme.breakpoints.up("laptop")]: {
                                    width: 295,
                                },
                                [Theme.breakpoints.up("desktop")]: {
                                    width: 516,
                                },
                            }}
                        >
                            {displayDescription}
                        </Typography>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
};

GoodMapCardHeader.propTypes = {
    displayDescription: PropTypes.string
}

GoodMapCardHeader.defaultProps = {
    displayDescription: "To lista działań CSR - dobrych inicjatyw - podejmowanych przez\n" +
        "                            Małych i Średnich Operatorów Telekomunikacyjnych (MiŚOTÓW) w\n" +
        "                            Twojej okolicy. Poznaj ich bliżej"
}