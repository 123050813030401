import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { InfoCard } from "../Lokalni2/InfoCard";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import WifiIcon from "@mui/icons-material/Wifi";
import RouterIcon from "@mui/icons-material/Router";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TvIcon from "@mui/icons-material/Tv";
import { Helmet } from 'react-helmet-async';
import { ImageScroller } from "../Atoms/ImageScroller";

export const ClientServices = () => {
  

  return (
    <>
      <Helmet>
        <title>Usługi - oferta: Internet (światłowód), Telewizja i Telefon | lokalni.pl
        </title>
        <meta name="description" content=" Usługi - Internet, Internet światłowodowy, Internet radiowy, Internet mobilny, 
        Telewizja, Telewizja cyfrowa, Telefon, Telefon komórkowy, Telefon domowy. Opis usług, które świadczymy" />
      </Helmet>
      <Box
        //backgroundColor="#2B46AF"
        height="100%"
        width="100%"
        id="partnersList"
        sx={{
          display: "flex",

          [Theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            p: "3vw",
            //gap: "16px",
          },

          [Theme.breakpoints.up("tablet")]: {
            flexDirection: "column",
            p: "5vw",
            gap: "24px",
          },
          [Theme.breakpoints.up("laptop")]: {
            flexDirection: "row",
            padding: "80px",
            justifyContent: "space-evenly",
            alignItems: "space-between",
          },
          [Theme.breakpoints.up("desktop")]: {
            flexDirection: "row",
            padding: "80px",
            justifyContent: "space-evenly",
          },
        }}
      >
        <h1 style={{ display: 'none' }}>Usługi - oferta: Internet (światłowód), Telewizja i Telefon</h1>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <InfoCard
            header="Internet światłowodowy"
            text="Internet światłowodowy to obecnie najlepsza spośród dostępnych technologii umożliwiająca połączenie z globalną siecią. Poza osiąganiem zawrotnych prędkości jest również odporna na warunki atmosferyczne. Temperatura - bardzo wysoka czy też skrajnie niska - nie wpływa na jakość sygnału. To nie prawda, że jest to usługa dostępna tylko w dużych miastach. Wielu małych i średnich lokalnych operatorów dociera w miejsca, gdzie innym się nie opłaca, podłączając do internetu mieszkańców nawet bardzo małych miejscowości. Internet światłowodowy dosłownie przesyła wewnątrz przewodu światło. Odbija się ono w rdzeniu, który zazwyczaj powstaje z włókna szklanego lub półprzewodnika. Odbijające się światło przenosi dane dosłownie z prędkością światła. Internet dostarczany tą technologią będzie coraz szybszy – w miarę postępu technologicznego."
            bcgcolor="#fff"
            avatarBgColor="#db2251"
            icon={
              <RouterIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
          <InfoCard
            header="Internet radiowy"
            text="Internet radiowy umożliwia korzystanie z zasobów sieci, przesyłając dane za pomocą fal radiowych, wykorzystując do tego częstotliwości 2,4 GHz oraz 5 GHz. Wymaga specjalnego urządzenia nadającego i odbierającego sygnał. Jest to druga po internecie światłowodowym najlepsza spośród dostępnych technologii. Warunki atmosferyczne, szczególnie skrajne, takie jak burze, bardzo niska temperatura powodująca oblodzenie urządzeń, mogą wpłynąć na jakość połączenia. Internet radiowy dociera jednak w miejsca, gdzie nie sposób jest dotrzeć światłowodem, dodatkowo jakość połączenia jest – ze względu na stabilność -  zdecydowanie lepszym wyborem niż internet mobilny."
            bcgcolor="#fff"
            avatarBgColor="#ba1fa5"
            icon={
              <WifiIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
          <InfoCard
            header="Internet mobilny"
            text="Internet mobilny to relatywnie prosty sposób połączenia z siecią. Zaletą niewątpliwie jest dostępność. Większość ofert telefonii komórkowej zapewnia pakiet danych. Ten rodzaj łącza dostępny jest w wielu ofertach. Może ono zostać wykorzystane jako łącze zapasowe lub w przypadku, gdy nie ma innej możliwości łącze podstawowe. Najmniej stabilne spośród dostępnych technologii, prędkość uzależniona jest od wielu czynników, takich jak: pogoda, liczba użytkowników podłączonych w danej chwili do nadajnika sieci i korzystających z usługi."
            bcgcolor="#fff"
            avatarBgColor="#2d4aad"
            icon={
              <TapAndPlayIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
          <InfoCard
            header="Telewizja cyfrowa"
            text="Telewizja cyfrowa to wysokiej jakości dostęp do setek kanałów. Sygnał telewizyjny dostarczany jest za pośrednictwem Internetu. Wysokie przepustowości pozwalają na odbiór kanałów w jakości HD, 4K, a nawet 8K, również gdy programy odbierane są na kilku urządzeniach jednocześnie. Obraz jest krystalicznie czysty i kolorowy. Telewizja cyfrowa to nowe doświadczenie, które przeniesie użytkownika w świat aktualnie oglądanego programu."
            bcgcolor="#fff"
            avatarBgColor="#18a75c"
            icon={
              <TvIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
          <InfoCard
            header="Telefon komórkowy"
            text="Telefon komórkowy to opcja dostępna nie tylko u znanych z telewizji telekomów. Usługa ta dostępna jest również u małych i średnich operatorów telekomunikacyjnych. Połączenia realizowane są za pośrednictwem tych samych łączy, które udostępnia infrastrukturalny operator, często zdarza się, że w łączonej ofercie z internetem światłowodowym czy telewizją cyfrową ceny są korzystniejsze."
            bcgcolor="#fff"
            avatarBgColor="#a9ce50"
            icon={
              <PhoneIphoneIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
          <InfoCard
            header="Telefon domowy"
            text="Telefon domowy to nadal popularny rodzaj kontaktu. Szczególnie dla osób, które nie są usatysfakcjonowane jakością połączeń komórkowych w swoim domu, korzystają lub odbierają połączenia międzynarodowe od rodziny. Telefon domowy realizowany jest za pośrednictwem łącza cyfrowego, dzięki czemu zapewnia zdecydowanie wyższą jakość w porównaniu do łącza analogowego."
            bcgcolor="#fff"
            avatarBgColor="#f29a26"
            icon={
              <PhoneIcon
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            }
          />
        </Box>
        <ImageScroller />
      </Box>
    </>
  );
};
