import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Theme } from "../styles/styles";
import { Box, Button, Checkbox, Link, Modal, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";

import { LabelLeftInput } from '../Atoms/LabelLeftInput';
import { AcceptConsent } from '../Atoms/AcceptConsent';
import { CustomizedCheckBoxGroup } from '../Atoms/CustomizedCheckBoxGroup';
import { Approvals } from '../Privacy/Approvals';
import Privacy from '../Privacy/Privacy';
//import useStore from './store';


export const NewLeadForm = ({ address, numOfOperators, radioList,
    piDataConsentText, conditionsConsentText, marketingConsentText,
    onCancel, onSave, }) => {

    const [piChecked, setPiChecked] = React.useState(false);
    const [conditionsChecked, setConditionChecked] = React.useState(false);
    const [marketinChecked, setMarketingChecked] = React.useState(false);
    const [isSaveActive, setIsSaveActive] = React.useState(false);

    const [isOpenPrivacyModal, setIsOpenPrivacyModal] = React.useState(false);
    const [isOpenApprovalsModal, setIsOpenApprovalsModal] = React.useState(false);

    const [isEmailValidated, setIsEmailValidated] = React.useState(false);
    const [isPhoneValidated, setIsPhoneValidated] = React.useState(false);

    const handleClosePrivacyModal = () => setIsOpenPrivacyModal(false);
    const handleCloseApprovalsModal = () => setIsOpenApprovalsModal(false)

    const [formState, setFormState] = React.useState({
        //searchId: 1234,
        name: "",
        address: address,
        contactPhone: "",
        emailAddress: "",
        internet: false,
        tv: false,
        tel: false,
        biz: false,
        companyName: "",
        nip: "",
        service: "",
    })

    React.useEffect(() => {
        setIsSaveActive(piChecked && conditionsChecked && marketinChecked)
    }, [piChecked, conditionsChecked, marketinChecked]);

    React.useEffect(() => {
        //console.log(formState);
    }, [formState]);

    const handlePiChange = (event) => {
        setPiChecked(event.target.checked);

    };

    const handleConditionChange = (event) => {
        setConditionChecked(event.target.checked);

    };

    const handleMarketingChange = (event) => {
        setMarketingChecked(event.target.checked);

    };

    const handleFormChange = (obj) => {
        setFormState({
            ...formState,
            ...obj
        });
    };

    const handleAcceptApprovals = () => {

        setIsOpenApprovalsModal(false);
        //localStorage.setItem('privacyApprovalsGranted', true);
    }

    const handleSave = () => onSave(formState);

    const handleClick = (event) => {
        event.preventDefault();  // Zapobiega domyślnemu działaniu linku
        setIsOpenApprovalsModal(true);
    };

    //const { piConsent } = useStore();

    const updatedConditionsConsentText = (
        <span>
            {`Wyrażam zgodę na udostępnienie moich danych lokalnym operatorom telekomunikacyjnym, przygotowanie przez nich oferty dla mnie i kontakt ze mną. Oświadczam, że znam `}
            <Link href="#" onClick={handleClick}>
                Regulamin
            </Link>
            {` korzystania z Usług Portalu Lokalni.pl, w tym zasady przetwarzania danych osobowych opublikowane w `}
            <Link href="#" onClick={handleClick}>
                Polityce prywatności
            </Link>
            {`, akceptuję ich treść i przyjmuję do stosowania.`}
        </span>
    );

    return (
        <ThemeProvider theme={Theme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "96%",
                    alignItems: "center",
                    justifyContent: "space-around",
                    minHeight: "1218px",
                    borderRadius: "16px",
                    backgroundColor: "#FFFFFF",
                    p: "12px",
                    [Theme.breakpoints.up("tablet")]: {
                        width: "90%",
                        minHeight: "779px",

                    },
                    [Theme.breakpoints.up("laptop")]: {
                        width: "90%",
                        minHeight: "781px",
                        p: "32px",
                    },
                    [Theme.breakpoints.up("desktop")]: {
                        width: "60%",
                        minHeight: "781px"
                    },

                }}
            >

                <Typography variant="subtitle2" sx={{ fontSize: "18px" }}>
                    Adres:
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: "18px", color: "#2B46AF", mb: "12px" }}>
                    {address ? address : ""}
                </Typography>

                <Typography variant="h4" sx={{ fontSize: "28px", textAlign: "center", mb: "12px" }}>
                    Jesteś w zasięgu <span style={{ color: "#F49B00" }}>{numOfOperators}</span> operatorów
                </Typography>

                <Typography variant="subtitle2" sx={{ fontSize: "18px", mb: "12px" }}>
                    Jakiej usługi szukasz?
                </Typography>


                <CustomizedCheckBoxGroup checkboxList={radioList} changeHandler={handleFormChange} />

                <Typography variant="subtitle2" sx={{ fontSize: "18px", textAlign: "center", mb: "12px", }}>
                    Zostaw nam swoje dane,
                    a przygotujemy dla Ciebie
                    specjalną ofertę.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "12px",
                        width: "100%",
                        mb: "16px"
                    }}
                >
                    <LabelLeftInput label="Twoje imię" placeholderText="np. Jan Kowalski" name="name" changeHandler={handleFormChange} />
                    <LabelLeftInput label="Adres" name="address" changeHandler={handleFormChange} inputText={address} />
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            [Theme.breakpoints.down("tablet")]: {
                                flexDirection: "column",
                                gap: "12px",
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                flexDirection: "row",
                                justifyContent: "space-between",

                            },
                        }}

                    >
                        <Box
                            sx={{

                                [Theme.breakpoints.up("tablet")]: {
                                    width: "49%",

                                },
                            }}
                        >
                            <LabelLeftInput label="Telefon" placeholderText="np. 500 600 700"
                                name="contactPhone"
                                isEmail={false}
                                isPhone={true}
                                checkIsPhoneValidated={(prop) => setIsPhoneValidated(prop)}
                                checkIsEmailValidated={(prop) => setIsEmailValidated(prop)}
                                changeHandler={handleFormChange}
                            />

                        </Box>
                        <Box
                            sx={{

                                [Theme.breakpoints.up("tablet")]: {
                                    width: "49%",

                                },
                            }}
                        >
                            <LabelLeftInput label="E-mail" placeholderText="np. jan.kowalski@lokalni.pl"
                                name="emailAddress"
                                isEmail={true}
                                isPhone={false}
                                checkIsPhoneValidated={(prop) => setIsPhoneValidated(prop)}
                                checkIsEmailValidated={(prop) => setIsEmailValidated(prop)}
                                changeHandler={handleFormChange}
                            />

                        </Box>

                    </Box>


                    {formState.biz &&
                        <LabelLeftInput label="Firma" placeholderText="..." name="companyName" changeHandler={handleFormChange} />
                    }

                    {formState.biz &&
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                [Theme.breakpoints.down("tablet")]: {
                                    flexDirection: "column",
                                    gap: "12px",
                                },
                                [Theme.breakpoints.up("tablet")]: {
                                    flexDirection: "row",
                                    justifyContent: "space-between",

                                },
                            }}

                        >

                            <Box
                                sx={{

                                    [Theme.breakpoints.up("tablet")]: {
                                        width: "49%",

                                    },
                                }}
                            >
                                <LabelLeftInput label="NIP" placeholderText="..." name="nip" changeHandler={handleFormChange} />

                            </Box>
                            <Box
                                sx={{

                                    [Theme.breakpoints.up("tablet")]: {
                                        width: "49%",

                                    },
                                }}
                            >
                                <LabelLeftInput label="Usługa" placeholderText="np. transmisja danych" name="service" changeHandler={handleFormChange} />

                            </Box>

                        </Box>
                    }

                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", mb: "12px" }}>
                    <AcceptConsent label={piDataConsentText} checked={piChecked} handleChange={handlePiChange} />
                    <AcceptConsent label={updatedConditionsConsentText} checked={conditionsChecked} handleChange={handleConditionChange} />
                    <AcceptConsent label={marketingConsentText} checked1={marketinChecked} handleChange={handleMarketingChange} />

                </Box>

                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-evenly", mt: "12px", mb: "16px", }}>
                    <Button
                        onClick={() => {
                            onCancel();
                        }}
                        sx={{
                            ...Theme.outlinedOrangeButton,
                            [Theme.breakpoints.down("tablet")]: {
                                width: "145px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "151px"
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                width: "166px"
                            },


                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={!isSaveActive}
                        sx={{
                            ...Theme.OrangeButton,
                            [Theme.breakpoints.down("tablet")]: {
                                width: "145px"
                            },
                            [Theme.breakpoints.up("tablet")]: {
                                width: "151px"
                            },
                            [Theme.breakpoints.up("laptop")]: {
                                width: "166px"
                            },

                        }}
                    >
                        Wyślij
                    </Button>
                </Box>

            </Box>
            <Modal
                open={isOpenPrivacyModal}
                onClose={handleClosePrivacyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                        borderRadius: "24px",
                        backgroundColor: "white",
                        paddingTop: "44px",
                        display: "flex",
                        flexDirection: "column",

                        [Theme.breakpoints.down("tablet")]: {
                            width: "90vw",
                        },
                    }}
                >
                    <Privacy />
                    <Button
                        onClick={() => {
                            handleClosePrivacyModal();
                        }}
                        sx={{
                            ...Theme.OrangeButton,
                            alignSelf: "flex-end",

                            mr: "44px",
                            mb: "44px",
                        }}
                    >
                        Zamknij
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={isOpenApprovalsModal}
                onClose={handleCloseApprovalsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                        borderRadius: "24px",
                        backgroundColor: "white",
                        paddingTop: "44px",
                        display: "flex",
                        flexDirection: "column",

                        [Theme.breakpoints.down("tablet")]: {
                            width: "90vw",
                        },
                    }}
                >
                    <Privacy />
                    <Button
                        onClick={() => {
                            handleAcceptApprovals()
                        }}
                        sx={{
                            ...Theme.OrangeButton,
                            alignSelf: "flex-end",

                            mr: "44px",
                            mb: "44px",
                        }}
                    >
                        Zamknij
                    </Button>
                </Box>
            </Modal>
        </ThemeProvider >
    )
}