import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
//import Container from "@material-ui/core/Container";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import Avatar from "@mui/material/Avatar";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,

} from "@mui/material";
import { getApplicationData } from "../../requests";

const ResponsiveAppBar = ({
  //pages = ["WYSZUKIWARKA", "O NAS", "USŁUGI", "BLOG", "MAPA DOBRA", "KONTAKT"],



  bgcolor = "#f2f3f5",
  btnbgcolor = "#f2f3f5",
  fgcolor = "black",
  city,

}) => {


  const [anchorEl, setAnchorEl] = useState(null);  // For controlling the Menu open/close
  const [breadcrumbPath, setBreadcrumbPath] = useState(['Home']);  // Track breadcrumb path
  const navigate = useNavigate();  // React Router's hook to navigate programmatically

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);  // Open the Menu
  };

  const handleClose = () => {
    setAnchorEl(null);  // Close the Menu
  };

  const handleMenuItemClick = (path, label) => {
    setBreadcrumbPath((prevPath) => [...prevPath, label]);  // Update breadcrumbs
    navigate(path);  // Navigate to the selected path
    handleClose();  // Close the Menu
  };


  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [enteredValue, setEnteredValue] = useState("");

  const [openNotReady, setopenNotReady] = useState(false);

  const [lokalniData, setLokalniData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const searchedPlace = localStorage.getItem("searchedPlace");
  const placeID = localStorage.getItem("placeID");

  const pages = [{ name: "WYSZUKIWARKA", url: "/" }, { name: "O NAS", url: "/onas/" }, { name: "USŁUGI", url: "/uslugi/" },
  { name: "BLOG", url: "/blog/" }, { name: "KONTAKT", url: "/kontakt/" },
  { name: "MAPA DOBRA", url: placeID ? `/mapadobra/${searchedPlace}/${placeID}` : enteredValue ? `/mapadobra/${enteredValue}` : `/mapadobra/${city}` }];

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    const label = event.target.innerText.trim();
    switch (label) {
      case "BLOG":
        //navigate("/lokalni.pl/blog ");
        //setopenNotReady(true);
        window.location.href = 'http://lokalni.pl/blog/';
        break;

      case "O NAS":
        navigate(`/onas/`);
        break;

      case "USŁUGI":
        navigate(`/uslugi/`);
        break;

      case "MAPA DOBRA":
        //const searchedPlace = localStorage.getItem("searchedPlace");
        //const placeID = localStorage.getItem("placeID");
        if (placeID) {
          navigate(`/mapadobra/${searchedPlace}/${placeID}`);
        } else if (enteredValue) {
          navigate(`/mapadobra/${enteredValue}`);
        } else {
          navigate(`/mapadobra/${city}`);
        }
        break;

      case "WYSZUKIWARKA":
        navigate(`/`);
        break;

      case "KONTAKT":
        window.location.href = 'http://lokalni.pl/kontakt/';
        break;

      default:
        console.log(`Sorry, we are out of ${name}.`);
    }
  };


  //const navigate = useNavigate();

  useEffect(() => {
    getApplicationData()
      .then((results) => {
        if (
          results.status !== 200 &&
          results.status !== 201 &&
          results.status !== 202 &&
          results.status !== 203
        ) {
          setLokalniData({});
          return;
        }
        return results.json();
      })
      .then((result) => {
        if (result.status === "Company not found") {
          setLokalniData({});
          return;
        }
        const dane = result;
        setLokalniData(dane);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Actuator => ERROR HAS OCCURRED: " + error);
      });
  }, []);



  return (
    <>
      <AppBar
        position="sticky"
        id="header-test"
        sx={{
          gridArea: "nav",
          width: "100vw",
          backgroundColor: `${bgcolor}`,
          color: `${fgcolor}`,
          height: "fit-content",
          // position: 'fixed',
          zIndex: "1200",
        }}
      >
        <Container maxWidth="xl" sx={{ marginRight: "40px" }}>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "flex", sm: "flex" },
                flexGrow: { md: "1", sm: "1" },
              }}
            // onClick={()=>{navigate(`/home/`)}}
            >
              <Box
                sx={{ display: "flex", mr: "16px", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/`);
                }}
              >
                <Avatar alt="Logo" src={logo} sx={{ width: "200px", height: "58px" }} variant="square" />
              </Box>
            </Typography>
            <Box
              sx={{
                flexGrow: { md: "1", sm: "1" },
                display: { xs: "flex", md: "none", sm: "flex" },
                justifyContent: "end",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleClose}
                sx={{
                  display: { xs: "block", md: "none", sm: "block" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: { md: "1", sm: "1" },
                display: {
                  xs: "none",
                  md: "none",
                  sm: "none",
                  color: `${fgcolor}`,
                },
              }}
            >
              {/*LOKALNI*/}
            </Typography>
            <Box>
              <Box
                sx={{
                  flexGrow: { md: "2", sm: "2" },
                  display: { xs: "none", md: "flex", sm: "none" },
                }}
              >

                {/** pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: `${fgcolor}`,
                      display: "block",
                      backgroundColor: `${btnbgcolor}`,
                    }}
                  >
                    {page}
                  </Button>
                ))**/}
                <Breadcrumbs separator=""

                >
                  {pages.map((page) => (
                    <Button
                      key={page.name}
                      component="a"
                      onClick={() => navigate(page.url)}
                      sx={{
                        my: 2,
                        color: `${fgcolor}`,
                        display: "block",
                        //backgroundColor: `${btnbgcolor}`,

                      }}
                    >
                      {page.name}
                    </Button>
                  ))}
                </Breadcrumbs>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {
        <Dialog
          open={openNotReady}
          onClose={() => {
            setopenNotReady(false);
          }}
          onBackdropClick={() => {
            setopenNotReady(false);
          }}
        >
          <DialogTitle>Blog jest tymczasowo niedostępny.</DialogTitle>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <DialogContent>
              <div>
                <strong>build:</strong> {lokalniData?.build}
                <div></div>
                <strong>version:</strong> {lokalniData?.applicationVersion}
              </div>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                setopenNotReady(false);
              }}
            >
              Powrót
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

ResponsiveAppBar.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.string),
  settings: PropTypes.arrayOf(PropTypes.string),
  bgcolor: PropTypes.string,
  btnbgcolor: PropTypes.string,
  fgcolor: PropTypes.string,
};

export default ResponsiveAppBar;
