import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

export default function Popup(props) {
   const {message,children,openPopup,setOpenPopup,title='Problem',disabledButton=false} = props ;
    return (
        <Dialog open={openPopup} maxWidth="md"
                onClose={setOpenPopup}
                disableEscapeKeyDown={disabledButton}
                disablebackdropclick={disabledButton}
                sx={{
            width: '100',
        }}>
            <DialogTitle>
                <div>{title}</div>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}

            >
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={setOpenPopup} autoFocus disabled={disabledButton}>
                    Zamknij
                </Button>
            </DialogActions>
        </Dialog>
    );
}



