import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";

export const SeoInfoBox =
    (
        {
            header,
            text
        },

    ) => {
        return (
            <>
                <Box

                    sx={{
                        display: "flex",
                        borderRadius: "24px",
                        backgroundColor: "#fff",
                        flexDirection: "column",
                        alignItems: "flex-start",

                        [Theme.breakpoints.down("tablet")]: {
                            width: "81vw",
                            p: "19px",
                        },
                        [Theme.breakpoints.up("tablet")]: {
                            width: "89.5vw",
                            p: "19px",

                        },
                        [Theme.breakpoints.up("laptop")]: {
                            width: "87.5vw",
                            p: "24px",
                        },
                        [Theme.breakpoints.up("desktop")]: {
                            width: "76vw",
                            p: "24px",
                        },
                    }}
                >
                    <ThemeProvider theme={Theme}>
                        <h1>
                            <Typography variant="subtitle"
                                sx={{
                                    fontSize: 22,
                                    lineHeight: 1.27,
                                    letterSpacing: "0.2px",
                                    [Theme.breakpoints.up("tablet")]: {
                                        fontSize: 24,
                                    },
                                }}>{header}
                            </Typography>
                        </h1>
                        <Typography variant="bodyMediumRegural"
                            sx={{

                            }}>{text}
                        </Typography>


                    </ThemeProvider>

                </Box>
            </>
        );
    }

