export const defaultOptions = {
    announcement: {
        position: "absolute",
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: "1px",
        width: "1px",
        // overflow: "hidden"
    },
    combobox: {
        display: "inline-block"
    },
    searchField: {
        padding: ".5rem",
        // border: "2px solid #c8c8c8",
        backgroundColor: "#fff",
        borderRadius: "45px",
        color: "#000",
        fontWeight: "normal",
        fontSize: "1.35rem",
        margin: "0 auto",
        width: "45rem",
        display: "flex",
        focus: {
            color: "#000",
            // border: "2px solid #005499",
            // outline: "none"
        }
    },
    searchLabel: {
        display: "block",
        fontSize: "1.35rem"
    },
    suggestionsContainer: {
        display: "flex",
        position: "absolute",
        top: "100%",
        width: "70%",
        margin: "8px 0 0",
        padding: "14.4px 14px 23.4px",
        borderRadius: "45px",
        boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        backgroundColor: "#fff",
    },

    suggestionsContainer2: {
        display: "block",
        position: "absolute",
        border: "1px solid #999",
        background: "#fff",
        borderRadius: "45px",
        width: "45rem",
        zIndex: "1"

    },
    suggestionOptions: {

        margin: "0",
        padding: "0",
        // listStyle: "none"
    },
    suggestionOption: {
        margin: "0",
        // padding: ".5rem",
        fontSize: "1.35rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        cursor: "default",
        borderRadius: "45px"

    },

};