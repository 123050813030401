import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import ResponsiveAppBar from "./MainAppBar";
import { useLocation } from 'react-router-dom';

const Layout = (
    city,
    backOfficeFlag,
) => {
    const location = useLocation();
    
   
    return (
        location.pathname==="/zaplecze/" ? (
            <Outlet />
        ) :
            <div id='appcontainer'>
                <ResponsiveAppBar
                    city={`${city}`} />

                <Outlet />
            </div>
    )
}

export default Layout