import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

export const InfoCard = ({ header, text, icon, bcgcolor, avatarBgColor }) => {
  return (
    <Box
      sx={{
        bgcolor: `${bcgcolor}`,
        [Theme.breakpoints.down("tablet")]: {
          width: "82.8vw",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "89.6vw",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "52.6vw",
        },
        borderRadius: 4,
        padding: 3,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box id="icon">
            <Avatar
              sx={{
                bgcolor: avatarBgColor,
                width: 44,
                height: 44,
                ml: 2,
                mr: 3,
                display: "flex",
              }}
            >
              {icon}
            </Avatar>
          </Box>
          <Box
            id="message"
            sx={{
              ml: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle"
              lineHeight={1}
              letterSpacing={0.16}
              marginBottom="8px"
            >
              {header}
            </Typography>
            <Typography
              variant="bodyMediumRegural"
              lineHeight={1.29}
              letterSpacing={0.13}
            >
              {text}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
