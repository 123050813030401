import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Avatar } from "@mui/material";
import koalaTelImg from "../../images/marketing/koala.png";
import kameleonTvImg from "../../images/marketing/kameleon.png";

const chipColors = ["#db2251", "#ba1fa5", "#2a45ad", "#18a75c"]

const ServiceListItem = ({ category, descriptions, other }) => {
    return (

        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", mb:"16px"}}>
            <ThemeProvider theme={Theme}>


                <Typography variant="bodySmallBlack"
                    sx={{
                        [Theme.breakpoints.up("laptop")]: {
                            fontSize: 20,
                            fontWeight: 900,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#363636",
                        },
                        [Theme.breakpoints.up("desktop")]: {
                            fontSize: 24,

                        },

                    }}
                >
                    {category}
                </Typography>


                <Box sx={{
                    //width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    //alignItems: "space-between",
                    mt: "18px",
                    
                    flexWrap: "wrap",


                }}>
                    {descriptions.map((el, idx) => (
                        <Typography variant="bodySmallRegural" sx={{
                            mr:"7.5%",
                            [Theme.breakpoints.up("tablet_new")]: {
                                
                                mr:"2%",

                            },
                            [Theme.breakpoints.up("laptop")]: {
                                fontSize: 20,
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                color: "#363636",
                                mr:"0.4%"
                            },
                            [Theme.breakpoints.up("laptop_new")]: {
                                
                                mr:"5.5%",

                            },
                            [Theme.breakpoints.up("desktop")]: {
                                fontSize: 24,
                                mr:"0.2%",

                            },
                        }}>
                            <Box sx={{ display: "flex", mr:"24px" }}>
                                <Box sx={{ backgroundColor: chipColors[idx], width: "8px", borderRadius: "4px", mr: "9px", mb: "9px" }} ></Box>

                                <Box
                                    sx={{
                                        mb: "9px",
                                        width: "130px",

                                        [Theme.breakpoints.up("tablet")]: {
                                            //mr: "8px",

                                        },

                                        [Theme.breakpoints.up("laptop")]: {
                                            width: "214px",

                                        },

                                        [Theme.breakpoints.up("desktop")]: {
                                            width: "300px",

                                        },

                                    }}>

                                    {category === "Internet mobilny" && el
                                        ? `${el.productName}  ${el.productDescription} Mb/s`
                                        : category === "Internet radiowy" && el
                                            ? `${el.productName}  ${el.productDescription} Mb/s`
                                            : category === "Internet światłowodowy" && el
                                                ? `${el.productName}  ${el.productDescription} Mb/s`
                                                : category === "Telefon domowy" && el
                                                    ? `${el.productName}  ${el.productDescription} Darmowych minut`
                                                    : category === "Telefon komórkowy" && el
                                                        ? `${el.productName}  ${el.productDescription == 0
                                                            ? "-bez limitu"
                                                            : el.productDescription && "GB"
                                                        }`
                                                        : category === "Telewizja" && el
                                                            ? `${el.productName}  ${el.productDescription} Kanałów`
                                                            : `${el.productName}  ${el.productDescription}`}

                                </Box>
                            </Box>
                        </Typography>
                    ))}
                </Box>

            </ThemeProvider>
        </Box>


    );
};

export const ServicesInfoBox = ({ serviceName, services, koalaTel, kameleonTV }) => {

    return (
        <Box
            sx={{

                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                borderRadius: "24px",


                [Theme.breakpoints.down("tablet")]: {
                    width: "81vw",
                    p: "19px",
                },
                [Theme.breakpoints.up("tablet")]: {
                    width: "89.5vw",
                    p: "19px",
                    pt: "12px"
                },
                [Theme.breakpoints.up("laptop")]: {
                    width: "87.5vw",
                    p: "24px",
                    minHeight: 267,
                },
                [Theme.breakpoints.up("desktop")]: {
                    width: "76vw",
                    p: "24px",
                    minHeight: 267,
                },
            }}
        >
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                mb:"18px", 
                [Theme.breakpoints.down("tablet")]: {
                    flexDirection: "column",
                    alignItems:"flex-start",
                },
                [Theme.breakpoints.up("tablet")]: {
                    flexDirection: "row",
                    
                },
                
                }}>
                <ThemeProvider theme={Theme}>
                    <Typography variant="bodyVeryLargeBlack" marginLeft="16px" marginTop="16px" textTransform="uppercase"
                        sx={{
                            [Theme.breakpoints.up("laptop")]: {
                                fontSize: 24,
                            },
                        }}
                    >{serviceName}</Typography>
                </ThemeProvider>
                {kameleonTV &&
                    <Avatar variant="rounded" sx={{ width: "200px", height: "auto", ml: "24px" }} src={kameleonTvImg} />
                }
                {koalaTel &&
                    <Avatar variant="rounded" sx={{ width: "200px", height: "auto", ml: "24px" }} src={koalaTelImg} />
                }
            </Box>

            <List sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", width: "100%" }}>
                {services
                    //              
                    .map((filtredItem, i) => (
                        <ListItem
                            key={i}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "transparent", 
                                    // Remove the background color on hover
                                },
                            }}
                        >
                            {filtredItem.label.length > 0 ? (
                                <ServiceListItem
                                    key={i}
                                    category={filtredItem.info.length > 0 ? filtredItem.label : ""}
                                    descriptions={filtredItem.info}
                                    other={filtredItem.other}
                                />
                            ) : (
                                "Brak danych"
                            )}
                        </ListItem>
                    ))}
            </List>

        </Box>

    )

}
