import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { InputIconTextItem } from "../../Components/Atoms/InputIconTextItem";

export const AcceptGoodForm = ({
  boldText = "Czy na pewno chcesz zaakceptować",
  boldText2 = "użytkownika",
  reguralText,
  header,
  goodName,
  misotName,
  goodPointsValueHandler,
  justificationValueHandler,
  cancelActionHandler,
  saveActionHandler,
  disableVerifyButton,
  top = '50%',
  left = '50%',
  transform = 'translate(-50%, -50%)',
  position = 'absolute',
}) => {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        id="mainBox"
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          width: "23%",
          minHeight: "570px",
          p: "34px 31px 40px 40px",
          borderRadius: "24px",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-around",
          top: top,
          left: left,
          transform: transform,
          position: position,
        }}
      >
        <Box id="boldText" sx={{mb:"24px"}}>
          <Typography variant="h4">{`${boldText} "${goodName}" ${boldText2} ${misotName}`}</Typography>
        </Box>
        <Box id="reguralText">
          <Typography variant="bodyMediumBlack">Nazwa dobra:</Typography>
        </Box>
        <Box id="reguralText">
          <Typography variant="bodyMediumRegural" sx={{mb:"24px"}}>{header}</Typography>
        </Box>
        <Box id="reguralText">
          <Typography variant="bodyMediumBlack">Opis dobra:</Typography>
        </Box>
        <Box id="reguralText" sx={{mb:"24px", maxHeight: 200, overflow: "auto"}}>
          <Typography variant="bodyMediumRegural">{reguralText}</Typography>
        </Box>
        <Box id="inputTextGoodPoints">
          <InputIconTextItem
            label="Wpisz ilość punktów"
            placeholder="np. 25"
            icon={null}
            customWidth="19vw"
            getValue={goodPointsValueHandler}
          />
        </Box>
        <Box id="inputTextJustyfication" sx={{mb:"24px"}}>
          <InputIconTextItem
            label="Uzasadnienie punktacji"
            placeholder="np. zgodność z regulaminem"
            icon={null}
            customWidth="19vw"
            getValue={justificationValueHandler}
          />
        </Box>
        <Box
          id="ButtonsCancelAccept"
          sx={{
            width: "65%",
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            gap: 14,
          }}
        >
          <Button
            onClick={cancelActionHandler}
            variant="outlined"
            sx={{
              ...Theme.outlinedAcceptBlackButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
                width: 90,
              },
            }}
          >
            Anuluj
          </Button>

          <Button
            disabled={disableVerifyButton}
            onClick={
              saveActionHandler
            }
            variant="outlined"
            sx={{
              display: { disableVerifyButton },
              ...Theme.containedGreenButton,
              [Theme.breakpoints.down("laptop")]: {
                fontSize: 12,
              },
            }}
          >
            Akceptuj
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

AcceptGoodForm.propTypes = {
  goodPointsValueHandler: PropTypes.func,
  justificationValueHandler: PropTypes.func,
  cancelActionHandler: PropTypes.func,
  saveActionHandler: PropTypes.func,
};
