import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { StatusBox } from "./StatusBox";

export const GoodHistoryListItem = ({
  date,
  updatedBy,
  comments,
  status,
  buttonCancelEvent
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 92,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08);",
        borderRadius: "14px",
          // bgcolor: "yellow"
      }}
    >
      <ThemeProvider theme={Theme}>
          <Box
              sx={{
                  display: "flex",
                  bgcolor:'white',
                  width: '25%'
              }}
          >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: '100%',
          }}
        >
          <Typography variant="bodyMediumRegural" color="#9d9d9d">Data</Typography>
          <Typography variant="bodyMediumBlack">{date}</Typography>
        </Box>
          </Box>
          <Box
              sx={{
                  display: "flex",
                  bgcolor:'white',
                  width: '25%'
              }}
          >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
              width: '100%',
          }}
        >
          <Typography variant="bodyMediumRegural" color="#9d9d9d">Status</Typography>
          <StatusBox statusMessage={status} defaultWidth={170}/>
        </Box>
          </Box>
          <Box
              sx={{
                  display: "flex",
                  bgcolor:'white',
                  width: '25%'
              }}
          >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
              width: '100%',

          }}
        >
          <Typography variant="bodyMediumRegural" color="#9d9d9d">
            Aktualizowany przez
          </Typography>
          <Typography variant="bodyMediumBlack">{updatedBy}</Typography>
        </Box>
          </Box>
          <Box
          sx={{
              display: "flex",
              bgcolor:'white',
              width: '25%'
          }}
          >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
              width: '100%',

          }}
        >
          <Typography variant="bodyMediumRegural" color="#9d9d9d">Uwagi</Typography>
          <Typography variant="bodyMediumBlack">{comments}</Typography>
        </Box>
          </Box>
      </ThemeProvider>
    </Box>
  );
};
