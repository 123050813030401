import React, { useState, useEffect, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Theme } from "../styles/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { SearchIconButton } from "../SearchIconButton/SearchIconButton";

export const AutocompleteLocalSearch = ({
  searchHandler,
  getPredictions,
  getValue,
  setValue,
  getPlace,
}) => {
  const debouncedSearchHandler = useMemo(
    () => debounce(searchHandler, 700),
    []
  );

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={getPredictions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={getValue}
      noOptionsText="np. Kraków PCK 12"
      onChange={(event, newValue) => {
        setValue(newValue);
        getPlace(newValue);
      }}
      autoHighlight
      onInputChange={debouncedSearchHandler}
      renderInput={(params) => (
        <Paper
          component="form"
          sx={{
            p: "10px 16px 2px 24px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            // width: customWidth,
            // minHeight: customHeight,
            borderRadius: "45px",
            boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
            [Theme.breakpoints.down("tablet")]: {
              width: "91vw",
            },
            [Theme.breakpoints.up("tablet")]: {
              width: "57vw",
            },
            [Theme.breakpoints.up("laptop")]: {
              width: "36vw",
            },
            [Theme.breakpoints.up("laptop")]: {
              width: "38vw",
            },
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">
              Wpisz lokalizację, a znajdziemy ofertę!
            </Typography>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                fontFamily: "Lato",
                fontSize: 14,
                color: "#363636",
                fontWeight: "normal",
                width: "90%",
                backgroundColor: "#fff",
              }}
              {...params}
              ref={params.InputProps.ref}
              //sx={{ ml: "24px", flex: 1 }}
              placeholder="Wprowadź adres"
            />
          </Box>

          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => {
              getPlace();
            }}
          >
            <SearchIconButton />
          </IconButton>
        </Paper>
      )}
      popupIcon={<SearchIconButton />}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          //<Paper sx={{borderRadius:"16px"}}>
          <li {...props}>
             
            <Grid container alignItems="center" sx={{ borderRadius: 10 }}>
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "#f29a26" }} />
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: part.highlight ? "bold" : "regular",
                      color:"gray",
                    }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondaryblack">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
           
          </li>
          //</Paper>
        );
      }}
    />
  );
};
