import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { debounce } from "@mui/material";

export const InputIconTextItem = ({
  id,
  isEmail = false,
  isPhone = false,
  isDescription,
  label,
  name,
  disabled,
  placeholder="wpisz tekst...",
  text = null,
  text2 = null,
  getValue,
  icon,
  customWidth = "34.7%",
  customHeight = "55px",
  multiLine = false,
  minRows,
  maxRows,
  error,
  helperText,
  delay = 1000,
  onChangeActionHandler,
  onChangeServicesList,
  onChangeHistoryHandler,
  type = "text",
  checkIsPhoneValidated,
  checkIsEmailValidated,
}) => {
  const [value, setValue] = React.useState(text ? text : text2);
  const [debounceValue] = useDebounce(value, delay);

  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState(true);

  const validateEmail = (email) => {
    // Regular expression to validate email addresses
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression to validate Polish phone numbers (9 digits)
    //const phoneRegex = /^(?:(?:(\+|00)48)|(?:0))?(?:(61|62|63|65|67|68|69|71|74|75|76|77|81|82|83|84|85|86|87|89)[1-9]\d{6}|[1-9]\d{8})$/;
    const phoneRegex =
      /^(?:(?:(\+|00)48)|(?:0))?\s?(?:(61|62|63|65|67|68|69|71|74|75|76|77|81|82|83|84|85|86|87|89))?\s?[1-9]\d{2}\s?\d{3}\s?\d{3}$/;
    return phoneRegex.test(phoneNumber);
  };

  useEffect(() => {
    getValue && getValue(debounceValue);
  }, [debounceValue, getValue]);

  useEffect(() => {
    isEmail && checkIsEmailValidated(isValidEmail ? true : false);
    isPhone && checkIsPhoneValidated(isValidPhoneNumber ? true : false);
  }, [isValidPhoneNumber, isValidEmail]);

  //const debounceHandleChange = debounce(handleChange, 500)

  const handleChange = (event) => {
    const tmpValue = event.target.value;
    isEmail && setIsValidEmail(validateEmail(tmpValue));
    isPhone && setIsValidPhoneNumber(validatePhoneNumber(tmpValue));
    event.preventDefault();
    setValue(event.target.value);
    onChangeActionHandler &&
      onChangeActionHandler({ [event.target.name]: event.target.value });
    onChangeServicesList &&
      onChangeServicesList(
        { [event.target.name]: event.target.value },
        id,
        isDescription
      );
    onChangeHistoryHandler && onChangeHistoryHandler(event.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <Paper
        component="form"
        sx={{
          p: "10px 16px 2px 24px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: customWidth,
          minHeight: customHeight,
          maxHeight:"300px",
          overflow:"auto",
          borderRadius: "45px",
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box
          sx={{
            width: "96%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1">{label}</Typography>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              fontFamily: "Lato",
              fontSize: 14,
              color: "#363636",
              fontWeight: "normal",
              width: "100%",
              //backgroundColor: "#F5F5F7",
              backgroundColor: "#fff"
            }}
            id={id}
            //variant="filled"
            type={type}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            //defaultValue={value}
            name={name}
            value={value}
            //error={isEmail ? !isValidEmail : error}
            //error={"tarararararar"}
            //helperText={!isValidEmail ? "Nieprawidłowy email" : ""}
            disabled={disabled}
            onChange={(event) => debounce(handleChange(event), 1000)}
            multiline={multiLine}
            //onKeyDown={keyPress}
            inputProps={{
              "aria-label": "wprowadź dane",
            }}
            error={error}
            helpertext={helperText}
            /** 
            onKeyPress={(event) => {
              if (type === "number" && !/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
              */
          />
        </Box>
        {icon && (
          <IconButton
            type="button"
            sx={{
              bgcolor: "#2bfece",
              width: "28px",
              height: "28px",
              mt: -5,
              alignSelf: "center",
            }}
            aria-label="search"
            onClick={() => {
              setValue("");
            }}
          >
            {icon}
          </IconButton>
        )}
      </Paper>
      <Typography variant="bodyMediumRegural" marginTop={-17} color="#db2251">
        {!isValidEmail && isEmail ? "Nieprawidłowy adres email !! " : ""}
      </Typography>
      <Typography variant="bodyMediumRegural" marginTop={-17} color="#db2251">
        {!isValidPhoneNumber && isPhone
          ? "Nieprawidłowy numer telefonu !! "
          : ""}
      </Typography>
    </ThemeProvider>
  );
};

InputIconTextItem.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};
