import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonsFooter } from "../Atoms/ButtonFooter";
import { InputSelectListCardContainer } from "../InputSelectListCard/InputSelectListCardContainer";
import { TriviasList } from "../Atoms/TriviasList";
import { InputListCard } from "../InputListCard/InputListCard";

export const withEditableMeetCompany = (
  cardName,
  profileId,
  authToken,
  urlApi,
  authEpid,
  trivias,
  informationList,
  socialMediaList,
  otherSocialMedia,
  customerOfficeAdressList,
  seoArticles,
  cancelActionHandler,
  refreshProfile,
  setopenInprogress
) => {
  return (props) => {
    const config = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
    };

    const [companySocialChanges, setCompanySocialChanges] = useState();

    const onChangeSocialMedia = (changes) => {
      setCompanySocialChanges({ ...companySocialChanges, ...changes })
    }

    const onSaveSocialMediaChanges =  () => {
      const payload = {
        id: profileId,
        ePid: authEpid,
        ...companySocialChanges,
      };
      if (companySocialChanges) {
        axios.patch(`${urlApi}/profile`, payload, config)
        .then((res) => {
          refreshProfile();
          
        });
      }
      cancelActionHandler();
    }

    const addMetadata = (header, text, category) => {
      const body = [{ category: category, order: 0, key: header, stringValue1: text }];
      axios
        .put(`${urlApi}/metadata/${profileId}`, body,
          config,
        )
        .then((res) => {
          const data = res.data;
          refreshProfile();
         


        })
        .catch((err) => {
          console.log(err);
        });
    }

    const deleteSocialItem = (rowId) => {
      
      const delConfig = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
      data:[rowId],
    };
      axios
        .delete(`${urlApi}/metadata/${profileId}`, 
          delConfig,
        )
        .then((res) => {
          const data = res.data;
          refreshProfile();
      


        })
        .catch((err) => {
          console.log(err);
        });
      
    }

    const isAdmin = localStorage.getItem("isAdmin");
    console.log(isAdmin);

    return (
      <InputSelectListCardContainer
        cardName={cardName}
        inputListCard1={
          <InputListCard
            itemList={socialMediaList}
            onChangeActionHandler={onChangeSocialMedia}
            saveActionHandler={onSaveSocialMediaChanges}
            cancelActionHandler={cancelActionHandler}
          //onSelectFile={onSelectFile}
          />
        }
        inputListCard2={
          <TriviasList
            header="Kanały social media"
            textList={otherSocialMedia}
            label1="Nazwa kanału"
            label2="Adres internetowy"
            addHandler={(val1, val2) => addMetadata(val1, val2, "Kanały socialmedia")}
            deleteHandler={deleteSocialItem}
          />
        }
        inputListCard3={
          <TriviasList
            header="Poznaj firmę od podszewki"
            textList={informationList}
            label1="Nagłówek"
            label2="Treść"
            addHandler={(val1, val2) => addMetadata(val1, val2, "Poznaj firme")}
            deleteHandler={deleteSocialItem}
          />
        }
        inputListCard4={
          <TriviasList
            header="Ciekawostki"
            textList={trivias}
            label1="Nagłówek"
            label2="Treść"
            addHandler={(val1, val2) => addMetadata(val1, val2, "Ciekawostki")}
            deleteHandler={deleteSocialItem}
          />
        }
        
        inputListCard5={
          <TriviasList
            header="Adresy biur obsługi klienta"
            textList={customerOfficeAdressList}
            label1="Ulica, numer domu"
            label2="Miasto"
            addHandler={(val1, val2) => addMetadata(val1, val2, "Adresy biur")}
            deleteHandler={deleteSocialItem}
          />
        }
        
        inputListCard6={
          isAdmin==="true" &&
          <TriviasList
            header="Artykuły SEO"
            textList={seoArticles}
            label1="Nagłówek"
            label2="Treść"
            addHandler={(val1, val2) => addMetadata(val1, val2, "Artykuły SEO")}
            deleteHandler={deleteSocialItem}
          />
        }
        buttonsFooter={
          <ButtonsFooter
            saveActionHandler={onSaveSocialMediaChanges}
            cancelActionHandler={cancelActionHandler}
            isAnulujVisible={true}
            acceptText="Akceptuj"
            
          />
        }
      />
    );
  };
};
