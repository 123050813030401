import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { ImageBox } from "../Atoms/ImageBox"
import { BasicInfoBox } from "../Atoms/BasicInfoBox";
import { Theme } from "../styles/styles";
import { ServicesInfoBox } from "../Atoms/ServicesInfoBox";
import { ListInformationCard } from "../Atoms/ListInformationCard";
import { TriviasBox } from "../Atoms/TriviasBox";
import { DetailsInfoBox } from "../Atoms/DetailsInfoBox";
import { SeoInfoBox } from "../Atoms/SeoInfoBox";

export const SupplierCard = ({
    logoImg,
    backgroundImg,
    providerName,
    address,
    internetServiceName,
    internetServices,
    tvServiceName,
    tvServices,
    phoneServiceName,
    phoneServices,
    infoHeader,
    textList,
    triviasHeader,
    triviasTextList,
    webAddress,
    facebookAddress,
    email,
    serviceAddress,
    servicePhone,
    generalPhone,
    workingHours,
    otherSocialList,
    specialProducts,
    clickButtonHandler,
    isContactEmail,
    companyInfo,
    seoArticles,
}) => {
    
    const countNumOfRecords = (acc, item) => {
        return item.info.length+acc
    }

    //console.log(phoneServices.length)
    const tmpPhoneServices = tvServices.reduce(countNumOfRecords, 0);
    console.log(tmpPhoneServices)
    

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: "column",
                width: "100vw",
                minHeight: "100vh",
                alignItems: "center",
                gap: 2,
                backgroundColor: "#2a45ad",
                p: 0,
                pt: "17px",
                pb: "17px",
                [Theme.breakpoints.up("tablet")]: {
                    pt: "40px",
                    pb: "40px",
                },
                [Theme.breakpoints.up("laptop")]: {
                    pt: "80px",
                    pb: "80px",
                },

            }}
        >

            <ImageBox logoImg={logoImg} backgroundImg={backgroundImg} />
            <BasicInfoBox providerName={providerName} address={address} clickButtonHandler={clickButtonHandler} isContactEmail={isContactEmail} companyInfo={companyInfo} />
            {internetServices?.reduce(countNumOfRecords, 0) >0 && 
                <ServicesInfoBox serviceName={internetServiceName} services={internetServices} />
           
            }
            {tvServices?.reduce(countNumOfRecords, 0) >0 &&
                <ServicesInfoBox serviceName={tvServiceName} services={tvServices} kameleonTV={specialProducts[0]?.kameleonTV} />
            }
            {phoneServices?.reduce(countNumOfRecords, 0) >0 &&
                <ServicesInfoBox serviceName={phoneServiceName} services={phoneServices} koalaTel={specialProducts[0]?.koalaTel} />
            }
            {textList.length > 0 &&
                <ListInformationCard header={infoHeader} textList={textList} />
            }
            {triviasTextList.length >0 &&
                <TriviasBox header={triviasHeader} textList={triviasTextList} />
            }
            <DetailsInfoBox webAddress={webAddress} facebookAddress={facebookAddress} email={email} address={serviceAddress} servicePhone={servicePhone} generalPhone={generalPhone} workingHours={workingHours} otherSocialList={otherSocialList} />

            {seoArticles?.map((el, i) => (
                <SeoInfoBox header={el.header} text={el.text} />
            ) )}
            

        </Box>
    )
}