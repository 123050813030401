import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Theme, NaviStyle } from "../styles/styles";

export const GeneralButton = ({ text, color, providerId, redirectToDetailsHandler, ...props }) => {
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          redirectToDetailsHandler(providerId);
        }}
        {...props}
        sx={{
          borderRadius: 16,
          border: 2,
          borderColor: "#e6e6e6",
          color: { color },
          fontFamily: "Lato",
          fontWeight: 900,
          textTransform: "initial",
          display:"none",
          [Theme.breakpoints.up("laptop")]: {
            display:"flex",
          },
        }}
        
      >
        {text}
      </Button>
    </>
  );
};

// eslint-disable-next-line react/no-typos
GeneralButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  borderColor: PropTypes.string,
};

GeneralButton.defaultProps = {
  text: "Zobacz wszystko",
  color: "#363636",
};
