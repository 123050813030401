import { Drawer, ListItem, ListItemButton } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

import List from "@mui/material/List";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import MapIcon from "@mui/icons-material/Map";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Theme } from "../styles/styles";
import logo from "../../images/logo.svg";

const ZapleczeDrawer = ({
  isReviewer,
  theWidth,
  menuITems = isReviewer
    ? ["Obsługa leadów", "Mapa dobra", "Profil firmy"]
    : ["Obsługa leadów", "Mapa dobra"],
  drawerItemClickHandler,
}) => {
  const [clickedId, setClickedId] = React.useState(-1);
  const handleClick = (i) => {
    setClickedId(i);
  };

  return (
    <Drawer
      id="drawer"
      variant="permanent"
      PaperProps={{
        sx: { width: theWidth },
      }}
      sx={{
        backgroundColor: "white",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Avatar
          alt="Logo"
          src={logo}
          sx={{ width: "200px", height: "58px" }}
          variant="square"
        />
      </Box>

      <Box sx={{ overflow: "auto" }}>
        <List
          sx={{
            // selected and (selected + hover) states
            "&& .Mui-selected, && .Mui-selected:hover": {
              bgcolor: "red",
              "&, & .MuiListItemIcon-root": {
                color: "white",
              },
            },
            // hover states
            "& .MuiListItemButton-root:hover": {
              bgcolor: "#a9ce50",
              opacity: 0.5,
              "&, & .MuiListItemIcon-root": {
                color: "none",
              },
            },
          }}
        >
          {menuITems.map((text, index) => {
            let theIcon = <TextSnippetOutlinedIcon />;
            if (index === 0) theIcon = <FiberNewOutlinedIcon />;
            if (index === 1) theIcon = <MapIcon />;
            if (index === 2) theIcon = <TextSnippetOutlinedIcon />;
            if (index === 3) theIcon = <AccountBalanceWalletOutlinedIcon />;
            if (index === 4) theIcon = <QuizOutlinedIcon />;
            return (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClick(index);
                    drawerItemClickHandler(index, text);
                  }}
                  sx={{
                    ...Theme.textMenuButton,
                    ...(index === clickedId && { bgcolor: "#a9ce50" }),
                  }}
                >
                  <ListItemIcon>{theIcon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};
export default ZapleczeDrawer;
