import classes from "./ZapleczeMapaUserAndAdmin.module.css";
import * as React from "react";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import useHttp from "../CustomHooks/use-http";
import { getUrl } from "../../requests";
import { GoodListItem } from "../Atoms/GoodListItem";

import { Theme } from "../styles/styles";
import MapContainer from "../UI/Map/MapContainer";
import UnderMapButton from "../UnderMapButon/UnderMapButton";

import { Modal, TextField } from "@mui/material";
import { AddGoodForm } from "../AddGoodForm/AddGoodForm";
import dayjs from "dayjs";
import "dayjs/locale/pl";
import Popup from "../UI/Popup/Popup";
import { SearchClearIcon } from "../SearchClearIcon/SearchClearIcon";
import { SearchIconButton } from "../SearchIconButton/SearchIconButton";
import { makeStyles } from "@mui/styles";
import { useDebounce } from "use-debounce";
import searchFunction from "./search";
import { Circles } from "react-loader-spinner";
import { GoodListItemAdmin } from "../Atoms/GoodListItemAdmin";
import { AcceptGoodForm } from "../ManageGoodForms/AcceptGoodForm";
import { ConfirmationForm } from "../ManageGoodForms/ConfirmationForm";
import { GoodHistoryCard } from "../ManageGoodForms/GoodHistoryCard";

import { useAuth0 } from "@auth0/auth0-react";


const slizeSize = 40;
const useStyles = makeStyles((theme) => ({
    input: {
        color: "black",
        backgroundColor: "white",
        disableUnderline: "false",
        borderRadius: "24px",
    },
    root: {
        disableUnderline: "true",
        backgroundColor: "white",
        color: "black",
        borderRadius: "24px",
    },
    root_bad: {
        disableUnderline: "true",
        backgroundColor: "white",
        color: "red",
        borderRadius: "24px",
    },
    floatingLabelFocusStyle: {
        color: "black",
        "&.Mui-focused": {
            color: "#000000",
        },
    },
    closeIcon: {
        border: "1px solid transparent",
        backgroundColor: "white",
        display: "inline-block",
        verticalAlign: "middle",
        outline: "0",
        cursor: "pointer",
        position: "relative",
        padding: "10px",

        "&:after": {
            content: "X",
            display: "block",
            width: "15px",
            height: "15px",
            position: "absolute",
            backgroundColor: "#FA9595",
            Zindex: "400",
            right: "35px",
            top: "0",
            bottom: "0",
            margin: "auto",
            padding: "2px",
            borderRadius: "50%",
            textAlign: "center",
            color: "black",
            fontWeight: "normal",
            fontSize: "12px",
            boxShadow: "0 0 2px #E50F0F",
            cursor: "pointer",
        },
    },

    InputText: {
        width: "163px",
        height: "36px",
        margin: "0 71px 51.5px 11.4px",
        padding: "8.7px 122.2px 8.2px 25.5px",
        fontFamily: "Lato",
        fontSize: "30px",
        fontWeight: "900",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.3",
        letterSpacing: "5.4px",
        textAlign: "left",
        color: "#363636",
        "&:hover:not(.Mui-disabled)": {
            cursor: "default",
            backgroundColor: "white",
            color: "#363636",
        },
    },
    textStyle1: {
        color: "#2af9be",
    },
}));
export const GoodMapManager = (props) => {
    let givenLongitude = "";
    let givenLatitude = "";

    const [isValid, setIsValid] = useState(true);
    const [auto_isValid, auto_setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [openEmpty, setOpenEmpty] = useState(false);
    const [openNumber, setopenNumber] = useState(false);
    const [enteredValue, setEnteredValue] = useState("");
    const [auto_enteredValue, auto_setEnteredValue] = useState("");
    const [auto_placeId, auto_setplaceId] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [localStatus, setLocalStatus] = useState("");
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const { isLoading, error, sendRequest: fetchData } = useHttp();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [refs, setRefs] = useState([]);
    const [editObject, setEditObject] = useState(null);
    const [goodTitle, setGoodTitle] = useState("");
    const goodLocation = React.useRef("");

    const [goodDescription, setGoodDescription] = useState("");
    const [goodTitleError, setgoodTitleError] = useState(false);
    const [goodLocationError, setgoodLocationError] = useState(false);
    const [goodDescriptionError, setgoodDescriptionError] = useState(false);
    const ref = React.useRef(null);
    const editEntity = React.useRef(null);
    const [openInprogress, setopenInprogress] = useState(false);
    const [notImplemented, setNotImplemented] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [openHistory, setopenHistory] = useState(false);
    const [value, setValue] = React.useState(" ");
    const [debounceValue] = useDebounce(value, 500);
    const [debouncePlaceId] = useDebounce(auto_placeId, 700);
    const [runSearch, setRunSearch] = useState(true);
    const [isFiltering, setIsFiltering] = useState(true);
    const [clickSearchIcon, setClickSearchIcon] = useState(false);
    const [itemList, setItemList] = useState([]);

    const emptyErrorMsg = "Wszystkie pola musza byc wypelnione!";
    const inprogressMsg = "Pracuję...";
    const [numberOfPointsForGood, setnumberOfPointsForGood] = useState(0);
    const [validationPointsForGood, setvalidationPointsForGood] = useState(" ");
    const [dobroFormData, setDobroFormData] = useState(new FormData());

    const [httpError, setHttpError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [openPopUp, setopenPopUp] = useState(false);
    const [popUpError, setpopUpError] = useState("");
    const [popUpTitle, setPopUpTitle] = useState("");
    const [preview, setPreview] = useState(false);

    const { user } = useAuth0();
    //const auth = useAuth();

    //const navigate = useNavigate();

    const downloadBoxHandler = useCallback((theFile, epid, seoLink) => {
        handleRangeFile(theFile, epid, seoLink);
    }, []);

    const handleRangeFile = async (theFile, epid, seoLink) => {
        if (theFile) {
            const formData = new FormData();
            formData.append("epid", epid);
            formData.append("csvFile", theFile);
            submitCsvFile(formData);
            return;
        }
    };

    const submitCsvFile = async (theFile) => {
        setIsSubmitting(true);

        const uri = encodeURI(`${getUrl()}/api/v1/rfsManagement/upload`);
        try {
            const response = await fetch(uri, {
                method: "PUT",
                headers: { accept: "*/*", Authorization: `Bearer ${props.auth.user}` },
                body: theFile ? theFile : new FormData(),
            }).catch((err) => {
                setHttpError(
                    `Brak danych, ${err.message ? err : "Wystąpił niezidentyfikowany problem"
                    }`
                );
                setIsSubmitting(false);
                setDidSubmit(false);
                setPopUpTitle(httpError);
                setopenPopUp(true);
                throw new Error(
                    `${err ? err.message : "Prawdopodobnie problem sieciowy"}`
                );
            });

            if (!response.ok) {
                response
                    .json()
                    .then((data) => {
                        setHttpError(
                            `Błąd: ${data?.status
                                ? data?.status
                                : data?.errorCode
                                    ? data.errorCode
                                    : ""
                            }, kategoria: ${data?.errorSubCode}, komunikat: ${data?.message
                            }, info: ${data?.info}`
                        );
                        setIsSubmitting(false);
                        setDidSubmit(false);
                        setopenPopUp(true);
                        setpopUpError(
                            `Błąd: ${data?.status ? data?.status : ""}, kategoria: ${data?.errorSubCode
                            }, komunikat: ${data?.message}, info: ${data?.info}`
                        );
                        setPopUpTitle("Problem");
                        return;
                    })
                    .catch((error) => {
                        setHttpError(
                            `${response ? response.status : "Prawdopodobnie problem sieciowy"
                            } `
                        );
                        setopenPopUp(true);
                        setpopUpError(`Error ${error}`);
                        setPopUpTitle("Problem");
                        return;
                    });
            } else {
                response
                    .json()
                    .then(async (data) => {
                        setpopUpError(
                            `Plik z zasięgami został załadowany, dodano: ${data} wpisów.`
                        );
                        return data;
                    })
                    .catch((error) => console.log(`GK errorr attachment json ${error}`));

                setopenPopUp(true);

                setPopUpTitle(`Sukces!`);
                setIsSubmitting(false);
                setDidSubmit(true);
                setHttpError(false);
            }
        } catch (error) {
            setHttpError(`${error.message}`);
            setIsSubmitting(false);
            setDidSubmit(false);
            setopenPopUp(true);
            setpopUpError(error.message);
            setPopUpTitle("Problem");
        }

    };

    const [companyProfile, setcompanyProfile] = useState(false);
    const [companyBilling, setcompanyBilling] = useState(false);
    const [companyLeads, setCompanyLeads] = useState(true);
    const [companyGood, setcompanyGood] = useState(false);

    const keys = {
        ESC: 27,
        TAB: 9,
        RETURN: 13,
        UP: 38,
        DOWN: 40,
        BCKSPC: 8,
    };
    const doKeyPress = (event) => {
        switch (event.which) {
            case keys.BCKSPC:
                setRunSearch(false);
                return;
            case keys.RETURN:
                formSubmitHandler();
                // setRunSearch(true)
                return;
            default:
                // setRunSearch(true)
                return;
        }
    };
    const enteredSearchHandler = useCallback((data) => {
        if (!data?.result || error) {
            setLocalStatus(
                `Przepraszamy, nie mogliśmy znaleźć wyniku dla ${auto_enteredValue}`
            );
            setSearchResults([]);
            if (props.cityId) {
                auto_setplaceId(props.cityId);
            } else if (localStorage.getItem("cityId")) {
                auto_setplaceId(localStorage.getItem("cityId"));
            }
            return;
        }
        setSearchResults([data?.result]);
        setLat(data?.result?.geometry?.location?.lat?.toFixed(4));
        setLon(data?.result?.geometry?.location?.lng?.toFixed(4));
    }, []);
    // auto_enteredValue,props.cityId,error
    useEffect(() => {
        searchFunction(debounceValue, data, setFilterData);
        setRunSearch(false);
    }, [clickSearchIcon]);
    useEffect(() => {
        if (!runSearch || debounceValue?.trim().length < 4) {
            setFilterData([]);
            setRunSearch(false);
            return;
        }

        searchFunction(debounceValue, data, setFilterData);
        setRunSearch(false);
    }, [debounceValue, data]);

    useEffect(() => {
        setIsFiltering(runSearch);
    }, [runSearch]);
    useEffect(() => {
        setgoodTitleError(false);
        setgoodLocationError(false);
        setgoodDescriptionError(false);

        if (!goodTitle || goodTitle.trim().length === 0) {
            setgoodTitleError(true);
        }
        if (!goodLocation.current || goodLocation.current.trim().length === 0) {
            setgoodLocationError(true);
        }
        if (!goodDescription || goodDescription.trim().length === 0) {
            setgoodDescriptionError(true);
        }
    }, [goodTitle, goodLocation.current, goodDescription]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const myrefs = data.reduce((acc, value) => {
            acc[value.id] = React.createRef();
            return acc;
        }, {});
        setRefs(myrefs);
    }, [data]);
    useEffect(() => {
        if (error) {
            setOpen(true);
            setHttpError(error);
            return;
        }
        setOpen(false);
        setHttpError(null);
    }, [error]);
    useEffect(() => {
        if (props.auth?.epid === null || props.auth.user === null) {
            return;
        }
        setopenInprogress(true);
        let reqConfig = "";
        if (props.auth.epid !== "ADMIN") {
            reqConfig = {
                // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                // method: 'GET',
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    epid: [`${props.auth.epid}`],
                    limit: 100000,
                    offset: 0,
                },
            };
        } else {
            reqConfig = {
                // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                // method: 'GET',
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    limit: 100000,
                    offset: 0,
                },
            };
        }

        fetchData(reqConfig, preparePointsOfGood);
    }, [props.lat, props.lon, fetchData, props.auth.epid, props.auth.user]);

    useEffect(() => {
        const myrefs = searchResults.reduce((acc, value) => {
            acc[value?.id ? value?.id : value?.placeId] = React.createRef();
            return acc;
        }, {});
        setRefs(myrefs);
    }, [searchResults]);

    useEffect(() => {
        if (debouncePlaceId) {
            // fetchData({url: `${getUrl()}/api/v1/rfs/byPlaceId?placeId=${auto_placeId}&sessionId=${encodeURIComponent(props.sessionid)}`},enteredSearchHandler);
            fetchData(
                {
                    url: `${getUrl()}/api/v1/places/details?placeId=${debouncePlaceId}&sessionId=${encodeURIComponent(
                        props.sessionid
                    )}`,
                },
                enteredSearchHandler
            );
        }

        if (error) {
            setLocalStatus(
                `Przepraszamy, nie mogliśmy znaleźć wyniku dla ${auto_enteredValue}`
            );
        }
    }, [debouncePlaceId, fetchData, props.sessionid, enteredSearchHandler]);

    const saveDobroAction = (theFile) => {
        if (
            !goodTitle ||
            goodTitle.trim().length === 0 ||
            !goodLocation.current ||
            goodLocation.current.trim().length === 0 ||
            !goodDescription ||
            goodDescription.trim().length === 0
        ) {
            setOpenEmpty(true);
            return;
        }
        setOpenEmpty(false);

        if (editObject && editObject.goodStage === `New`) {
            updateGood(null, null, null, theFile);
            return;
        }
        createNewGood(null, null, null, theFile, editObject?.id);
    };

    const [openNewDobro, setOpenNewDobro] = React.useState(false);
    const handleOpenNewDobro = () => {
        setDateValue(new Date());
        goodLocation.current = "";
        setGoodDescription("");
        setEditObject(null);
        auto_setEnteredValue("");

        setLat(props.lat);
        setLon(props.lon);

        setOpenNewDobro(true);
    };
    const handleOpenEditDobro = (index) => {
        if (data[index] && filterData.length === 0) {
            setDateValue(data[index].goodDate);
            auto_setEnteredValue(data[index].city);
            setLat(data[index].geographicCoordinates.latitude);
            givenLatitude = data[index].geographicCoordinates.latitude;
            setLon(data[index].geographicCoordinates.longitude);
            givenLongitude = data[index].geographicCoordinates.longitude;

            setSearchResults([data[index]]);

            setOpenNewDobro(true);

            if (!data[index]?.shortDescription) {
                data[index] = {
                    ...data[index],
                    shortDescription: data[index]?.description.slice(0, 50),
                };
                setEditObject({ ...data[index] });
                return;
            }
            setEditObject({ ...data[index] });

            return;
        }
        setDateValue(filterData[index].goodDate);
        auto_setEnteredValue(filterData[index].city);
        setLat(filterData[index].geographicCoordinates.latitude);
        setLon(filterData[index].geographicCoordinates.longitude);

        setOpenNewDobro(true);

        if (!filterData[index]?.shortDescription) {
            filterData[index] = {
                ...filterData[index],
                shortDescription: filterData[index]?.description.slice(0, 50),
            };
            setEditObject({ ...filterData[index] });
            return;
        }

        setSearchResults([filterData[index]]);
        setEditObject({ ...filterData[index] });
    };
    const handleCloseNewDobro = () => {
        setOpenNewDobro(false); // openNewDobro.current = false;
        setEditObject(null);
        if (preview === true) {
            setPreview((val) => !val);
        }
    };
    const [dateValue, setDateValue] = React.useState(dayjs(new Date()));
    /**  
      const handleChangeDate = (newValue) => {
          setDateValue(newValue);
      };
  */
    const preparePointsOfGood = (data) => {
        if (!props.auth.epid) {
            return;
        }
        data.sort((a, b) => {
            return a.updated < b.updated ? 1 : -1;
        });
        let _data = data;

        if (props.auth.epid !== "ADMIN") {
            _data = data.filter((elem) => elem.goodStage !== "Archived");
        }

        setData(_data);
        setFilterData([]);
        setopenInprogress(false);
    };
    const afterUpdatingGoodHandler = (data) => {
        setEditObject(null);
        afterAddingGoodHandler(data);
    };
    const afterAddingGoodHandler = (data) => {
        handleCloseNewDobro();

        let reqConfig = "";
        if (props.auth.epid !== "ADMIN") {
            reqConfig = {
                url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                // method: 'GET',
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    epid: [`${props.auth.epid}`],
                    limit: 100000,
                    offset: 0,
                },
            };
        } else {
            reqConfig = {
                url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                // method: 'GET',
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    limit: 100000,
                    offset: 0,
                },
            };
        }

        fetchData(reqConfig, preparePointsOfGood);
    };


    const verifyGood = (description, location, title) => {
        fetchData(
            {
                url: `${getUrl()}/api/v1/goodWorkflow`,
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    goodAction: "Send to acceptance stage 1",
                    good: {
                        id: editObject.id,
                        parentId: editObject.id,
                        goodStage: editObject?.goodStage,
                        created: editObject?.created,
                        updated: editObject?.updated,
                        owner: editObject?.owner,
                        epid: `${props.auth.epid}`,
                        geographicCoordinates: editObject?.geographicCoordinates,
                        shortDescription: editObject?.shortDescription,
                        description: editObject?.description,
                        goodDate: editObject?.goodDate,
                        score: parseInt(editObject?.score),
                        rejectReason: editObject?.rejectReason,
                        acceptReason: editObject?.acceptReason,
                        deleteReason: editObject?.deleteReason,
                        city: editObject?.city,
                        provinceName: editObject?.provinceName,
                        districtName: editObject?.districtName,
                        streetName: editObject?.streetName,
                        buildNumber: editObject?.buildNumber,
                        status: "Oczekuje na weryfikację",
                    },
                },
            },
            afterUpdatingGoodHandler
        );
    };
    const updateGood = (location, description, title, theFile) => {
        fetchData(
            {
                url: `${getUrl()}/api/v1/goodWorkflow`,
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth.user}`,
                    "Content-Type": "application/json",
                },
                body: {
                    goodAction: "Save",
                    good: {
                        id: editObject.id,
                        parentId: editObject.id,
                        goodStage: "Public",
                        created: `${new Date().toISOString()}`,
                        updated: `${new Date().toISOString()}`,
                        owner: `${props.auth?.epid ? props.auth?.epid : " "}`,
                        epid: `${props.auth?.epid ? props.auth?.epid : " "}`,
                        geographicCoordinates: {
                            latitude: lat ? lat : props?.lat,
                            longitude: lon ? lon : props?.lon,
                        },
                        shortDescription: title ? title : goodTitle,
                        description: description ? description : goodDescription,
                        goodDate: new Date(dateValue).toISOString(),
                        score: 0,
                        rejectReason: " ",
                        acceptReason: " ",
                        deleteReason: " ",
                        city: location ? location : goodLocation.current,
                        provinceName: " ",
                        districtName: " ",
                        streetName: " ",
                        buildNumber: " ",
                        status: "Nowy",
                    },
                },
            },
            async (data) => {
                if (theFile) {
                    const formData = new FormData();
                    formData.append("file", theFile);
                    setDobroFormData(formData);

                    const uri = encodeURI(
                        `${getUrl()}/api/v1/goodWorkflow/${data?.id}/image`
                    );

                    try {
                        const response = await fetch(uri, {
                            method: "PUT",
                            headers: { Authorization: `Bearer ${props.auth.user}` },
                            body: formData ? formData : new FormData(),
                        }).catch((err) => {
                            setHttpError(`Błąd: ${err?.message} ${err?.info}`);
                            setIsSubmitting(false);
                            setDidSubmit(false);
                            setPopUpTitle("Problem");
                            setopenPopUp(true);
                            throw new Error(`Błąd: ${err.message} ${err.info}`);
                        });

                        if (!response.ok) {
                            response
                                .json()
                                .then((data) => {
                                    setHttpError(
                                        `${data?.errorCode} ${data?.errorSubCode}  ${data?.message} ${data?.info}`
                                    );
                                    setIsSubmitting(false);
                                    setDidSubmit(false);

                                    setopenPopUp(true);
                                    setpopUpError(
                                        `Error ${response
                                            ? response.status
                                            : "Prawdopodobnie problem sieciowy"
                                        } error sub code ${data.errorSubCode} message: ${data.message
                                        } info: ${data.info}`
                                    );
                                    setPopUpTitle("Problem");
                                    return;
                                })
                                .catch((error) => {
                                    setHttpError(` Błąd: ${response?.status} ${error?.message}`);

                                    setopenPopUp(true);
                                    setpopUpError(`Error ${error?.message} ${error?.info}`);
                                    setPopUpTitle("Problem");
                                    return;
                                });
                        } else {
                            response
                                .json()
                                .then(async (data) => {
                                    setopenPopUp(true);
                                    setpopUpError(`Zaktualizowano dobro`);
                                    setPopUpTitle("Sukces!");
                                    setIsSubmitting(false);
                                    setDidSubmit(true);
                                    setHttpError(`Zaktualizowano dobro`);
                                })
                                .catch((error) => {
                                    setopenPopUp(true);
                                    setpopUpError(`Błąd: ${error?.message} ${error?.info}`);
                                    setPopUpTitle("Problem!");
                                    setIsSubmitting(false);
                                    setDidSubmit(true);
                                    setHttpError(`Błąd: ${error?.message} ${error?.info}`);
                                });
                        }
                    } catch (error) {
                        setHttpError(`Błąd: ${error?.message} ${error?.info}`);
                        setIsSubmitting(false);
                        setDidSubmit(false);
                        setopenPopUp(true);
                        setpopUpError(`${error?.message} ${error?.info}`);
                        setPopUpTitle("Problem");
                    }
                }

                setopenPopUp(true);
                setpopUpError(`Zaktualizowano dobro`);
                setPopUpTitle("Sukces!");
                setIsSubmitting(false);
                setDidSubmit(true);
                setHttpError(`Zaktualizowano dobro`);
                afterAddingGoodHandler();
            }
        );
    };

    const createNewGood = (location, description, title, theFile, id = null) => {
        const theGoodObject = {
            goodAction: "New",
            good: {
                parentId: `${id}`,
                goodStage: "New",
                created: `${new Date().toISOString()}`,
                updated: `${new Date().toISOString()}`,
                owner: `${props.auth?.epid ? props.auth?.epid : " "}`,
                epid: `${props.auth?.epid ? props.auth?.epid : " "}`,
                geographicCoordinates: {
                    latitude: lat ? lat : props?.lat,
                    longitude: lon ? lon : props?.lon,
                },
                shortDescription: title ? title : `${goodTitle}`,
                description: description ? description : goodDescription,
                goodDate: new Date(dateValue).toISOString(),
                score: 0,
                rejectReason: " ",
                acceptReason: " ",
                deleteReason: " ",
                city: location ? location : goodLocation.current,
                provinceName: " ",
                districtName: " ",
                streetName: " ",
                buildNumber: " ",
                status: "Nowy",
            },
        };

        fetchData(
            {
                url: `${getUrl()}/api/v1/goodWorkflow`,
                method: "POST",
                headers: {
                    accept: "*/*",
                    Authorization: `Bearer ${props.auth?.user}`,
                    "Content-Type": "application/json",
                },
                body: theGoodObject,
            },
            async (data) => {
                if (theFile) {
                    const formData = new FormData();
                    formData.append("file", theFile);
                    setDobroFormData(formData);

                    const uri = encodeURI(
                        `${getUrl()}/api/v1/goodWorkflow/${data?.id}/image`
                    );

                    try {
                        const response = await fetch(uri, {
                            method: "PUT",
                            headers: { Authorization: `Bearer ${props.auth.user}` },
                            body: formData ? formData : new FormData(),
                        }).catch((err) => {
                            setHttpError(`Błąd: ${err?.message} ${err?.info}`);
                            setIsSubmitting(false);
                            setDidSubmit(false);
                            setPopUpTitle("Problem");
                            setopenPopUp(true);
                            throw new Error(`Błąd: ${err.message} ${err.info}`);
                        });

                        if (!response.ok) {
                            response
                                .json()
                                .then((data) => {
                                    setHttpError(
                                        `${data?.errorCode} ${data?.errorSubCode}  ${data?.message} ${data?.info}`
                                    );
                                    setIsSubmitting(false);
                                    setDidSubmit(false);

                                    setopenPopUp(true);
                                    setpopUpError(
                                        `Error ${response
                                            ? response.status
                                            : "Prawdopodobnie problem sieciowy"
                                        } error sub code ${data.errorSubCode} message: ${data.message
                                        } info: ${data.info}`
                                    );
                                    setPopUpTitle("Problem");
                                    return;
                                })
                                .catch((error) => {
                                    setHttpError(` Błąd: ${response?.status} ${error?.message}`);

                                    setopenPopUp(true);
                                    setpopUpError(`Error ${error?.message} ${error?.info}`);
                                    setPopUpTitle("Problem");
                                    return;
                                });
                        } else {
                            response
                                .json()
                                .then(async (data) => {
                                    setopenPopUp(true);
                                    setpopUpError(`Zaktualizowano dobro`);
                                    setPopUpTitle("Sukces!");
                                    setIsSubmitting(false);
                                    setDidSubmit(true);
                                    setHttpError(`Dodano dobro`);
                                    return data;
                                })
                                .catch((error) => {
                                    setopenPopUp(true);
                                    setpopUpError(`Błąd: ${error?.message} ${error?.info}`);
                                    setPopUpTitle("Błąd!");
                                    setIsSubmitting(false);
                                    setDidSubmit(true);
                                    setHttpError(false);
                                });
                        }
                    } catch (error) {
                        setHttpError(`Błąd: ${error?.message} ${error?.info}`);
                        setIsSubmitting(false);
                        setDidSubmit(false);
                        setopenPopUp(true);
                        setpopUpError(`${error?.message} ${error?.info}`);
                        setPopUpTitle("Problem");
                    }
                }
                afterAddingGoodHandler();
            }
        );
    };

    const formSubmitHandler = () => {
        if (!debounceValue) {
            return;
        }
        if (debounceValue.trim().length < 4) {
            setIsValid(false);
        }
        if (debounceValue.trim().length > 3) {
            setIsValid(true);
            setClickSearchIcon((prev) => !prev);
            setRunSearch(true);
        }

        if (
            debounceValue != null &&
            debounceValue &&
            debounceValue.length > 0 &&
            debounceValue.match(/[\^¬~`§!£@#$%*|/\][{}]+/)
        ) {
            setIsValid(false);
        }

        setIsValid(true);

        if (!debounceValue) {
            return;
        }
        if (
            debounceValue.trim().length < 4 &&
            !debounceValue.match(/[;!£@#$%^&]/)
        ) {
            setIsValid(true);
            localStorage.setItem("backofficelastSearch", enteredValue);
        }
        if (
            debounceValue.trim().length > 3 &&
            !debounceValue.match(/[;!£@#$%^&]/)
        ) {
            localStorage.setItem("backofficelastSearch", enteredValue);
            setIsValid(true);
        }

        localStorage.setItem("backofficelastSearch", enteredValue);
    };
    const auto_formSubmitHandler = (event, second) => {
        event.preventDefault();

        if (!auto_enteredValue) {
            return;
        }
        if (auto_enteredValue.trim().length < 4) {
            auto_setIsValid(false);
        }
        if (auto_enteredValue.trim().length > 3) {
            auto_setIsValid(true);
        }
        auto_searchByIdHandler(auto_enteredValue, "");
        localStorage.setItem("lastSearch", auto_enteredValue);
    };
    const auto_searchByIdHandler = (enteredValue, placeId) => {
        if (
            enteredValue != null &&
            enteredValue &&
            enteredValue.length > 0 &&
            enteredValue.match(/[\^¬~`§!£@#$%*|/\][{}]+/)
        ) {
            auto_setIsValid(false);
            auto_setEnteredValue(enteredValue);
            return;
        }
        auto_setIsValid(true);
        auto_setEnteredValue(enteredValue);

        goodLocation.current = enteredValue;
        if (placeId === "search") {
            return;
        }
        auto_setplaceId(placeId);
        localStorage.setItem("last_place_id", placeId);

        if (!enteredValue) {
            return;
        }
        if (enteredValue.trim().length < 4 && !enteredValue.match(/[;!£@#$%^&]/)) {
            auto_setIsValid(true);
            localStorage.setItem("lastSearch", enteredValue);
        }
        if (enteredValue.trim().length > 3 && !enteredValue.match(/[;!£@#$%^&]/)) {
            localStorage.setItem("lastSearch", enteredValue);
            auto_setIsValid(true);
        }
    };

    const clickacceptRow = (dobro) => {
        if (!dobro?.shortDescription) {
            dobro = { ...dobro, shortDescription: dobro?.description.slice(0, 50) };
        }

        if (
            dobro &&
            dobro?.goodStage !== "Acceptance stage 2" &&
            props.auth.epid === "ADMIN"
        ) {
            setopenInprogress(true);

            fetchData(
                {
                    url: `${getUrl()}/api/v1/goodWorkflow`,
                    method: "POST",
                    headers: {
                        accept: "*/*",
                        Authorization: `Bearer ${props.auth?.user}`,
                        "Content-Type": "application/json",
                    },
                    body: {
                        goodAction: `Send to acceptance stage 2`,
                        good: {
                            id: dobro?.id,
                            parentId: dobro?.id,
                            goodStage: dobro?.goodStage,
                            created: dobro?.created,
                            updated: dobro?.updated,
                            owner: dobro?.owner,
                            epid: `${props.auth?.epid}`,
                            geographicCoordinates: dobro?.geographicCoordinates,
                            shortDescription: dobro?.shortDescription,
                            description: dobro?.description,
                            goodDate: dobro?.goodDate,
                            score: parseInt(dobro?.score),
                            rejectReason: dobro?.rejectReason,
                            acceptReason: dobro?.acceptReason,
                            deleteReason: dobro?.deleteReason,
                            city: dobro?.city,
                            provinceName: dobro?.provinceName,
                            districtName: dobro?.districtName,
                            streetName: dobro?.streetName,
                            buildNumber: dobro?.buildNumber,
                            status: "Oczekuje na weryfikację",
                        },
                    },
                },
                () => {
                    fetchData(
                        {
                            url: `${getUrl()}/api/v1/goodWorkflow/${dobro.id}`,
                            method: "GET",
                            headers: { accept: "*/*", Authorization: `Bearer ${props.auth.user}` },
                        },
                        (data) => {
                            editEntity.current = { ...data };
                            setEditObject({ ...data });
                            setopenInprogress(false);
                            setOpenNewDobro(true);

                            let reqConfig = "";
                            if (props.auth.epid !== "ADMIN") {
                                reqConfig = {
                                    // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                    url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                    // method: 'GET',
                                    method: "POST",
                                    headers: {
                                        accept: "*/*",
                                        Authorization: `Bearer ${props.auth.user}`,
                                        "Content-Type": "application/json",
                                    },
                                    body: {
                                        epid: [`${props.auth.epid}`],
                                        limit: 100000,
                                        offset: 0,
                                    },
                                };
                            } else {
                                reqConfig = {
                                    // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                    url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                    // method: 'GET',
                                    method: "POST",
                                    headers: {
                                        accept: "*/*",
                                        Authorization: `Bearer ${props.auth.user}`,
                                        "Content-Type": "application/json",
                                    },
                                    body: {
                                        limit: 100000,
                                        offset: 0,
                                    },
                                };
                            }

                            fetchData(reqConfig, preparePointsOfGood);
                        }
                    );
                }
            );

            return;
        }

        editEntity.current = { ...dobro };
        setEditObject({ ...dobro });
        setPreview(false);
        setOpenNewDobro(true);
        return;
    };

    const content = (
        <Box id="good-points" sx={{ overflow: "auto", height: "95vh", mt: "32px", width:"52vw" }}>
            <div>
                {filterData.length === 0 &&
                    data.map((dobro, i) => {
                        let theDate = new Date(`${dobro.goodDate}`);
                        return (
                            <Box
                                key={i}
                                sx={{
                                    margin: "10px",
                                }}
                            >
                                {props.auth.epid !== "ADMIN" && (
                                    <GoodListItem
                                        goodName={
                                            dobro?.shortDescription
                                                ? dobro?.shortDescription
                                                : dobro.description.slice(0, 50)
                                        }
                                        date={theDate.toLocaleDateString("pl-pl", {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                        })}
                                        location={`${dobro.city.slice(0, 20)}`}
                                        goodPionts={dobro.score}
                                        status={
                                            dobro?.goodStage === "Archived"
                                                ? "Usunięto"
                                                : `${dobro.status.slice(0, 17)}.`
                                        }
                                        onClickEdit={() => {
                                            handleOpenEditDobro(i);
                                        }}
                                        editObject={{}}
                                        onClickHistory={() => {
                                            fetchData(
                                                {
                                                    url: `${getUrl()}/api/v1/goodWorkflow/history/${dobro.id
                                                        }`,
                                                    method: "GET",
                                                    headers: {
                                                        accept: "*/*",
                                                        Authorization: `Bearer ${props.auth.user}`,
                                                    },
                                                },
                                                (data) => {
                                                    let itemList = [];
                                                    data?.forEach((elem) => {
                                                        let theDate = new Date(
                                                            `${elem?.trxDate}`
                                                        ).toLocaleDateString("pl-pl", {
                                                            weekday: "short",
                                                            year: "numeric",
                                                            month: "numeric",
                                                            day: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        });
                                                        itemList.push({
                                                            date: theDate,
                                                            updatedBy: elem?.username,
                                                            comments: "brak",
                                                            status:
                                                                elem?.toStage === "Acceptance stage 1"
                                                                    ? "Oczekuje na weryfikację"
                                                                    : elem?.toStage === "Acceptance stage 2"
                                                                        ? "W trakcie weryfikacji"
                                                                        : elem?.toStage === "Archived"
                                                                            ? "Usunięto"
                                                                            : elem?.toStage === "Public"
                                                                                ? "Akceptacja"
                                                                                : elem?.toStage === "New"
                                                                                    ? "Nowy"
                                                                                    : "",
                                                        });
                                                    });
                                                    setItemList(itemList);

                                                    setopenHistory((val) => !val);
                                                }
                                            );
                                        }}
                                        onClickPreview={() => {
                                            setPreview(true);
                                            handleOpenEditDobro(i);
                                        }}
                                        onClickDelete={() => {
                                            editEntity.current = { ...dobro };
                                            setConfirmation((val) => !val);
                                        }}
                                        itemList={
                                            dobro.status === "Oczekuje na weryfikację"
                                                ? ["Edycja dobra", "Usuń dobro", "Historia"]
                                                : dobro.status === "W trakcie weryfikacji"
                                                    ? ["Podgląd dobra", "Usuń dobro", "Historia"]
                                                    : dobro.status === "Usunięto"
                                                        ? ["Edycja dobra", "Usuń dobro"]
                                                        : dobro.status === "Akceptacja"
                                                            ? ["Edycja dobra", "Usuń dobro", "Historia"]
                                                            : ["Edycja dobra", "Usuń dobro", "Historia"]
                                        }
                                    />
                                )}
                                {props.auth.epid === "ADMIN" && (
                                    <GoodListItemAdmin
                                        goodName={
                                            dobro?.shortDescription
                                                ? dobro?.shortDescription
                                                : dobro?.description.slice(0, 50)
                                        }
                                        date={theDate.toLocaleDateString("pl-pl", {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                        })}
                                        location={`${dobro.city.slice(0, slizeSize)}`}
                                        goodPionts={dobro.score}
                                        status={
                                            dobro?.goodStage === "Archived"
                                                ? "Usunięto"
                                                : `${dobro.status.slice(0, 17)}.`
                                        }
                                        rejectDisabled={
                                            dobro.goodStage === "Acceptance stage 1" ? true : false
                                        }
                                        clickaccept={() => {
                                            clickacceptRow({ ...dobro });
                                        }}
                                        onClickReject={() => {
                                            editEntity.current = { ...dobro };
                                            setConfirmation((val) => !val);
                                        }}
                                        onClickEdit={() => {
                                            setNotImplemented((v) => !v);
                                        }}
                                        onClickHistory={() => {
                                            fetchData(
                                                {
                                                    url: `${getUrl()}/api/v1/goodWorkflow/history/${dobro.id
                                                        }`,
                                                    method: "GET",
                                                    headers: {
                                                        accept: "*/*",
                                                        Authorization: `Bearer ${props.auth.user}`,
                                                    },
                                                },
                                                (data) => {
                                                    let itemList = [];
                                                    data?.forEach((elem) => {
                                                        let theDate = new Date(
                                                            `${elem?.trxDate}`
                                                        ).toLocaleDateString("pl-pl", {
                                                            weekday: "short",
                                                            year: "numeric",
                                                            month: "numeric",
                                                            day: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        });
                                                        itemList.push({
                                                            date: theDate,
                                                            updatedBy: elem?.username,
                                                            comments: "brak",
                                                            status:
                                                                elem?.toStage === "Acceptance stage 1"
                                                                    ? "Oczekuje na weryfikację"
                                                                    : elem?.toStage === "Acceptance stage 2"
                                                                        ? "W trakcie weryfikacji"
                                                                        : elem?.toStage === "Archived"
                                                                            ? "Usunięto"
                                                                            : elem?.toStage === "Public"
                                                                                ? "Akceptacja"
                                                                                : elem?.toStage === "New"
                                                                                    ? "Nowy"
                                                                                    : "",
                                                        });
                                                    });
                                                    setItemList(itemList);
                                                    setopenHistory((val) => !val);
                                                }
                                            );
                                        }}
                                        onClickPreview={() => {
                                            setopenInprogress((val) => !val);
                                            setPreview(true);
                                            handleOpenEditDobro(i);
                                            setopenInprogress((val) => !val);
                                        }}
                                        itemList={["Historia", "Podgląd dobra"]}
                                    />
                                )}
                            </Box>
                        );
                    })}
                {filterData.length > 0 &&
                    filterData.map((dobro, i) => {
                        let theDate = new Date(`${dobro.goodDate}`);
                        return (
                            <Box
                                key={i}
                                sx={{
                                    margin: "10px",
                                }}
                            >
                                {props.auth.epid !== "ADMIN" && (
                                    <GoodListItem
                                        goodName={
                                            dobro?.shortDescription
                                                ? dobro?.shortDescription
                                                : dobro?.description.slice(0, 50)
                                        }
                                        date={theDate.toLocaleDateString("pl-pl", {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                        })}
                                        location={`${dobro.city.slice(0, 20)}`}
                                        goodPionts={dobro.score}
                                        status={
                                            dobro?.goodStage === "Archived"
                                                ? "Usunięto"
                                                : `${dobro.status.slice(0, 17)}.`
                                        }
                                        onClickEdit={() => {
                                            handleOpenEditDobro(i);
                                        }}
                                        editObject={{}}
                                        onClickHistory={() => {
                                            fetchData(
                                                {
                                                    url: `${getUrl()}/api/v1/goodWorkflow/history/${dobro.id
                                                        }`,
                                                    method: "GET",
                                                    headers: {
                                                        accept: "*/*",
                                                        Authorization: `Bearer ${props.auth.user}`,
                                                    },
                                                },
                                                (data) => {
                                                    let itemList = [];
                                                    data?.forEach((elem) => {
                                                        let theDate = new Date(
                                                            `${elem?.trxDate}`
                                                        ).toLocaleDateString("pl-pl", {
                                                            weekday: "short",
                                                            year: "numeric",
                                                            month: "numeric",
                                                            day: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        });
                                                        itemList.push({
                                                            date: theDate,
                                                            updatedBy: elem?.username,
                                                            comments: "brak",
                                                            status:
                                                                elem?.toStage === "Acceptance stage 1"
                                                                    ? "Oczekuje na weryfikację"
                                                                    : elem?.toStage === "Acceptance stage 2"
                                                                        ? "W trakcie weryfikacji"
                                                                        : elem?.toStage === "Archived"
                                                                            ? "Usunięto"
                                                                            : elem?.toStage === "Public"
                                                                                ? "Akceptacja"
                                                                                : elem?.toStage === "New"
                                                                                    ? "Nowy"
                                                                                    : "",
                                                        });
                                                    });
                                                    setItemList(itemList);

                                                    setopenHistory((val) => !val);
                                                }
                                            );
                                        }}
                                        onClickPreview={() => {
                                            setPreview(true);
                                            handleOpenEditDobro(i);
                                        }}
                                        onClickDelete={() => {
                                            editEntity.current = { ...dobro };
                                            setConfirmation((val) => !val);
                                        }}
                                        itemList={
                                            dobro.status === "Oczekuje na weryfikację"
                                                ? ["Edycja dobra", "Usuń dobro", "Historia"]
                                                : dobro.status === "W trakcie weryfikacji"
                                                    ? ["Podgląd dobra", "Usuń dobro", "Historia"]
                                                    : dobro.status === "Usunięto"
                                                        ? ["Edycja dobra", "Usuń dobro"]
                                                        : dobro.status === "Akceptacja"
                                                            ? ["Edycja dobra", "Usuń dobro", "Historia"]
                                                            : ["Edycja dobra", "Usuń dobro", "Historia"]
                                        }
                                    />
                                )}
                                {props.auth.epid === "ADMIN" && (
                                    <GoodListItemAdmin
                                        goodName={
                                            dobro?.shortDescription
                                                ? dobro?.shortDescription
                                                : dobro?.description.slice(0, 50)
                                        }
                                        date={theDate.toLocaleDateString("pl-pl", {
                                            weekday: "short",
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                        })}
                                        location={`${dobro.city.slice(0, slizeSize)}`}
                                        goodPionts={dobro.score}
                                        status={
                                            dobro?.goodStage === "Archived"
                                                ? "Usunięto"
                                                : `${dobro.status.slice(0, 17)}.`
                                        }
                                        rejectDisabled={
                                            dobro.goodStage === "Acceptance stage 1" ? true : false
                                        }
                                        clickaccept={() => {
                                            clickacceptRow({ ...dobro });
                                        }}
                                        onClickReject={() => {
                                            editEntity.current = { ...dobro };
                                            setConfirmation((val) => !val);
                                        }}
                                        onClickEdit={() => {
                                            setNotImplemented((v) => !v);
                                        }}
                                        onClickHistory={() => {
                                            fetchData(
                                                {
                                                    url: `${getUrl()}/api/v1/goodWorkflow/history/${dobro.id
                                                        }`,
                                                    method: "GET",
                                                    headers: {
                                                        accept: "*/*",
                                                        Authorization: `Bearer ${props.auth.user}`,
                                                    },
                                                },
                                                (data) => {
                                                    let itemList = [];
                                                    data?.forEach((elem) => {
                                                        let theDate = new Date(
                                                            `${elem?.trxDate}`
                                                        ).toLocaleDateString("pl-pl", {
                                                            weekday: "short",
                                                            year: "numeric",
                                                            month: "numeric",
                                                            day: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        });
                                                        itemList.push({
                                                            date: theDate,
                                                            updatedBy: elem?.username,
                                                            comments: "brak",
                                                            status:
                                                                elem?.toStage === "Acceptance stage 1"
                                                                    ? "Oczekuje na weryfikację"
                                                                    : elem?.toStage === "Acceptance stage 2"
                                                                        ? "W trakcie weryfikacji"
                                                                        : elem?.toStage === "Archived"
                                                                            ? "Usunięto"
                                                                            : elem?.toStage === "Public"
                                                                                ? "Akceptacja"
                                                                                : elem?.toStage === "New"
                                                                                    ? "Nowy"
                                                                                    : "",
                                                        });
                                                    });
                                                    setItemList(itemList);

                                                    setopenHistory((val) => !val);
                                                }
                                            );
                                        }}
                                        onClickPreview={() => {
                                            setopenInprogress((val) => !val);
                                            setPreview(true);
                                            handleOpenEditDobro(i);
                                            setopenInprogress((val) => !val);
                                        }}
                                        itemList={["Historia", "Podgląd dobra"]}
                                    />
                                )}
                            </Box>
                        );
                    })}
            </div>
        </Box>
    );

    function debounce(func, timeout = 750) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const processChange = debounce((event) => {
        if (event.target.value === value) {
            return;
        }
        setRunSearch(true);
        doSearch(event);
    });



    const doSearch = (event) => {
        if (event.target.value.value === `${value}`) {
            setRunSearch(false);
        }
        setValue(event.target.value);
    };

    const classesTextField = useStyles();

    const goodFormModal =
        props.auth.epid !== "ADMIN" || (preview && props.auth.epid === "ADMIN") ? (
            <AddGoodForm
                topLabel={
                    !editObject
                        ? "Dodaj dobro"
                        : !preview &&
                            editObject &&
                            editObject.status === "Akceptacja" &&
                            editObject.goodStage !== "Public"
                            ? "Wniosek w procesie akceptacji nie można edytować"
                            : !preview &&
                                editObject &&
                                editObject.status === "Akceptacja" &&
                                editObject.goodStage === "Public"
                                ? `Dodaj dobro`
                                : !preview &&
                                    editObject &&
                                    editObject.status === "W trakcie weryfikacji" &&
                                    editObject.goodStage === "Acceptance stage 2"
                                    ? "Wniosek w procesie weryfikacji nie można edytować"
                                    : // (!preview && editObject && editObject.goodStage === 'Public') ? 'Wniosek publiczny nie można edytować' :
                                    !preview && editObject && editObject.status === "Nowy"
                                        ? "Popraw dobro lub wyślij do weryfikacji"
                                        : !preview && editObject && editObject.status === "Usunięto"
                                            ? "Wniosek odrzucony nie można edytować"
                                            : !preview &&
                                                editObject &&
                                                editObject.status === "Oczekuje na weryfikację"
                                                ? "Wniosek wysłany do weryfikacji"
                                                : preview
                                                    ? "Podgląd dobra"
                                                    : ` `
                }
                topText="Wprowadź szczegóły dotyczące miejsca oraz zrealizowanego dobra"
                titleLabel="Tytuł"
                titleText={editObject ? editObject.shortDescription : ""}
                locationLabel="Miejsce dobra"
                titlePlaceHolder="Internet dla dzieci"
                locationPlaceHolder="Gromadka"
                descriptionPlaceHolder="opis dobra "
                descriptionLabel="opis dobra"
                locationText={editObject ? editObject.city : ""}
                descriptionText={editObject ? editObject.description : ""}
                disableVerifyButton={
                    !editObject ||
                    editObject.status === "Oczekuje na weryfikację" ||
                    editObject.status === "Usunięto" ||
                    editObject.status === "W trakcie weryfikacji" ||
                    editObject.status === "Akceptacja" ||
                    preview === true
                }
                disableSaveButton={
                    (editObject &&
                        (editObject.status === "Usunięto" ||
                            editObject.status === "W trakcie weryfikacji")) ||
                    preview === true
                }
                cancelAction={() => {
                    handleCloseNewDobro();
                }}
                dateValue={dateValue}
                picture={editObject?.image}
                handleChangeDate={setDateValue}
                saveAction={(evt, files) => {
                    files?.length > 0 ? saveDobroAction(files[0]) : saveDobroAction();
                }}
                sendToVerifyAction={() => {
                    verifyGood();
                }}
                titleAction={setGoodTitle}
                goodTitleError={goodTitleError}
                locationAction={(data) => {
                    goodLocation.current = data;
                }}
                goodLocationError={goodLocationError}
                descriptionAction={setGoodDescription}
                goodDescriptionError={goodDescriptionError}
                helperText="Puste pole!"
                sessionid={props.sessionid}
                lat={lat > 0 ? lat : givenLatitude ? givenLatitude : props.lat}
                lon={lon > 0 ? lon : givenLongitude ? givenLongitude : props.lon}
                city={props.city}
                apikey={props.apikey}
                searchByIdHandler={auto_searchByIdHandler}
                formSubmitHandler={auto_formSubmitHandler}
                isValid={auto_isValid}
                enteredValue={auto_enteredValue}
                searchResults={searchResults}
                modapMap={preview && props.auth.epid === "ADMIN" ? false : true}
            ></AddGoodForm>
        ) : (
            <AcceptGoodForm
                reguralText={
                    editEntity?.current ? editEntity?.current?.description : " "
                }
                header={editEntity?.current ? editEntity?.current?.shortDescription : " "}
                goodName={editEntity ? editEntity?.current?.shortDescription : " "}
                misotName={editEntity ? editEntity?.current?.epid : " "}
                goodPointsValueHandler={setnumberOfPointsForGood}
                justificationValueHandler={setvalidationPointsForGood}
                cancelActionHandler={() => {
                    handleCloseNewDobro();
                }}
                saveActionHandler={(theFile) => {
                    if (
                        !numberOfPointsForGood ||
                        numberOfPointsForGood.trim().length === 0 ||
                        !validationPointsForGood ||
                        validationPointsForGood.trim().length === 0
                    ) {
                        setOpenEmpty(true);
                        return;
                    }
                    if (numberOfPointsForGood && isNaN(numberOfPointsForGood)) {
                        setopenNumber(true);
                        return;
                    }
                    setOpenEmpty(false);
                    if (editEntity?.current) {
                        if (
                            editEntity?.current &&
                            editEntity?.current?.goodStage === "Acceptance stage 2" &&
                            props.auth.epid === "ADMIN"
                        ) {
                            fetchData(
                                {
                                    url: `${getUrl()}/api/v1/goodWorkflow`,
                                    method: "POST",
                                    headers: {
                                        accept: "*/*",
                                        Authorization: `Bearer ${props.auth?.user}`,
                                        "Content-Type": "application/json",
                                    },
                                    body: {
                                        goodAction: `Accept`,
                                        good: {
                                            id: editEntity?.current?.id,
                                            parentId: editEntity?.current?.parentId,
                                            goodStage: editEntity?.current?.goodStage,
                                            created: editEntity?.current?.created,
                                            updated: editEntity?.current?.updated,
                                            owner: editEntity?.current?.owner,
                                            epid: `${props.auth?.epid}`,
                                            geographicCoordinates:
                                                editEntity?.current?.geographicCoordinates,
                                            shortDescription: editEntity?.current?.shortDescription,
                                            description: editEntity?.current?.description,
                                            goodDate: editEntity?.current?.goodDate,
                                            score: parseInt(numberOfPointsForGood),
                                            rejectReason: editEntity?.current?.rejectReason,
                                            acceptReason: `${validationPointsForGood}`,
                                            deleteReason: editEntity?.current?.deleteReason,
                                            city: editEntity?.current?.city,
                                            provinceName: editEntity?.current?.provinceName,
                                            districtName: editEntity?.current?.districtName,
                                            streetName: editEntity?.current?.streetName,
                                            buildNumber: editEntity?.current?.buildNumber,
                                            status: "Oczekuje na weryfikację",
                                        },
                                    },
                                },
                                async () => {
                                    afterAddingGoodHandler();
                                }
                            );
                            return;
                        }
                    }
                }}
                disableVerifyButton={false}
                delay={100}
            >
                {" "}
            </AcceptGoodForm>
        );

    return (
        <Box>
            <Container id="goodSearch" maxWidth="xl" sx={{ marginRight: "40px" }}>

                <Toolbar
                    disableGutters
                    sx={{ justifyContent: "space-between", width: "53.22vw" }}
                >
                    <>
                        <Box
                            className={isValid ? "Rectangle-2876" : "Rectangle-2876_bad"}
                        >
                            <span style={{ flexGrow: "5" }}>
                                <Box>
                                    <TextField
                                        id="search"
                                        sx={{
                                            width: "100%",
                                            bgcolor: "white",
                                        }}
                                        // ref={ref}
                                        onChange={processChange}
                                        label="Wyszukaj dobro"
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: classesTextField.input,
                                        }}
                                        InputLabelProps={{
                                            className: classesTextField.floatingLabelFocusStyle,
                                        }}
                                        inputProps={{
                                            className: `${classesTextField.input}  ${isValid
                                                ? classesTextField.root
                                                : classesTextField.root_bad
                                                }`,
                                        }}
                                        type="text"
                                        disabled={false}
                                        autoComplete="off"
                                        error={!isValid}
                                        onKeyDown={doKeyPress}
                                        placeholder={`Wpisz np. Nazwę miasto, ulicę, adres`}
                                    />
                                </Box>
                            </span>

                            <Box
                                type="clear"
                                onClick={() => {
                                    setFilterData(data);
                                    document.getElementById("search").focus();
                                    document.getElementById("search").value = "";
                                    setValue("");
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    zIndex: "100",
                                }}
                            >
                                <SearchClearIcon />
                            </Box>

                            <Box
                                type="submit"
                                onClick={formSubmitHandler}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <SearchIconButton type="submit" />
                            </Box>
                        </Box>
                    </>

                    {!isFiltering && (
                        <Box
                            sx={{
                                display: "flex",
                                width: "20%",
                                justifyContent: "center",
                            }}
                        >
                            wpisów:{" "}
                            {filterData.length > 0 ? filterData.length : data.length} /{" "}
                            {data.length}
                        </Box>
                    )}
                    {isFiltering && (
                        <Box
                            sx={{
                                display: "flex",
                                width: "20%",
                                justifyContent: "center",
                            }}
                        >
                            <Circles
                                height="50"
                                width="50"
                                radius="9"
                                color="#2bfece"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            />
                        </Box>
                    )}
                </Toolbar>

            </Container>

            <Box sx={{ display: "flex", justifyContent:"space-between" }}>
                <Box>
                {content}
                </Box>
                {props.auth.epid !== "ADMIN" && (
                    <Box id="mapContainer" className={classes.mapContainer}>

                        <Box
                            id="mapa-glowny"
                            sx={{
                                [Theme.breakpoints.up("desktop")]: {
                                    width: "100%",
                                    height: "575px",
                                    borderRadius: "24px",
                                    position: "sticky",
                                    top: "calc(1em + 12vh)",
                                },

                                [Theme.breakpoints.down("desktop")]: {
                                    width: "100%",
                                    height: "405px",
                                    borderRadius: "24px",
                                    position: "sticky",
                                    top: "calc(1em + 12vh)",
                                    mt: companyBilling ? 10 : 4,
                                },

                                [Theme.breakpoints.down("laptop")]: {
                                    // display: "none",
                                },

                                gridArea: "maptotal",
                                borderRadius: "24px",
                                pr: "2%",
                                ml: companyBilling ? 3 : -4,
                                mt: -5,
                            }}
                        >

                            <UnderMapButton
                                title={
                                    ""
                                }
                                subtitle={
                                    companyProfile || companyBilling ? "" : "Dodaj dobro"
                                }
                                subtitleTextTransform={companyProfile ? "" : "uppercase"}
                                subtitletextDecoration={companyProfile ? "" : "underline"}
                                savingIcon={companyBilling ? true : false}
                                onClick={() => {
                                    if (!companyProfile && !companyBilling) {
                                        handleOpenNewDobro();
                                        return;
                                    }
                                    setNotImplemented((val) => !val);
                                }}
                            />



                            <MapContainer
                                lat={!Number.isNaN(props?.lat) ? props?.lat : 50.369}
                                lon={!Number.isNaN(props?.lon) ? props?.lon : 19.1789}
                                companies={data}
                                points={data}
                                references={refs}
                                zoom={10}
                                apikey={props.apikey}
                                place={{
                                    geographicCoordinates: {
                                        lat: !Number.isNaN(props.lat) ? props.lat : 50.369,
                                        lon: !Number.isNaN(props.lon) ? props.lon : 19.1789,
                                    },
                                    companyName: "placeYouAreLookingFor.addressSearch",
                                }}
                            />

                        </Box>


                    </Box>
                )}

            </Box>

            <Modal
                open={openNewDobro}
                onClose={() => {
                    handleCloseNewDobro();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {goodFormModal}
            </Modal>

            <Modal
                open={openHistory}
                onClose={() => {
                    setopenHistory((value) => !value);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GoodHistoryCard
                    label="Historia dobra"
                    text="Tekst historii dobra"
                    buttonAction={setopenHistory}
                    itemList={itemList}
                ></GoodHistoryCard>
            </Modal>

            <Modal
                open={confirmation}
                onClose={() => {
                    setConfirmation((value) => !value);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConfirmationForm
                    goodName={
                        editEntity?.current?.shortDescription
                            ? editEntity?.current?.shortDescription
                            : editEntity?.current?.description
                    }
                    misotName={
                        editEntity?.current?.epid ? editEntity?.current?.epid : "lala"
                    }
                    disableVerifyButton={
                        editEntity?.current?.status === "Akceptacja" ? false : false
                    }
                    accordionText={"test"}
                    buttonLabel={
                        editEntity.current && props.auth.epid === "ADMIN" ? "Odrzuć" : "Usuń"
                    }
                    cancelActionHandler={() => {
                        editEntity.current = {};
                        setConfirmation((value) => !value);
                    }}
                    boldText={
                        editEntity?.current?.status === "Usunięto"
                            ? "Dobro odrzucone"
                            : editEntity?.current?.status === "Oczekuje na weryfikację"
                                ? "W trakcie weryfikacji"
                                : props.auth.epid === "ADMIN"
                                    ? "Czy na pewno chcesz odrzucić"
                                    : "Czy na pewno chcesz usunąć"
                    }
                    saveActionHandler={() => {
                        let dobro = editEntity.current;

                        if (dobro && props.auth.epid === "ADMIN") {
                            setopenInprogress(true);

                            fetchData(
                                {
                                    url: `${getUrl()}/api/v1/goodWorkflow`,
                                    method: "POST",
                                    headers: {
                                        accept: "*/*",
                                        Authorization: `Bearer ${props.auth?.user}`,
                                        "Content-Type": "application/json",
                                    },
                                    body: {
                                        goodAction: `Reject`,
                                        good: {
                                            id: dobro?.id,
                                            parentId: dobro?.id,
                                            goodStage: dobro?.goodStage,
                                            created: dobro?.created,
                                            updated: dobro?.updated,
                                            owner: dobro?.owner,
                                            epid: `${props.auth?.epid}`,
                                            geographicCoordinates: dobro?.geographicCoordinates,
                                            shortDescription: dobro?.shortDescription,
                                            description: dobro?.description,
                                            goodDate: dobro?.goodDate,
                                            score: parseInt(dobro?.score),
                                            rejectReason: dobro?.rejectReason,
                                            acceptReason: dobro?.acceptReason,
                                            deleteReason: dobro?.deleteReason,
                                            city: dobro?.city,
                                            provinceName: dobro?.provinceName,
                                            districtName: dobro?.districtName,
                                            streetName: dobro?.streetName,
                                            buildNumber: dobro?.buildNumber,
                                            status: "Nowy",
                                        },
                                    },
                                },
                                (data) => {
                                    editEntity.current = { ...data };
                                    setopenInprogress(false);
                                    setConfirmation(false);

                                    let reqConfig = "";
                                    if (props.auth.epid !== "ADMIN") {
                                        reqConfig = {
                                            // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                            url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                            // method: 'GET',
                                            method: "POST",
                                            headers: {
                                                accept: "*/*",
                                                Authorization: `Bearer ${props.auth.user}`,
                                                "Content-Type": "application/json",
                                            },
                                            body: {
                                                epid: [`${props.auth.epid}`],
                                                limit: 100000,
                                                offset: 0,
                                            },
                                        };
                                    } else {
                                        reqConfig = {
                                            // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                            url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                            // method: 'GET',
                                            method: "POST",
                                            headers: {
                                                accept: "*/*",
                                                Authorization: `Bearer ${props.auth.user}`,
                                                "Content-Type": "application/json",
                                            },
                                            body: {
                                                limit: 100000,
                                                offset: 0,
                                            },
                                        };
                                    }

                                    fetchData(reqConfig, preparePointsOfGood);
                                }
                            );

                            return;
                        }

                        setopenInprogress(true);
                        fetchData(
                            {
                                url: `${getUrl()}/api/v1/goodWorkflow`,
                                method: "POST",
                                headers: {
                                    accept: "*/*",
                                    Authorization: `Bearer ${props.auth?.user}`,
                                    "Content-Type": "application/json",
                                },
                                body: {
                                    goodAction: `Delete`,
                                    good: {
                                        id: dobro?.id,
                                        parentId: dobro?.id,
                                        goodStage: dobro?.goodStage,
                                        created: dobro?.created,
                                        updated: dobro?.updated,
                                        owner: dobro?.owner,
                                        epid: `${props.auth?.epid}`,
                                        geographicCoordinates: dobro?.geographicCoordinates,
                                        shortDescription: dobro?.shortDescription,
                                        description: dobro?.description,
                                        goodDate: dobro?.goodDate,
                                        score: parseInt(dobro?.score),
                                        rejectReason: dobro?.rejectReason,
                                        acceptReason: dobro?.acceptReason,
                                        deleteReason: dobro?.deleteReason,
                                        city: dobro?.city,
                                        provinceName: dobro?.provinceName,
                                        districtName: dobro?.districtName,
                                        streetName: dobro?.streetName,
                                        buildNumber: dobro?.buildNumber,
                                        status: "Zarchiwizowano",
                                    },
                                },
                            },
                            (data) => {
                                // editEntity.current = ({ ...data});
                                setopenInprogress(false);
                                setConfirmation(false);

                                let reqConfig = "";
                                if (props.auth.epid !== "ADMIN") {
                                    reqConfig = {
                                        // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                        url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                        // method: 'GET',
                                        method: "POST",
                                        headers: {
                                            accept: "*/*",
                                            Authorization: `Bearer ${props.auth.user}`,
                                            "Content-Type": "application/json",
                                        },
                                        body: {
                                            epid: [`${props.auth.epid}`],
                                            limit: 100000,
                                            offset: 0,
                                        },
                                    };
                                } else {
                                    reqConfig = {
                                        // url: `${getUrl()}/api/v1/goodWorkflow?limit=100000`,
                                        url: `${getUrl()}/api/v1/goodWorkflow/filteredGood`,
                                        // method: 'GET',
                                        method: "POST",
                                        headers: {
                                            accept: "*/*",
                                            Authorization: `Bearer ${props.auth.user}`,
                                            "Content-Type": "application/json",
                                        },
                                        body: {
                                            limit: 100000,
                                            offset: 0,
                                        },
                                    };
                                }

                                fetchData(reqConfig, preparePointsOfGood);
                            }
                        );

                        editEntity.current = {};

                        return;
                    }}
                />
            </Modal>

            {
                <Popup
                    openPopup={openPopUp}
                    title={popUpTitle}
                    setOpenPopup={() => {
                        setopenPopUp(false);
                    }}
                >
                    <span>
                        {httpError?.data?.errorCode
                            ? `${"errorCode" in httpError?.data
                                ? httpError?.data?.errorCode
                                : ""
                            }
                         ${"errorSubCode" in httpError?.data
                                ? httpError?.data?.errorSubCode
                                : ""
                            }
                         ${"message" in httpError?.data
                                ? httpError?.data?.message
                                : ""
                            }
                         ${"info" in httpError?.data
                                ? httpError?.data?.info
                                : ""
                            }`
                            : error?.data?.errorCode
                                ? `${"errorCode" in error?.data ? error?.data?.errorCode : ""}
                             ${"errorSubCode" in error?.data
                                    ? error?.data?.errorSubCode
                                    : ""
                                }
                             ${"message" in error?.data
                                    ? error?.data?.message
                                    : ""
                                }
                             ${"info" in error?.data ? error?.data?.info : ""}`
                                : httpError
                                    ? httpError
                                    : error
                                        ? error
                                        : popUpError}
                    </span>
                </Popup>
            }

            {
                <Popup
                    openPopup={open}
                    setOpenPopup={() => {
                        setOpen(false);
                    }}
                >
                    <span>
                        {httpError?.data?.errorCode
                            ? `${"errorCode" in httpError?.data
                                ? httpError?.data?.errorCode
                                : ""
                            }
                    ${"errorSubCode" in httpError?.data
                                ? httpError?.data?.errorSubCode
                                : ""
                            }
                    ${"message" in httpError?.data
                                ? httpError?.data?.message
                                : ""
                            }
                    ${"info" in httpError?.data ? httpError?.data?.info : ""}`
                            : error?.data?.errorCode
                                ? `${"errorCode" in error?.data ? error?.data?.errorCode : ""}
                    ${"errorSubCode" in error?.data
                                    ? error?.data?.errorSubCode
                                    : ""
                                }
                    ${"message" in error?.data ? error?.data?.message : ""}
                    ${"info" in error?.data ? error?.data?.info : ""}`
                                : httpError?.message
                                    ? httpError.message
                                    : error?.message
                                        ? error.message
                                        : "Błąd nieznany"}
                    </span>
                </Popup>
            }

            {
                <Popup
                    openPopup={openEmpty}
                    message={emptyErrorMsg}
                    setOpenPopup={() => {
                        setOpenEmpty(false);
                    }}
                >
                    <span>{emptyErrorMsg}</span>
                </Popup>
            }

            {
                <Popup
                    title={inprogressMsg}
                    openPopup={openInprogress || isLoading}
                    message={inprogressMsg}
                    disabledButton={true}
                    setOpenPopup={() => {
                        setopenInprogress(false);
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "20%",
                            justifyContent: "center",
                        }}
                    >
                        <Circles
                            height="50"
                            width="50"
                            radius="9"
                            color="#2bfece"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                    </Box>
                </Popup>
            }

            {
                <Popup
                    title="wartość musi być liczbą"
                    openPopup={openNumber}
                    message={inprogressMsg}
                    disabledButton={false}
                    setOpenPopup={() => {
                        setopenNumber(false);
                    }}
                ></Popup>
            }
            {
                <Popup
                    title="Funkcja niezaimplementowana, jeszcze."
                    openPopup={notImplemented}
                    message="Funkcja niezaimplementowana, jeszcze."
                    disabledButton={false}
                    setOpenPopup={() => {
                        setNotImplemented(false);
                    }}
                ></Popup>
            }
        </Box>

    );
};


