import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Img from "../../images/marketing/L2.jpg"


export const GoodDetailsBox = ({

    goodImg,
    goodTitle,
    goodDescription,
    goodDate,

}) => {

    const theDate = new Date(goodDate);

    return (
        <ThemeProvider theme={Theme}>
            <Box
                id="mainBox"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#F2F3F5",
                    p: "24px",
                    justifyContent: "flex-start",
                    [Theme.breakpoints.down("tablet")]: {
                        width: "95vw",
                        minHeight: "547px"
                    },
                    [Theme.breakpoints.up("tablet")]: {
                        width: "47vw",
                        minHeight: "723px",
                        //borderRadius: "16px"
                    },
                    [Theme.breakpoints.up("laptop")]: {
                        width: "33vw",
                    },
                    [Theme.breakpoints.up("desktop")]: {
                        width: "30vw",
                    },

                }}
            >


                <Avatar variant="rounded"
                    src={goodImg ? goodImg : Img}
                    sx={{
                        justifySelf: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#f2f2f2",
                        borderRadius: "12px",
                        mt: "16px",
                        width: "auto",  
                        height: "50%", 
                        maxheight: "50vh", 
                        objectFit: "contain",
                    }}
                >
                    {!goodImg &&
                        <NoPhotographyOutlinedIcon
                            sx={{
                                fontSize: "80px",
                                color: "#363636"

                            }}
                        />
                    }
                </Avatar>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: "16px",

                        [Theme.breakpoints.up("tablet")]: {
                            alignSelf: "flex-end"
                        },
                    }}>

                    <CalendarTodayIcon
                        sx={{
                            fontSize: 14,
                            color: "#f29a26",
                            mr: "6px",
                        }}
                    />

                    <Typography variant="bodyMediumReguralLinks"

                    >{theDate.toLocaleDateString("pl-pl", {
                        //weekday: "short",
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                    })}
                    </Typography>

                </Box>

                <Typography variant="subtitle" sx={{ alignSelf: "flex-start", mb: "16px", mt: "16px" }}

                >{goodTitle}
                </Typography>
                <Typography variant="bodySmallRegural" sx={{ alignSelf: "flex-start", fontSize: "12px", }}

                >{goodDescription}
                </Typography>


            </Box>
        </ThemeProvider>

    );
};


