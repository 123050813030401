import * as React from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { InputIconTextItem } from "./../Atoms/InputIconTextItem";
import Stack from "@mui/material/Stack";

export const SwitchListCard = ({
  header,
  objectToUpdate = null,
  otherServicesList,
  wiredInternetServicesList,
  fiberInternetServicesList,
  wirelessInternetServicesList,
  tvServicesList,
  telephonyServicesList,
  mobileTelephonyServicesList,
  tvPacketsServicesList,
  inputLabel = null,
  inputText = null,
  onChangeServices,
  onChangeServicesList,
}) => {
  return (
    <Box
      id="mainInputListCard"
      sx={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={Theme}>
        {header && (
          <Box id="header" marginBottom={16} marginTop={16}>
            <Typography variant="body1" textTransform="uppercase">
              {header}
            </Typography>
          </Box>
        )}
        <Box
          id="switchList"
          width="100%"
          sx={{
            maxHeight: 400,
            overflow: "auto",
            mb: "25px",
          }}
        >
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {Object.entries(objectToUpdate).map(([key, value]) => (
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {value ? (
                      <Typography variant="body1">TAK</Typography>
                    ) : (
                      <Typography variant="body1">NIE</Typography>
                    )}

                    <Switch
                      checked={value}
                      onChange={(event) => {
                        onChangeServices({
                          [event.target.name]: event.target.checked,
                        });
                      }}
                      name={key}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#2bfece",
                          "&:hover": {
                            backgroundColor: "#2bfece",
                          },
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#2bfece",
                          },
                      }}
                    />
                    <Typography variant="bodyMediumRegural">
                      {key === "Telefonia"
                        ? "Telefon domowy"
                        : key === "Telefonia mobilna"
                        ? "Telefon komórkowy"
                        : key}
                    </Typography>
                  </Stack>
                  {value &&  (
                    <Box sx={{ mb: "36px" }}>
                      {Array(4)
                        .fill()
                        .map((x, i) => (
                          <Box marginBottom="12px" sx={{ display: "flex" }}>
                            <InputIconTextItem
                              id={i}
                              isDescription={false}
                              label="Opis usługi"
                              name={key}
                              text={
                                key === "Telewizja"
                                  ? tvServicesList[i]?.productName
                                  : key === "Pakiety TV"
                                  ? tvPacketsServicesList[i]?.productName
                                  : key === "Telefonia"
                                  ? telephonyServicesList[i]?.productName
                                  : key === "Telefonia mobilna"
                                  ? mobileTelephonyServicesList[i]?.productName
                                  : key === "Internet radiowy"
                                  ? wirelessInternetServicesList[i]?.productName
                                  : key === "Internet światłowodowy"
                                  ? fiberInternetServicesList[i]?.productName
                                  : key === "Internet mobilny"
                                  ? wiredInternetServicesList[i]?.productName
                                  : key === "Inne"
                                  ? otherServicesList[i]?.productName
                                  : ""
                              }
                              icon={null}
                              customWidth="68%"
                              marginBottom="24px"
                              onChangeServicesList={onChangeServicesList}
                            />
                            <InputIconTextItem
                              id={i}
                              isDescription={true}
                              label={
                                key === "Internet radiowy"
                                  ? "Prędkość Mb/s"
                                  : key === "Internet światłowodowy"
                                  ? "Prędkość Mb/s"
                                  : key === "Internet mobilny"
                                  ? "Prędkość Mb/s"
                                  : key === "Telewizja"
                                  ? "Ilość kanałów"
                                  : key === "Pakiety TV"
                                  ? "Nazwa pakietu"
                                  : key === "Telefonia mobilna"
                                  ? "Ilość GB"
                                  : key === "Telefonia"
                                  ? "Ilość minut"
                                  : `Opis`
                              }
                              name={key}
                              text={
                                key === "Telewizja"
                                  ? tvServicesList[i]?.productDescription
                                  : key === "Pakiety TV"
                                  ? tvPacketsServicesList[i]?.productDescription
                                  : key === "Telefonia"
                                  ? telephonyServicesList[i]?.productDescription
                                  : key === "Telefonia mobilna"
                                  ? mobileTelephonyServicesList[i]
                                      ?.productDescription
                                  : key === "Internet radiowy"
                                  ? wirelessInternetServicesList[i]
                                      ?.productDescription
                                  : key === "Internet światłowodowy"
                                  ? fiberInternetServicesList[i]
                                      ?.productDescription
                                  : key === "Internet mobilny"
                                  ? wiredInternetServicesList[i]
                                      ?.productDescription
                                  : key === "Inne"
                                  ? otherServicesList[i]?.productDescription
                                  : ""
                              }
                              type={key === "Other" ? "text" : "number"}
                              icon={null}
                              customWidth="30%"
                              marginBottom="24px"
                              onChangeServicesList={onChangeServicesList}
                            />
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
