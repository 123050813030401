import { Theme } from "../styles/styles";
import { Avatar, Button, ThemeProvider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import classes from "../../Components/Zaplecze/ZapleczeMapaUserAndAdmin.module.css";
import NativeSelect from '@mui/material/NativeSelect';

export const ZapleczeHeader = ({
    //epid = "Janek DGNET",
    user = "John Doe",
    contextList = ["DGNETSA", "JTEAM"],
    //getSelectedContext,
    selectedContext,
    setSelectedContext,
    title,
}) => {

    const [userContextList, setUserContenxtList] = React.useState(contextList);

    const { logout } = useAuth0();
    const handleSelectChange = (event) => {
        setSelectedContext(event.target.value);

    }


    return (
        <div className={classes.middlePart__label}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexGrow: "2" }}>
                    {title}
                    <Box
                        sx={{
                            ml: "5%",
                        }}
                    >
                        <Button
                            onClick={() => {
                                logout({
                                    logoutParams: { returnTo: `${window.location.origin}` },
                                });
                            }}
                            sx={{
                                ...Theme.OrangeButton,
                                backgroundColor: "#18a75c",
                                border: 0,
                                width: 128,
                            }}
                        >
                            Wyloguj
                        </Button>
                    </Box>
                </Box>
                <Box
                    id="imageTitle"
                    sx={{
                        mt: "2%",
                        // fontSize: 25
                    }}
                >
                    <ThemeProvider theme={Theme}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                mt: 5,
                                width: "300px"
                            }}
                        >
                            <Avatar
                                variant="circular"
                                sx={{
                                    width: "2vw",
                                    height: "2vw",
                                }}
                                alt="Logo"
                                src={user["picture"]}
                            ></Avatar>

                            <Box
                                sx={{ mr: "16px", }}
                            >
                                <Typography variant="bodyMediumBlack" >
                                    {`${user}`}
                                </Typography>

                            </Box>
                            <NativeSelect
                                defaultValue={userContextList[0]}
                                onChange={handleSelectChange}
                            >

                                {userContextList.map((el, idx) =>
                                    <option value={el} key={idx}>{el}</option>
                                )}
                            </NativeSelect>

                        </Box>
                    </ThemeProvider>
                </Box>
            </Box>
        </div>
    )
}