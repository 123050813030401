import * as React from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

export const MessageBox = ({ headerText, info, properties, onNextButton }) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        minHeight: "480px",
        [Theme.breakpoints.down("tablet")]: {
          width: "82.8vw",
          padding: "7px",
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "43vw",
          padding: 3,
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "35.2vw",
          padding: 3,
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "23.4vw",
          padding: 3,
        },
        borderRadius: 4,
        boxShadow: "0px 0px 32px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 3,
          p: 4,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Box>
            <Typography
              variant="h4"
              lineHeight={1.38}
              letterSpacing={0.23}
              fontSize="26px"
            >
              {headerText}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="bodyMediumRegural"
              lineHeight={1.33}
              letterSpacing={0.16}
              fontSize="18px"
            >
              {info}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            {!properties ? (
              <Button
                onClick={() => {
                  onNextButton();
                }}
                sx={{
                  ...Theme.OrangeButton,
                  alignSelf: "flex-end",
                  [Theme.breakpoints.down("laptop")]: {
                    width: "225px",
                  },
                  [Theme.breakpoints.up("laptop")]: {
                    width: "255px",
                  },
                }}
              >
                Wróć na stronę główną
              </Button>
            ) : (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {properties.map((el) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: "16px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="bodyMediumBlack"
                        lineHeight={1.07}
                        letterSpacing={0.13}
                        
                      >
                        {el.label}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="bodyMediumRegural"
                        lineHeight={1.07}
                        letterSpacing={0.13}
                        
                      >
                        {el.value}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                <Button
                  onClick={() => {
                    onNextButton();
                  }}
                  sx={{
                    ...Theme.OrangeButton,
                    alignSelf: "flex-end",
                    mt: "32px",
                  }}
                >
                  Zamknij
                </Button>
              </Box>
            )}
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

MessageBox.propTypes = {
  headerText: PropTypes.string,
  info: PropTypes.string,
};
