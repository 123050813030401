import * as React from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Avatar from "@mui/material/Avatar";
import { GeneralButton } from "../Button/GeneralButton";
import FavoriteIcon from "@mui/icons-material/Favorite";

export const GoodMapListItem = React.forwardRef(
  (
    {
      companyName,
      goodDescription,
      goodPoints,
      redirectToDetailsHandler,
      providerId,
      onClick,
      color,
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          display: "flex",
          [Theme.breakpoints.down("tablet")]: {
            width: "91vw",
          },
          [Theme.breakpoints.up("tablet")]: {
            width: "89.4vw",
          },
          [Theme.breakpoints.up("laptop")]: {
            width: "28.5vw",
          },
          [Theme.breakpoints.up("desktop")]: {
            width: "32.6vw",
          },
          
        }}
      >
        <Box sx={{
          backgroundColor: color,

          [Theme.breakpoints.up("tablet")]: {

            width: "8px", //height: "auto",
            //mr: "16px",
            //mt:"10px",
            //mb: "10px"
            mb:1,
          },

        }} ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRadius: 5,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
            padding: 3,
            mb: 1,
            width:"100%"
          }}
        >
          <Box
            id="CompanyNameAndGoodPoints"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >

            <Box
              sx={{
                [Theme.breakpoints.down("tablet")]: {
                  width: 132,
                },
                [Theme.breakpoints.up("tablet")]: {
                  width: 400,
                },

              }}
            >
              <ThemeProvider theme={Theme}>
                <Typography variant="bodyLargeBlack">{companyName}</Typography>
              </ThemeProvider>
            </Box>

            <Box
              id="GoodPoints"
              sx={{
                width: 90,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Avatar sx={{ bgcolor: color, width: 27, height: 27 }}>
                <FavoriteIcon
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                  }}
                />
              </Avatar>

              <ThemeProvider theme={Theme}>
                <Typography variant="bodyMediumBlack">
                  + 1 PKT {/**{goodPoints} PKT */}
                </Typography>
              </ThemeProvider>
            </Box>
          </Box>

          <Box id="GoodDescription" sx={{ mt: 2 }}>
            <ThemeProvider theme={Theme}>
              <Typography variant="bodyMediumRegural">
                {goodDescription}
              </Typography>
            </ThemeProvider>
          </Box>
          <Box
            id="Buttons"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
            ref={ref}
          >
            <Avatar
              sx={{
                bgcolor: "#f29a26",
                width: 44,
                height: 44,

                [Theme.breakpoints.up("tablet")]: {
                  mr: 3,
                  display: "none",
                },
                [Theme.breakpoints.down("laptop")]: {
                  mr: 3,
                  display: "flex",
                },
              }}
            >
              <MapOutlinedIcon
                onClick={onClick}
                sx={{
                  fontSize: 26,
                  color: "#fff",
                }}
              />
            </Avatar>
            
            <GeneralButton
              text="Zobacz Profil"
              redirectToDetailsHandler={redirectToDetailsHandler}
              providerId={providerId}
              
            />
            
          </Box>
        </Box>
      </Box>
    );
  }
);

GoodMapListItem.propTypes = {
  companyName: PropTypes.string,
  goodDescription: PropTypes.string,
  goodPoints: PropTypes.string,
  onClick: PropTypes.func,
  redirectToDetailsHandler: PropTypes.func,
  ref: PropTypes.func
};
