import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GoodHistoryListItem } from "../Atoms/GoodHistoryListItem";
import { Button } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export const GoodHistoryCard = ({ label, text, itemList,buttonAction = () => {},
                                    top= '50%',
                                    left= '50%',
                                    transform  = 'translate(-50%, -50%)',
                                    position = 'absolute',
}) => {
  return (
    <Box
      sx={{
        width: "56vw",
        boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
        borderRadius: "24px",
        p: 5,
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'white',

          top: top,
          left: left,
          transform: transform,
          position: position,
        
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box id="label" sx={{ mb: 20 }}>
          <Typography variant="subtitle">{label}</Typography>
        </Box>
        <Box id="text" sx={{ mb: 20 }}>
          <Typography variant="bodySmallBlack">{text}</Typography>
        </Box>
        <List id="historyList" width="100%" 
        sx={{
          maxHeight:300, 
          overflow:"auto",
         
          }} >
          {itemList.map((item, i) => (
            <ListItem >
              <GoodHistoryListItem
                date={item.date}
                updatedBy={item.updatedBy}
                comments={item.comments}
                status={item.status}
              />
            </ListItem>
          ))}
        </List>

        <Button
          variant="outlined"
          sx={{
            ...Theme.outlinedCancelGrayButton,
            [Theme.breakpoints.down("laptop")]: {
              fontSize: 12,
            },
            alignSelf: "flex-end",
            mt:20,
          }}
          onClick={()=>{buttonAction()}}
        >
          Zamknij
        </Button>
      </ThemeProvider>
    </Box>
  );
};
