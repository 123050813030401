import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonsFooter } from "../Atoms/ButtonFooter";
import { InputSelectListCardContainer } from "../InputSelectListCard/InputSelectListCardContainer";
import cameraImg from "../../images/camera.png";

export const withEditableCompanyInfo = (
  Component,
  cardName,
  imageName,
  profileId,
  itemList,
  companyDifferentiatorsMap,
  workingHours,
  imageUrl,
  authToken,
  urlApi,
  authEpid,
  //trivias,
  //informationList,
  cancelActionHandler,
  refreshProfile,
  setopenInprogress
) => {
  return (props) => {
    const config = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
    };

    const [companyInfoChanges, setCompanyInfoChanges] = useState();
    const [workingHoursState, setWorkingHoursState] = useState(workingHours);
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    const [features, setFeatures] = useState(companyDifferentiatorsMap);

    useEffect(() => {
      if (!selectedFile) {
        setPreview(imageUrl);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    useEffect(() => {
      if (features !== undefined && features !== companyDifferentiatorsMap) {
        const tmp = {
          companyDifferentiatorsMap: features,
        };

        setCompanyInfoChanges({ ...companyInfoChanges, ...tmp });
      }
    }, [features]);

   /**
    useEffect(() => {
      console.log(trivias);
      console.log(informationList);

  }, []);
   */

    const onChangeCompanyInfo = (changes) => {
      if (changes.workingHours) {
        const workingHoursTmp = workingHoursState.reduce(
          (acc, currentValue) =>
            changes.workingHours[0].weekDay === currentValue.weekDay
              ? [...acc, changes.workingHours[0]]
              : [...acc, currentValue],
          []
        );
        const tmp = {
          workingHours: workingHoursTmp,
        };
        setWorkingHoursState(tmp.workingHours);
        if (workingHours !== workingHoursState) {
          setCompanyInfoChanges({ ...companyInfoChanges, ...tmp });
        }
      } else if (
        changes.feature_one ||
        changes.feature_two ||
        changes.feature_three ||
        changes.feature_four
      ) {
        setFeatures({ ...features, ...changes });
      } else {
        setCompanyInfoChanges({ ...companyInfoChanges, ...changes });
      }
    };

    const onSaveCompanyInfo = async () => {
      const payload = {
        id: profileId,
        ePid: authEpid,
        ...companyInfoChanges,
      };
      setopenInprogress(true);
      if (!selectedFile) {
      } else {
        const imgType = imageName === "Logo" ? "logo" : "photo";
        const formData = new FormData();
        formData.append("file", selectedFile);

        const result = await axios.put(
          `${urlApi}/${profileId}/${imgType}`,
          formData,
          { ...config, "Content-Type": "multipart/form-data" }
        );
      }
      if (companyInfoChanges) {
        const result = await axios.patch(urlApi, payload, config);
      }
      setopenInprogress(false);
      refreshProfile();
      cancelActionHandler();
    };

    const onSelectFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        return;
      }
      setSelectedFile(e.target.files[0]);
    };

    const deleteImgFile = async () => {
      const imageBlob = await fetch(cameraImg).then(res => res.blob());
      setSelectedFile(imageBlob);
    }

    return (
      <InputSelectListCardContainer
        cardName={cardName}
        inputListCard1={
          <Component
            imageName={imageName}
            ImageUrl={preview}
            itemList={itemList}
            selectItemList={workingHours}
            onChangeActionHandler={onChangeCompanyInfo}
            saveActionHandler={onSaveCompanyInfo}
            cancelActionHandler={cancelActionHandler}
            onSelectFile={onSelectFile}
            onImgDelete={deleteImgFile}
          />
        }
        buttonsFooter={
          <ButtonsFooter
            saveActionHandler={onSaveCompanyInfo}
            cancelActionHandler={cancelActionHandler}
          />
        }
      />
    );
  };
};
