import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import classes from "./Privacy.module.css";
import React, { useRef } from "react";
import Typography from "@mui/material/Typography";

const Privacy = (props) => {
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const target4 = useRef(null);
  const target5 = useRef(null);
  const target6 = useRef(null);
  const target7 = useRef(null);
  const target8 = useRef(null);
  const target9 = useRef(null);
  const target10 = useRef(null);
  const target11 = useRef(null);
  const target12 = useRef(null);
  const targetP = useRef(null);

  const onSelect = (item) => {
    item?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <>
      <Box
        id="PrivBox001"
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "auto",
          height:"70vh",
          borderRadius: "16px",
          [Theme.breakpoints.down("laptop")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            //alignItems: "center",
          },
        }}
      >
        <Box
          id="PrivBox002"
          sx={{
            height: "auto",

            [Theme.breakpoints.down("laptop")]: {
              width: "0",
              display: "none",
            },


          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifySelf: "center",
            }}
            className={classes.Rectangle3111}
          >
            <span className={classes.InputtextLeft0}>NA SKRÓTY</span>

            <span className={classes.InputtextLeft1}>
              Kliknięcie na link przeniesie cię do wybranej sekcji
            </span>



            <Box
              id="PrivBox004"
              onClick={() => {
                onSelect(target1);
              }}
              sx={{
                display: "flex",
                mt: -3,
                cursor: "pointer",
              }}
            >
              <div className={classes.Ellipse262}></div>
              <span
                style={{ textDecoration: "underline" }}
                className={classes.LeftHeader001Main2}
              >
                REGULAMIN
              </span>
            </Box>

            <Box
              id="PrivBox004"
              onClick={() => {
                onSelect(target12);
              }}
              sx={{
                display: "flex",
                mt: -3,
                cursor: "pointer",
              }}
            >
              <div className={classes.Ellipse262}></div>
              <span
                style={{ textDecoration: "underline" }}
                className={classes.LeftHeader001Main2}
              >
                POLITYKA PRYWATNOŚCI
              </span>
            </Box>

          </Box>
        </Box>
        <Box
          id="PrivBox005"
          sx={{
            display: "flex",
            flexDirection: "column",

          }}
        >



          <Box
            id="PrivBox006"
            sx={{
              //justifySelf: "center",
              mt: -34,
            }}
            className={classes.BlokDefinicje}
          >
            <span className={classes.Definicje}>
              <br></br>

              <Box id="target1" ref={target1} className={classes.PointClassM} >
                <Typography
                  variant="bodyMediumBlack"
                  textTransform="uppercase"
                >
                  Regulamin korzystania z Usług Portalu Lokalni.pl
                </Typography>


              </Box>

              <Box id="target1" ref={target1} className={classes.PointClassM} >
                I. Postanowienia ogólne:

              </Box>

              <Box>
                1. Niniejszy  Regulamin  określa  w  szczególności  warunki  wyszukiwania  usług  i  produktów
                oferowanych przez małych i średnich operatorów telekomunikacyjnych w danym obszarze
                geograficznym kraju.<br></br><br></br>
                2. Podmiotem prowadzącym portal Lokalni.pl, który znajduje się pod adresem  www.lokalni.pl,
                jest Stowarzyszenie e-Południe  z  siedzibą  w  Bytomiu  (41-902 Bytom), ul.  Antoniego
                Józefczaka 29/40 wpisane do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy
                Katowice-Wschód w Katowicach, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego
                pod numerem KRS: 0000305459, REGON: 240927030, NIP: 6262925765.<br></br><br></br>
                3.  Użytkownik  portalu  Lokalni.pl  zobowiązuje  się  do  przestrzegania  wszystkich  postanowień
                niniejszego Regulaminu.<br></br><br></br>
                4.  Celem  portalu  Lokalni.pl  jest  utworzenie  serwisu  informacyjnego  o  zasięgu  terytorialnym
                i krajowym, umożliwiającego użytkownikom łatwy dostęp do usług i produktów oferowanych
                przez  małych  i  średnich  operatorów  usług  telekomunikacyjnych,  a    małym  i  średnim
                operatorom telekomunikacyjnym promocję i reklamę ich usług.<br></br><br></br>
              </Box>

              <Box id="target2" ref={target2} className={classes.PointClassM}>
                II. Definicje:
              </Box>

              <Box>
                1. Administrator – Stowarzyszenie e-Południe  z  siedzibą  w  Bytomiu  (41-902 Bytom),
                ul.  Antoniego Józefczaka 29/40 wpisane do rejestru przedsiębiorców prowadzonego przez
                Sąd  Rejonowy  Katowice-Wschód  w  Katowicach,  VIII  Wydział  Gospodarczy  Krajowego
                Rejestru  Sądowego  pod  numerem  KRS:  0000305459,  REGON:  240927030,  NIP:
                6262925765;<br></br><br></br>
                2. Formularz  -  interaktywny  dokument  elektroniczny  udostępniony  w  Portalu  Lokalni.pl,  za
                pomocą którego Użytkownik kieruje Zapytanie;<br></br><br></br>
                3. MiŚOT  -  mały  i  średni  operator  telekomunikacyjny,  którego  Usługi  prezentowane  są  na
                Portalu Lokalni.pl, potencjalny dostawca Usług dla Użytkownika;<br></br><br></br>
                4. Oferta -  oferta  handlowa    MiŚOT-a  w  rozumieniu  przepisów  kodeksu  cywilnego,
                przedstawiona w odpowiedzi na Zapytanie;<br></br><br></br>
                5. Portal Lokalni.pl - system teleinformatyczny,  dostępny  pod  adresem  www.lokalni.pl,
                dedykowany  do  przedstawiania  informacji  na  temat  zasięgu  terytorialnego,  zakresu  usług
                MiŚOT;<br></br><br></br>
                6. Regulamin – niniejszy regulamin;<br></br><br></br>
                7. Umowa - umowa zawierana pomiędzy Użytkownikiem a MiŚOT-em na świadczenie Usług;<br></br><br></br>
                8. Usługi  - prezentowane  za  pośrednictwem  Portalu  Lokalni.pl  usługi  oferowane  przez
                MiŚOT-ów  w  ramach  prowadzonej  przez  nich  działalności  gospodarczej:  Internet
                światłowodowy,  Internet  radiowy,  Internet  mobilny,  telewizja  cyfrowa,  telefon  komórkowy,
                telefon domowy, inne;<br></br><br></br>
                9. Użytkownik  -  osoba fizyczna posiadająca zdolność do czynności prawnych, osoba prawna
                lub  jednostka  organizacyjna  nieposiadająca  osobowości  prawnej  składająca  Zapytanie  za
                pośrednictwem Portalu Lokalni.pl;<br></br><br></br>
                10.  Wizytówka  -  zakres  i  forma  informacji  przedstawianych  na  temat  MiŚOT-a  na  Portalu
                Lokalni.pl,  uwzględniająca  zakres  świadczonych  przez  niego  Usług  z  przykładowym
                podziałem na:<br></br>
                a. internet światłowodowy z prędkościami wyrażonymi w Mb/s;<br></br>
                b. internet radiowy z prędkościami wyrażonymi w Mb/s;<br></br>
                c. internet mobilny z ilością danych wyrażonych w GB;<br></br>
                d. telewizję cyfrową z ilością kanałów w poszczególnych pakietach;<br></br>
                e. telefon komórkowy: rozmowy, SMS, MMS, pakiet danych;<br></br>
                f. telefon domowy: rozmowy ile minut.<br></br><br></br>
                11.  Zakres  terytorialny  -  obszar geograficzny, w obrębie którego MIŚOT prowadzi działalność
                gospodarczą;<br></br><br></br>
                12.  Zapytanie – zapytanie złożone za pośrednictwem Portalu Lokalni.pl celem pozyskania oferty
                świadczenia usług przez MiŚOT-a w danym obszarze geograficznym wskazanym przez
                Użytkownika Portalu Lokalni.pl.<br></br><br></br>
              </Box>


              <Box id="target3" ref={target3} className={classes.PointClassM}>
                III.Wymagania  techniczne  i  postanowienia    związane    ze  świadczeniem  usług  drogą
                elektroniczną
              </Box>
              <br></br>
              <Box>
                1. Dla  prawidłowego  korzystania  z  Portalu  Lokalni.pl  wymagane  jest  posiadanie  przez
                Użytkownika:<br></br>
                1) urządzenia multimedialnego z dostępem do Internetu;<br></br>
                2) dostępu do poczty elektronicznej (e-mail);<br></br>
                3) korzystanie z ogólnie dostępnej przeglądarki internetowej zaktualizowanej do bieżącej
                wersji;<br></br>
                4) włączenia w przeglądarce internetowej opcji plików cookies oraz Javascript.<br></br><br></br>
                2. Usługodawca  za  pośrednictwem  Portalu  Lokalni.pl  świadczy  drogą  elektroniczną  usługę
                pośredniczenia w przekazywaniu danych Klientów do Użytkowników zgodnie z deklarowanym
                przez Użytkownika zasięgiem z pliku CSV.<br></br><br></br>
                3. W  związku  ze  świadczeniem  usług  drogą  elektroniczną  oraz  występującymi  ryzykami,
                w szczególności ryzykiem ingerencji osób trzecich w transmisję danych, Administrator zaleca
                między  innymi  stosowanie  odpowiedniego  oprogramowania  chroniącego  przed  złośliwym
                oprogramowaniem,  Użytkownika  obowiązuje  zakaz  dostarczania  treści  o  charakterze
                bezprawnym w rozumieniu art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                elektroniczną.<br></br><br></br>
                4. Użytkownik ponosi pełną  odpowiedzialność za nieprawidłowości  w funkcjonowaniu Usługi,
                będące  następstwem  jego  zawinionego  działania  lub  zaniechania,  w  tym  niepoprawnego
                wykorzystania funkcjonalności danej Usługi lub integracji z zewnętrznym, wybranym przez
                Użytkownika oprogramowaniem.<br></br><br></br>
              </Box>

              <Box id="target4" ref={target4} className={classes.PointClassM}>
                IV. Ogólne warunki korzystania z Portalu Lokalni.pl
              </Box>

              <Box>
                1. Korzystanie z Portalu Lokalni.pl jest bezpłatne dla Użytkowników..<br></br><br></br>
                2. Z Portalu Lokalni.pl mogą korzystać wyłącznie osoby pełnoletnie, posiadające pełną zdolność
                do czynności prawnych..<br></br><br></br>
                3. Portal  Lokalni.pl    może  być  wykorzystywany  tylko  w  celu  wysłania  Zapytania  na  własne
                potrzeby lub potrzeby prowadzonej działalności gospodarczej..<br></br><br></br>
                4. Administrator zastrzega, że nie jest sprzedawcą, ani stroną transakcji zawieranych w ramach
                przedstawianych Ofert..<br></br><br></br>
                5. Administrator  nie  jest  stroną  umów,  porozumień  lub  innych  czynności  prawnych
                dokonywanych pomiędzy Użytkownikiem a MiŚOT-em, wobec czego Administrator nie ponosi
                wobec Użytkownika odpowiedzialności za niewykonanie lub nienależyte wykonanie Umów
                zawartych w następstwie lub w związku z przesłaniem Zapytania za pośrednictwem Portalu
                Lokalni.pl..<br></br><br></br>
                6. Administrator  za  pośrednictwem  Portalu  Lokalni.pl  dokonuje  prezentacji  Usług  MiŚOT-ów,
                z uwzględnieniem Zakresu terytorialnego prowadzonej przez nich działalności gospodarczej,
                w oparciu o dostarczone przez nich treści..<br></br><br></br>
                7. Administrator  nie  odpowiada  za  treść  udostępnianych  Ofert,  w  szczególności  za  ich
                zgodność, kompletność, rzetelność i aktualność..<br></br><br></br>
                8. Oferty widoczne są dla wszystkich Użytkowników Portalu Lokalni.pl w Wizytówce MiŚOT-a..<br></br><br></br>
                9. MiŚOT przedstawia Ofertę w Portalu Lokalni.pl, dostęp do pełnej Oferty wraz z wykazem cen
                Użytkownik może uzyskać po udanym kontakcie z MiŚOT-em..<br></br><br></br>
              </Box>
              <Box id="target5" ref={target5} className={classes.PointClassM}>
                Zasady pozyskania Oferty za pośrednictwem Portalu Lokalni.pl.
              </Box>

              <Box>
                1. Zapytania  wysyłane  są  przez  Użytkowników  za  pośrednictwem  Portalu  Lokalni.pl  - po
                wypełnieniu i wysłaniu Formularza dostępnego pod adresem: https://lokalni.pl.<br></br><br></br>
                2. W  celu prawidłowego wypełnienia Formularza należy uzupełnić znajdujące się w nim pola,
                w tym takie dane jak: imię, nazwisko, lokalizację usługi, adres e-mail  i/lub  numer  telefonu
                kontaktowego Użytkownika.<br></br><br></br>
                3. Administrator  za  pośrednictwem  Portalu  Lokalni.pl  przekazuje  Zapytanie  MiŚOT-om
                spełniającym kryteria według poszukiwanych Usług, Zakresu terytorialnego.<br></br><br></br>
                4. Dostęp do Oferty dla Użytkownika następuje po akceptacji Zapytania przez MiŚOT-a. MiŚOT
                po  uzyskaniu  dostępu  do  treści  zapytania,  może  (nie  jest  zobowiązany)  kontaktować  się
                z  Użytkownikiem  w  celu  doprecyzowania  potrzeb  oraz  możliwości,  po  czym  powinien
                zaproponować spersonalizowaną ofertę Użytkownikowi.<br></br><br></br>
                5. Dostęp  do danych Użytkownika jest udzielany  MiŚOT-owi  jednorazowo  w  celu  udzielenia
                odpowiedzi na złożone Zapytanie. W przypadku braku odpowiedzi ze strony MiŚOT-a  lub
                braku  możliwości  realizacji  oferty,  dane  mogą  zostać  przekazane  do  innego  MiŚOT-a
                posiadającego  infrastrukturę  pod  podanym  w  zapytaniu  adresie.  W  pierwszej  kolejności
                przekazywany jest adres, po potwierdzeniu  chęci  podłączenia  usług  pod  wskazanym
                adresem  MiŚOT  otrzyma  dostęp  do  pozostałych  danych  wskazanych  w  Formularzu.
                Administrator nie jest uprawniony do weryfikacji prawdziwości, kompletności lub uzupełnienia
                informacji podanych przez Użytkownika w Formularzu.<br></br><br></br>
              </Box>
              <Box id="target6" ref={target6} className={classes.PointClassM}>
                VI.  Prawa i obowiązki Użytkownika
              </Box>

              <Box>
                1. Zapytanie należy kierować przy pomocy Formularza, wypełniając poszczególne jego pola.<br></br><br></br>
                2. Zapytanie nie może wprowadzać w błąd.<br></br><br></br>
                3. Zabronione jest umieszczanie w opisie Zapytania reklam.<br></br><br></br>
                4. W Zapytaniach zabrania się zamieszczania odnośników do innych stron.<br></br><br></br>
                5. W  Zapytaniach  zabrania  się  stosowania  elementów  języka  JavaScript,  Java  oraz  innych
                języków programowania z wyjątkiem HTML.<br></br><br></br>
                6. Nie można stosować elementów języka HTML służących do: wczytywania i osadzania innych
                stron,  używania  plików  „cookie”,  tworzenia  formularzy  oraz  innego  kodu  naruszającego
                integralność Portalu Lokalni.pl.<br></br><br></br>
                7. Zabrania się wysyłania w ramach Zapytań informacji, które nie są zapytaniami o Oferty.<br></br><br></br>
                8. Zabrania się umieszczania w Formularzu wulgaryzmów, treści obraźliwych, naruszających
                godność  człowieka,  obrażających  uczucia  lub  poglądy  innych  osób,  itp.  W  przypadku
                powzięcia wiedzy o zamieszczeniu takich treści Administrator zastrzega sobie prawo stałego
                lub czasowego zablokowania Użytkownika.<br></br><br></br>
              </Box>
              <Box id="target7" ref={target7} className={classes.PointClassM}>
                VII. Odpowiedzialność Administratora
              </Box>

              <Box>
                1. Administrator zobowiązuje się do zapewnienia jak najwyższej jakości w ramach prezentacji
                oferowanych Usług za pośrednictwem Portalu Lokalni.pl.<br></br><br></br>
                2. Administrator  nie  udziela  Użytkownikowi  jakichkolwiek  gwarancji,  że  Portal  Lokalni.pl  jest
                wolny od wad.<br></br><br></br>
                3. Administrator  nie  ponosi  wobec  Użytkownika  odpowiedzialności  za  zakłócenia
                w  funkcjonowaniu  Portalu Lokalni.pl wywołane działaniem siły wyższej, za którą uważa się
                wydarzenia, których Administrator nie mógł przewidzieć i które zostały spowodowane przez
                okoliczności od niego niezależne.<br></br><br></br>
                4. W razie wystąpienia awarii portalu Administrator będzie dążył do jej niezwłocznego usunięcia.<br></br><br></br>
                5. Administrator  zastrzega  sobie  prawo  do  czasowych  przerw  w  działaniu  Portalu  Lokalni.pl
                wynikających  z  przyczyn  technicznych.  Administrator  planuje  przerwy  techniczne  w  taki
                sposób,  aby  były  one  jak  najmniej  uciążliwe  dla  Użytkowników,  w  szczególności,  aby
                planowane były na godziny nocne i jedynie na czas niezbędny do dokonania koniecznych
                działań przez Administratora.<br></br><br></br>
                6. Administrator  nie  ponosi  odpowiedzialności  za  treść  umieszczanych  i  przesyłanych  za
                pośrednictwem Portalu Lokalni.pl treści Zapytań, informacji o Usługach, Ofertach.<br></br><br></br>
                7. Na wniosek sądu Administrator zmieni lub usunie z Portalu Lokalni.pl treści dodane przez
                MiŚOT-a,  które  naruszają  prawa  osób  trzecich,  w  szczególności  prawa  autorskie,  prawa
                własności intelektualnej, w tym prawa autorskie lub prawa własności przemysłowej.<br></br><br></br>
                8. Administrator  nie  ponosi  odpowiedzialności  za  rezygnację  z  zawarcia  Umowy  przez
                Użytkownika lub/i MiŚOT-a.<br></br><br></br>
                9. Administrator nie ponosi odpowiedzialności wobec MiŚOT-ów za błędne bądź nieaktualne
                Zapytania Użytkowników.<br></br><br></br>
                10.  Administrator nie gwarantuje Użytkownikom otrzymania odpowiedzi od MIŚOT-ów.<br></br><br></br>
              </Box>
              <Box id="target8" ref={target8} className={classes.PointClassM}>
                VIII. Ochrona danych osobowych
              </Box>

              <Box>
                1. Użytkownik jest zobowiązany do podania w Formularzu prawdziwych danych osobowych.<br></br><br></br>
                2. Użytkownik ponosi pełną odpowiedzialność za nieprawidłowe funkcjonowanie Usługi będące
                następstwem  podania  przez  Użytkownika  niepoprawnych  danych  osobowych
                uniemożliwiających realizację Usługi.<br></br><br></br>
                3. Administrator odpowiada za przetwarzanie danych osobowych tylko i wyłącznie w zakresie
                relacji  pośredniczenia  pomiędzy  Użytkownikiem  zainteresowanym  Usługą  a  MiŚOT-em
                zainteresowanym złożeniem Oferty Użytkownikowi.<br></br><br></br>
                4. Administrator  nie  ponosi  wobec  Użytkownika  odpowiedzialności  za  niewykonanie  lub
                nienależyte wykonanie obowiązków prawnych z zakresu ochrony danych  osobowych  przez
                MiŚOT-a,  do  których  ten  ostatni  jest  zobowiązany  po  otrzymaniu  danych  osobowych
                Użytkownika.<br></br><br></br>
                5. Rola  Administratora  w  zakresie  przetwarzania  danych  osobowych  sprowadza  się  do
                udzielenia jednorazowego dostępu do danych Użytkownika wybranemu MiŚOT-owi  w  celu
                udzielenia odpowiedzi na złożone przez niniejszego Użytkownika Zapytanie.<br></br><br></br>
                6. Użytkownik podając dane osobowe w Formularzu, wyraża zgodę na przetwarzanie swoich
                danych osobowych przez Administratora oraz udostępnienie ich właściwemu ze względu na
                zakres usług i zasięg geograficzny MiŚOT-owi zgodnie z postanowieniami Rozdziału V pkt 5.<br></br><br></br>
                7. Administrator  upoważniony  przez  Użytkownika  do  udostępnienia  jego  danych  osobowych
                MiŚOT-owi  po  wykonaniu  niniejszej  czynności  nie  odpowiada  za  przetwarzanie  danych
                osobowych  Użytkownika  przez  MiŚOT-a,  ani  za  skuteczność  środków  technicznych
                i  organizacyjnych, które MiŚOT wdrożył w ramach swojej organizacji w systemie ochrony
                danych osobowych.<br></br><br></br>
                8. Polityka  ochrony  prywatności  została  szczegółowo  opisana  w  dokumencie  stanowiącym
                integralną część niniejszego Regulaminu (Załącznik do Regulaminu).<br></br><br></br>
              </Box>
              <Box id="target9" ref={target9} className={classes.PointClassM}>
                IX. Reklamacje
              </Box>

              <Box>
                1. Administrator  odpowiada  za  niewykonanie  lub  nienależyte  wykonanie  swoich  zobowiązań
                wynikających z niniejszego Regulaminu w zakresie w nim określonym.<br></br><br></br>
                2. Użytkownik  może  zgłaszać  reklamacje  dotyczące  usługi  świadczonej  w  ramach  Portalu
                Lokalni.pl  poprzez wysłanie wiadomości na adres e-mail: zespol@lokalni.pl.<br></br><br></br>
                3. Zgłoszenie  reklamacji  powinno  zawierać  oznaczenie  osoby  zgłaszającej  reklamację  (imię,
                nazwisko, adres e-mail)  oraz  opis  zdarzenia  będącego  przyczyną  reklamacji.  Jeżeli
                przekazane  w  reklamacji  dane  lub informacje wymagają uzupełnienia, przed rozpatrzeniem
                reklamacji  Administrator  zwraca  się  do  Użytkownika  o  jej  uzupełnienie  we  wskazanym
                zakresie.<br></br><br></br>
                4. Reklamacje zostaną rozpatrzone w terminie 14 dni od daty otrzymania przez Administratora
                prawidłowo  złożonej  reklamacji  (zawierającej  wymagane  elementy  i  niewymagającej
                uzupełnienia).<br></br><br></br>
                5. Użytkownik  otrzyma  informację  o  sposobie  rozpatrzenia  reklamacji  drogą  korespondencji
                elektronicznej, na adres e-mail podany w reklamacji.<br></br><br></br>
                6. Administrator  zastrzega,  że  reklamacje  dotyczące  jakości  lub  wad  Usług  nabytych  od
                MiŚOT-ów,  czy  też  nienależytego  wykonania  bądź  niewykonania  Umowy,  w  tym  procesu
                realizacji Umowy, należy kierować bezpośrednio do MiŚOT-a realizującego Usługę.<br></br><br></br>
              </Box>
              <Box id="target10" ref={target10} className={classes.PointClassM}>
                X.  Prawa autorskie
              </Box>

              <Box>
                1. Portal Lokalni.pl jest własnością Administratora.<br></br><br></br>
                2. Nazwa i logo Lokalni.pl są zastrzeżonymi znakami towarowymi i mogą być wykorzystywane
                tylko za pisemną zgodą Administratora.<br></br><br></br>
                3. Portal Lokalni.pl zawiera lub wykorzystuje utwory chronione prawem autorskim, a także znaki
                towarowe oraz inne materiały, w szczególności teksty, zdjęcia, grafikę.<br></br><br></br>
                4. Autorskie prawa majątkowe do wszelkich graficznych elementów Lokalni.pl, wyglądu serwisu
                internetowego  i  innych  treści  w  nim  umieszczonych,  stanowiących  utwór  w  rozumieniu
                Ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych („Prawo Autorskie”),
                przysługują Administratorowi.<br></br><br></br>
                5. Jakiekolwiek kopiowanie, rozpowszechnianie i dokonywanie opracowań Portalu Lokalni.pl, ich
                poszczególnych  elementów,  a  także  ich  wyglądu  jako  całości,  stanowiących  utwór
                w  rozumieniu  Prawa  Autorskiego,  bez  wyraźnej  pisemnej  zgody  Administratora  stanowi
                naruszenie majątkowych praw autorskich Lokalni.pl.<br></br><br></br>
              </Box>
              <Box id="target11" ref={target11} className={classes.PointClassM}>
                XI. Postanowienia końcowe
              </Box>

              <Box>
                1. Administrator zastrzega sobie prawo dokonywania zmian w Regulaminie, lub wydanie
                nowego Regulaminu w szczególności w następujących przypadkach:<br></br>
                1) zmiana przepisów prawa;<br></br>
                2) zmiana warunków technicznych prezentacji usług w Portalu Lokalni.pl;<br></br>
                3) zmiany organizacyjne lub reorganizacyjne Administratora.<br></br><br></br>
                2. Zmiana  Regulaminu,  o  której  mowa  w  pkt  1  powyżej,    wiąże  Użytkownika  od  dnia
                udostępnienia nowego Regulaminu pod adresem https://lokalni.pl.<br></br><br></br>
                3. W sprawach nieuregulowanych niniejszym Regulaminem stosuje się odpowiednie przepisy
                prawa polskiego, w szczególności Kodeksu cywilnego.<br></br><br></br>
                4. Regulamin wchodzi w życie z dniem 01.02.2024 r<br></br><br></br>
              </Box>
              <br></br>

              <Box id="target12" ref={target12} className={classes.PointClassM} >
                <Typography
                  variant="bodyMediumBlack"
                  textTransform="uppercase"
                >
                  Polityka Prywatności korzystania z Usług Portalu Lokalni.pl
                </Typography>

              </Box>


              <Box className={classes.PointClassM}>
              <br></br><br></br>
                I. Postanowienia ogólne:
              </Box>
              <Box>
              <br></br><br></br>
                1. Niniejsza  Polityka  Prywatności  (dalej:  Polityka)  jest  skierowana  do  Użytkowników  Portalu
                Lokalni.pl (dalej: Portal).<br></br><br></br>
                2. W Polityce  zostały  opisane standardy przetwarzania danych osobowych stosowane przez
                Nas w celu poszanowania Twojej prywatności jako Użytkownika Portalu.<br></br><br></br>
                3. W  treści  poniżej  znajdziesz  ogólne  zasady,  zgodnie  z  którymi  przetwarzamy  informacje
                dotyczące osób korzystających z Portalu, zwane danymi osobowymi.<br></br><br></br>
                4. Odniesienia  „RODO”  użyte  w  treści  Polityki  dotyczą  Rozporządzenia  Parlamentu
                Europejskiego  i  Rady  (UE)  2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych  w  związku  z  przetwarzaniem  danych  osobowych  i  w  sprawie  swobodnego
                przepływu  takich  danych  oraz  uchylenia  dyrektywy  95/46/WE  (ogólne  rozporządzenie  o
                ochronie danych) (Dz. U. UE. L. z 2016 r. Nr 119, str. 1 z późn. zm.).<br></br><br></br>
                5. Celem portalu Lokalni.pl jest utworzenie serwisu informacyjnego o  zasięgu terytorialnym i
                krajowym, umożliwiającego użytkownikom łatwy dostęp do usług i produktów oferowanych
                przez małych i średnich operatorów usług telekomunikacyjnych (dalej: MiŚOT), a  małym i
                średnim operatorom telekomunikacyjnym promocję i reklamę ich usług.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                II. Administrator oraz Inspektor Danych Osobowych:
              </Box>
              <Box>
                1. Administratorem  danych  (dalej:  Administrator),  czyli  podmiotem  prowadzącym  Portal  i
                decydującym o tym, jak będą przetwarzane Twoje dane osobowe, gromadzone w związku z
                korzystaniem z Portalu jest Stowarzyszenie E-Południe  reprezentowane  przez  Prezesa
                Zarządu z siedzibą przy ul. Antoniego Józefczaka 29/40, 41-902  Bytom,  NIP  6262925765,
                REGON 240927030, KRS 0000305459, tel.: 32 666-66-99, e-mail: biuro@e-poludnie.pl.<br></br><br></br>
                2. We  wszelkich  sprawach  związanych  z  przetwarzaniem  danych  osobowych  przez
                Administratora możesz uzyskać informację, kontaktując się z Inspektorem Ochrony Danych,
                Panem Marcinem Zemła w następujących formach:<br></br>
                1) przesyłając informację na adres e-mail: marcin@informatics.jaworzno.pl,<br></br>
                2) listownie i osobiście pod adresem siedziby Administratora,<br></br>
                3) telefonicznie pod numerem: 600991705.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                III. Zakres i źródło zbieranych danych:
              </Box>
              <Box>
                1. Administrator  za  pośrednictwem  Portalu  dokonuje  prezentacji  Usług  MiŚOT-ów,  z
                uwzględnieniem  zakresu terytorialnego prowadzonej przez  nich działalności gospodarczej,
                tym  samym  umożliwia  Ci  przekazanie  danych  identyfikacyjnych  (imię,  nazwisko),  danych
                kontaktowych  (numer  telefonu,  adres  e-mail), a także tych podanych przez Ciebie w treści
                wiadomości do odpowiedniego pod kątem zakresu terytorialnego MiŚOTa w celu skierowania
                zapytania oraz skorzystania z usług i produktów przez nich oferowanych. W szczególności są
                to zatem dane podawane bezpośrednio od Ciebie.<br></br><br></br>
                2. Administrator gromadzi również dane związane z Twoją aktywnością, jak na przykład czas
                spędzony na stronie, wyszukiwane frazy, liczba wyświetlanych podstron, data i źródło wizyty.<br></br><br></br>
                3. Twoje dane mogły nam zostać przekazane również pośrednio w związku z załatwianą sprawą
                przez osobę, która tę sprawę skierowała do Administratora. Ich  źródłem jest wówczas ta
                osoba, która podała Twoje dane identyfikacyjne, adresowe i związane ze sprawą, jak opis
                sprawy.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                IV. Cele i podstawy prawne przetwarzania danych osobowych:
              </Box>
              <Box>
                Dane, które otrzymujemy od Ciebie, mogą być przetwarzane w następujących celach:<br></br>
                1) skierowania  zapytania  do  MiŚOT-ów,  przekazania  im  danych  kontaktowych,  aby
                mogli przygotować dla Ciebie ofertę i zachęcić Ciebie do skorzystania z ich usług i
                produktów,  prowadzenia  korespondencji  w  celu  załatwienia  sprawy.  Wypełnienie
                formularza i dobrowolne podanie danych jest jednoznaczne z wyrażeniem zgody na
                przetwarzanie  danych  osobowych  w  celu  rozpatrzenia  Twojego  zgłoszenia.
                Decydując  się  skorzystać  z  usługi,  akceptujesz  to,  że  Twoje  dane  zostaną
                przekazane lokalnym operatorom telekomunikacyjnym, którzy przygotują ofertę dla
                Ciebie i skontaktują się z Tobą w celu ustalenia zasad świadczenia interesującej Cię
                usługi  internetu, telefonu i telewizji.
                Zgodność  przetwarzania  z  prawem:  art. 6 ust. 1 lit. a) RODO (zgoda właściciela
                danych) oraz art. 6 ust. 1 lit. f) RODO (uzasadniony interes Administratora);<br></br>
                2) zapewnienia  bezpieczeństwa  Portalu  oraz  prawidłowego  działania  usług,
                dostosowywania  treści  do  potrzeb  Twoich  i  innych  Użytkowników,  przyjmowania  i
                obsługiwania  zgłoszeń  i  zapytań,  dokonywania  pomiarów  statystycznych  i  działań
                analitycznych.<br></br>
                Zgodność przetwarzania z prawem: art. 6 ust. 1 lit. f) RODO.<br></br>
                3) dopełnienia  obowiązków  prawnych  wynikających  z  procedury  reklamacyjnej  oraz
                świadczenia usług drogą elektroniczną.
                Zgodność przetwarzania z prawem: art. 6 ust. 1 lit. c) RODO w związku z  art. 172
                ustawy z dnia 16 lipca 2004 r. prawo telekomunikacyjne oraz art. 10 ust. 2 ustawy z
                dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną oraz ustawą z dnia 23
                kwietnia 1964 r. - Kodeks cywilny.<br></br>
              </Box>
              <Box className={classes.PointClassM}>
                V. Twoje  uprawnienia  jako  właściciela  danych  wynikające  z  RODO  w  zakresie
                przetwarzania  danych  -  wobec  przetwarzania  danych  osobowych  masz  następujące
                prawa:
              </Box>
              <Box>
                <br></br><br></br>
                1. prawo  dostępu  do danych osobowych  -  masz prawo  żądać od Administratora  wglądu do
                swoich danych, a także otrzymania ich kopii;<br></br><br></br>
                2. prawo żądania sprostowania danych - masz prawo żądać od Administratora sprostowania lub
                poprawienia danych;<br></br><br></br>
                3. prawo do usunięcia danych - masz prawo żądać od Administratora usunięcia danych;<br></br><br></br>
                4. prawo  do  ograniczenia  przetwarzania  -  masz prawo żądać od Administratora ograniczenia
                przetwarzania;<br></br><br></br>
                5. prawo do przenoszenia danych - uprawnienie w zakresie,  w  jakim  Twoje  dane  są
                przetwarzane na podstawie zgody lub w celu zawarcia i wykonania umowy;<br></br><br></br>
                6. prawo  sprzeciwu  -  prawo  uzasadnione  Twoją  szczególną  sytuacją  wobec  przetwarzania
                danych na podstawie lub w celach marketingowych;<br></br><br></br>
                7. prawa  do  wycofania  zgody  -  masz  prawo  wycofać  zgodę,  jeśli  Twoje  dane  osobowe  są
                przetwarzane  na  podstawie  wyrażonej  przez  Ciebie  zgody,  co  jednak  nie  wpływa  na
                zgodność  z  prawem  przetwarzania  dokonanego  przed  jej  wycofaniem.  Wycofanie  zgody
                może jednak utrudnić lub uniemożliwić kontakt;<br></br><br></br>
                8. prawo  wniesienia  skargi  w  związku  z  przetwarzaniem  przez  Administratora  do  Prezesa
                Urzędu Ochrony Danych Osobowych pod adresem: ul. Stawki 2, 00-193 Warszawa.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                VI. Odbiorcy i przekazywanie Twoich danych:
              </Box>
              <Box>
                1. Dostęp  do  danych  będą  miały  osoby  pracujące  i  współpracujące  z  Administratorem  w
                zakresie  realizacji  jego  zadań  statutowych,  w  tym  prowadzenia  Portalu  wspierającego  i
                promującego  działalność  MiŚOT,  w  szczególności  będą  to:  MiŚOT  S.A.,  ul.  Józefczaka
                29/40, 41-902 Bytom, NIP 626-303-74-81, REGON 385370626, KRS 0000824003, tel.: (+48)
                32  810  10  55,  e-mail:  misot@misot.pl,  kontakt  e-mail  do  IOD:  iod@misot.pl  oraz  inne
                podmioty  realizujące  usługi  prawne,  finansowe,  techniczne,  informatyczne  na  rzecz
                Administratora  –  każdorazowo będą zawierane wówczas umowy powierzenia, jak również
                współpracownicy  Administratora  współpracujący  na  podstawie  umów  cywilnoprawnych  lub
                podwykonawcy.<br></br><br></br>
                2. Twoje  dane  osobowe  zostaną  udostępnione  lokalnym  operatorom  telekomunikacyjnym  -
                MiŚOT-om w celu przygotowania dla Ciebie najkorzystniejszej oferty, na co wyrażasz zgodę,
                wysyłając  formularz.  Każdorazowo  wówczas  lokalny  operator  telekomunikacyjny  staje  się
                samodzielnym administratorem Twoich danych osobowych.<br></br><br></br>
                3. Dane w przypadkach ściśle określonych przepisami prawa mogą zostać ujawnione poprzez
                przesłanie uprawnionym podmiotom.<br></br><br></br>
                4. Administrator  nie  zamierza  przekazywać  danych  poza  obszar  Europejskiego  Obszaru
                Gospodarczego.<br></br><br></br>
                5. Dane, jeżeli będą przetwarzane w sposób zautomatyzowany, to tylko wyłącznie na podstawie
                dobrowolnej zgody właścicieli danych, w tym również profilowane.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                VII. Czas przechowywania danych:
              </Box>
              <Box>
                1. Dane  osobowe  będą  przechowywane  do  momentu  odwołania  zgody  na  przetwarzanie
                danych osobowych lub do momentu zakończenia korzystania z naszych usług tj. przekazania
                danych  MiŚOT-owi  oraz  zakończenia  terminu  złożenia  możliwej  reklamacji  lub  do  czasu
                wyrażenia  skutecznego  sprzeciwu  wobec  przetwarzania  danych  osobowych,  chyba  że
                obowiązujące przepisy prawa, na które powoła się Administrator, stanowiłyby inaczej.<br></br><br></br>
                2. Dane związane z analizą ruchu sieciowego gromadzone za pośrednictwem plików cookies
                oraz podobnych technologii  mogą  być  przechowywane  do  momentu  wygaśnięcia  pliku
                cookies. Niektóre pliki cookies nigdy nie wygasają, w związku z tym czas przechowywania
                danych będzie równoważny z czasem niezbędnym Administratorowi do zrealizowania celów
                związanych  z  gromadzeniem  danych,  jak  zapewnienie  bezpieczeństwa  i  analiza  danych
                historycznych związanych z ruchem na stronie.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                VIII. Wykorzystanie plików cookies oraz podobnych technologii:
              </Box>
              <Box>
                1. Pliki cookies to niewielkie pliki tekstowe  zapisywane na urządzeniu Użytkownika podczas
                korzystania z Portalu oraz wszelkie inne podobne technologie służące do zbierania informacji
                o aktywności Użytkownika w Portalu. Pliki cookies mogą pochodzić od Administratora lub od
                zaufanych partnerów Administratora.<br></br><br></br>
                2. Portal  umożliwia  gromadzenie  informacji  o  Użytkowniku  za  pośrednictwem  cookies  oraz
                podobnych  technologii,  których  korzystanie  najczęściej  wiąże  się  z  zainstalowaniem  tego
                narzędzia  na  urządzeniu  Użytkownika  (komputer,  smartfon,  itd.).  Wykorzystuje  się  te
                informacje do  zapamiętywania decyzji  Użytkownika (wybór czcionki, kontrastu, akceptacja
                polityki), utrzymania sesji Użytkownika (np. po zalogowaniu), zapamiętania hasła (za zgodą),
                gromadzenia informacji o urządzeniu Użytkownika oraz jego wizycie służące do zapewnienia
                bezpieczeństwa, ale także analizy wizyt i dostosowania treści.<br></br><br></br>
                3. Administrator  wykorzystuje  Pliki  cookies  przede  wszystkim,  aby  umożliwić  Użytkownikowi
                dostęp do Portalu i ułatwić Użytkownikowi korzystanie z niego. Administrator wykorzystuje
                Pliki cookies również w celach analitycznych oraz marketingowych – jednak wyłącznie wtedy,
                gdy  Użytkownik  wyrazi  na  to  zgodę  przy  pierwszym  wejściu  do  Portalu.  Administrator
                wykorzystuje też inne technologie i rozwiązania techniczne pozwalające uzyskać dostęp do
                informacji przechowywanej na urządzeniu  lub  w  przeglądarce  Użytkownika  (np.  Local
                Storage,  dzięki  której  Administrator  uzyskuje  dostęp  do  informacji  zapisywanych  podczas
                korzystania z Portalu w wydzielonej części pamięci przeglądarki Użytkownika).<br></br><br></br>
                4. Informacje pozyskane za pośrednictwem cookies oraz podobnych technologii nie są łączone
                z innymi danymi Użytkowników Portalu, nie służą też do ich identyfikacji przez Administratora.<br></br><br></br>
                5. Użytkownik ma możliwość ustawić w przeglądarce blokowanie określonych rodzajów cookies
                i innych technologii, poprzez określenie na przykład, że dozwolone będą tylko i wyłącznie te,
                które są niezbędne do poprawnego wyświetlenia strony. Domyślnie większość przeglądarek
                dopuszcza stosowanie wszystkich cookies, jednak Użytkownik ma możliwość zmiany tych
                ustawień w dowolnym momencie, może także usunąć zainstalowane już pliki cookies. Każda
                z  przeglądarek umożliwia takie działanie poprzez jedną  z dostępnych  w ustawieniach lub
                preferencjach opcji.<br></br><br></br>
                6. Istnieje  przy  tym  możliwość  takiego  skonfigurowania  przeglądarki  internetowej,  które
                spowoduje, że całkowicie zostanie wyłączona możliwość przechowywania plików cookies na
                dysku twardym komputera. Efektem takiej zmiany może być jednak utrata pewnych funkcji
                Lokalni.pl.<br></br><br></br>
                7. Użytkownik ma także możliwość korzystać ze strony w tzw. trybie incognito, który blokuje
                możliwość gromadzenia danych o jego wizycie.<br></br><br></br>
                8. Korzystanie z Portalu bez zmiany ustawień przeglądarki, tzn. z domyślnym zaakceptowaniem
                plików cookies i podobnych technologii oznacza zgodę na ich wykorzystanie do określonych
                powyżej  celów.  Administrator  nie  wykorzystuje  uzyskanych  informacji  do  celów
                marketingowych.<br></br><br></br>
                9. Cookies stosowane w Portalu dzielą się na następujące kategorie:<br></br>
                1) Niezbędne  Pliki  cookies  - te  pliki  cookies  są  instalowane,  aby  zapewnić
                Użytkownikowi dostęp do Portalu  i jego podstawowych funkcji, nie wymagają zatem
                zgody Użytkownika. Bez niezbędnych Plików cookies Administrator nie byłby w stanie
                świadczyć Użytkownikom usług w ramach Portalu.<br></br>
                2) Opcjonalne Pliki cookies - z tych cookies Administrator korzysta wyłącznie wtedy, gdy
                Użytkownik  wyrazi  na  to  zgodę.  Są  to  cookies:  funkcjonalne  – pozwalają  na
                zapamiętanie preferencji lub wyborów Użytkowników (takich jak nazwa Użytkownika,
                język,  rozmiar  tekstu  lub  inne  elementy,  które  można  dostosować)  i  dostarczanie
                Użytkownikom spersonalizowanych  treści  w  ramach  Portalu;  analityczne  –
                umożliwiają sprawdzenie liczby wizyt i źródeł ruchu w Portalu. Pomagają rozpoznać,
                które strony są mniej lub bardziej popularne, i zrozumieć, jak Użytkownicy poruszają
                się  po  stronie.  Dzięki  temu  Administrator  może  poprawiać  wydajność  Portalu;
                reklamowe – wykorzystywane  są  w  celu  dostarczenia  reklam  zgodnych  z
                zainteresowaniami  i  preferencjami  Użytkowników.  Na  podstawie  informacji  z  tych
                plików  i  aktywności  w  innych  serwisach  jest  budowany  profil  zainteresowań
                Użytkowników.<br></br><br></br>
                10.  Korzystając  z  Portalu,  Użytkownik  może  otrzymać  Pliki  cookies  pochodzące  od
                współpracujących z Administratorem podmiotów trzecich. W Portalu stosowane są sesyjne
                pliki  cookies - niektóre  cookies  są  plikami  tymczasowymi,  przechowywanymi  do  czasu
                wylogowania,  opuszczenia  strony  lub  wyłączenia  przeglądarki  internetowej.  Tego  rodzaju
                cookies  pomagają  analizować  ruch  sieciowy,  umożliwiają  identyfikację  i  rozwiązywanie
                problemów technicznych oraz łatwiejsze poruszanie się po Portalu.<br></br><br></br>
                11.  Przeglądarka internetowa Użytkownika powinna zapewniać możliwość zmiany ustawień tak,
                aby odrzucić, usunąć lub zablokować określone Pliki cookies. Pod tymi linkami znajdują się
                odpowiednie informacje dla najbardziej popularnych przeglądarek:<br></br>
                1) Ustawienia dla przeglądarki Chrome<br></br>
                2) Ustawienia dla przeglądarki Firefox<br></br><br></br>
                12.  Ponieważ niektóre Pliki cookies są niezbędne do funkcjonowania Portalu, zmiana ustawień
                przeglądarki  może  sprawić,  że  niektóre  usługi  nie  będą  działać  prawidłowo,  a  nawet
                całkowicie uniemożliwić korzystanie z Portalu.<br></br><br></br>
              </Box>
              <Box className={classes.PointClassM}>
                IX. Zmiany Polityki Prywatności:
              </Box>
              <Box>
                Polityka jest na bieżąco weryfikowana i w razie potrzeby aktualizowana.
              </Box>
            </span>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Privacy;
