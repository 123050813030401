import classes from './ModalMap.module.css';
import {createPortal} from "react-dom";
import {GeneralButton} from "../../Button/GeneralButton";
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {ThemeProvider} from "@mui/material/styles";
import {Theme} from "../../styles/styles";
import Typography from "@mui/material/Typography";

const Backdrop = props => {
    return <div className={classes.backdrop} onClick={props.onClose}/>
};
const ModalOverlay = props => {
    return (

        <div className={classes.modal}>
        <div className={classes.content}>{props.children}</div>
        <div className={classes.buttons}>
            <Box sx={{mr: '16px'}}>
            <GeneralButton text="Zamknij" onClick={props.onClose} />
        </Box>
            {/*<Box sx={{backgroundColor:"#2bfece", borderRadius: '16px'}}>*/}
            {/*<GeneralButton text="MAPA DOBRA" border='0px' />*/}
            {/*</Box>*/}
        </div>
    </div>

    );
};
const portalElement = document.getElementById('overlays');
portalElement.className = classes.overlays;

const ModalMap = props => {
return ( <>
    {createPortal(<Backdrop onClose={props.onClose}/>,portalElement)}
    {createPortal(<ModalOverlay onClose={props.onClose}>{props.children}</ModalOverlay>,portalElement)}
   </>);

};

export default ModalMap;