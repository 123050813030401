import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { HorizontalMenu } from "../Atoms/HorizontalMenu";
import { CompanyProfileCard } from "../CompanyProfileCard/CompanyProfileCard";
import { FileDownloadBox } from "../Atoms/FileDownloadBox";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import placeholder from "../../images/zdjecie.png";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import { useEffect, useState } from "react";
import { getUrl } from "../../requests";
import { Modal } from "@mui/material";
import { InputListCard } from "../InputListCard/InputListCard";
import { SwitchListCard } from "../InputListCard/SwitchListCard";
import { withEditableServices } from "./withEditableServices";
import { withEditableClientTypes } from "./withEditableClientTypes";
import { withEditableCompanyInfo } from "./withEditableCompanyInfo";
import { withEditableInfo } from "./withEditableInfo";
import { withEditableMeetCompany } from "./withEditableMeetCompany";
import useHttp from "../CustomHooks/use-http";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";


const CompanyProfileCardsList = ({
  profilerefs,
  downloadBox,

  auth,

  setopenInprogress,

}) => {
  //const { sendRequest: fetchData } = useHttp();
  const [data, setData] = useState([]);
  //const [workingHours, setWorkingHours] = useState([]);
  const [telephones, setTelephones] = useState([
    {
      label: "Główny",
      value: "",
      name: "phone"
    },
    {
      label: "Ogólny",
      value: "",
      name: "phonePayments",
    },
    {
      label: "Serwisowy",
      value: data?.phoneService,
      name: "phoneService",
    }

  ]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [companyDiffInfo, setcompanyDiffInfo] = useState([]);

  const [bok, setBok] = useState([]);
  const [bokInfo, setBokInfo] = useState([]);
  const [bokSvcCust, setBokSvcCust] = useState([]);

  const [fiberInternetServicesList, setFiberInternetServicesList] = useState();
  const [wirelessInternetServicesList, setWirelessInternetServicesList] =
    useState();
  const [mobileInternetServicesList, setMobileInternetServicesList] =
    useState();

  const [tvServicesList, setTvServicesList] = useState();
  const [tvPacketsServicesList, setTvPacketsServicesList] = useState();

  const [telephonyServicesList, setTelephonyServicesList] = useState();
  const [otherServicesList, setOtherServicesList] = useState();
  const [mobileTelephonyServicesList, setMobileTelephonyServicesList] =
    useState();

  const [editServices, setEditServices] = useState(false);
  const [editClientTypes, setEditClientTypes] = useState(false);
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [editCompanyGeneralInfo, setEditCompanyGeneralInfo] = useState(false);
  const [editVariousInfo, setEditVariousInfo] = useState(false);
  const [workingHoursState, setWorkingHoursState] = useState([]);
  const [editRangesNew, setEditRangesNew] = useState(false);
  const [editMeetCompany, setEditMeetCompany] = useState(false);

  const handleOpenEditMeetCompany = () => setEditMeetCompany(true)
  const handleCloseEditMeetCompany = () => setEditMeetCompany(false)

  const handleOpenEditServices = () => setEditServices(true);
  const handleCloseEditServices = () => setEditServices(false);
  const handleCloseClientTypes = () => setEditClientTypes(false);
  const handleCloseEditCompanyInfo = () => setEditCompanyInfo(false);
  const handleCloseEditCompanyGeneralInfo = () =>
    setEditCompanyGeneralInfo(false);
  const handleCloseEditVariousInfo = () => setEditVariousInfo(false);
  const handleCloseEditRangesNew = () => setEditRangesNew(false);


  const { logout } = useAuth0();

  const setProfileVariables = (data) => {
    setData(data);
    setFiberInternetServicesList(
      data.products.filter(
        (product) => product.productCategory === "Internet światłowodowy"
      )
    );
    setMobileInternetServicesList(
      data.products.filter(
        (product) => product.productCategory === "Internet mobilny"
      )
    );
    setWirelessInternetServicesList(
      data.products.filter(
        (product) => product.productCategory === "Internet radiowy"
      )
    );
    setTvServicesList(
      data.products.filter(
        (product) => product.productCategory === "Telewizja"
      )
    );
    setTelephonyServicesList(
      data.products.filter(
        (product) => product.productCategory === "Telefonia"
      )
    );
    setOtherServicesList(
      data.products.filter((product) => product.productCategory === "Inne")
    );
    setMobileTelephonyServicesList(
      data.products.filter(
        (product) => product.productCategory === "Telefonia mobilna")
    );

    setTvPacketsServicesList(
      data.products.filter((product) => product.productCategory === "Pakiety TV")
    );



    setWorkingHoursState(
      data?.workingHours.map((el) =>
        el.weekDay === "Monday"
          ? {
            label: "Poniedziałek",
            value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
          }
          : el.weekDay === "Tuesday"
            ? {
              label: "Wtorek",
              value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
            }
            : el.weekDay === "Wednesday"
              ? {
                label: "Środa",
                value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
              }
              : el.weekDay === "Thursday"
                ? {
                  label: "Czwartek",
                  value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
                }
                : el.weekDay === "Friday"
                  ? {
                    label: "Piątek",
                    value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
                  }
                  : el.weekDay === "Saturday"
                    ? {
                      label: "Sobota",
                      value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
                    }
                    : {
                      label: "Niedziela",
                      value: el.open ? `${el.hourFrom}-${el.hourTo}` : "Nieczynne",
                    }
      )
    );


    const _telephonesArray = [
      data.phone ? {
        label: "Główny",
        value: data?.phone,
        name: "phone"
      } : {
        label: "Główny",
        value: "",
        name: "phone"
      },
      data.phonePayments ? {
        label: "Ogólny",
        value: data?.phonePayments,
        name: "phonePayments",
      } : {
        label: "Ogólny",
        value: "",
        name: "phonePayments",
      },
      data.phoneService ? {
        label: "Serwisowy",
        value: data?.phoneService,
        name: "phoneService",
      } : {
        label: "Serwisowy",
        value: data?.phoneService,
        name: "phoneService",
      },

    ];
    setTelephones(_telephonesArray);


    let _companyArray = [];

    _companyArray.push({
      label: `Rok założenia firmy`,
      value: !data.yearOfEstablishment
        ? ""
        : `${data?.yearOfEstablishment}`,
      name: "yearOfEstablishment",
    });

    _companyArray.push({
      label: `Forma prawna`,
      value: !data.legalForm ? "" : `${data?.legalForm}`,
      name: "legalForm",
    });

    _companyArray.push({
      label: `Opis firmy`,
      value: !data.information ? "" : `${data?.information}`,
      name: "information",
    });

    _companyArray.push({
      label: `Opis firmy`,
      value: !data.companyDifferentiators
        ? ""
        : `${data?.companyDifferentiators}`,
      name: "companyDifferentiators",
    });

    setCompanyInfo([..._companyArray]);

    let _companyDiffArray = [];

    if (
      data?.companyDifferentiatorsMap &&
      !!Object.keys(data?.companyDifferentiatorsMap).length
    ) {
      let index = 1;
      for (const [key, value] of Object.entries(
        data?.companyDifferentiatorsMap
      )) {
        _companyDiffArray.push({
          label: `Cecha ${index++}`,
          value: `${value}`,
          name: `${key}`,
        });
      }
      setcompanyDiffInfo(_companyDiffArray);
    }

    let _bokArray = [];

    if (
      data?.bokZipcode ||
      data?.bokCity ||
      data?.bokStreet ||
      data?.bokHouseFlatNumber
    ) {
      _bokArray.push({
        label: `Adres BOK`,
        value: `${data?.bokZipcode ? data?.bokZipcode : " "} ${data?.bokCity ? data?.bokCity : " "
          } ${data?.bokStreet ? data?.bokStreet : " "} ${data?.bokHouseFlatNumber ? "nr lokalu:" : " "
          } ${data?.bokHouseFlatNumber ? data?.bokHouseFlatNumber : " "}`,
      });
    }
    setBok(_bokArray);

    let _bokInfoArray = [];
    if (data?.bokFloor) {
      _bokArray.push({
        label: `Gdzie się znajduje`,
        value: `${data?.bokFloor}`,
      });
    }
    setBokInfo(_bokInfoArray);

    let _bokCustomerServicesArray = [];
    if (data?.waitingTimeDuringBusinessHours) {
      _bokCustomerServicesArray.push({
        label: `Szacowany czas reakcji na telefon w godzinach pracy`,
        value: `${data?.waitingTimeDuringBusinessHours}`,
      });
    }
    if (data?.waitingTimeOverBusinessHours) {
      _bokCustomerServicesArray.push({
        label: `Szacowany czas reakcji na telefon poza godzinami pracy`,
        value: `${data?.waitingTimeOverBusinessHours}`,
      });
    }

    if (data?.waitingTimeChangeService) {
      _bokCustomerServicesArray.push({
        label: `Szacowany czas oczekiwania na realizację podłączenia`,
        value: `${data?.waitingTimeChangeService}`,
      });
    }

    if (data?.waitingTimeFixService) {
      _bokCustomerServicesArray.push({
        label: `Szacowany czas oczekiwania usunięcia usterki`,
        value: `${data?.waitingTimeFixService}`,
      });
    }
    setBokSvcCust(_bokCustomerServicesArray);

  }

  const fetchProfilesData = () => {
    if (auth.epid) {
      axios
        // .get(`${getUrl()}/api/v1/profile/seoLink/${auth.seoLink}`, {
        .get(`${getUrl()}/api/v1/profile/epid/${auth.epid}`, {
          headers: { Authorization: `Bearer ${auth.user}` },
        })
        .then((res) => {
          const data = res.data;
          setProfileVariables(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401 || err.response.status === 500 || err.response.status === 403) {
            alert('Twoja sesja wygasła, za chwile zostaniesz wylogowany z systemu i przekierowany na stronę główną. Aby ponownie skorzystać z systemu musisz się ponownie zalogować ');
            logout({
              logoutParams: { returnTo: `${window.location.origin}` },
            });
          }
        });
    }
  };

  useEffect(() => {
    fetchProfilesData();
    //readProfile();
  }, [auth.epid]);

  /** 
  useEffect(() => {
    data.id && refreshProfile();
  }, [data]);
*/
  const RangesWithEditableLoader = withEditableCompanyInfo(
    InputListCard,
    "Edytuj zasięgi (tylko liczby)",
    null,
    data.id,
    [
      {
        header: "Szacowana Liczba Mieszkań",
        list: [
          {
            label: "Liczba mieszkań",
            value: data?.totalHp,
            name: "totalHp",
            type: "number",
          },
          {
            label: "Wszyscy abonenci",
            value: data?.totalCustomers,
            name: "totalCustomers",
            type: "number",
          },
          {
            label: "Światłowód",
            value: data?.totalCustomersFiber,
            name: "totalCustomersFiber",
            type: "number",
          },
          {
            label: "Ethernet",
            value: data?.totalCustomersEthernet,
            name: "totalCustomersEthernet",
            type: "number",
          },
          {
            label: "Wifi/radio",
            value: data.totalCustomersWifi,
            name: "totalCustomersWifi",
            type: "number",
          },
          {
            label: "Inni w zasięgu",
            value: data.totalCustomersOther,
            name: "totalCustomersOther",
            type: "number",
          },
        ],
      },
    ],
    data.companyDifferentiatorsMap,
    null,
    `${getUrl()}${data?.photo}` ? `${getUrl()}${data?.photo}` : placeholder,
    `${auth.user}`,
    `${getUrl()}/api/v1/profile`,
    auth.epid,
    handleCloseEditRangesNew,
    //readProfile,
    fetchProfilesData,
    setopenInprogress
  );

  const ServicesWithEditableLoader = withEditableServices(
    SwitchListCard,
    data.id,
    {
      "Internet światłowodowy": data.fiberInternetServices
        ? data.fiberInternetServices
        : false,

      "Internet radiowy": data.wirelessInternet ? data.wirelessInternet : false,

      "Internet mobilny": data.wiredInternetServices
        ? data.wiredInternetServices
        : false,

      "Pakiety TV": data.tvPackagesFlag
        ? data.tvPackagesFlag
        : false,

      Telewizja: data.tvServices ? data.tvServices : false,

      Telefonia: data.telephonyServices ? data.telephonyServices : false,

      "Telefonia mobilna": data.mobileServicesFlag ? data.mobileServicesFlag : false,

      Inne: data.servicesOther ? data.servicesOther : false,
    },
    //data.otherServicesList,
    otherServicesList,
    //data.wiredInternetServicesList,
    mobileInternetServicesList,
    //data.fiberInternetServicesList,
    fiberInternetServicesList,
    //data.wirelessInternetServicesList,
    wirelessInternetServicesList,
    //data.tvServicesList,
    tvServicesList,
    //data.telephonyServicesList,
    telephonyServicesList,
    mobileTelephonyServicesList,
    tvPacketsServicesList,
    [...data.metadata ? data?.metadata.filter(el => el.category === "Usługi").map(el => ({
      koalaTel: el.booleanValue1, kameleonTV: el.booleanValue2, rowId: el.rowId
    })) : ""],
    `${auth.user}`,
    //`${getUrl()}/api/v1/profile`,
    `${getUrl()}/api/v1`,
    auth.epid,
    handleCloseEditServices,
    //readProfile
    fetchProfilesData,
  );

  const ClientTypesWithEditableLoader = withEditableClientTypes(
    SwitchListCard,
    data.id,
    [
      {
        "Klient indywidualny": !data.b2c ? "" : data.b2c,
      },
      {
        "Klient biznesowy": !data.b2b ? "" : data.b2b,
      },
      {
        "Instytucje rządowe": !data.institutions ? "" : data.institutions,
      },
    ],
    `${auth.user}`,
    `${getUrl()}/api/v1/profile`,
    auth.epid,
    handleCloseClientTypes,
    //readProfile
    fetchProfilesData
  );

  const MeetCompanyWithEditableLoader = withEditableMeetCompany(
    "Edytuj poznaj firmę oraz ciekawostki",
    data.id,
    `${auth.user}`,
    `${getUrl()}/api/v1`,
    auth.epid,
    [...data.metadata ? data?.metadata.filter(el => el.category === "Ciekawostki").map(el => ({ header: el.key, text: el.stringValue1, rowId: el.rowId })) : ""],
    [...data.metadata ? data?.metadata.filter(el => el.category === "Poznaj firme").map(el => ({ header: el.key, text: el.stringValue1, rowId: el.rowId })) : ""],
    [{
      header: "SOCIAL MEDIA",
      list: [
        {
          label: "Facebook",
          value: data?.socialMediaLinks ? `${data?.socialMediaLinks}` : "",
          name: "socialMediaLinks",
        },
        { label: `www`, value: data?.website ? `${data?.website}` : "", name: "website" },
        {
          label: `Email`,
          value: data?.contactEmail ? `${data?.contactEmail}` : "",
          name: "contactEmail",
        },
      ],
    },],
    [...data.metadata ? data?.metadata.filter(el => el.category === "Kanały socialmedia").map(el => ({ header: el.key, text: el.stringValue1, rowId: el.rowId })) : ""],
    [...data.metadata ? data?.metadata.filter(el => el.category === "Adresy biur").map(el => ({ header: el.key, text: el.stringValue1, rowId: el.rowId })) : ""],
    [...data.metadata ? data?.metadata.filter(el => el.category === "Artykuły SEO").map(el => ({ header: el.key, text: el.stringValue1, rowId: el.rowId })) : ""],
    handleCloseEditMeetCompany,
    fetchProfilesData,
    setopenInprogress,
  );

  const CompanyInfoWithEditableLoader = withEditableCompanyInfo(
    InputListCard,
    "Edytuj o firmie",
    "Zdjęcia firmy/pracownikow",
    data.id,
    [
      {
        header: null,
        list: [...companyInfo],
      }

      /** 
      {
        header: "Wyróżniki firmy (cechy)",
        list: [...companyDiffInfo],
      },
*/
    ],
    data.companyDifferentiatorsMap,
    null,
    `${getUrl()}${data?.photo}` ? `${getUrl()}${data?.photo}` : placeholder,
    `${auth.user}`,
    `${getUrl()}/api/v1/profile`,
    auth.epid,
    //data.metadata? data?.metadata.filter(el => el.category==="Ciekawostki") : "",
    //data.metadata? data?.metadata.filter(el => el.category==="Poznaj firme") : "",
    handleCloseEditCompanyInfo,
    //readProfile,
    fetchProfilesData,
    setopenInprogress
  );

  const CompanyGeneralInfoWithEditableLoader = withEditableCompanyInfo(
    InputListCard,
    "Edytuj podstawowe dane o firmie",
    "Logo",
    data.id,
    [
      {
        list: [
          { label: "Numer EPID", value: `${auth.epid}`, disabled: true },
          {
            label: `Nazwa Firmy`,
            value: data.companyName ? `${data.companyName}` : "",
            disabled: true,
          },
          {
            label: `Nazwa SEO`,
            value: data.seoLink ? `${data.seoLink}` : "",
            disabled: true,
          },
        ],
      },
      {
        header: "Adres",
        list: [
          {
            label: "Miasto",
            value: data?.companyAddressCity,
            name: "companyAddressCity",
          },
          {
            label: "Ulica",
            value: data?.companyAddressStreet,
            name: "companyAddressStreet",
          },
          {
            label: "Numer lokalu",
            value: data?.companyAddressBuildNumber,
            name: "companyAddressBuildNumber",
            type: "number",
          },
        ],
      },
      {
        header: "NUMERY TEL.",
        list: telephones,
      },
      /** 
      {
        header: "SOCIAL MEDIA",
        list: [
          {
            label: "Facebook",
            value: data?.socialMediaLinks ? `${data?.socialMediaLinks}` : "",
            name: "socialMediaLinks",
          },
          { label: `www`, value: data?.website ? `${data?.website}` : "", name: "website" },
          {
            label: `Email`,
            value: data?.contactEmail ? `${data?.contactEmail}` : "",
            name: "contactEmail",
          },
        ],
      },
      */
    ],
    null,
    data.workingHours,
    `${getUrl()}${data?.companyLogoUrl}`,
    `${auth.user}`,
    `${getUrl()}/api/v1/profile`,
    auth.epid,
    handleCloseEditCompanyGeneralInfo,
    //readProfile,
    fetchProfilesData,
    setopenInprogress
  );

  const VariousInfoWithEditableLoader = withEditableInfo(
    InputListCard,
    "Edytuj informacje",
    null,
    data.id,
    [
      {
        header: "Adres BOK",
        list: [
          {
            label: "Miasto",
            // value: data?.companyAddressCity,
            value: data?.bokCity ? data?.bokCity : " ",
            // name: "companyAddressCity",
            name: "bokCity",
          },
          {
            label: "Ulica",
            // value: data?.companyAddressStreet,
            value: data?.bokStreet,
            // name: "companyAddressStreet",
            name: "bokStreet",
          },
          {
            label: "Numer lokalu",
            // value: data?.companyAddressBuildNumber,
            value: data?.bokHouseFlatNumber,
            name: "bokHouseFlatNumber",
          },
        ],
      },
    ],
    null,
    null, //data.workingHours,
    [
      {
        header: "Informacje o BOK/eBOK",
        list: [
          {
            label: "Gdzie się znajduje",
            value: data?.bokFloor,
            name: "bokFloor",
          },
          {
            label: "Co można załatwić",
            value: data.ebokServicesList && data.ebokServicesList[0],
            name: "ebokServicesList",
          },
        ],
      },
    ],

    {
      "Czy jest miejsce na rower": data.placeForBike
        ? data.placeForBike
        : false,
      "Czy jest miejsce na pieska": data.placeForDog ? data.placeForDog : false,
      "Czy jest poczekalnia": data.waitingRoom ? data.waitingRoom : false,
      "Czy jest klimatyzacja": data.airConditioning
        ? data.airConditioning
        : false,
      "Czy oferowane są napoje": data.drinks ? data.drinks : false,
      "Czy jest obsługa w języku obcym": data.foreignLanguage
        ? data.foreignLanguage
        : false,
    },

    [
      {
        list: [
          {
            label: "Odległość od komunikacji miejsckiej",
            value: data.distanceToBus ? data.distanceToBus : "np. 1 km",
            name: "distanceToBus",
          },
        ],
      },
    ],

    {
      "Czy jest możliwy kontakt telefoniczny po godzinach pracy biura":
        data.supportAfterHours ? data.supportAfterHours : false,
      "Czy można zgłaszać usterki": data.supportAvailable
        ? data.supportAvailable
        : false,
      "Czy można zamówić usługi": data.ordersAvailable
        ? data.ordersAvailable
        : false,
    },
    [
      {
        header: "Obsługa klienta",
        list: [
          {
            label: "Szacowany czas na odebranie telefonu w godzinach biura",
            value: data.waitingTimeDuringBusinessHours
              ? data.waitingTimeDuringBusinessHours
              : "np. 3 min",
            name: "waitingTimeDuringBusinessHours",
          },
          {
            label:
              "Szacowany czas na odebranie telefonu poza godzinami pracy biura",
            value: data.waitingTimeOverBusinessHours
              ? data.waitingTimeOverBusinessHours
              : "np. 3 min",
            name: "waitingTimeOverBusinessHours",
          },
          {
            label:
              "Szacowany czas oczekiwania na realizację podłączenia usługi",
            value: data.waitingTimeInstallationService
              ? data.waitingTimeInstallationService
              : "np. 3 min",
            name: "waitingTimeInstallationService",
          },
          {
            label: "Szacowany czas oczekiwania na realizację usług dodatkowych",
            value: data.waitingTimeChangeService
              ? data.waitingTimeChangeService
              : "np. 3 min",
            name: "waitingTimeChangeService",
          },
          {
            label: "Szacowany czas oczekiwania usunięcie usterki",
            value: data.waitingTimeFixService
              ? data.waitingTimeFixService
              : "np. 3 min",
            name: "waitingTimeFixService",
          },
        ],
      },
    ],
    null,
    `${auth.user}`,
    `${getUrl()}/api/v1/profile`,
    auth.epid,
    handleCloseEditVariousInfo,
    //readProfile,
    fetchProfilesData,
    setopenInprogress
  );

  return (
    <>
      <Box
        alignItems="flex-end"
        sx={{
          mb: "13px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HorizontalMenu
          menuList={[
            "Zasięgi",
            "Podstawowe dane",
            "Usługi",
            //        "Odbiorcy",
            "O firmie",
            "Informacje",
          ]}
          selectedHandler={(index) => {
            if (profilerefs && profilerefs[index]?.current) {
              profilerefs[index].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
        />
      </Box>
      <Box
        id="list-container-test"
        sx={{
          bgcolor: "#fafafc",
          dislplay: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "auto",
          height: "95vh",
          mt: "32px",
        }}
      >
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[0]}
            cardName="Zasięgi"
            fileDownloadBox={
              <FileDownloadBox
                clickHandler={downloadBox}
                auth={auth}
                fileName="Plik_z_wgranymi_zasiegami.csv"
                fileUrl="http://urldopliku.pl"
                wiredInternetServices
                header="Wgrane Zasięgi"
              />
            }
            fileDownloadBoxHeader="Wgrane zasięgi"
            infoList={[
              {
                header: "Szacowana liczba mieszkań",

                list: [
                  { label: "liczba mieszkań", value: data?.totalHp },
                  { label: "Wszyscy abonenci", value: data?.totalCustomers },
                  {
                    label: `światłowód`,
                    value: `${data?.totalCustomersFiber}`,
                  },
                  {
                    label: `Ethernet`,
                    value: `${data?.totalCustomersEthernet}`,
                  },
                  { label: `Wifi/radio`, value: `${data?.totalCustomersWifi}` },
                  {
                    label: `Inni w zasięgu`,
                    value: `${data?.totalCustomersOther}`,
                  },
                ],
              },
            ]}
            buttonHandler={() => {
              setEditRangesNew(true);
            }}
          >
            <ConnectWithoutContactIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[1]}
            cardName="Podstawowe dane"
            buttonHandler={() => {
              setEditCompanyGeneralInfo(true);
            }}
            infoList={[
              {
                header: "LOGO",
                imageUrl: `${getUrl()}${data?.companyLogoUrl}`,
                list: [
                  { label: "Numer EPID", value: `${auth.epid}` },
                  { label: `Nazwa Firmy`, value: data?.companyName },
                  { label: `Nazwa SEO`, value: data?.seoLink },
                  {
                    label: `Adres`,
                    value: `
               ${data?.bokCity
                        ? data?.bokCity
                        : data?.companyAddressCity
                          ? data?.companyAddressCity
                          : " "
                      }
               ${data?.bokStreet
                        ? data?.bokStreet
                        : data?.companyAddressStreet
                          ? data?.companyAddressStreet
                          : " "
                      } 
               ${data?.bokHouseFlatNumber
                        ? data?.bokHouseFlatNumber
                        : data?.companyAddressBuildNumber
                          ? data?.companyAddressBuildNumber
                          : " "
                      }`,
                  },
                ],
              },
              {
                header: "NUMERY TEL.",
                list: telephones,
              },
              
              {
                header: "Godziny otwarcia",
                list: [...workingHoursState],
              },
            ]}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[2]}
            cardName="Usługi"
            buttonHandler={handleOpenEditServices}
            infoList={[
              {
                header: "Rodzaj świadczonych usług",
                list: [
                  {
                    label: `Internet światłodowy`,
                    value: `${data?.fiberInternetServices ? "TAK" : "NIE"}`,
                  },
                  {
                    label: `Internet radiowy`,
                    value: `${data?.wirelessInternet ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Internet mobilny",
                    value: `${data?.wiredInternetServices ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Telewizja",
                    value: `${data?.tvServices ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Paakiety TV",
                    value: `${data?.tvPackagesFlag ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Telef domowy",
                    value: `${data?.telephonyServices ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Telef komórkowy",
                    value: `${data?.mobileServicesFlag ? "TAK" : "NIE"}`,
                  },
                  {
                    label: "Inne",
                    value: `${data?.otherServicesList?.length > 0 ? "TAK" : "NIE"
                      }`,
                  },
                ],
              },
            ]}
          >
            <HomeRepairServiceOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>
        {/** 
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[3]}
            setopenInprogress
            cardName="Odbiorcy"
            icon={AccountBoxOutlinedIcon}
            buttonHandler={() => {
              setEditClientTypes(true);
            }}
            infoList={[
              {
                header: "Rodzaj obsługiwanych odbiorców",

                list: [
                  {
                    label: "Klient indywidualny",
                    value: `${data?.b2c ? "TAK" : "NIE"}`,
                  },
                  {
                    label: `Klient biznesowy`,
                    value: `${data?.b2b ? "TAK" : "NIE"}`,
                  },
                  {
                    label: `Instytucje rządowe`,
                    value: `${data?.institutions ? "TAK" : "NIE"}`,
                  },
                ],
              },
            ]}
          >
            <AccountBoxOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>
*/}
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[4]}
            cardName="O firmie"
            buttonHandler={() => {
              setEditCompanyInfo(true);
            }}
            infoList={[
              {
                header: "Zdjęcie wyróznik firmy",
                imageUrl: `${getUrl()}${data?.photo}`
                  ? `${getUrl()}${data?.photo}`
                  : placeholder,
                list: [...companyInfo],
              },
              /** 
              {
                header: "Wyróżniki firmy (cechy)",
                list: [...companyDiffInfo],
              },
*/
            ]}
          >
            <ApartmentOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>



        </Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[4]}
            cardName="Poznaj firmę oraz ciekawostki"
            buttonHandler={() => {
              setEditMeetCompany(true);
            }}
            infoList={[
              {
                header: "SOCIAL MEDIA",
                list: [
                  {
                    label: "Facebook",
                    value: data?.socialTiktok
                      ? data?.socialTiktok
                      : data?.socialMediaLinks
                        ? data?.socialMediaLinks
                        : " "
                    ,
                  },
                  { label: `www`, value: data?.website },
                  {
                    label: `Email`,
                    value: `${data?.emailCustomerCare
                      ? data?.emailCustomerCare
                      : data?.contactEmail
                        ? data?.contactEmail
                        : data?.emailMarketing
                          ? data?.emailMarketing
                          : " "
                      }${data?.emailMarketing ? ", " : ""}${data?.emailMarketing ? data?.emailMarketing : " "
                      }`,
                  },
                ],
              },
              {
                header: "Poznaj firmę od podszewki",

                list: [...data.metadata ? data?.metadata.filter(el => el.category === "Poznaj firme").map(el => ({ label: el.key, key: el.stringValue1 })) : ""]
              },
              {
                header: "Ciekawostki",

                list: [...data.metadata ? data?.metadata.filter(el => el.category === "Ciekawostki").map(el => ({ label: el.key, key: el.stringValue1 })) : ""]
              },

            ]}
          >
            <ApartmentOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>

        {/** 
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompanyProfileCard
            ref={profilerefs[5]}
            cardName="Informacje"
            buttonHandler={() => {
              setEditVariousInfo(true);
            }}
            infoList={[
              {
                header: "Informacje o BOK",
                list: [
                  {
                    label: "Miasto",
                    // value: data?.companyAddressCity,
                    value: data?.bokCity ? data?.bokCity : " ",
                    // name: "companyAddressCity",
                    name: "bokCity",
                  },
                  {
                    label: "Ulica",
                    // value: data?.companyAddressStreet,
                    value: data?.bokStreet,
                    // name: "companyAddressStreet",
                    name: "bokStreet",
                  },
                  {
                    label: "Numer lokalu",
                    // value: data?.companyAddressBuildNumber,
                    value: data?.bokHouseFlatNumber,
                    name: "bokHouseFlatNumber",
                  },
                  {
                    label: "Gdzie się znajduje",
                    value: data?.bokFloor,
                    name: "bokFloor",
                  },
                  {
                    label: "Co można załatwić",
                    value: data?.ebokServicesList && data?.ebokServicesList[0],
                    name: "ebokServicesList",
                  },
                ],
              },

              {
                header: "Obsługa klienta",
                list: [
                  {
                    label:
                      "Szacowany czas na odebranie telefonu w godzinach biura",
                    value: data?.waitingTimeDuringBusinessHours
                      ? data?.waitingTimeDuringBusinessHours
                      : "np. 3 min",
                    name: "waitingTimeDuringBusinessHours",
                  },
                  {
                    label:
                      "Szacowany czas na odebranie telefonu poza godzinami pracy biura",
                    value: data?.waitingTimeOverBusinessHours
                      ? data?.waitingTimeOverBusinessHours
                      : "np. 3 min",
                    name: "waitingTimeOverBusinessHours",
                  },
                  {
                    label:
                      "Szacowany czas oczekiwania na realizację podłączenia usługi",
                    value: data?.waitingTimeInstallationService
                      ? data?.waitingTimeInstallationService
                      : "np. 3 min",
                    name: "waitingTimeInstallationService",
                  },
                  {
                    label:
                      "Szacowany czas oczekiwania na realizację usług dodatkowych",
                    value: data?.waitingTimeChangeService
                      ? data?.waitingTimeChangeService
                      : "np. 3 min",
                    name: "waitingTimeChangeService",
                  },
                  {
                    label: "Szacowany czas oczekiwania usunięcie usterki",
                    value: data?.waitingTimeFixService
                      ? data?.waitingTimeFixService
                      : "np. 3 min",
                    name: "waitingTimeFixService",
                  },
                  {
                    label: "Czy jest miejsce na rower",
                    value: data?.placeForBike ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy jest miejsce na pieska",
                    value: data?.placeForDog ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy jest poczekalnia",
                    value: data?.waitingRoom ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy jest klimatyzacja",
                    value: data?.airConditioning ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy oferowane są napoje",
                    value: data?.drinks ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy jest obsługa w języku obcym",
                    value: data?.foreignLanguage ? "TAK" : "NIE",
                  },
                ],
              },
              {
                header: "Odległości",
                list: [
                  {
                    label: "Odległość od komunikacji miejsckiej",
                    value: data?.distanceToBus
                      ? data?.distanceToBus
                      : "np. 1 km",
                  },
                ],
              },
              {
                header: "Informacje dot. zgłoszeń",
                list: [
                  {
                    label:
                      "Czy jest możliwy kontakt telefoniczny po godzinach pracy biura",
                    value: data?.supportAfterHours ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy można zgłaszać usterki",
                    value: data?.supportAvailable ? "TAK" : "NIE",
                  },
                  {
                    label: "Czy można zamówić usługi",
                    value: data?.ordersAvailable ? "TAK" : "NIE",
                  },
                ],
              },
            ]}
          >
            <InfoOutlinedIcon
              sx={{
                color: "#fff",
              }}
            />
          </CompanyProfileCard>
        </Box>
        */}

        <Modal
          open={editServices}
          onClose={handleCloseEditServices}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <ServicesWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editClientTypes}
          onClose={handleCloseClientTypes}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <ClientTypesWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editCompanyInfo}
          onClose={handleCloseEditCompanyInfo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <CompanyInfoWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editCompanyGeneralInfo}
          onClose={handleCloseEditCompanyGeneralInfo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <CompanyGeneralInfoWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editVariousInfo}
          onClose={handleCloseEditVariousInfo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <VariousInfoWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editRangesNew}
          onClose={handleCloseEditRangesNew}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <RangesWithEditableLoader />
          </Box>
        </Modal>

        <Modal
          open={editMeetCompany}
          onClose={handleCloseEditMeetCompany}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          >
            <MeetCompanyWithEditableLoader />
          </Box>
        </Modal>

      </Box>
    </>
  );
};
export default CompanyProfileCardsList;
