import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Divider from "@mui/material/Divider";
import useOnClickOutside from "../CustomHooks/useOnClickOutside";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";

export const IconMenu = ({
  list = ["Edycja dobra", "Historia", "Podgląd dobra", "Usuń / Odrzuć dobro"],
  optionSelected,
  onClickHistory,
  onClickPreview,
  onClickDelete,
  onClickStatusChange,
  onClieckLeadView,
  onClickNotesView,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const open = Boolean(anchorEl);
  const suggestionRef = React.createRef();
  useOnClickOutside(suggestionRef, () => {
    if (openMenu === true) {
      setOpenMenu(false);
      return;
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (openMenu === false) {
      setOpenMenu(true);
      return;
    }
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    //Tutaj sobie obsłuż option Selected
    if (option === "Edycja dobra") {
      optionSelected();
      return;
    }
    if (option === "Podgląd dobra") {
      onClickPreview();
      return;
    }
    if (option === "Usuń dobro") {
      onClickDelete();
      return;
    }
    if (option === "Zmień status") {
      onClickStatusChange();
      return;
    }
    if (option === "Podgląd leada") {
      onClieckLeadView();
      return;
    }

    if (option === "Notatki") {
      onClickNotesView();
      return;
    }

    onClickHistory(true);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        ref={suggestionRef}
      >
        <Avatar
          sx={{
            bgcolor: "#f29a26",
            width: 44,
            height: 44,
          }}
        >
          <MoreVertIcon
            sx={{
              fontSize: 26,
              color: "#fff",
            }}
          />
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={() => {
          // handleClose
        }}
        onClick={() => {
          // handleClose
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            borderRadius: "14px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {list.map((option, i) => (
          <MenuItem
            key={option}
            onClick={() => {
              handleClose(list[i]);
            }}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  bgcolor: "#f29a26",
                  width:"20px",
                  height:"20px",

                }}
              >
                
                <VisibilityOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "#fff",
                  }}
                />
              </Avatar>
              
            </ListItemIcon>
            <Typography variant="bodyMediumBlack">{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
