import * as React from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Avatar from "@mui/material/Avatar";
import { GeneralButton } from "../Button/GeneralButton";
import FavoriteIcon from "@mui/icons-material/Favorite";

export const MapSlider = ({ displayText, onClick }) => {
  return (
    <Box
    sx={{
      [Theme.breakpoints.down("laptop")]: {
        width: "100vw",
      },

      [Theme.breakpoints.up("laptop")]: {
        width: "32.9vw",
        borderRadius: 5,
      },
      [Theme.breakpoints.up("desktop")]: {
        width: "35.4vw",
        borderRadius: 5,
      },
    }}
    >
      <Box
        onClick={onClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 4,
          boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
          padding: 1,

          [Theme.breakpoints.down("tablet")]: {
            height: 81,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          [Theme.breakpoints.up("tablet")]: {
            height: 81,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#2bfece",
            width: 44,
            height: 44,
            ml: 2,
            mr: 3,
            display: "flex",
          }}
        >
          <MapOutlinedIcon
            sx={{
              fontSize: 26,
              color: "#363636",
            }}
          />
        </Avatar>

        <ThemeProvider theme={Theme}>
          <Typography variant="h4" sx={{ textTransform: "uppercase", ml: -1 }}>
            {displayText}{" "}
          </Typography>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

MapSlider.propTypes = {
  displayText: PropTypes.string,
};

MapSlider.defaultProps = {
  displayText: "Mapa dobra",
};
