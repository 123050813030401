import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { getUrl } from "../../requests";
import { SupplierCard } from "./SupplierCard";
import { useParams } from "react-router";
import { Box, Modal } from "@mui/material";
import { ContactMailForm } from "../Lokalni2/ContactMailForm";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const SupplierPage = () => {

    const mockSeaoArticles = [
        {header:"Article 1", text:"Lorem sed risus ultricies tristique nulla aliquet enim tortor. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Cras fermentum odio eu feugiat pretium nibh ipsum consequat nisl. Aliquet enim tortor at auctor urna. Pharetra diam sit amet nisl suscipit."},
        {header:"Article 2", text:"Text1 sksksks kskskssk sksksksks ksksksks kkowowowowo, sksksow, sksksks"},
        {header:"Article 3", text:"Text1 sksksks kskskssk sksksksks ksksksks kkowowowowo, sksksow, sksksks"}
    ]

    const { seolink } = useParams();
    const [profileData, setProfileData] = useState([]);
    const [isOpenContactMailForm, setIsOpenContactMailForm] = useState(false);

    const handleOpenContactMailForm = () => setIsOpenContactMailForm(true)
    const handleCloseContactMailForm = () => setIsOpenContactMailForm(false)
    const navigate = useNavigate()

    const epid = "DGNET";

    //seoLink = "dg-net-s-a"

    const postEmailForm = (topic, emailText, emailFrom) => {

        const body = {
            topic: topic,
            body: emailText,
            emailFrom: emailFrom
        }

        axios.post(`${getUrl()}/api/v1/profile/${profileData?.id}/message`, body)
            .then(function (response) {
                console.log(response.data);
                handleCloseContactMailForm();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const fetchProfileData = (seoLink) => {
        axios
            .get(`${getUrl()}/api/v1/profile/seoLink/${seoLink}`, {
                headers: {},
            })
            .then((res) => {
                const data = res.data;
                setProfileData(data);
                if (!data.isActive) navigate(`/`);
            })
            .catch((err) => {
                console.log(err);
                navigate("/")

            });
    }
    useEffect(() => {
        fetchProfileData(seolink);

    }, []);
    /** 
        useEffect(() => {
            console.log(internet);
    
        }, [internet]);
        */

    return (
        <>
            <Helmet>
                <link rel="canonical" href={`${getUrl()}/partner/${profileData.companyName}`}/>
            </Helmet>
            
            <Box>
            <h1 style={{ display: 'none' }}>{`Dostawca usług internetowych ${profileData.companyName} ${profileData.companyAddressCity}`} </h1>
                <SupplierCard
                    logoImg={profileData?.companyLogoUrl ? profileData?.companyLogoUrl : ""}
                    companyInfo={profileData?.companyDifferentiators ? profileData?.companyDifferentiators : ""}
                    providerName={profileData?.companyName ? profileData?.companyName : ""}
                    isContactEmail={profileData?.contactEmail ? true : false}
                    address={`${profileData?.bokCity
                        ? profileData?.bokCity
                        : profileData?.companyAddressCity
                            ? profileData?.companyAddressCity
                            : " "
                        }
                ${profileData?.bokStreet
                            ? profileData?.bokStreet
                            : profileData?.companyAddressStreet
                                ? profileData?.companyAddressStreet
                                : " "
                        } 
                ${profileData?.bokHouseFlatNumber
                            ? profileData?.bokHouseFlatNumber
                            : profileData?.companyAddressBuildNumber
                                ? profileData?.companyAddressBuildNumber
                                : " "
                        }`}
                    webAddress={profileData?.website ? profileData?.website : ""}
                    facebookAddress={profileData?.socialMediaLinks ? profileData?.socialMediaLinks : ""}
                    email={profileData?.contactEmail ? profileData?.contactEmail : ""}
                    serviceAddress={[...profileData.metadata ? profileData?.metadata.filter(el => el.category === "Adresy biur").map(el => (`${el.stringValue1}, ${el.key}`)) : ""]}
                    servicePhone={profileData?.phoneService ? profileData?.phoneService : ""}
                    generalPhone={profileData?.phone ? profileData?.phone : ""}
                    workingHours={[...profileData.workingHours ? profileData?.workingHours
                        .map(el => ({
                            weekDays: el.weekDay === "Monday" ? "Poniedziałek"
                                : el.weekDay === "Tuesday" ? "Wtorek"
                                    : el.weekDay === "Wednesday" ? "Środa"
                                        : el.weekDay === "Thursday" ? "Czwartek"
                                            : el.weekDay === "Friday" ? "Piątek"
                                                : el.weekDay === "Saturday" ? "Sobota"
                                                    : "Niedziela",
                            hours: el.open ? `${el.hourFrom}-${el.hourTo}` : "nieczynne"
                        })
                        ) : ""]}

                    otherSocialList={[...profileData.metadata ? profileData?.metadata.filter(el => el.category === "Kanały socialmedia").map(el => (`${el.key}`)) : ""]}

                    infoHeader={"Poznaj firmę od podszewki"}
                    textList={[...profileData.metadata ? profileData?.metadata.filter(el => el.category === "Poznaj firme").map(el => ({ header: el.key, text: el.stringValue1 })) : ""]}

                    triviasHeader={"Ciekawostki"}
                    triviasTextList={[...profileData.metadata ? profileData?.metadata.filter(el => el.category === "Ciekawostki").map(el => ({ header: el.key, text: el.stringValue1 })) : ""]}

                    internetServiceName={"Internet"}
                    internetServices={[
                        {
                            label: "Internet Światłowodowy",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Internet światłowodowy") : ""],
                            other: ""
                        },
                        {
                            label: "Internet radiowy",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Internet radiowy") : ""],
                            other: "",
                        },
                        {
                            label: "Internet mobilny",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Internet mobilny") : ""],
                            other: ""
                        },

                    ]}

                    tvServiceName={"Telewizja"}
                    tvServices={[
                        {
                            label: "Telewizja",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Telewizja") : ""],
                            other: ""
                        },
                        {
                            label: "Pakiety TV",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Pakiety TV") : ""],
                            other: "",
                        },

                    ]}

                    clickButtonHandler={handleOpenContactMailForm}

                    phoneServiceName={"Telefon"}
                    phoneServices={[
                        {
                            label: "Telefon komórkowy",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Telefonia mobilna") : ""],
                            other: ""
                        },
                        {
                            label: "Telefon domowy",
                            info: [...profileData.products ? profileData?.products.filter(el => el.productCategory === "Telefonia") : ""],
                            other: "",
                        },

                    ]}

                    specialProducts={profileData.metadata ? profileData?.metadata.filter(el => el.rowId === 33).map(el => ({
                        koalaTel: el.booleanValue1, kameleonTV: el.booleanValue2
                    })) : ""}

                    //seoArticles={mockSeaoArticles}
                    seoArticles={[...profileData.metadata ? profileData?.metadata.filter(el => el.category === "Artykuły SEO").map(el => ({ header: el.key, text: el.stringValue1 })) : ""]}
                />

                <Modal
                    open={isOpenContactMailForm}
                    onClose={handleCloseContactMailForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                        }}
                    >
                        <ContactMailForm onCancel={handleCloseContactMailForm} onSaveButton={postEmailForm} />
                    </Box>
                </Modal>
            </Box>
        </>
    );
};

