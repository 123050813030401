import React from "react";
import { AutoSuggestOption } from "./AutoSuggestOption.js";
import List from '@mui/material/List';
import { Box } from "@mui/material";

export const AutoSuggestOptions = React.forwardRef(({ id, onClick, options, styles, name, selected }, ref) => {
    return (
        <List>
            {options &&
                options.map((elem,i) => (
                    <AutoSuggestOption
                        key={`aso${i}`}
                        id={`${elem.place_id}`}
                        selected={selected}
                        onClick={()=>{onClick(elem.place_id,elem.description)}}
                        value={elem.description}
                        //styles={styles}
                    />
                ))}
        </List>
    );
});