import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "@fontsource/lato";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SelectListItem = ({
  item,
  availabeHours = [
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
  ],
  changeHandler = null,
}) => {
  const [itemState, setItemState] = useState(item);

  const [checked, setChecked] = React.useState(item.open);
  const [diasbleStatus, setDisableStatus] = React.useState(!item.open);

  const handleChange = (event) => {
    setItemState({
      ...itemState,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
    setItemState({
      ...itemState,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    changeHandler && changeHandler({ workingHours: [itemState] });
  }, [itemState]);

  const selectStyle = {
    width: 153,
    borderRadius: "45px",
    backgroundColor: "#fff",
    boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    ".MuiSelect-iconOutlined": { fontSize: 36, color: "#b2b2b2" },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(228, 219, 233, 0.25)",
    },
    fontFamily: "Lato",
    color: "#b2b2b2",
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "85%",
      }}
    >
      <ThemeProvider theme={Theme}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              name="open"
              onChange={handleChangeChecked}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                "&.Mui-checked": {
                  color: "black",
                },
              }}
            />
          }
          label={<Typography variant="body1">{itemState.weekDay}</Typography>}
          sx={{ mb: "19px" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            width: "100%",
          }}
        >
          <FormControl disabled={!itemState.open}>
            <InputLabel id="demo-simple-select-label">
              <Typography variant="body1" color="#b2b2b2">
                Od
              </Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="hourFrom"
              variant="outlined"
              name="hourFrom"
              value={itemState.hourFrom}
              label={itemState.hourFrom}
              onChange={(event) => handleChange(event)}
              inputProps={{}}
              IconComponent={ExpandMoreIcon}
              sx={selectStyle}
            >
              {availabeHours.map((item, i) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl disabled={!itemState.open}>
            <InputLabel id="demo-simple-select-label">
              <Typography variant="body1" color="#b2b2b2">
                Do
              </Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="hourTo"
              name="hourTo"
              value={itemState.hourTo}
              label={itemState.hourTo}
              onChange={(event) => handleChange(event)}
              inputProps={{}}
              IconComponent={ExpandMoreIcon}
              sx={selectStyle}
            >
              {availabeHours.map((item, i) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
