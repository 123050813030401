import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Button, Avatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

export const BasicInfoBox =
    (
        {
            providerName,
            address,
            clickButtonHandler,
            isContactEmail,
            companyInfo
        },

    ) => {
        return (
            <>
                <Box

                    sx={{
                        display: "flex",
                        //boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
                        borderRadius: "24px",
                        backgroundColor: "#fff",
                        flexDirection: "column",
                        alignItems: "flex-start",

                        [Theme.breakpoints.down("tablet")]: {
                            width: "81vw",
                            p: "19px",
                        },
                        [Theme.breakpoints.up("tablet")]: {
                            width: "89.5vw",
                            p: "19px",
                            [Theme.breakpoints.up("tablet")]: {
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            },
                        },
                        [Theme.breakpoints.up("laptop")]: {
                            width: "87.5vw",
                            p: "24px",
                        },
                        [Theme.breakpoints.up("desktop")]: {
                            width: "76vw",
                            p: "24px",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",

                        }}>
                        <Box
                            id="LogoAndGoofPointsBox"
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <ThemeProvider theme={Theme}>


                                <Typography variant="subtitle"
                                    sx={{
                                        fontSize: 22,
                                        lineHeight: 1.27,
                                        letterSpacing: "0.2px",
                                        [Theme.breakpoints.up("tablet")]: {
                                            fontSize: 24,
                                        },
                                    }}>{providerName}</Typography>

                                <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27, ml: "16px" }}>
                                    <FavoriteIcon
                                        sx={{
                                            fontSize: 14,
                                            color: "#fff",
                                        }}
                                    />
                                </Avatar>

                            </ThemeProvider>

                        </Box>

                        <Box
                            id="FacitilitiesList"
                            sx={{ ml: -2, fontFamily: "Lato", fontSize: 12, mb: 2.5, width:"400px",

                                [Theme.breakpoints.up("laptop")]: {
                                    width:"600px"
                                },
                             }}
                            disablePadding
                        >
                            <List>
                                {/** 
                                <ListItem
                                    key={1}
                                    sx={{
                                        mb: -1,
                                        display: `${companyInfo ? "" : "none"}`,
                                        "&:hover": {
                                            backgroundColor: "transparent", // Remove the background color on hover
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                                            <InfoOutlinedIcon
                                                sx={{
                                                    fontSize: 16,
                                                    color: "#fff",
                                                }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText sx={{ ml: -2.5 }}>
                                        <ThemeProvider theme={Theme}>
                                            <Typography variant="bodySmallRegural">{companyInfo} </Typography>
                                        </ThemeProvider>
                                    </ListItemText>
                                </ListItem>
                                */}
                                <ListItem
                                    key={1}
                                    sx={{
                                        mb: -1,
                                        display: `${address ? "" : "none"}`,
                                        "&:hover": {
                                            backgroundColor: "transparent", // Remove the background color on hover
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                                            <FmdGoodOutlinedIcon
                                                sx={{
                                                    fontSize: 16,
                                                    color: "#fff",
                                                }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText sx={{ ml: -2.5 }}>
                                        <ThemeProvider theme={Theme}>
                                            <Typography variant="bodySmallRegural">{address} </Typography>
                                        </ThemeProvider>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    {isContactEmail &&
                    <Button
                        onClick={() => {clickButtonHandler()}}
                        sx={{
                            ...Theme.OrangeButton,
                            backgroundColor: "#18a75c",
                            border: 0,
                            width: "290px",
                            height: "62px",
                            alignSelf: "center",
                        }}
                    >
                        Formularz kontaktowy
                    </Button>
                    }
                </Box>
            </>
        );
    }

