import { Theme } from "../styles/styles";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";

export const ButtonsFooter = ({
  saveActionHandler,
  cancelActionHandler,
  cancelText = "Anuluj",
  acceptText = "Akceptuj",
  isAnulujVisible = true,
}) => {
  return (
    <Box
      id="ButtonsCancelAccept"
      sx={{
        mt: "25px",
        display: "flex",
        gap: 3
      }}
    >
      {isAnulujVisible &&
        <Button
          onClick={() => {
            cancelActionHandler();
          }}
          variant="outlined"
          sx={{
            ...Theme.outlinedOrangeButton,
            [Theme.breakpoints.down("laptop")]: {
              fontSize: 12,
              width: 90,
            },
          }}
        >
          {cancelText}
        </Button>
      }
      <Button
        onClick={() => {
          saveActionHandler();
        }}
        variant="outlined"
        sx={{
          ...Theme.OrangeButton,
          [Theme.breakpoints.down("laptop")]: {
            fontSize: 12,
            width: 90,
          },
        }}
      >
        {acceptText}
      </Button>
    </Box>
  )
}