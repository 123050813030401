import * as React from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import "@fontsource/lato/900.css";

export const StatusBox = ({ statusMessage, defaultWidth = 130 }) => {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          borderRadius: 8,
          fontFamily: "Lato",
          fontSize: 14,
          fontWeight: 900,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.71,
          letterSpacing: 0.1,
          ...((statusMessage.match(/W trakcie weryf/) ||
            statusMessage.match(/Oczekuje na wer/g) ||
            statusMessage.match(/Oczekuje na aktywac/)) && {
            width: defaultWidth,
            height: 25,
            backgroundColor: "rgba(255, 186, 0, .12)",

            color: "#ffba00",
          }),
          ...(statusMessage.match(/Przyjęte przez oper/g) && {
            width: 160,
            height: 25,
            backgroundColor: "rgba(136, 136, 136, .12)",

            color: "#888888",
          }),
          ...(statusMessage.match(/Przyjęte przeze mnie/g) && {
            width: 160,
            height: 25,
            backgroundColor: "rgba(45, 74, 173, .12)",

            color: "#2d4aad",
          }),
          ...(statusMessage.match(/Oczekuje na oper/g) && {
            width: 160,
            height: 25,
            backgroundColor: "rgba(255, 186, 0, .12)",
            color: "#ffba00",
          }),
          ...((statusMessage.match(/Akceptacja/) ||
            statusMessage.match(/Zrealizow/g) ||
            statusMessage.match(/Aktywowano/)) && {
            width: 100,
            height: 25,
            backgroundColor: "#e5fbf7",
            color: "#1cb5a0",
          }),
          ...((statusMessage === "Odrzucono" ||
            statusMessage === "Odrzuć" ||
            statusMessage === "Usunięto") && {
            // width: 86,
            width: "fit-content",
            height: 25,
            backgroundColor: "rgba(252, 13, 27, .12)",
            color: "#fc0d1b",
          }),
          ...(statusMessage === "Edytowano" && {
            width: 87,
            height: 25,
            backgroundColor: "rgba(24, 152, 199, .12)",
            color: "#1898c7",
          }),
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ ml: 4 }}>{statusMessage}</Box>
      </Box>
    </ThemeProvider>
  );
};
