import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const InputSelectListCardContainer = ({
  cardName,
  inputListCard1,
  inputListCard2,
  inputListCard3,
  inputListCard4,
  inputListCard5,
  inputListCard6,
  inputListCard7,
  buttonsFooter,
}) => {
  return (
    <Box
      id="mainInputListCard"
      sx={{
        width: "40vw",
        maxHeight: "830px",
        borderRadius: "24px",
        p: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "white",
      }}
    >
      <ThemeProvider theme={Theme}>
        <Box id="cardName" sx={{ mb: "25px" }}>
          <Typography variant="h4">{cardName}</Typography>
        </Box>
      </ThemeProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          width: "100%",
        }}
      >
        {inputListCard1}
        {inputListCard2}
        {inputListCard3}
        {inputListCard4}
        {inputListCard5}
        {inputListCard6}
        {inputListCard7}
      </Box>
      <Box alignSelf="flex-end">{buttonsFooter}</Box>
    </Box>
  );
};
