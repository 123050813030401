import * as React from "react";
import Box from "@mui/material/Box";
import { Theme } from "../styles/styles";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

export const LimitedSearchResultCard = React.forwardRef(
  (
    {
      
      providerName,
      address,
    },
    ref
  ) => {
    return (
      <>
        <Box
          ref={ref}
          sx={{
            display: "flex",
            boxShadow: "0 0 32px 0 rgba(0, 0, 0, 0.08)",
            borderRadius: 4,
            backgroundColor:"#fff",
            flexDirection: "column",
            alignItems: "flex-start",
            p:"32px",
            [Theme.breakpoints.down("tablet")]: {
              width: "90.8vw",
              paddingBottom: 1,
            },
            [Theme.breakpoints.up("tablet")]: {
              width: "90vw",
            },
            [Theme.breakpoints.up("laptop")]: {
              width: "50.5vw",
            },
            [Theme.breakpoints.up("desktop")]: {
              width: "53.5vw",
            },
          }}
        >
          <Box
            id="LogoAndGoofPointsBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ThemeProvider theme={Theme}>
              <Avatar variant="rounded" 
              sx={{
                width:"64px",
                height:"63px",
                bgcolor:"#f2f2f2",
                borderRadius:"12px"
              }}
              >
                <NoPhotographyOutlinedIcon
                  sx={{
                    fontSize: "27px",
                    color:"#363636"
                   
                  }}
                />
              </Avatar>
              <Box
                sx={{
                  
                  ml: "32px",
                  
                }}
              >
                <Typography variant="bodyLargeBlack">{providerName}</Typography>
              </Box>
            </ThemeProvider>
          </Box>

          <Box
            id="FacitilitiesList"
            sx={{ ml: -2, fontFamily: "Lato", fontSize: 12, mb: 2.5 }}
            disablePadding
          >
            <List>
              <ListItem
                key={1}
                sx={{
                  mb: -1,
                  display: `${address ? "" : "none"}`,
                  "&:hover": {
                    backgroundColor: "transparent", // Remove the background color on hover
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#f29a26", width: 27, height: 27 }}>
                    <FmdGoodOutlinedIcon
                      sx={{
                        fontSize: 16,
                        color: "#fff",
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText sx={{ ml: -2.5 }}>
                  <ThemeProvider theme={Theme}>
                    <Typography variant="bodySmallRegural">{address} </Typography>
                  </ThemeProvider>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </>
    );
  }
);
