import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';

export const HorizontalMenu = ({ menuList, selectedHandler }) => {
  const [clickedId, setClickedId] = React.useState(-1);
  

  const handleClick = (i) => {
    setClickedId(i);
    selectedHandler(i);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-around"
      alignItems="flex-end"
      sx={{
        width: "46vw",
        height: "49px",
        boxShadow: "0 0 24px 0 rgba(177, 167, 188, 0.1)",
        borderRadius: "14px",
      }}
    >
      <ThemeProvider theme={Theme}>
        {menuList.map((item, i) => (
          <Box key={i} sx={{display:"flex", flexDirection:"column"}}>
          <Button
            key={i}
            name={item}
            onClick={() => handleClick(i)}
            sx={{
              ...Theme.textMenuButton,
              ...(i===clickedId && {color:"#363636"}),
            }}
            
          >
            {item}
          </Button>
          {(i===clickedId) && <Divider sx={{borderBottomWidth:5, bgcolor:"#2af9be"}}/>}
          
          </Box>
        ))}
      </ThemeProvider>
    </Stack>
  );
};
