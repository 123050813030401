const searchFunction =  (searchString,my_array,setFilterData) => {
    const strings = searchString.split(/\s+/)

    let filteredArray = [];

    filteredArray =  my_array?.filter((data)=>Object.values(data)?.includes(`${searchString}`.trim().toLowerCase()));

        my_array.forEach((elem,i) => {

            strings.forEach((item,i)=>{

                const regexp = new RegExp( item, 'gi' );

                if (elem.description?.match(regexp) ||
                    elem.shortDescription?.match(regexp) ||
                    elem.city?.match(regexp) ||
                    elem.provinceName?.match(regexp) ||
                    elem.districtName?.match(regexp) ||
                    elem.streetName?.match(regexp) ||
                    elem.buildNumber?.match(regexp) ||
                    elem.status?.match(regexp) ||
                    elem.owner?.match(regexp)
                ) {

                        const isFound = filteredArray.some(element=>{
                            if (element.id === elem.id) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        if (!isFound) {
                            filteredArray.push(elem);

                        }
                }

            })
        })


        my_array.forEach((elem,i) => {

            if (elem.description?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.shortDescription?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.city?.toLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.provinceName?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.districtName?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.streetName?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.buildNumber?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.status?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
                elem.owner?.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase())
            ) {

                    const isFound = filteredArray.some(element=>{
                        if (element.id === elem.id) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    if (!isFound) {
                        filteredArray.push(elem);
                    }
            }

        })

    setFilterData(filteredArray)
}

export default searchFunction;