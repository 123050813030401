import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { InputList } from "../Atoms/InputList";
import { SelectListItem } from "../Atoms/SelectListItem";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";

export const InputListCard = ({
  imageName = null,
  ImageUrl = null,
  itemList,
  selectItemList = null,
  onChangeActionHandler,
  onSelectFile,
  onImgDelete,
}) => {
  return (
    <Box
      id="mainInputListCard"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%"
      }}
    >
      <ThemeProvider theme={Theme}>
        
        {imageName && (
          <Box
            id="icon"
            sx={{
              width:"100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: "25px",
            }}
          >
            <Box marginBottom="25px">
              <Typography variant="body1" textTransform="uppercase">
                {imageName}
              </Typography>
            </Box>
            <IconButton
              onClick={() => document.getElementById("input_file").click()}
            >
              <Avatar
                sx={{
                  bgcolor: "#f2f2f2",
                  width: "200px",
                  height: "auto",
                  mr: 2,
                  borderRadius: "45px",
                }}
                src={ImageUrl}
                variant="square"
              ></Avatar>
            </IconButton>
            <input
              type="file"
              id="input_file"
              hidden
              onChange={(e) => onSelectFile(e)}
              accept="image/png, image/jpeg"
            />
            <Button onClick={onImgDelete}>Usuń logo</Button>
          </Box>
        )}

        <Box
          id="inputList"
          width="100%"
          sx={{
            
            mb: "25px",
          }}
        >
          {itemList.map((item, i) => (
            <InputList
              header={item.header}
              list={item.list}
              icon={item.icon}
              type={item.type}
              handlers={item?.handlers}
              onChangeActionHandler={onChangeActionHandler}
            />
          ))}

          {selectItemList && (
            <Box sx={{ display: "flex", flexDirection: "column", mt: "40px" }}>
              <Typography variant="body1" textTransform="uppercase">
                Godziny otwarcia
              </Typography>

              {selectItemList?.map((item, i) => (
                <SelectListItem
                  item={item}
                  changeHandler={onChangeActionHandler}
                />
              ))}
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
};
