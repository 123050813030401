import React, { useState } from "react";
import axios from "axios";
import { ButtonsFooter } from "../Atoms/ButtonFooter";
import { ShortSwitchListCard } from "../InputListCard/ShortSwitchListCard";
//import { InputListCardContainer } from "../InputListCard/InputListCardContainer";
import { InputSelectListCardContainer } from "../InputSelectListCard/InputSelectListCardContainer";

export const withEditableServices = (
  Component,
  profileId,
  initialServices,
  otherServices = [],
  wiredInternetList = [],
  fiberInternetList = [],
  wirelessInternetList = [],
  tvList = [],
  telephonyList = [],
  mobileTelephonyList = [],
  tvPacketsList = [],
  specialProducts,
  authToken,
  urlApi,
  authEpid,
  cancelActionHandler,
  refreshProfile
) => {
  return (props) => {
    
    const config = {
      headers: { accept: "*/*", Authorization: `Bearer ${authToken}` },
    };
    const [originalServices, setOriginalServices] = useState(initialServices);
    const [services, setServices] = useState(initialServices);
    const [servicesList, setServicesList] = useState();
    const [koalaTel, setKoalaTel] = useState(specialProducts[0]?.koalaTel);
    const [kameleonTV, setKamaleonTV] = useState(specialProducts[0]?.kameleonTV);

    const [otherServicesList, setOtherServicesList] = useState(otherServices);

    const [wiredInternetServicesList, setWiredInternetServicesList] =
      useState(wiredInternetList);
    const [fiberInternetServicesList, setFiberInternetServicesList] =
      useState(fiberInternetList);
    const [wirelessInternetServicesList, setWirelessInternetServicesList] =
      useState(wirelessInternetList);
    const [tvServicesList, setTvServicesList] = useState(tvList);
    const [telephonyServicesList, setTelephonyServicesList] =
      useState(telephonyList);
    const [mobileTelephonyServicesList, setMobileTelephonyServicesList] =
      useState(mobileTelephonyList);
    
      const [tvPacketsServicesList, setTvPacketsServicesList] =
      useState(tvPacketsList);

    const onChangeServices = (changes) => {
      setServices({ ...services, ...changes });
    };

    const setListOfServices = (
      tmpService,
      id,
      isDescription,
      key,
      value,
      setFunction
    ) => {
      if (tmpService[id]) {
        tmpService[id].productName = isDescription
          ? tmpService[id].productName
          : value;
        tmpService[id].productDescription = isDescription
          ? value
          : tmpService[id].productDescription;
        setFunction(tmpService);
      } else {
        setFunction([
          ...tmpService,
          {
            productCategory: key,
            productName: isDescription ? key : value,
            productDescription: isDescription ? value : "",
          },
        ]);
      }
    };

    const onChangeServicesList = (changes, id, isDescription) => {
      Object.entries(changes).map(([key, value]) => {
        if (key === "Internet mobilny") {
          const tmp = wiredInternetServicesList;
          setListOfServices(
            tmp,
            id,
            isDescription,
            key,
            value,
            setWiredInternetServicesList
          );
        }

        if (key === "Internet światłowodowy") {
          const tmp = fiberInternetServicesList;
          setListOfServices(
            tmp,
            id,
            isDescription,
            key,
            value,
            setFiberInternetServicesList
          );
        }

        if (key === "Internet radiowy") {
          const tmp = wirelessInternetServicesList;
          setListOfServices(
            tmp,
            id,
            isDescription,
            key,
            value,
            setWirelessInternetServicesList
          );
        }

        if (key === "Telewizja") {
          setListOfServices(
            tvServicesList,
            id,
            isDescription,
            key,
            value,
            setTvServicesList
          );
        }

        if (key === "Pakiety TV") {
          setListOfServices(
            tvPacketsServicesList,
            id,
            isDescription,
            key,
            value,
            setTvPacketsServicesList
          );
        }

        if (key === "Telefonia") {
          setListOfServices(
            telephonyServicesList,
            id,
            isDescription,
            key,
            value,
            setTelephonyServicesList
          );
        }

        if (key === "Telefonia mobilna") {
          setListOfServices(
            mobileTelephonyServicesList,
            id,
            isDescription,
            key,
            value,
            setMobileTelephonyServicesList
          );
        }

        if (key === "Inne") {
          setListOfServices(
            otherServicesList,
            id,
            isDescription,
            key,
            value,
            setOtherServicesList
          );
        }
      });

      setServicesList({ ...servicesList, ...changes });
    };

    const addMetadata = async (category, header, booleanValue1, booleanValue2, ) => {
      const body = [{ category: category, order: 0, key: header, booleanValue1:booleanValue1, booleanValue2:booleanValue2 }];
      axios
        .put(`${urlApi}/metadata/${profileId}`, body,
          config,
        )
        .then((res) => {
          const data = res.data;
          refreshProfile();
          //refreshProfile();
          //console.log(data);


        })
        .catch((err) => {
          //console.log(err);
        });
    }

    const onSaveServices = async () => {
      const convertedServices = {
        id: profileId,
        ePid: authEpid,
        ...(services["Internet mobilny"] !== undefined && {
          wiredInternetServices: services["Internet mobilny"],
        }),
        ...(services["Internet światłowodowy"] !== undefined && {
          fiberInternetServices: services["Internet światłowodowy"],
        }),
        ...(services["Pakiety TV"] !== undefined && {
          tvPackagesFlag: services["Pakiety TV"],
        }),
        ...(services["Internet radiowy"] !== undefined && {
          wirelessInternet: services["Internet radiowy"],
        }),
        ...(services.Telewizja !== undefined && {
          tvServices: services.Telewizja,
        }),
        ...(services.Telefonia !== undefined && {
          telephonyServices: services.Telefonia,
        }),
        ...(services["Telefonia mobilna"] !== undefined && {
          mobileServicesFlag: services["Telefonia mobilna"],
        }),
        ...(services.Inne !== undefined && {
          servicesOther: services.Inne,
        }),
        products: [
          ...fiberInternetServicesList,
          ...wirelessInternetServicesList,
          ...wiredInternetServicesList,
          ...tvServicesList,
          ...telephonyServicesList,
          ...mobileTelephonyServicesList,
          ...otherServicesList,
          ...tvPacketsServicesList,
        ],
      };
      const result = await axios.patch(
        `${urlApi}/profile`,
        { ...convertedServices },
        config
      );
      addMetadata("Usługi", "Produkty specjalne", kameleonTV, koalaTel )
      cancelActionHandler();
    };

    

    const onReset = () => {
      setServices(originalServices);
    };

    return (
      <InputSelectListCardContainer
        cardName="Edytuj Usługi"
        inputListCard1={
          <Component
            header="Rodzaje świadczonych usług"
            objectToUpdate={services}
            otherServicesList={otherServicesList}
            wiredInternetServicesList={wiredInternetServicesList}
            fiberInternetServicesList={fiberInternetServicesList}
            wirelessInternetServicesList={wirelessInternetServicesList}
            tvServicesList={tvServicesList}
            telephonyServicesList={telephonyServicesList}
            mobileTelephonyServicesList={mobileTelephonyServicesList}
            tvPacketsServicesList={tvPacketsServicesList}
            onChangeServices={onChangeServices}
            onChangeServicesList={onChangeServicesList}
          />
        }
        inputListCard2={
          <ShortSwitchListCard 
            header="Usługi Grupy MiŚOT"
            kameleonTV={kameleonTV}
            koalaTel={koalaTel}
            setKameleonTV={setKamaleonTV}
            setKoalaTel={setKoalaTel}
          />
        }
        buttonsFooter={
          <ButtonsFooter
            saveActionHandler={onSaveServices}
            cancelActionHandler={cancelActionHandler}
          />
        }
      />
    );
  };
};
