import * as React from 'react';
import { useState, useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, IconButton } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { InputIconTextItem } from './InputIconTextItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const TriviasList = ({ header, textList, label1 = "label1", label2 = "label2", name1, name2, addHandler, deleteHandler }) => {


    const [areMoreItemsVisible, setAreMoreItemsVisible] = useState(false);
    const [value2, setValue2] = useState(null);
    const [value1, setValue1] = useState(null);
    const [stateTextList, setStateTextList] = useState(textList);

    useEffect(() => {
        console.log(value2);

    }, [value2]);

    
    const addValues = () => {
        addHandler(value1, value2);
        /** 
        setStateTextList([{
            header: value1,
            text: value2,
        }, ...stateTextList])
        */
    }


    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography variant="body1" textTransform="uppercase">
                {header}
            </Typography>
            <InputIconTextItem
                label={label1}
                text=""
                //text2="text2"
                name={name1}
                type="text"
                disabled={false}
                icon={null}
                getValue={setValue1}
                delay={300}
                customWidth="86%"
                onChangeActionHandler={null}
            />
            <InputIconTextItem
                label={label2}
                text=""
                //text2="text2"
                multiLine = "true"
                minRows={10}
                maxRows={70}
                name={name2}
                type="text"
                disabled={false}
                icon={null}
                getValue={setValue2}
                delay={300}
                customWidth="86%"
                onChangeActionHandler={null}
            />
            <IconButton disabled={!value1 && !value2 ? true : false} onClick={addValues}>
                <AddCircleIcon />
            </IconButton>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                {stateTextList.map((el, i) => (
                    areMoreItemsVisible || i < 2 ?
                        <ListItem alignItems="flex-start">
                            <ListItemIcon >
                                <IconButton onClick={() => deleteHandler(el.rowId)}>
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography
                                    sx={{ display: 'inline' }}
                                    //component="span"
                                    variant="bodySmallBlack"

                                >
                                    {el.header}
                                </Typography>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            //component="span"
                                            variant="bodySmallRegural"

                                        >
                                            {el.text}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        : ""
                ))}
                
                <Button onClick={() => setAreMoreItemsVisible(!areMoreItemsVisible)} sx={{ mb: "32px", pl: 2, alignSelf: "flex-start" }} >
                    <ThemeProvider theme={Theme}>
                        <Typography variant="bodyMediumBlack" sx={{ textDecoration: "underline" }}>{!areMoreItemsVisible ? "Pokaz wszystkie" : "Schowaj"}</Typography>
                    </ThemeProvider>
                </Button>
                


            </List>
        </Box>
    );
}