import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { GoodListItem } from "../Atoms/GoodListItem";
import { GoodListItemAdmin } from "../Atoms/GoodListItemAdmin";
import { LeadListItem } from "../Atoms/LeadListItem";
import axios from "axios";
import { getUrl } from "../../requests";
import { Modal } from "@mui/material";
import { StatusChangeBox } from "../Lokalni2/StatusChangeBox";
import { MessageBox } from "../Lokalni2/MessageBox";
import { NotesHistoryBox } from "../Lokalni2/NotesHistoryBox";
import { useAuth0 } from "@auth0/auth0-react";
//import { useAuth0 } from "@auth0/auth0-react";

export const LeadManagementGrid = ({ auth, filterData = 0, leadList, context }) => {
  const [leads, setLeads] = useState([]);



  const [histiryitems, setHistoryItems] = useState([]);

  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const handleCloseChangeStatusModal = () => setChangeStatusModal(false);

  const [leadDetailsModal, setLeadDetailsModal] = useState(false);
  const handleCloseLeadDetailsModal = () => setLeadDetailsModal(false);

  const [notesModal, setNotesModal] = useState(false);
  const handleCloseNotesModal = () => setNotesModal(false);

  const [activeLead, setActiveLead] = useState("Oczekuje na operatora");
  const [activeLeadDetails, setActiveLeadDetails] = useState({});

  const [isLoading, SetIsLoading] = useState(false);
 

  const handleMenuStatusChangeOption = (lead) => {
    setActiveLead(lead);
    setChangeStatusModal(true);
  };

  const handleMenuLeadDetailsOption = (searchId) => {
    fetchLeadDatails(searchId);
    setLeadDetailsModal(true);
  };

  const handleMenuNotesOption = (lead) => {
    setActiveLead(lead);
    fetchNotesHistoryData(lead.searchId);
    setNotesModal(true);
  };

  const { logout} = useAuth0();

  const fetchLeadData = () => {
    if (context) {
    SetIsLoading(true);
    const url = context==="ADMIN" ? `${getUrl()}/api/v1/lead` : `${getUrl()}/api/v1/lead/epid/${context}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((res) => {
        const data = res.data;
        setLeads(data);
        SetIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        SetIsLoading(false);
        if (err.response.status === 401 || err.response.status === 500 || err.response.status === 403) {
          alert('Twoja sesja wygasła, za chwile zostaniesz wylogowany z systemu i przekierowany na stronę główną. Aby ponownie skorzystać z systemu musisz się ponownie zalogować ');
          logout({
              logoutParams: { returnTo: `${window.location.origin}` },
          });
         }
      });
    }
  };

  const fetchNotesHistoryData = (searchId) => {
    axios
      .get(`${getUrl()}/api/v1/lead/note/${searchId}`, {
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((res) => {
        const data = res.data;
        setHistoryItems(data);
      })
      .catch((err) => {
        console.log(err);
        
      });
  };

  const fetchLeadDatails = (searchId) => {
    axios
      .get(`${getUrl()}/api/v1/lead/${searchId}`, {
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((res) => {
        const data = res.data;
        setActiveLeadDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeLeadStatus = (searchId, newStatus) => {
    const body = {
      leadStatus: newStatus,
      epid: context,
    };
    axios
      .post(`${getUrl()}/api/v1/lead/${searchId}`, body, {
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((res) => {
        const data = res.data;
        fetchLeadData();
        
        /** 
        const histItem = `Zmiana statusu leada na: ${newStatus}, wprowadzona przez: ${context}`
        if (newStatus !== "Oczekuje na operatora" && newStatus !== "Zrealizowane") {
          addHistoryItem(histItem, searchId);
        }
        */
        handleCloseChangeStatusModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addHistoryItem = (historyItem, searchID) => {
    const body = {
      searchId: searchID,
      note: historyItem,
      epid: context,
    };
    axios
      .post(`${getUrl()}/api/v1/lead/note`, body, {
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((res) => {
        const data = res.data;
        fetchNotesHistoryData(activeLead.searchId);
      })
      .catch((err) => {
        console.log(err);
      });
  };




  useEffect(() => {
    if (context) {
      fetchLeadData();
    }
  }, [context]);

  if (isLoading) {
    return <div>Ładuje dane ...</div>;
}

  return (
    <Box
      id="good-points1"
      sx={{ overflow: "auto", height: "95vh", mt: "32px" }}
    >
      {filterData === 0 && leads.length > 0
        ? leads.map((lead, i) => {
          let theDate = new Date(`${lead.createdDate}`);

          return (
            <Box
              key={i}
              sx={{
                margin: "10px",
              }}
            >
              <LeadListItem
                isReviewer={context==="ADMIN" ? true : false}
                misot={lead?.epid}
                date={theDate.toLocaleDateString("pl-pl", {
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
                location={`${lead.address?.slice(0, 50)}`}
                handleTime={lead?.hoursLeft}
                status={
                  lead.leadStatus === "Przyjęte przez operatora" &&
                    context === lead?.epid
                    ? "Przyjęte przeze mnie"
                    : lead.leadStatus
                }
                note={`${lead?.notes} notatki, ${lead?.newNotes} nowych`}
                onClickEdit={() => {
                  //handleOpenEditDobro(i);
                }}
                editObject={{}}
                onClickHistory={() => { }}
                onClickPreview={() => {
                  //setPreview(true);
                  //handleOpenEditDobro(i);
                }}
                onClickDelete={() => {
                  //editEntity.current = { ...dobro };
                  //setConfirmation((val) => !val);
                }}
                handleAcceptLead={() => {
                  changeLeadStatus(lead.searchId, "Przyjęte przez operatora");
                }}
                handleRejectLead={() => {
                  changeLeadStatus(lead.searchId, "Odrzucone");
                }}
                itemList={
                  lead.leadStatus === "Oczekuje na operatora"
                    ? ["Zmień status", "Podgląd leada", "Notatki"]
                    : ["Zmień status", "Podgląd leada", "Notatki"]
                }
                showvertIcon={false}
                onClickStatusChange={() => {
                  handleMenuStatusChangeOption(lead);
                }}
                onClieckLeadView={() => {
                  handleMenuLeadDetailsOption(lead.searchId);
                }}
                onClickNotesView={() => {
                  handleMenuNotesOption(lead);
                }}
              />
            </Box>
          );
        })
        : "Brak wprowadzonych leadów"}
      <Modal
        open={changeStatusModal}
        onClose={handleCloseChangeStatusModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <StatusChangeBox
            headerText="Zmień status leada"
            info="W tym miejscu możesz zmienić status leada, jeżeli z jakichkolwiek powodów nie możesz go obsłużyć"
            statusList={[
              {
                label: "Oczekuje na operatora",
                value: "Oczekuje na operatora",
              },
              {
                label: "Przyjęte przez operatora",
                value: "Przyjęte przez operatora",
              },
              { label: "Zrealizowane", value: "Zrealizowane" },
            ]}
            currentStatus={{
              label: activeLead.leadStatus,
              value: activeLead.leadStatus,
            }}
            cancelHandler={handleCloseChangeStatusModal}
            acceptHandler={(nStatus) => {
              changeLeadStatus(activeLead.searchId, nStatus);
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={leadDetailsModal}
        onClose={handleCloseLeadDetailsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <MessageBox
            headerText="Podgląd leada"
            info="Szczegółowe informacje o kontakcie"
            properties={[
              {
                label: "Imię i nazwisko",
                value: activeLeadDetails.name,
              },
              {
                label: activeLeadDetails.phone
                  ? "Numer telefonu"
                  : activeLeadDetails.mail
                    ? "Adres email"
                    : "",
                value: activeLeadDetails.phone
                  ? activeLeadDetails.phone
                  : activeLeadDetails.mail
                    ? activeLeadDetails.mail
                    : "",
              },

              {
                label: "Adres instalacji",
                value: activeLeadDetails.address,
              },
              {
                label: "Zainteresowany internetem",
                value: activeLeadDetails.internet ? "Tak" : "Nie",
              },
              {
                label: "Zainteresowany telewizją",
                value: activeLeadDetails.tv ? "Tak" : "Nie",
              },
              {
                label: "Zainteresowany telefonem",
                value: activeLeadDetails.tel ? "Tak" : "Nie",
              },
              {
                label: "Prowadzi firmę",
                value: activeLeadDetails.biz ? "Tak" : "Nie",
              },
              {
                label: activeLeadDetails.companyName ? "Nazwa firmy" : "",
                value: activeLeadDetails.companyName ? activeLeadDetails.companyNam: "",
              },
              {
                label: activeLeadDetails.companyName ? "NIP" : "",
                value: activeLeadDetails.nip ? activeLeadDetails.nip: "",
              },

            ]}
            onNextButton={handleCloseLeadDetailsModal}
          />
        </Box>
      </Modal>

      <Modal
        open={notesModal}
        onClose={handleCloseNotesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <NotesHistoryBox
            headerText="Notatki"
            info={`Dotyczące adresu: ${activeLead.address}`}
            notesList={histiryitems}
            onCloseButton={handleCloseNotesModal}
            addNotesHandler={(newHistoryItem) => {
              addHistoryItem(newHistoryItem, activeLead.searchId);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};
