import * as React from "react";
import { createTheme } from "@mui/material/styles";
import "@fontsource/lato";
import "@fontsource/lato/900.css";



export const NaviStyle = {
  fontFamily: "Poppins",
  fontSize: 12,
  fontWeight: 900,
  color: "#9b9b9b",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.25,
  letterSpacing: 0.11,
  textTransform: "initial",
  mr: -1.5,
};

export const Theme = createTheme({
  spacing: 1,
  breakpoints: {
    values: {
      mobile: 360,
      tablet: 768,
      tablet_new: 950,
      laptop: 1280,
      laptop_new: 1600,
      desktop: 1920,
    },
  },

  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: "#2bfece",
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: "#2bfece",
      
    }
  },

  typography: {
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.11,
      color: "#9b9b9b",
      textTransform: "uppercase",

      allVariants: {
        "&": {
          display: "inline",
        },
      },
    },

    

    h2: {
      fontFamily: "Poppins",
      fontWeight: 900,
      fontSize: 48,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.17,
      letterSpacing: "normal",
      color: "#363636",
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: 34,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.06,
      letterSpacing: "normal",
      color: "#363636",
      textDecoration: "underline",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      color: "#343433",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: 24,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.18,
      color: "#343433",
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: 0.11,
      color: "#363636",
      // textDecoration: "underline",
      textTransform: "uppercase",
    },

    body1: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.07,
      letterSpacing: 0.13,
      color: "#363636",
      "&:hover": {
        opacity:1
      },
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.94,
      letterSpacing: 0.14,
      color: "#363636",
    },
    subtitle: {
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: 0.15,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#363636",
        borderColor: "#363636",
      },
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.83,
      letterSpacing: 0.16,
      color: "#363636",
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#363636",
    },
    bodyMediumBlack: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#363636",
      "&:hover": {
        opacity:1
      },
    },
    bodyMediumRegural: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#363636",
      
    },
    bodyMediumReguralLinks: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#363636",
      "&:hover": {
        cursor: "pointer",
        color: "#363636",
        borderColor: "#363636",
      },
    },

    bodyMediumReguralSp: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#363636",
      
    },

    bodyVeryLargeBlack: {
      fontFamily: "Poppins",
      fontSize: 24,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.42,
      letterSpacing: "0.22px",
      color: "#363636",
      
      
    },

    bodyLargeBlack: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.14,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#363636",
        borderColor: "#363636",
      },
    },

    bodyLargeBlackNavBarLogo: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.14,
      color: "#363636",
      "&:hover": {
        cursor: "pointer",
        color: "#363636",
        borderColor: "#363636",
      },
    },

    bodyLargeBlackHomeMainInfoBox: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.14,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#363636",
        borderColor: "#363636",
      },
    },



    bodySmallRegural: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      color: "#363636",
      "&:hover": {
        
      },
    },

    bodySmallReguralAboutUsCard: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        // color: "#fff",
        // borderColor: "#363636",
      },
    },

    bodySmallReguralAboutUsCardRole: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "gray",
        // borderColor: "#363636",
      },
    },

    bodySmallReguralAboutUsCardName: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      // color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#28eabe",
        // borderColor: "#363636",
      },
    },

    bodySmallBlack: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      color: "#363636",
    },

    bodySmallBlackForm: {
      fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 900,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      color: "#363636",
      "&:hover": {
        cursor: "default",
        color: "#363636",
        borderColor: "#363636",
      },
    },

    button: {
      fontStyle: "italic",
    },
  },

  outlinedGreenButton: {
    borderRadius: 16,
    width: 211,
    height: 44,
    border: 2,
    borderColor: "#363636",
    color: "#363636",
    backgroundColor: "#2bfece",
    fontSize: 16,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: 4.38,
    letterSpacing: 0.14,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  OrangeButton: {
    borderRadius: 16,
    minWidth: 112,
    height: 44,
    border: "solid 2px #f29a26",
    borderColor: "#f29a26",
    color: "#fff",
    backgroundColor: "#f29a26",
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 900,
    lineHeight: 4.38,
    letterSpacing: 0.14,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  outlinedOrangeButton: {
    borderRadius: 16,
    minWidth: 112,
    height: 44,
    border: "solid 2px #f29a26",
    borderColor: "#f29a26",
    color: "#f29a26",
    backgroundColor: "#fff",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  WhiteButton: {
    border:0,
    borderRadius: "8px",
    width: "140px",
    height: "42px",
    color: "#363636",
    backgroundColor: "#fff",
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: 800,
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.14px",
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      
    },
  },

  containedGreenButton: {
    borderRadius: 16,
    minWidth: 112,
    height: 44,
    border: 0,
    borderColor: "#363636",
    color: "#363636",
    backgroundColor: "#2bfece",
    fontSize: 16,
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 900,
    lineHeight: 4.38,
    letterSpacing: 0.14,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  outlinedCancelButton: {
    borderRadius: "24px",
    width: 95,
    height: 33,
    border: "solid 2px",
    borderColor: "#fc0d1b",
    color: "#fc0d1b",
    backgroundColor: "#fff",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  outlinedCancelGrayButton: {
    borderRadius: "24px",
    width: 112,
    height: 43,
    border: "solid 2px",
    borderColor: "#e6e6e6",
    color: "#363636",
    backgroundColor: "#fff",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  outlinedAcceptBlackButton: {
    borderRadius: "24px",
    width: 112,
    height: 43,
    border: "solid 2px",
    borderColor: "#363636",
    color: "#363636",
    backgroundColor: "#fff",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  outlinedAcceptButton: {
    borderRadius: "24px",
    width: 106,
    height: 33,
    border: "solid 2px",
    borderColor: "#03cb0f",
    color: "#03cb0f",
    backgroundColor: "#fff",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
      borderColor: "#363636",
    },
  },

  textMenuButton: {
    
    color: "#9b9b9b",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: 1.7,
    letterSpacing: 0.13,
    textTransform: "initial",
    "&:hover": {
      cursor: "pointer",
      color: "#363636",
    },
    
  },

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ff4400",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
