import fetch from "node-fetch";

export const getApplicationData = () => {
    return fetch(`${getUrl()}/api/v1/system/application`);
}

export const getLokalni = async queryStr => {
    return fetch(`${getUrl()}${queryStr}`);
    };


export const getMapAPiKey =  () => {
    return fetch( `${getUrl()}/api/v1/system/frontendConfig`);
}

export const autoSuggestFetch = (queryStr) => {
    return fetch(`${getUrl()}/api/v1/places/autocomplete?zapytanie=${queryStr}`)
}

export const getUrl = () => {
    const url1= `${window.location.protocol}//${window.location.host}`;
    const url=url1.replace(/:3000/i,':8080');
    return url;
}
