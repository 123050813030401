import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { Theme, NaviStyle } from "../styles/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Avatar from "@mui/material/Avatar";
import { GeneralButton } from "../Button/GeneralButton";
import { Divider } from "@mui/material";
import koalaTelImg from "../../images/marketing/koala.png";
import kameleonTvImg from "../../images/marketing/kameleon.png";

const chipColors = ["#db2251", "#ba1fa5", "#2a45ad", "#18a75c"]

const ServiceListItem = ({ category, descriptions, other }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [Theme.breakpoints.down("desktop")]: {
          width: "100%",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "50%",
        },
      }}
    >
      <Box>
        <ThemeProvider theme={Theme}>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mb: "9px"
            }}
          >
            <Typography variant="bodySmallBlack">
              <Box width="100%">{category}</Box>
            </Typography>
          </Box>

          <Box sx={{
            display: "flex",
            [Theme.breakpoints.down("desktop")]: {
              flexDirection: "column",

            },
            [Theme.breakpoints.up("desktop")]: {
              flexDirection: "row",

            },
          }}>
            {descriptions.map((el, idx) => (
              <Typography variant="bodySmallRegural">
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ backgroundColor: chipColors[idx], width: "8px", borderRadius: "4px", mr: "9px", mb: "9px" }} ></Box>

                  <Box
                    sx={{
                      mb: "9px",

                      [Theme.breakpoints.up("desktop")]: {
                        width: "140px",

                      },

                    }}>

                    {category === "Internet mobilny" && el
                      ? `${el.productName}  ${el.productDescription} Mb/s`
                      : category === "Internet radiowy" && el
                        ? `${el.productName}  ${el.productDescription} Mb/s`
                        : category === "Internet światłowodowy" && el
                          ? `${el.productName}  ${el.productDescription} Mb/s`
                          : category === "Telefon domowy" && el
                            ? `${el.productName}  ${el.productDescription} Darmowych minut`
                            : category === "Telefon komórkowy" && el
                              ? `${el.productName}  ${el.productDescription == 0
                                ? "-bez limitu"
                                : el.productDescription && "GB"
                              }`
                              : category === "Telewizja" && el
                                ? `${el.productName}  ${el.productDescription} Kanałów`
                                : `${el.productName}  ${el.productDescription}`}

                  </Box>
                </Box>
              </Typography>
            ))}
          </Box>

        </ThemeProvider>
      </Box>
      {category !== "Other" && (
        <Box sx={{}}>
          <ThemeProvider theme={Theme}>
            <Typography variant="bodyLargeBlack">{other}</Typography>
          </ThemeProvider>
        </Box>
      )}
    </Box>
  );
};

export const ProviderServicesBox = ({
  services,
  kameleonTV,
  koalaTel,
  //showModalMapHandler,
  //redirectToDetailsHandler,
  //providerId,
}) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(`koalaTel: ${koalaTel}, kameleonTV: ${kameleonTV}`)

  return (
    <Box
      sx={{
        //height:"100%",
        //mb: 2,
        [Theme.breakpoints.down("tablet")]: {
          width: "91vw",
          mt: -3,
        },
        [Theme.breakpoints.up("tablet")]: {
          width: "47vw",
          //minHeight: "367px",
          borderLeft: 2,
          borderColor: "#e3e3e3",
        },
        [Theme.breakpoints.up("laptop")]: {
          width: "24.5vw",
          minHeight: "380px",
        },
        [Theme.breakpoints.up("desktop")]: {
          width: "35.6vw",
          minHeight: "380px"
        },
      }}
    >
      <Box
        borderColor="#e3e3e3"
        sx={{
          //minHeight:"392px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          [Theme.breakpoints.down("tablet")]: {
            borderTopColor: "white",
            borderBottomRightRadius: "24px",
            borderBottomLeftRadius: "24px",
          },
          [Theme.breakpoints.up("tablet")]: {
            borderBottomRightRadius: "24px",
            borderTopRightRadius: "24px",
            minHeight: "300px",
          },


        }}
      >
        <TabContext value={value} height="100%">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight:"392px",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", height: "100%" }}
            >
              <TabList
                onChange={handleChange}
                sx={{
                  "& button.Mui-selected": { color: "#363636" },
                }}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  sx: {
                    width: 10,
                    backgroundColor: "#18a75c",
                    height: 4,
                  },
                }}
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab label="Internet" value="1" sx={NaviStyle} />
                <Tab label="Telewizja" value="2" sx={NaviStyle} />
                <Tab label="Telefon" value="3" sx={NaviStyle} />
                <Tab label="Usługi" value="4" sx={NaviStyle} />
              </TabList>
            </Box>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "space-between" }}>
              <TabPanel value="1">
                <List sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", }}>
                  {services
                    .filter((item) => item.label.includes("Internet"))
                    .map((filtredItem, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent", // Remove the background color on hover
                          },
                        }}
                      >
                        {filtredItem.label.length > 0 ? (
                          <ServiceListItem
                            key={i}
                            category={filtredItem.info.length > 0 ? filtredItem.label : ""}
                            descriptions={filtredItem.info}
                            other={filtredItem.other}
                          />
                        ) : (
                          "Brak danych"
                        )}
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
              <TabPanel value="2">
              {kameleonTV &&
                  <Avatar variant="rounded" sx={{ width: "124px", height: "19px", ml: "16px" }} src={kameleonTvImg} />
                }
                <List sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", }}>
                  {services
                    .filter((item) => item.label.includes("Telewizja") || item.label.includes("Pakiety"))
                    .map((filtredItem, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent", // Remove the background color on hover
                          },
                        }}
                      >
                        <ServiceListItem
                          category={filtredItem.info.length > 0 ? filtredItem.label : ""}
                          descriptions={filtredItem.info}
                          other={filtredItem.other}
                        />
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
              <TabPanel value="3">
              {koalaTel &&
                  <Avatar variant="rounded" sx={{ width: "124px", height: "19px", ml: "16px" }} src={koalaTelImg} />
                }
                <List sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", }}>
                  {services
                    .filter((item) => item.label.includes("Telefon"))
                    .map((filtredItem, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent", // Remove the background color on hover
                          },
                        }}
                      >
                        <ServiceListItem
                          category={filtredItem.info.length > 0 ? filtredItem.label : ""}
                          descriptions={filtredItem.info}
                          other={filtredItem.other}
                        />
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
              <TabPanel value="4">
                <List sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", }}>
                  {services
                    .filter((item) => item.label.includes("Inne"))
                    .map((filtredItem, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent", // Remove the background color on hover
                          },
                        }}
                      >
                        <ServiceListItem
                          category={filtredItem.info.length > 0 ? filtredItem.label : ""}
                          descriptions={filtredItem.info}
                          other={filtredItem.other}
                        />
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
            </Box>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};
ProviderServicesBox.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      info: PropTypes.string,
      other: PropTypes.string,
    })
  ),
};
