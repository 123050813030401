import React from "react";
import PropTypes from "prop-types";
import "./SearchIconButton.css";
import { IoSearchCircle } from "react-icons/io5";
import Avatar from "@mui/material/Avatar";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SearchIcon from '@mui/icons-material/Search';

export const SearchIconButton = ({ color, ...props }) => {
  return (
    <>
      {/*<IoSearchCircle className="Ellipse-88"*/}
      {/*  style={color && { color }}*/}
      {/*  {...props}*/}
      {/*/>*/}

        <Avatar sx={{bgcolor: "#18a75c", width: '2em', height: '2em'}}>
            <SearchIcon
                sx={{
                    fontSize: 24,
                    color: "#fff",
                }}
            />
        </Avatar>
    </>
  );
};

// eslint-disable-next-line react/no-typos
SearchIconButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};
